import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  Alert,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IProjectReportInfectiousComplianceControl,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useLoading,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import { CatalogService } from "src/services";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import {
  IGeneralMatrix,
  IGeneralMatrixItems,
} from "src/ts/interfaces/study/generalMatrix";
import generalMatrixService from "src/services/study/generalMatrixService";
import GeneralMatrixGrid from "./components/GeneralMatrixGrid";
import getProjectService from "src/services/project/getProjectService";
import SteadyStateDialog from "../components/SteadyState";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { HvacOutlined, ViewModuleOutlined } from "@mui/icons-material";
import {
  MEASUREMENTTYPE,
  ROLES,
  STEPSVALUES,
  TYPE_DEVICE,
} from "src/constants";
import getProjectSingleService from "src/services/getProjectSingleService";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import { FooterButtons } from "../components/FooterButtons";
import generalMatrixItemsService from "src/services/study/generalMatrixItemsService";
import DimensionsMatrix from "../components/DimensionsMatrix";
import { IDimensions } from "src/ts/interfaces/study/velgridMatrix";
import ResultsDataMatrix from "../components/ResultsDataMatrix";
import SubStudyTypeSelect from "src/components/studies/SubStudyTypeSelect";
import DampersComponent from "../components/DampersComponent";

const initialValues: IGeneralMatrix = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  isRectangleType: false,
  isRoundType: false,
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  comments: "",
  nocVisual: false,
  nocActual: false,
  numberOfDucts: 0,
  systemTotal: false,
  conditionId: 0,
  temperatureCompensated: false,
  barometricalyCompensated: false,
  centerlineStaticPressureId: 0,
  x: 0,
  y: 0,
  relativeHumidityBool: false,
  wetBulbFBool: false,
  dewPointBool: false,
  isActualLocalBarometricPressure: false,
  isComplete: false,
  generalReportTypeId: 0,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  isProjectDisabled: false,
  useSweepMethod: false,
  totalHeat: false,
  typeDevice: "0",
  nocRaNumber: 0,
  nocOaNumber: 0,
  nocMaNumber: 0,
  nocExhaustNumber: 0,
  basAllNotApplicable: false,
  basAllNotAccessible: false,
  basOADamperpositionNotApplicable: false,
  basOADamperpositionNotAccessible: false,
  basOADamperposition: 0,
  basRADamperpositionNotApplicable: false,
  basRADamperpositionNotAccessible: false,
  basRADamperposition: 0,
  basMADamperpositionNotApplicable: false,
  basMADamperpositionNotAccessible: false,
  basMADamperposition: 0,
  basAHUExhaustDamperNotApplicable: false,
  basAHUExhaustDamperNotAccessible: false,
  basAHUExhaustDamper: 0,
  visualAllNotApplicable: false,
  visualAllNotAccessible: false,
  visualOADamperpositionNotApplicable: false,
  visualOADamperpositionNotAccessible: false,
  visualOADamperposition: 0,
  visualRADamperpositionNotApplicable: false,
  visualRADamperpositionNotAccessible: false,
  visualRADamperposition: 0,
  visualMADamperpositionNotApplicable: false,
  visualMADamperpositionNotAccessible: false,
  visualMADamperposition: 0,
  visualAHUExhaustDamperNotApplicable: false,
  visualAHUExhaustDamperNotAccessible: false,
  visualAHUExhaustDamper: 0,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

interface IMessagePsychrometric {
  text: string;
  color: "info" | "warning" | "success" | "error";
}

const GeneralMatrix = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const [mesurement, setMesurement] = useState("");
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string; code: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [loadingCorrect, setLoadingCorrect] = useState(false);
  const [titleReport, setTitleReport] = useState("");
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [selectRadioButton] = useState(initialValues.typeDevice);
  const [stepContinuos, setStepContinuos] = useState("step1");
  const [showSteady, setShowSteady] = useState(false);
  const [generalMatrix, setGeneralMatrix] = useState<IGeneralMatrix | null>();
  const [project, setProject] = useState<ProjectDTO | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [saveItems, setSaveItems] = useState<boolean>(false);
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataX, setDataX] = useState(0);
  const [dataY, setDataY] = useState(0);
  const [messagePsychrometric, setMessagePsychrometric] =
    useState<IMessagePsychrometric>({ text: "", color: "info" });
  const [disableEditData, setDisableEditData] = useState(false);
  const [generalaMatrixItemChanges, setGeneralaMatrixItemChanges] =
    useState(false);
  const [changeMatrixButton, setChangeMatrixButton] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);
  const [items, setItems] = useState<IGeneralMatrixItems[][]>();
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: 0,
    height: 0,
  });
  const [customValidation, setCustomValidation] = useState(false);

  const {
    startRequest: startChangeType,
    endRequest: endChangeType,
    isLoading: loadingchangeType,
  } = useLoading();

  const typeOfTerminal = [
    {
      key: "0",
      labelCustom: (
        <>
          <Stack direction={"row"}>
            <ViewModuleOutlined />
            <Typography mt={0.2}>
              <b>Rectangular</b>
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      key: "1",
      labelCustom: (
        <>
          <Stack direction={"row"} spacing={1}>
            <HvacOutlined />
            <Typography mt={0.2}>
              <b>Round</b>
            </Typography>
          </Stack>
        </>
      ),
      disabled: user?.role === ROLES.Training,
    },
  ];

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(generalMatrixService.update, {
      onSuccess: async (result) => {
        const valuePrevious = values;
        setValues({
          ...result,
          typeDevice: valuePrevious.isRectangleType ? "0" : "1",
          basOADamperposition: valuePrevious?.basOADamperposition ?? 0,
          basRADamperposition: valuePrevious?.basRADamperposition ?? 0,
          basMADamperposition: valuePrevious?.basMADamperposition ?? 0,
          basAHUExhaustDamper: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualOADamperposition: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualRADamperposition: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualMADamperposition: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualAHUExhaustDamper: valuePrevious?.basAHUExhaustDamper ?? 0,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh, isLoading: isLoadingUpdate } = useAsyncQuery(
    generalMatrixService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
          typeDevice: values?.isRectangleType ? "0" : "1",
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    }
  );

  const { execute: executeRefreshPsyco, isLoading: isLoadingRefresh } =
    useAsyncQuery(generalMatrixService.update, {
      onSuccess: async (result) => {
        setValues({
          ...result,
          typeDevice: values?.isRectangleType ? "0" : "1",
        });
        await validateTotalCFM(result?.totalCFM ?? 0);
        await calcPsycometrics(result?.totalCFM!, result);
      },
    });

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (stepContinuos === "step2") {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .isRequired("Airflow Temperature For Energy Calculation Is Required")
        .toString();
    } else {
      temp.dryBulbF = "";
    }

    fieldValues.dimensionWidth === 0
      ? (temp.dimensionWidth = "This field is Required")
      : (temp.dimensionWidth = "");

    fieldValues.dimensionHeight === 0 && values?.isRectangleType
      ? (temp.dimensionHeight = "This field is Required")
      : (temp.dimensionHeight = "");

    setErrors({
      ...temp,
    });
    const hasNonEmptyValue = Object.values(temp).some((x) => x !== "");

    return hasNonEmptyValue;
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IGeneralMatrix>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IGeneralMatrix>(
    generalMatrixService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          let result = await formulaSystem.setSystem(
            dataResult?.projectId ?? values.projectId
          );
          setFormulas(result);
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult?.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = result.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }
          if (!dataResult.isRectangleType && !dataResult.isRoundType) {
            dataResult.isRectangleType = true;
            setValues({ ...values });
          }
          if (
            !dataResult.relativeHumidityBool &&
            !dataResult.wetBulbFBool &&
            !dataResult.dewPointBool
          ) {
            dataResult.relativeHumidityBool = true;
          }

          setDimensions({
            width: dataResult.dimensionWidth ?? 0,
            height: dataResult.dimensionHeight ?? 0,
          });
          setDataX(dataResult.readingsX === null ? 0 : dataResult.readingsX);
          setDataY(dataResult.readingsY === null ? 0 : dataResult.readingsY);

          setValues({
            ...dataResult,
            dimensionTotal:
              dataResult?.dimensionTotal !== 0 &&
              dataResult?.dimensionTotal !== null
                ? dataResult?.dimensionTotal
                : 0,
            typeDevice: dataResult.isRectangleType ? "0" : "1",
            basOADamperposition: dataResult?.basOADamperposition ?? 0,
            basRADamperposition: dataResult?.basRADamperposition ?? 0,
            basMADamperposition: dataResult?.basMADamperposition ?? 0,
            basAHUExhaustDamper: dataResult?.basAHUExhaustDamper ?? 0,
            visualOADamperposition: dataResult?.basAHUExhaustDamper ?? 0,
            visualRADamperposition: dataResult?.basAHUExhaustDamper ?? 0,
            visualMADamperposition: dataResult?.basAHUExhaustDamper ?? 0,
            visualAHUExhaustDamper: dataResult?.basAHUExhaustDamper ?? 0,
          });
          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(dataResult?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
          setMesurement(measurementSystemResponse.data);
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: deleteMatrix } = useAsyncQuery<IProjectReport>(
    generalMatrixItemsService.deleteMatrixItem
  );

  const { execute: executeSaveItem } = useAsyncQuery(
    generalMatrixItemsService.saveItems
  );

  const [activeStep, setActiveStep] = useState<number>(
    stepContinuos === "step1" ? 0 : 1
  );

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeReport(id, params.code);
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    switch (params.code) {
      case "GEMA":
        setTitleReport("General Matrix Report");
        break;
      case "HOWI":
        setTitleReport("Hot Wire Anemometer Report");
        break;
      case "ROVA":
        setTitleReport("Rotating Vane Anemometer Report");
        break;
      case "AIFO":
        setTitleReport("Air Foil Report");
        break;
    }
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        const generalMatrixResponse = await generalMatrixService.getById(id);
        setGeneralMatrix(generalMatrixResponse.data);
        await getParentReportIsComplete(
          generalMatrixResponse.data.id,
          params.code ?? ""
        );
        await getProject(generalMatrixResponse.data.projectId);
        await getProjectReport(
          generalMatrixResponse.data.id,
          generalMatrixResponse.data.reportTypeId,
          generalMatrixResponse.data.projectId
        );
        await getInfectiousControlData(generalMatrixResponse.data.id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };
    if (values.dryBulbF && values?.dryBulbF > 0 && id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dryBulbF]);

  useEffect(() => {
    let dimensionTotal = undefined;
    if (values.isRectangleType) {
      dimensionTotal = formulas?.sqft(
        (values?.dimensionWidth ?? 0) * (values?.dimensionHeight ?? 0)
      );
    } else if (values.isRoundType) {
      dimensionTotal = formulas?.sqft(
        ((values?.dimensionWidth ?? 0) / 2) *
          ((values?.dimensionWidth ?? 0) / 2) *
          3.14159265359
      );
    }
    setValues({
      ...values,
      dimensionTotal: Number(dimensionTotal?.toFixed(3)),
      typeDevice: values?.isRectangleType ? "0" : "1",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values.isRectangleType,
    values.isRoundType,
  ]);

  useEffect(() => {
    if (!values?.totalHeat) {
      setMessagePsychrometric({ text: "", color: "info" });
    }
  }, [values?.totalHeat]);

  useEffect(() => {
    if (!disableStudy && dataReport) {
      onBlurCalcPsycometrics();
    }
    setLoadingCorrect(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  useEffect(() => {
    if (values?.totalHeat && dataReport) {
      setLoadingCorrect(true);
      calcPsycometrics(values?.totalCFM!, values);
    } else {
      setLoadingCorrect(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.temperatureCompensated,
    values?.barometricalyCompensated,
    dataReport,
  ]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === "step1" ? 0 : 1);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const calcTotal = async (totalItems: number) => {
    if (values && values.id > 0) {
      let newValues = { ...values };
      newValues.totalVelocity = totalItems;
      newValues.avgVelocity =
        totalItems / ((newValues.readingsX ?? 0) * (newValues.readingsY ?? 0));
      setValues({
        ...newValues,
      });
      if (!values?.isComplete)
        await executeRefreshPsyco(newValues.id, newValues);
    }
  };

  const saveOnBlurHandler = async (e: any) => {
    const newValues = {
      ...values,
      [e.target.name]: e.target.value,
    };
    setValues({ ...newValues });
    await saveItemsMatrix();
    executeRefresh(newValues.id, newValues);
    executeRefreshPsyco(values.id, newValues);
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6)) {
      setTotalCFM(totalCFM);
      if (totalCFM !== 0 && values?.totalCFM !== null) setLoadingCorrect(true);
    } else {
      setLoadingCorrect(false);
    }
  };

  const totalHeatHandler = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
      sensibleHeat: 0,
      btuh: 0,
    });
  };

  const onBlurCalcPsycometrics = () => {
    calcPsycometrics(values?.totalCFM!, values);
  };

  const onBluChange = (e: any) => {
    const newValues = {
      ...values,
      [e.target.name]: e.target.value,
    };
    calcPsycometrics(values?.totalCFM!, newValues, e, true);
  };

  const calcPsycometrics = async (
    total: number,
    newValues: any,
    e?: any,
    calc?: boolean
  ) => {
    setLoadingCorrect(true);
    if (disableStudy) return;
    let humidityMeasuredAs = "";
    let humidityValue = 0;
    if (newValues?.relativeHumidityBool) {
      humidityMeasuredAs = "RH";
      humidityValue = newValues?.relativeHumidity ?? 0;
    }
    if (newValues?.wetBulbFBool) {
      humidityMeasuredAs = "WB";
      humidityValue = newValues?.wetBulbF ?? 0;
    }
    if (newValues?.dewPointBool) {
      humidityMeasuredAs = "DP";
      humidityValue = newValues?.dewPoint ?? 0;
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: newValues.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: newValues.dryBulbF!,
      totalCFM: total,
    };

    if (
      newValues?.totalHeat &&
      !isNaN(humidityValue) &&
      !isNaN(newValues?.dryBulbF ?? 0) &&
      !isNaN(newValues?.totalCFM ?? 0)
    ) {
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      setMessagePsychrometric({ text: "", color: "info" });
      if (stepContinuos !== "step1") {
        if (
          psychrometricRes.psychrometric.message !== null &&
          psychrometricRes.psychrometric.enthalpy === 0
        )
          setMessagePsychrometric({
            text: psychrometricRes.psychrometric.message,
            color: "warning",
          });
        else if (psychrometricRes.psychrometric.message !== null) {
          setMessagePsychrometric({
            text: psychrometricRes.psychrometric.message,
            color: "info",
          });
        }
      }

      if (e) {
        const { name, value } = e.target;
        newValues[name] = value;
        setValues({
          ...newValues,
          btuh: psychrometricRes.btuh!,
          sensibleHeat: psychrometricRes.sensibleHeat!,
          [name]: value,
          typeDevice: values.isRectangleType ? "0" : "1",
        });
      } else {
        setValues({
          ...newValues,
          btuh: psychrometricRes.btuh!,
          sensibleHeat: psychrometricRes.sensibleHeat!,
          typeDevice: values.isRectangleType ? "0" : "1",
        });
      }
      newValues.btuh = psychrometricRes.btuh!;
      newValues.sensibleHeat = psychrometricRes.sensibleHeat!;
    }
    if (newValues.id > 0 && calc) await executeRefresh(newValues.id, newValues);
    else {
      setLoadingCorrect(false);
    }
  };

  const handleCheckNoc = (e: any) => {
    let nocActual = false;
    let nocVisual = false;
    switch (e.target.name) {
      case "nocActual": {
        nocActual = true;
        break;
      }
      case "nocVisual": {
        nocVisual = true;
        break;
      }
    }
    setValues({ ...values, nocActual: nocActual, nocVisual: nocVisual });
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    return found?.key === values?.conditionId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const isOther = useMemo(() => {
    const found = conditionOfTest.find((item) => item.value === "Other");
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (validate()) return;
    await executeUpdate(values.id, values);
    await execute(id);
    await saveItemsMatrix();
    setSaveItems(!saveItems);
    setShowSteady(true);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const handleChangeRadio = async (e: any) => {
    const newValues = { ...values };
    let relativeHumidityBool = false;
    let dewPointBool = false;
    let wetBulbFBool = false;
    switch (e.target.name) {
      case "relativeHumidityBool": {
        relativeHumidityBool = true;
        break;
      }
      case "dewPointBool": {
        dewPointBool = true;
        break;
      }
      case "wetBulbFBool": {
        wetBulbFBool = true;
        break;
      }
    }
    newValues.relativeHumidityBool = relativeHumidityBool;
    newValues.dewPointBool = dewPointBool;
    newValues.wetBulbFBool = wetBulbFBool;
    newValues.relativeHumidity = undefined;
    newValues.dewPoint = undefined;
    newValues.wetBulbF = undefined;
    await executeRefreshPsyco(newValues.id, newValues);
  };

  const showskeleton = useMemo(
    () =>
      isLoading ||
      isSubmittingUpdate ||
      generalaMatrixItemChanges ||
      loadingchangeType,
    [
      isLoading,
      isSubmittingUpdate,
      generalaMatrixItemChanges,
      loadingchangeType,
    ]
  );

  const showskeletonStep2 = useMemo(() => isLoading, [isLoading]);

  const showUpdateButton = useMemo(
    () =>
      !!(
        user?.role !== ROLES.Training &&
        changeMatrixButton &&
        !disableStudy &&
        values?.readingsX &&
        values?.readingsY &&
        values?.readingsX > 0 &&
        values?.readingsY > 0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeMatrixButton, disableStudy, values?.readingsX, values?.readingsY]
  );

  const trainingModeUpdateButton =
    user?.role === ROLES.Training &&
    changeMatrixButton &&
    values?.dimensionWidth &&
    values?.dimensionHeight &&
    values?.dimensionWidth < 23 &&
    values?.dimensionHeight < 23 &&
    values?.readingsX &&
    values?.readingsY &&
    values?.readingsX < 5 &&
    values?.readingsY < 5;

  useMemo(() => {
    const validateStep2 = async () => {
      let active = false;

      if (validate()) return;
      if (values?.isRectangleType) {
        const active = [
          values?.dimensionWidth,
          values?.dimensionHeight,
          values?.readingsX,
          values?.readingsY,
          values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (active && stepContinuos === "step1" && !customValidation) {
          setStepContinuos("step2");
        }
      } else if (values?.isRoundType) {
        active = [
          values?.dimensionWidth,
          values?.readingsX,
          values?.readingsY,
          values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (active && stepContinuos === "step1" && !customValidation) {
          setStepContinuos("step2");
        }
      }
    };

    const trainingValidate = async () => {
      if (values?.isRectangleType) {
        const active =
          values?.conditionId !== 0 &&
          values?.dimensionWidth &&
          values?.dimensionHeight &&
          values?.dimensionWidth < 23 &&
          values?.dimensionHeight < 23 &&
          values?.readingsX &&
          values?.readingsY &&
          values?.readingsX < 5 &&
          values?.readingsY < 5;

        if (active && stepContinuos === "step1") {
          setStepContinuos("step2");
        }
      }
    };
    if (user?.role !== ROLES.Training) validateStep2();
    if (user?.role === ROLES.Training) trainingValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.readingsX,
    values?.readingsY,
    values?.conditionId,
    values?.dimensionHeight,
    values?.dimensionWidth,
    customValidation,
  ]);

  const handleInputChangeCompesation = async (e: any) => {
    setLoadingCorrect(true);
    let temperatureCompensated = e.target.value;
    let dryBulbF = values?.dryBulbF;
    if (values.airflowTemperature === values.dryBulbF) {
      dryBulbF = formulas?.getAirflowTemperature();
    }
    let airflowTemperature = formulas?.getAirflowTemperature();
    if (values.temperatureCompensated === temperatureCompensated) {
      setLoadingCorrect(false);
    }

    try {
      const responseNewValues = await generalMatrixService.update(values.id, {
        ...values,
        temperatureCompensated: temperatureCompensated,
        dryBulbF: dryBulbF,
        airflowTemperature: airflowTemperature,
      });
      setValues({
        ...responseNewValues.data,
        isRectangleType: values?.isRectangleType,
        isRoundType: values?.isRoundType,
        typeDevice: values?.typeDevice,
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleChangeSpecificDefault = async (e: any) => {
    const newValues = { ...values };
    let isActualLocalBarometricPressure = e.target.value;
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF: mesurement === "imperialSystem" ? 70 : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      newValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    } else {
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    }
    await executeRefreshPsyco(newValues.id, newValues);
  };

  const handleInputChangeConditionId = (e: any) => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === e.target.value) {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        nocActual: false,
        nocVisual: true,
        nocOaNumber: 0,
        nocMaNumber: 0,
        nocRaNumber: 0,
        nocExhaustNumber: 0,
      });
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        nocOaNumber: 0,
        nocMaNumber: 0,
        nocRaNumber: 0,
        nocExhaustNumber: 0,
      });
    }
  };

  const handleInputChangePsycometric = async (e: any) => {
    setLoadingCorrect(true);
    const newValues = {
      ...values,
      [e.target.name]: e.target.value,
    };

    await executeRefreshPsyco(newValues.id, newValues);
  };

  const onChangeTypeDevice = async (e: any) => {
    startChangeType("change");
    const { value } = e.target;
    setStepContinuos("step1");
    setDataX(0);
    setDataY(0);
    setDimensions({
      width: 0,
      height: 0,
    });
    setButtonAction(false);
    setChangeMatrixButton(false);

    try {
      const responseItems = await generalMatrixItemsService.getByGeneralId(
        values?.id
      );

      if (responseItems.data.length > 0) {
        await deleteMatrix(values?.id);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    setItems([]);

    setValues({
      ...values,
      isRectangleType: value === TYPE_DEVICE.RECTANGULAR ? true : false,
      isRoundType: value === TYPE_DEVICE.ROUND ? true : false,
      typeDevice: value,
      dimensionTotal: undefined,
      headDiameterRVA: undefined,
      dimensionHeight: undefined,
      dimensionWidth: undefined,
      readingsX: undefined,
      readingsY: undefined,
      conditionId: 0,
      totalVelocity: 0,
      avgVelocity: 0,
      trueCorectedAirVelocity: 0,
      totalCFM: 0,
      required: 0,
      akFactor: 1,
      percentajeOfDesign: 0,
      dryBulbF: 0,
      relativeHumidity: 0,
      wetBulbF: 0,
      dewPoint: 0,
      btuh: 0,
      sensibleHeat: 0,
      barometricalyCompensated: false,
      temperatureCompensated: false,
      totalHeat: false,
    });

    endChangeType("change");
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );

    setProjectReport(projectReportResposne.data);
  };

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      if (error?.message.title !== "Not Found") {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  const refreshCompletedStudy = async () => {
    try {
      const generalMatrixResponse = await generalMatrixService.getById(id);
      setGeneralMatrix(generalMatrixResponse.data);
      const projectResponse = await getProjectSingleService.single(
        generalMatrixResponse.data.projectId
      );
      setProject(projectResponse.data);
      await execute(id);
      await getProjectReport(
        generalMatrixResponse?.data.id ?? 0,
        generalMatrixResponse?.data.reportTypeId ?? 0,
        generalMatrixResponse?.data.projectId ?? 0
      );
      setMessagePsychrometric({ text: "", color: "info" });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const studieROVA = params.code === "ROVA";

  const disableStep1 =
    disableStudy || stepContinuos === "step1" || changeMatrixButton;

  const showSteadyPopUp = useMemo(() => {
    return (
      showSteady && stepContinuos !== "step1" && values && values?.projectId > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSteady, stepContinuos, values, values?.projectId]);

  const disableCompleteStudy = values?.dryBulbF === 0 && changeMatrixButton;

  const disableMatrix = useMemo(
    () =>
      !!(
        disableStep1 ||
        disableEditData ||
        changeMatrixButton ||
        customValidation
      ),
    [disableStep1, disableEditData, changeMatrixButton, customValidation]
  );

  const porcentajeDesign =
    values &&
    values?.percentajeOfDesign &&
    (values?.percentajeOfDesign >
      (values?.requiredCFMSystemToleranceTop
        ? values?.requiredCFMSystemToleranceTop
        : 0) +
        100 ||
      values?.percentajeOfDesign <
        100 -
          (values?.requiredCFMSystemToleranceBottom
            ? values?.requiredCFMSystemToleranceBottom
            : 0));

  const modeReadWrite = disableStudy ? "read" : "read&Write";

  const changeMatrix = () => {
    setButtonAction(true);
  };

  const saveItemsMatrix = async () => {
    const matrixItems = items?.flat();
    if (matrixItems && matrixItems?.length > 0)
      await executeSaveItem(matrixItems);
  };

  const decimalNumber =
    systemOfMeasurement.measurementSystem === MEASUREMENTTYPE.IMPERIALTYPE
      ? 2
      : 3;

  const disableSwtiches =
    disableStep1 ||
    disableEditData ||
    isLoadingUpdate ||
    disableStudy ||
    changeMatrixButton ||
    customValidation;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: params.code ?? "",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <h3>System Configuration</h3>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showskeleton}
                    mode={modeReadWrite}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showskeleton}
                    maxLength={50}
                    mode={modeReadWrite}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showskeleton}
                    mode={modeReadWrite}
                  />
                </Grid>
                {dataReport && dataReport.parentReportId !== null && (
                  <SubStudyTypeSelect
                    disabled={disableStudy}
                    handleInputChange={handleInputChange}
                    setValues={setValues}
                    values={values}
                    showSkeleton={showskeleton}
                  />
                )}
                <Grid item xs={12}>
                  <Divider />
                  <Stack>
                    <h3>Type of Terminal Device being tested</h3>
                  </Stack>

                  <Grid container>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name="typeDevice"
                        value={values?.typeDevice}
                        items={typeOfTerminal}
                        row
                        onChange={onChangeTypeDevice}
                        defaultValue={selectRadioButton}
                        showSkeleton={showskeleton}
                        disabled={disableStudy}
                        mode={modeReadWrite}
                      />
                      {!disableStudy && (
                        <Grid item xs={12} mb={3}>
                          <Typography variant="h6" color={"primary"}>
                            Fields marked with an asterisk (*) are required for
                            matrix data enter.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>

                    <DimensionsMatrix
                      typeReport="General"
                      values={values}
                      setValues={setValues}
                      disableStudy={disableStudy}
                      mode={modeReadWrite}
                      systemOfMeasurement={systemOfMeasurement}
                      changeMatrix={changeMatrix}
                      executeRefresh={executeRefresh}
                      changeMatrixButton={changeMatrixButton}
                      dimensions={dimensions}
                      dataX={dataX}
                      dataY={dataY}
                      showSkeleton={showskeleton}
                      errors={errors}
                      showUpdateButton={showUpdateButton}
                      customValidation={customValidation}
                      trainingModeUpdateButton={trainingModeUpdateButton}
                      setCustomValidation={setCustomValidation}
                      setErrors={setErrors}
                      studieROVA={studieROVA}
                    />
                  </Grid>
                  <Divider mt={3} />
                  <h3>Condition of Test*</h3>
                  <Grid container direction={"row"} spacing={4}>
                    <Grid item xs={12} md={2.9}>
                      <FormSelect
                        name={"conditionId"}
                        label={"Condition*"}
                        onChange={handleInputChangeConditionId}
                        showSkeleton={showskeleton}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test",
                        }}
                        disabled={
                          disableStudy || changeMatrixButton || customValidation
                        }
                        mode={modeReadWrite}
                      />
                    </Grid>
                    <Grid item xs={12} md={9}>
                      <Grid container ml={3}>
                        <Grid item xs={12} md={4}>
                          <FormCheckSwitch
                            name={"barometricalyCompensated"}
                            label={"Correct For Barometric Compensation"}
                            showSkeleton={showskeleton}
                            onChange={handleInputChangePsycometric}
                            disabled={disableSwtiches}
                            value={values?.barometricalyCompensated}
                            mode={modeReadWrite}
                          ></FormCheckSwitch>
                        </Grid>
                        <Grid item xs={12} md={4.5}>
                          <FormCheckSwitch
                            name={"temperatureCompensated"}
                            label={"Correct For Temperature Compensation"}
                            showSkeleton={showskeleton}
                            onChange={handleInputChangeCompesation}
                            disabled={disableSwtiches}
                            value={values?.temperatureCompensated}
                            mode={modeReadWrite}
                          ></FormCheckSwitch>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FormCheckSwitch
                            name={"totalHeat"}
                            label={"Total Heat"}
                            showSkeleton={showskeleton}
                            onChange={totalHeatHandler}
                            disabled={disableSwtiches}
                            value={values?.totalHeat}
                            mode={modeReadWrite}
                          ></FormCheckSwitch>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {isOther && (
                    <Grid container mt={5}>
                      <Grid item xs={12}>
                        <FormTextArea
                          name={"comments"}
                          label={"Comments"}
                          value={values?.comments}
                          onChange={handleInputChange}
                          mode={modeReadWrite}
                        ></FormTextArea>
                      </Grid>
                    </Grid>
                  )}
                  {isNOC && (
                    <>
                      <DampersComponent
                        disableStudy={disableStudy || changeMatrixButton}
                        errors={errors}
                        handleInputChange={handleInputChange}
                        isLoading={isLoading}
                        mode={modeReadWrite}
                        setErrors={setErrors}
                        setValues={setValues}
                        values={values}
                      />
                    </>
                  )}

                  {/* <Grid container>
                    {isNOC && (
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={12}>
                          <RadioGroup aria-label="" name="" row>
                            <Grid container mt={2}>
                              <Grid item xs={3}>
                                {" "}
                                <FormControlLabel
                                  checked={values?.nocVisual === true}
                                  control={<Radio />}
                                  label="Visual Damper Position"
                                  onChange={handleCheckNoc}
                                  name={"nocVisual"}
                                  disabled={disableStudy}
                                />
                              </Grid>
                              <Grid item xs={3}>
                                {" "}
                                <FormControlLabel
                                  checked={values?.nocActual === true}
                                  control={<Radio />}
                                  label="Actual Damper Position"
                                  onChange={handleCheckNoc}
                                  name={"nocActual"}
                                  disabled={disableStudy}
                                />
                              </Grid>
                            </Grid>
                          </RadioGroup>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  {isNOC && (
                    <Grid
                      container
                      mt={1}
                      justifyContent={"center"}
                      spacing={2}
                    >
                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocOaNumber"}
                          label={""}
                          value={values?.nocOaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={decimalNumber}
                          thousandSeparator={true}
                          suffix=" % O/A"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                          maxValue={101}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocRaNumber"}
                          label={""}
                          value={values?.nocRaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={decimalNumber}
                          thousandSeparator={true}
                          suffix=" % R/A"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                          maxValue={101}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocMaNumber"}
                          label={""}
                          value={values?.nocMaNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={decimalNumber}
                          thousandSeparator={true}
                          suffix=" % M/A"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                          maxValue={101}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocExhaustNumber"}
                          label={""}
                          value={values?.nocExhaustNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={showskeletonStep2}
                          decimalScale={decimalNumber}
                          thousandSeparator={true}
                          suffix=" % Exhaust"
                          disabled={disableStudy}
                          mode={modeReadWrite}
                          maxValue={101}
                        />
                      </Grid>
                    </Grid>
                  )} */}

                  <Divider mt={3} mb={3} />
                  {values && (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <h3>Readings</h3>
                        </Grid>
                      </Grid>
                      <Grid container pt={2}>
                        {values?.barometricalyCompensated && (
                          <>
                            <Grid item xs={6} md={disableStudy ? 3 : 2.9}>
                              {values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"useSiteSpecificDefault"}
                                  label={"Local Barometric Pressure Default"}
                                  value={
                                    values?.useSiteSpecificDefault?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={decimalNumber}
                                  mode="read"
                                  thousandSeparator={true}
                                  disabled={disableSwtiches}
                                ></FormNumeric2>
                              )}
                              {!values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"localBarometricPressure"}
                                  label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                  value={
                                    values?.localBarometricPressure?.toString() ??
                                    ""
                                  }
                                  onChange={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={decimalNumber}
                                  thousandSeparator={true}
                                  disabled={disableSwtiches}
                                  mode={modeReadWrite}
                                ></FormNumeric2>
                              )}
                            </Grid>
                            <Grid item xs={6} md={5}>
                              <Stack ml={4}>
                                <FormCheckSwitch
                                  name={"isActualLocalBarometricPressure"}
                                  label={"Use Site Specific Default"}
                                  showSkeleton={showskeleton}
                                  onChange={handleChangeSpecificDefault}
                                  disabled={disableSwtiches}
                                  value={
                                    values?.isActualLocalBarometricPressure
                                  }
                                  mode={modeReadWrite}
                                />
                              </Stack>
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          {params.code === "ROVA" && (
                            <Grid item xs={12}>
                              <Typography>
                                <Typography
                                  variant="subtitle2"
                                  display={"inline-flex"}
                                  mt={2}
                                  pr={2}
                                >
                                  Use Sweep Method:
                                </Typography>
                                <FormCheckBox
                                  name={"useSweepMethod"}
                                  value={values?.useSweepMethod}
                                  onChange={handleInputChange}
                                  label={`Enter your total value in the first cell of the matrix, cells will automatically populate.`}
                                  disabled={disableSwtiches}
                                  mode={modeReadWrite}
                                ></FormCheckBox>
                              </Typography>
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12} pb={3}>
                          {values && id && (
                            <GeneralMatrixGrid
                              id={id}
                              x={values?.readingsX ?? 0}
                              y={values?.readingsY ?? 0}
                              disabled={disableMatrix}
                              showSkeleton={showskeleton}
                              onBlur={calcTotal}
                              useSweepMethod={values?.useSweepMethod}
                              systemOfMeasurement={systemOfMeasurement}
                              values={values}
                              setValues={setValues}
                              dataY={dataY}
                              dataX={dataX}
                              setShowSteady={setShowSteady}
                              showSteady={showSteady}
                              stepContinuos={stepContinuos}
                              setGeneralaMatrixItemChanges={
                                setGeneralaMatrixItemChanges
                              }
                              setDataX={setDataX}
                              setDataY={setDataY}
                              setChangeMatrixButton={setChangeMatrixButton}
                              changeMatrixButton={changeMatrixButton}
                              setButtonAction={setButtonAction}
                              buttonAction={buttonAction}
                              validateXY={customValidation}
                              items={items}
                              setItems={setItems}
                              setDimensions={setDimensions}
                            />
                          )}
                        </Grid>
                      </Grid>

                      <ResultsDataMatrix
                        systemOfMeasurement={systemOfMeasurement}
                        values={values}
                        modeReadWrite={modeReadWrite}
                        handlerChangeData={saveOnBlurHandler}
                        handleChangeRadio={handleChangeRadio}
                        disabel={disableSwtiches}
                        porcentajeDesign={porcentajeDesign}
                        disableStudy={disableStudy}
                        handleInputChange={handleInputChange}
                        errors={errors}
                        handlerTotalHeat={onBluChange}
                        showskeleton={showskeleton}
                        setErrors={setErrors}
                      />
                    </>
                  )}
                </Grid>
                {values?.totalHeat && messagePsychrometric.text !== "" && (
                  <Grid item xs={12} justifyContent={"center"}>
                    <Stack mb={2}>
                      <Alert severity={messagePsychrometric.color}>
                        {messagePsychrometric.text}
                      </Alert>
                    </Stack>
                  </Grid>
                )}
                {stepContinuos === STEPSVALUES.STEP2 && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Caution: Make sure the AK Factor is correct for all
                      Meter/Devices being used.
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={1}
                      stepsCount={3}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName={titleReport}
                      saveHandler={handleSave}
                      nextStepHandler={() => {}}
                      disabled={disableStudy || (values?.readingsY ?? 0) >= 20}
                      previousStepHandler={() => {}}
                      isComplete={values?.isComplete ?? true}
                      disableNext={(values?.readingsY ?? 0) >= 20}
                      singleStep={true}
                      hideSaveButton={disableStudy}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {project && projectReport && generalMatrix && (
                      <CompleteStudyPopup
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        project={project}
                        projectReport={projectReport}
                        completed={generalMatrix?.isComplete}
                        infectiousComplianceControl={
                          infectiousComplianceControl
                        }
                        parentStudy={parentStudy ?? null}
                        singleStep={disableCompleteStudy}
                        handlerCustomAccept={refreshCompletedStudy}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showSteadyPopUp && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={values?.reportTypeId}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
          setShowSteady={setShowSteady}
          setDisableEditData={setDisableEditData}
        />
      )}
    </>
  );
};

export default GeneralMatrix;
