import { Stack, Toolbar } from "@mui/material";
import { useState } from "react";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { FormActionButton } from "src/components/formControls";
import SpreadSheetActionButton from "src/components/spreadsheet/SpreadSheetActionButton";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import { useLog } from "src/hooks";
import useOutletReport from "src/pages/studies/outlet/hooks/useOutletReport";
import outletReportItemService from "src/services/study/outletReportItemService";
import {
  IKeyValue,
  IOutletReport,
  IOutletReportItem,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import { Mode } from "src/ts/types";

interface Props {
  outletReport: IOutletReport;
  mode: Mode;
  meterDeviceKeyValue: IKeyValue<number, string>[];
  updateComponent: () => Promise<void>;
  disabledStudy: boolean;
  systemOfMeasurement: ISystemOfMeasurement;
}

const OutletReportGrid = ({
  outletReport,
  mode,
  meterDeviceKeyValue,
  updateComponent,
  disabledStudy,
  systemOfMeasurement,
}: Props) => {
  const { log } = useLog();
  const [showDeleteItemPopup, setShowDeleteItemPopup] = useState(false);
  const {
    isLoading,
    outletReportComponentId,
    items,
    setItems,
    componentValues,
  } = useOutletReport();

  const updateRow = (row: IOutletReportItem, index: number) => {
    const newSet = items.map((item: IOutletReportItem, i: number) =>
      i === index ? row : item
    );

    setItems(newSet);
  };

  const onChangeText = (e: any, row: IOutletReportItem, indexRow: number) => {
    const { name, value } = e.target;
    const modifiedRow = { ...row, [name]: value };
    updateRow(modifiedRow, indexRow);
  };

  const [selectedItem, setSelectedItem] = useState<IOutletReportItem | null>(
    null
  );
  const deleteItemHandler = async (row: IOutletReportItem) => {
    setSelectedItem(row);
    setShowDeleteItemPopup(true);
  };

  const deleteItem = async () => {
    if (selectedItem === null) return;
    try {
      await outletReportItemService.remove(selectedItem.id);
      //maybe change for map
      const unmodified = items.filter((item) => item.id !== selectedItem.id);
      setItems(unmodified);
      await updateComponent();
      log.info("Item was deleted");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    } finally {
      setShowDeleteItemPopup(false);
    }
  };

  const duplicateItem = async (row: IOutletReportItem, index: number) => {
    try {
      const res = await outletReportItemService.addItem(row);
      const copiedItems: IOutletReportItem[] = [...items];
      copiedItems.splice(index + 1, 0, res.data);
      const sortedItems = copiedItems.sort((a, b) =>
        !a.order > !b.order ? -1 : 1
      );

      setItems(sortedItems);
      await updateComponent();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const updateActualCFM = async (
    e: any,
    row: IOutletReportItem,
    index: number
  ) => {
    const modifiedRow: IOutletReportItem = {
      ...row,
      [e.target.name]:
        e.target.value === "" ? null : parseFloat(e.target.value),
    };

    //Allow the row be duplicate and no wait for the response
    const modifiedItemsDup = items.map((item, i) =>
      i === index ? modifiedRow : item
    );
    setItems(modifiedItemsDup);

    try {
      const res = await outletReportItemService.updateItem(modifiedRow);

      const modifiedItems = items.map((item, i) =>
        i === index ? res.data : item
      );

      setItems(modifiedItems);
      await updateComponent();
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const nlHandler = async (e: any, row: IOutletReportItem, index: number) => {
    const modifiedRow: IOutletReportItem = {
      ...row,
      nl: !!e.target.value,
      requiredCFM: 0.01,
    };

    const updateItemRes = await outletReportItemService.updateItem(modifiedRow);

    const modifiedItems = items.map((item, i) =>
      i === index ? updateItemRes.data : item
    );

    setItems(modifiedItems);
    await updateComponent();
  };

  const diffuserGrilleModel = outletReport.diffuserGrilleModel;
  const diffuserGrillerNeckSize = outletReport.diffuserGrillerNeckSize;
  const columns: ISpreadsheetColumn[] = [
    {
      name: "index",
      type: "index",
      label: "#",
      align: "center",
    },
    {
      name: "roomAreaServed",
      type: "text",
      label: "Room or Area Served",
      disabled: disabledStudy,
      mode: mode,
      maxLength: 25,
      // width: "10%",
    },
    {
      name: "diffuserGrilleModel",
      type: "text",
      label: "Diffuser / Grille Model",
      hide: !diffuserGrilleModel,
      disabled: disabledStudy,
      mode: mode,
    },
    {
      name: "rectangle",
      type: "radioButton",
      align: "center",
      label: "Diff / Grille Rect.",
      disabled: disabledStudy,
      mode: mode,
      onChange: (row: IOutletReportItem, e: any, index: number): void => {
        const { checked } = e.target;
        const rectangleValue = !!checked;
        const modifiedRow = {
          ...row,
          circle: !rectangleValue,
          rectangle: rectangleValue,
        };
        updateRow(modifiedRow, index);
      },
    },
    {
      name: "circle",
      type: "radioButton",
      label: "Diff / Grille Round",
      align: "center",
      disabled: disabledStudy,
      mode: mode,
      onChange: (row: IOutletReportItem, e: any, index: number): void => {
        const { checked } = e.target;
        const circleValue = !!checked;
        const modifiedRow = {
          ...row,
          circle: circleValue,
          rectangle: !circleValue,
        };
        updateRow(modifiedRow, index);
      },
    },
    {
      name: "circle",
      type: "custom",
      label: "Grille Length / Diameter",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            <>
              {row.circle ? (
                "Diameter"
              ) : (
                <SpreadsheetNumericInput2
                  name="heigth"
                  value={row.heigth}
                  onChange={(e: any) => {
                    onChangeText(e, row, index);
                  }}
                  emptyFieldAccept={true}
                  decimalScale={3}
                  maxValue={1000000}
                  disabled={disabledStudy}
                  mode={mode}
                />
              )}
            </>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "width",
      type: "numericInput",
      label: "Diff / Grille Width",
      disabled: disabledStudy,
      decimalScale: 3,
      maxValue: 1000000,
      mode: mode,
      emptyFieldAccept: true,
    },
    {
      name: "neckCircle",
      type: "radioButton",
      align: "center",
      label: "Neck Round",
      hide: !diffuserGrillerNeckSize,
      disabled: disabledStudy,
      mode: mode,
      onChange: (row: IOutletReportItem, e: any, index: number) => {
        const { checked } = e.target;
        const rectangleValue = !!checked;
        const modifiedRow = {
          ...row,
          neckRectangle: !rectangleValue,
          neckCircle: rectangleValue,
        };
        updateRow(modifiedRow, index);
      },
    },
    {
      name: "neckRectangle",
      type: "radioButton",
      align: "center",
      label: "Neck Rect.",
      disabled: disabledStudy,
      mode: mode,
      hide: !diffuserGrillerNeckSize,
      onChange: (row: IOutletReportItem, e: any, index: number) => {
        const { checked } = e.target;
        const rectangleValue = !!checked;
        const modifiedRow = {
          ...row,
          neckCircle: !rectangleValue,
          neckRectangle: rectangleValue,
        };
        updateRow(modifiedRow, index);
      },
    },
    {
      name: "neckHeigth",
      type: "custom",
      label: "Neck Length",
      hide: !diffuserGrillerNeckSize,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            <>
              {row.neckCircle ? (
                "Diameter"
              ) : (
                <SpreadsheetNumericInput2
                  name="neckHeigth"
                  value={row?.neckHeigth}
                  onChange={(e: any) => onChangeText(e, row, index)}
                  disabled={disabledStudy}
                  decimalScale={3}
                  maxValue={1000000}
                  thousandSeparator={true}
                  mode={mode}
                />
              )}
            </>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "neckWidth",
      type: "numericInput",
      label: "Neck Width",
      hide: !diffuserGrillerNeckSize,
      disabled: disabledStudy,
      mode: mode,
      thousandSeparator: true,
    },
    {
      name: "akColumn",
      label: `AK Factor (${systemOfMeasurement.get("sqft").trim()})`,
      type: "custom",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            <>
              <SpreadsheetNumericInput2
                value={row.akColumn}
                onChange={(e: any) => updateActualCFM(e, row, index)}
                name="akColumn"
                decimalScale={2}
                maxValue={1000}
                disabled={disabledStudy}
                mode={mode}
                emptyFieldAccept={true}
              />
            </>
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "meterDeviceId",
      label: "Meter / Device",
      type: "select",
      items: meterDeviceKeyValue,
      disabled: disabledStudy,
      mode: mode,
      textFooter: "Total = ",
    },
    {
      name: "requiredCFM",
      type: "custom",
      label: "Required CFM",
      showTotals: true,
      thousandSeparator: true,
      decimalScale: 3,
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            <SpreadsheetNumericInput2
              value={row.requiredCFM}
              onChange={(e: any) => updateActualCFM(e, row, index)}
              name="requiredCFM"
              decimalScale={3}
              maxValue={1000000000}
              disabled={row.nl || disabledStudy}
              mode={mode}
              emptyFieldAccept={true}
            />
          </SpreadSheetCell>
        );
      },
    },
    {
      name: "nl",
      type: "checkBox",
      align: "center",
      label: "Not Listed",
      disabled: disabledStudy,
      mode: mode,
      onChange: async (
        row: IOutletReportItem,
        e: any,
        index: number
      ): Promise<void> => {
        await nlHandler(e, row, index);
      },
    },
    {
      name: "actio1",
      type: "custom",
      render: (
        row: IOutletReportItem,
        col: ISpreadsheetColumn,
        index: number
      ) => {
        return (
          <SpreadSheetCell>
            {mode === "read&Write" ? (
              <Stack direction="row">
                <SpreadSheetActionButton
                  type="delete"
                  tooltip="Delete this item"
                  onClick={() => deleteItemHandler(row)}
                  disabled={disabledStudy}
                />
                <SpreadSheetActionButton
                  type="copy"
                  tooltip="Copy this item"
                  onMouseUp={(e: any) => duplicateItem(row, index)}
                  disabled={disabledStudy}
                />
              </Stack>
            ) : (
              <></>
            )}
          </SpreadSheetCell>
        );
      },
    },
  ];

  const addOutletMasterItems = async () => {
    if (items.length > 0) {
      let newItem: any = {};
      if (outletReport.fillNextToogle) {
        const lastItem = items[items.length - 1];
        newItem = {
          ...lastItem,
          order: (lastItem?.order ?? 0) + 1,
        };
      } else {
        newItem = {
          order: 1,
          device: "Hood",
          outletReportComponentId: outletReportComponentId,
        };
      }

      try {
        const res = await outletReportItemService.addItem(newItem);
        const newItems = [...items];
        newItems.push(res.data);
        setItems(newItems);

        await updateComponent();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
      const newItem: any = {
        order:
          items.length === 0 ? 1 : (items[items.length - 1].order ?? 0) + 1,
        device: "Hood",
        outletReportComponentId: outletReportComponentId,
      };

      try {
        const res = await outletReportItemService.addItem(newItem);
        setItems([res.data]);
        await updateComponent();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    }
  };

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        {!disabledStudy && (
          <FormActionButton
            onClick={addOutletMasterItems}
            size="small"
            text="Add"
            type={"add"}
          />
        )}
      </Toolbar>
      <Spreadsheet
        items={items}
        setItems={setItems}
        cols={columns}
        defaultRowPerPage={25}
        totals={{
          requiredCFM: componentValues.totalRequiredCFM,
        }}
        showRowTotals={true}
        showSkeleton={isLoading}
      />
      <DialogMessagePopup
        title="Delete Item"
        text="Are certain you want to remove this Item?"
        showPopup={showDeleteItemPopup}
        setShowPopup={setShowDeleteItemPopup}
        onSave={deleteItem}
        isSubmitting={false}
      />
    </>
  );
};

export default OutletReportGrid;
