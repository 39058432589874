import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  IconButton,
  Alert as MuiAlert,
} from "@mui/material";
import { Box, spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckSwitch,
  FormNumeric2,
  FormSelect,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IEnergyStudyAirHydronicsDTO,
  IFormulaSystemService,
  IKeyValue,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import useFormulas from "src/hooks/useFormulas";
import getProjectService from "src/services/project/getProjectService";
import { steps } from "./const/const";
import { ICoils } from "src/ts/interfaces/study/coils";
import coilsService from "src/services/study/coilsService";
import CoilsGrid from "./components/CoilsGrid";
import { CatalogService } from "src/services";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import FormTooltip from "src/components/formControls/FormTooltip";
import SteadyStateDialog from "../components/SteadyState";
import { FooterButtons } from "../components/FooterButtons";
import StudyWizard from "../components/StudyWizard";
import { Validator } from "src/ts/types";
import { IGlycolFactor } from "src/ts/interfaces/glycolFactor";
import { GridActionButton } from "src/components/gridControls";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import projectService from "src/services/project/projectService";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
const initialValues: ICoils = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  roomAreaServed: "",
  description: "",
  pumpSystemTotal: false,
  pumpCoilTotal: false,
  totalBTUSystem: false,
  containsBlycol: false,
  glycolTypeOther: "",
  glycolPercentage: "",
  glycolPercentageActual: "",
  glycolFactorGPMFlows: false,
  totalDesignGpm: 0,
  totalActualGpm: 0,
  glycolFactor: "",
  glycolFreezeProtectionValueSign: "",
  glycolFreezeProtectionValueActualSign: "",
  glycolDataSource: "",
  fillNext: false,
  devicePDWPSI: false,
  devicePDWFeet: false,
  deviceCoefficient: false,
  airflowFlowingCoilsId: 0,
  nameOfPerson: "",
  title: "",
  company: "",
  dateWhenTold: "",
  designWaterTemperature: false,
  actualWaterTemperature: false,
  designPSIWater: false,
  actualPSIWater: false,
  designAirflowDB: false,
  actualAirflowDB: false,
  designAirflow: false,
  actualAirflow: false,
  rh: false,
  wb: false,
  dp: false,
  designAirflowSP: false,
  actualAirflowSP: false,
  designBTUH: false,
  actualBTUH: false,
  designBTUHTotal: false,
  actualBTUHTotal: false,
  systemSteadyThisTest: false,
  systemSteadyWillBeMonitored: false,
  step: 0,
  isComplete: false,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  mandatoryMinimum: false,
  numberOfItems: 0,
  testMode: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);
const Alert = styled(MuiAlert)(spacing);

const CoilsStep3 = () => {
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const [glycolType, setGlycolType] = useState<IKeyValue<number, string>[]>([]);
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [saveItems, setSaveItems] = useState<boolean>(false);
  const [saveTotals, setSaveTotals] = useState<boolean>(false);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [glycolWaterGravityModal, setGlycolWaterGravityModal] =
    useState<boolean>(false);
  const [showGPMSystemModal, setShowGPMSystemModal] = useState(false);
  const [energyValues, setEnergyValues] =
    useState<IEnergyStudyAirHydronicsDTO>();
  const [isEnergyProcess, setIsEnergyProcess] = useState(false);
  const [showSteadyState, setShowSteadyState] = useState(false);
  const [showCoilsGrid, setShowCoilsGrid] = useState(false);
  let navigate = useNavigate();

  const { execute: executeGetGlycolFactor, data: dataGlycolFactor } =
    useAsyncQuery<IGlycolFactor[]>(CatalogService.getAll);

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation(coilsService.update, {
      successfulMessage: "Report was updated successfully",
      onSuccess: async (result) => {
        await setValues({
          ...result,
        });
      },
    });

  const { execute: executeUpdateNoMessage } = useAsyncMutation(
    coilsService.update,
    {
      hideSuccessfulMessage: true,
      onSuccess: async (result) => {
        await setValues({
          ...result,
        });
      },
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.glycolDataSource) {
      temp.glycolTypeId = new Validator(fieldValues, "glycolTypeId")
        .selectedOption(null, "Design Glycol Type is required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ICoils>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<ICoils>(coilsService.getById, {
    onSuccess: (dataResult) => {
      const getData = async () => {
        let result = await formulaSystem.setSystem(dataResult?.projectId);
        setFormulas(result);
        const measurementSystemResponse =
          await getProjectService.getMeasurementSystem(dataResult?.projectId);
        systemOfMeasurement.setSystem(measurementSystemResponse.data);
      };
      getData();
      setValues(dataResult);
    },
  });

  const { execute: executeEnergy } = useAsyncMutation(
    singleEnergyStudyAirHydronicsService.getById,
    {
      hideSuccessfulMessage: true,
      onSuccess: (dataResult) => {
        const getData = async () => {
          setEnergyValues(dataResult);
          if (
            !values?.totalActualGpm &&
            !disableStudy &&
            dataResult.target !== "Water" &&
            user?.role === "EnergyAuditor"
          ) {
            setShowGPMSystemModal(true);
            return;
          }
          setShowCoilsGrid(true);
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      const responseGlycolType = await CatalogService.getAllDropdown(
        "GlycolType"
      );
      setGlycolType(
        responseGlycolType.filter((item) => item.value !== "Other")
      );
    };

    if (id !== 0) {
      getData();
      executeGetGlycolFactor("GlycolFactor");
    }
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      await executeReport(id, values?.reportTypeId === 4 ? "COTD" : "COAH");
    };

    if (values && values?.id > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.id]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.glycolTypeId]);

  useEffect(() => {
    const getData = async () => {
      await executeUpdateNoMessage(values?.id, values);
    };

    if (values && values?.id > 0 && !disableStudy) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.glycolFactor, saveTotals]);

  useEffect(() => {
    if (dataReport?.id) {
      if (dataReport?.parentReportTypeId === 18) {
        setIsEnergyProcess(true);
        executeEnergy(dataReport?.parentReportId);
        return;
      }
      setShowCoilsGrid(true);
    }
  }, [dataReport?.parentReportTypeId]);

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      setSaveItems(!saveItems);
      await executeUpdate(values.id, values);
    }
    navigate(`/studies/Coils/step4/${id}/`);
  };

  const handleBack = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      setSaveItems(!saveItems);
      await executeUpdate(values.id, values);
    }
    navigate(`/studies/Coils/step2/${id}/`);
  };

  const handleSave = async () => {
    if (!validate()) return;
    setSaveItems(!saveItems);
    await executeUpdate(values.id, values);
  };

  const nearestGlycolFreezeProtectionValue = (e: any) => {
    console.log("nearestGlycolFreezeProtectionValue called");
    let percent = e.target.value;
    if (percent == null) {
      return null;
    }
    let result: IGlycolFactor = {
      id: 0,
      glycolType: "",
      glycolPercent: 0,
      waterConstantFactor: 0,
      freezeProtectionValue: 0,
      glycolHeatTransferControlValue: 0,
      freezeProtectionValueSelectable: false,
      specificGravity: 0,
    };

    percent =
      values.glycolFreezeProtectionValueActualSign === "-"
        ? percent * -1
        : percent;

    const lowest =
      systemOfMeasurement.measurementSystem === "metricSystem" ? -51.1 : -60;
    const highest =
      systemOfMeasurement.measurementSystem === "metricSystem" ? 0 : 32;
    const dataGlycolFactorFiltered = dataGlycolFactor.filter(
      (item) =>
        item.glycolType ===
        (values?.actualGlycolTypeId === 1
          ? "Ethylene Glycol"
          : "Propylene Glycol")
    );

    if (Number(percent) >= highest) {
      result = dataGlycolFactorFiltered[0];
    } else if (Number(percent) <= lowest) {
      result = dataGlycolFactorFiltered[12];
    } else {
      dataGlycolFactorFiltered.forEach((item, index) => {
        const nextItem =
          index === dataGlycolFactorFiltered.length - 1
            ? dataGlycolFactorFiltered[index]
            : dataGlycolFactorFiltered[index + 1];
        if (index < dataGlycolFactorFiltered.length - 1) {
          const freezeProtectionValueHig =
            systemOfMeasurement.measurementSystem === "metricSystem"
              ? item.freezeProtectionValueIS
              : item.freezeProtectionValue;
          const freezeProtectionValueLow =
            systemOfMeasurement.measurementSystem === "metricSystem"
              ? nextItem.freezeProtectionValueIS
              : nextItem.freezeProtectionValue;
          if (
            Number(percent) <= Number(freezeProtectionValueHig) &&
            Number(percent) >= Number(freezeProtectionValueLow)
          ) {
            const gap = Math.abs(
              Number(freezeProtectionValueHig) -
                Number(freezeProtectionValueLow)
            );
            const diff = Math.abs(
              Number(freezeProtectionValueHig) - Number(percent)
            );
            result = diff <= gap / 2 ? item : nextItem;
          }
        }
      });
    }

    setValues({
      ...values,
      [e.target.name]: e.target.value,
      glycolPercentageActual: result.glycolPercent.toString(),
      glycolFactor: result.specificGravity.toString(),
      glycolHeatTransferControlValueActual:
        systemOfMeasurement.measurementSystem === "metricSystem"
          ? result.glycolHeatTransferControlValueIS
          : result.glycolHeatTransferControlValue,
    });
  };

  const nearestGlycolPercent = (e: any) => {
    const percent = e.target.value;
    if (percent == null) {
      return null;
    }
    let result: IGlycolFactor = {
      id: 0,
      glycolType: "",
      glycolPercent: 0,
      waterConstantFactor: 0,
      freezeProtectionValue: 0,
      glycolHeatTransferControlValue: 0,
      freezeProtectionValueSelectable: false,
      specificGravity: 0,
    };

    const dataGlycolFactorFiltered = dataGlycolFactor.filter(
      (item) =>
        item.glycolType ===
        (values?.actualGlycolTypeId === 1
          ? "Ethylene Glycol"
          : "Propylene Glycol")
    );

    dataGlycolFactorFiltered.forEach((item, index) => {
      const nextItem =
        index === dataGlycolFactorFiltered.length - 1
          ? dataGlycolFactorFiltered[index]
          : dataGlycolFactorFiltered[index + 1];
      if (!!result.id) return; //This line prevents the code to get an undefined item if it's running on the edge of the array
      if (
        parseFloat(percent) >= item.glycolPercent &&
        parseFloat(percent) <= nextItem.glycolPercent
      ) {
        const { glycolPercent } = item;
        const glycolPercentHigh = nextItem.glycolPercent;
        const gap = Math.abs(glycolPercentHigh - glycolPercent);
        const diff = Math.abs(parseFloat(percent) - glycolPercent);
        result = diff <= gap / 2 ? item : nextItem;
      }
    });
    const glycolFreezeProtectionValueActual =
      systemOfMeasurement.measurementSystem === "metricSystem"
        ? result.freezeProtectionValueIS
        : result.freezeProtectionValue;

    const glycolFreezeProtectionValueActualValues = Math.abs(
      glycolFreezeProtectionValueActual ?? 0
    );
    const glycolFreezeProtectionValueActualSignValues =
      (glycolFreezeProtectionValueActual ?? 0) < 0 ? "-" : "+";
    const glycolFactorValues = result.specificGravity;
    const glycolHeatTransferControlValueActualValues =
      systemOfMeasurement.measurementSystem === "metricSystem"
        ? result.glycolHeatTransferControlValueIS
        : result.glycolHeatTransferControlValue;

    setValues({
      ...values,
      [e.target.name]: e.target.value,
      glycolFreezeProtectionValueActual:
        glycolFreezeProtectionValueActualValues,
      glycolFreezeProtectionValueActualSign:
        glycolFreezeProtectionValueActualSignValues,
      glycolFactor: glycolFactorValues.toString(),
      glycolHeatTransferControlValueActual:
        glycolHeatTransferControlValueActualValues,
    });
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableAfterSteadyStep
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    disableAfterSteadyStep,
  ]);

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: dataReport?.reportTypeId === 4 ? "COTD" : "COAH",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={2}
                projectId={values?.projectId}
                reportId={id}
                reportTypeId={values?.reportTypeId}
              ></StudyWizard>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                {values?.containsBlycol && (
                  <Grid item xs={12}>
                    <Typography variant="h5" color="primary.main">
                      System Contains Glycol
                    </Typography>
                  </Grid>
                )}
                {values?.containsBlycol && (
                  <Grid item xs={6}>
                    <FormCheckSwitch
                      name={""}
                      label={"I know the % Glycol Solution"}
                      value={values?.glycolDataSource === "Percent"}
                      onChange={() => {
                        setValues({ ...values, glycolDataSource: "Percent" });
                      }}
                      disabled={disableStudy}
                    ></FormCheckSwitch>
                    <FormTooltip
                      text={
                        <>
                          <Typography>
                            Eutectic point - The point on a phase diagram where
                            the maximum number of allowable phases are in
                            equilibrium. When this point is reached, the
                            temperature must remain constant until one of the
                            phases disappears.
                          </Typography>
                          <Typography>
                            Eutectic point starts at 65% Glycol concentration.
                          </Typography>
                        </>
                      }
                    ></FormTooltip>
                  </Grid>
                )}
                {values?.containsBlycol && (
                  <Grid item xs={6}>
                    <FormCheckSwitch
                      name={""}
                      label={"I know the Freeze Protection Value"}
                      value={
                        values?.glycolDataSource === "Freeze Protection Value"
                      }
                      onChange={() => {
                        setValues({
                          ...values,
                          glycolDataSource: "Freeze Protection Value",
                        });
                      }}
                      disabled={disableStudy}
                    ></FormCheckSwitch>
                    <GridActionButton
                      type="picture"
                      onClick={() => {
                        setGlycolWaterGravityModal(true);
                      }}
                      tooltip="Glycol Refractometer Reading Example"
                    />
                    <FormTooltip
                      text={
                        <Typography>
                          Recommend testing Glycol/Freeze protection using a
                          Temperature Compensated Refractometer
                        </Typography>
                      }
                    ></FormTooltip>
                  </Grid>
                )}
                {values?.containsBlycol && values?.glycolDataSource != null && (
                  <>
                    <Grid item xs={2}>
                      <FormSelect
                        name={"actualGlycolTypeId"}
                        label={"Glycol Type"}
                        value={(
                          values?.actualGlycolTypeId || "null"
                        )?.toString()}
                        onChange={handleInputChange}
                        options={glycolType}
                        error={errors.actualGlycolTypeId}
                        defaultValue={{
                          key: "null",
                          value: "Select Glycol Type",
                        }}
                        disabled={disableStudy}
                      ></FormSelect>
                    </Grid>
                    <Grid item xs={2}>
                      <FormNumeric2
                        name={"glycolPercentageActual"}
                        label={`Glycol % by Volume`}
                        value={values?.glycolPercentageActual}
                        onBlur={nearestGlycolPercent}
                        disabled={
                          disableStudy ||
                          values?.glycolDataSource ===
                            "Freeze Protection Value" ||
                          !values?.glycolTypeId
                        }
                        maxValue={101}
                        decimalScale={3}
                        thousandSeparator={true}
                      ></FormNumeric2>
                    </Grid>
                    <Grid item xs={4}>
                      <Grid container>
                        <Grid item xs={1} textAlign={"right"}>
                          <Typography variant="h4" pt={1} pr={2}>
                            {values?.glycolFreezeProtectionValueActualSign ===
                            "-"
                              ? "-"
                              : "+"}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormNumeric2
                            name={"glycolFreezeProtectionValueActual"}
                            label={`Glycol Freeze Protection Value ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            value={values?.glycolFreezeProtectionValueActual?.toString()}
                            onBlur={nearestGlycolFreezeProtectionValue}
                            disabled={
                              disableStudy ||
                              values?.glycolDataSource === "Percent"
                            }
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setValues({
                                ...values,
                                glycolFreezeProtectionValueActualSign: "+",
                              });
                            }}
                            disabled={
                              disableStudy ||
                              values?.glycolDataSource === "Percent"
                            }
                          >
                            <AddCircleOutline />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              setValues({
                                ...values,
                                glycolFreezeProtectionValueActualSign: "-",
                              });
                            }}
                            disabled={
                              disableStudy ||
                              values?.glycolDataSource === "Percent"
                            }
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={2}>
                      <FormNumeric2
                        name={"glycolFactor"}
                        label={`Glycol Specific Gravity`}
                        value={values?.glycolFactor}
                        onChange={handleInputChange}
                        mode={"read"}
                        decimalScale={3}
                        thousandSeparator={true}
                        disabled={disableStudy}
                      ></FormNumeric2>
                    </Grid>
                    <Grid item xs={2} pr={0}>
                      <FormNumeric2
                        name={"glycolFreezeProtectionValuePOD"}
                        label={`Glycol Protection % of Design`}
                        value={values?.glycolFreezeProtectionValuePOD}
                        onChange={handleInputChange}
                        mode={"read"}
                        decimalScale={3}
                        color={
                          values?.glycolFreezeProtectionValuePOD !== 100
                            ? "red"
                            : ""
                        }
                        thousandSeparator={true}
                        disabled={disableStudy}
                      ></FormNumeric2>
                    </Grid>
                  </>
                )}
                {values?.glycolHeatTransferControlValue && (
                  <Grid item xs={12} textAlign={"center"}>
                    <Grid container>
                      <Grid item xs={4}></Grid>
                      <Grid item xs={4}>
                        <Alert mb={4} severity="error">
                          <Typography>
                            <Typography variant="subtitle2">
                              Heat Transfer Control Value
                            </Typography>
                            <Typography>
                              {values?.glycolHeatTransferControlValue?.toFixed(
                                2
                              )}
                            </Typography>
                          </Typography>
                        </Alert>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {values?.containsBlycol && (
                  <Grid item xs={12}>
                    <Divider></Divider>
                  </Grid>
                )}
                {showCoilsGrid && (
                  <Grid item xs={12}>
                    <CoilsGrid
                      id={values?.id}
                      isLoading={false}
                      mode={"read&Write"}
                      readOnly={false}
                      saveItems={saveItems}
                      systemOfMeasurement={systemOfMeasurement}
                      disableStudy={disableStudy}
                      coils={values}
                      setSaveTotals={setSaveTotals}
                      saveTotals={saveTotals}
                      setShowSteadyState={setShowSteadyState}
                      isEnergyProcess={isEnergyProcess}
                      systemIsMA={energyValues?.systemIsMA}
                    ></CoilsGrid>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={2}
                      stepsCount={steps.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId}
                      isSaving={isSubmittingUpdate}
                      disabled={isLoading || isSubmittingUpdate || disableStudy}
                      reportName="Coil AHU Mixed Air Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      previousStepHandler={handleBack}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {values && values?.projectId && showSteadyState && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={values?.reportTypeId}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
        />
      )}
      <DialogMessagePopup
        title={"Glycol Refractometer Reading Example"}
        size={"sm"}
        text={
          <Grid container>
            <Grid item textAlign={"center"}>
              <Box
                component="img"
                alt="InstantSSL"
                src={"/static/img/GlycolWaterGravity.png"}
                sx={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        }
        showPopup={glycolWaterGravityModal}
        setShowPopup={setGlycolWaterGravityModal}
        onSave={function (): void {
          throw new Error("Function not implemented.");
        }}
        hideAccept={true}
        isSubmitting={false}
        cancelTextButton="Close"
      />
      <DialogMessagePopup
        title="Important"
        size="sm"
        text={
          <p>
            Ensure Total System {systemOfMeasurement.get("gpm")} is properly
            represented and indicated here
          </p>
        }
        hideCancel
        acceptTextButton="Acknowledged"
        showPopup={showGPMSystemModal}
        setShowPopup={setShowGPMSystemModal}
        isSubmitting={false}
        onSave={() => setShowCoilsGrid(true)}
        disableClickOutside
      />
    </>
  );
};

export default CoilsStep3;
