import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  Chip as MuiChip,
  Alert,
} from "@mui/material";

import { SpacingProps, spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormButton,
  FormCheckBox,
  FormCheckSwitch,
  FormLabel,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IProjectReportInfectiousComplianceControl,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  CenterlineStaticPressure,
  DryBulbF,
  DuctType,
  MethodMeasurement,
  ROLES,
  STEPSVALUES,
} from "src/constants";
import { CatalogService } from "src/services";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import getProjectService from "src/services/project/getProjectService";
import { IPitotTraverseTest } from "src/ts/interfaces/study/pitot";
import pitotTraverseTestService from "src/services/study/pitotTraverseTestService";
import PitotFanTraverseGrid from "./components/PitotFanTraverseGrid";
import PitotFanTraverseTTGrid from "./components/PitotFanTraverseTTGrid";
import { STEPS } from "./components/constants";
import SteadyStateDialog from "../components/SteadyState";
import { FooterButtons } from "../components/FooterButtons";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import {
  DashboardCustomizeOutlined,
  HvacOutlined,
  SportsRugbyOutlined,
  StraightenOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import getProjectSingleService from "src/services/getProjectSingleService";
import SubStudyTypeSelect from "src/components/studies/SubStudyTypeSelect";

interface ChipProps extends SpacingProps {
  component?: React.ElementType;
  href?: string;
  icon?: JSX.Element | null;
}

interface IMessagePsychrometric {
  text: string;
  color: "info" | "warning" | "success" | "error";
}

interface IOvalaAction {
  ovalAction: boolean;
  yesOvalAction: boolean;
  noOvalAction: boolean;
}

const Chip = styled(MuiChip)<ChipProps>(spacing);

const typeOfTerminal = [
  {
    key: 1,
    labelCustom: (
      <>
        <Stack direction={"row"}>
          <ViewModuleOutlined />
          <Typography mt={0.2}>
            <b>Rectangular</b>
          </Typography>
        </Stack>
      </>
    ),
  },
  {
    key: 2,
    labelCustom: (
      <>
        <Stack direction={"row"} spacing={1}>
          <HvacOutlined />
          <Typography mt={0.2}>
            <b>Round</b>
          </Typography>
        </Stack>
      </>
    ),
  },
  {
    key: 3,
    labelCustom: (
      <>
        <Stack direction={"row"} spacing={1}>
          <SportsRugbyOutlined />
          <Typography mt={0.2}>
            <b>oval</b>
          </Typography>
        </Stack>
      </>
    ),
  },
];

const initialValues: IPitotTraverseTest = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  step: 0,
  systemTotal: false,
  outletTotal: false,
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  systemField: "",
  description: "",
  comments: "",
  minDucts: 0,
  location: "",
  areaServed: "",
  system: "",
  ductTypeId: 0,
  methodMeasurementsId: 0,
  centerlineStaticPressureId: 0,
  conditionId: 0,
  isVFD: false,
  numberOfDuctsId: 0,
  nocVisual: false,
  nocActual: false,
  vfdHzIsSelect: false,
  vfdPercentageIsSelect: false,
  vfdOtherName: "",
  vfdOtherIsSelect: false,
  isActualLocalBarometricPressure: false,
  barometricalyCompensated: false,
  velocityReadingTemperatureCompensated: false,
  isComplete: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  statusId: 0,
  isProjectDisabled: false,
  totalHeat: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const PitotFanTraverse = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [amcaAlertModal, setAmcaAlertModal] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [totalTT, setTotalTT] = useState(0);
  const [totalItemsTT, setTotalItemsTT] = useState(0);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [selectRadioButton] = useState(initialValues.ductTypeId);
  const [stepContinuos, setStepContinuos] = useState(STEPSVALUES.STEP1);
  const [showSteady, setShowSteady] = useState(false);
  const [disableEditData, setDisableEditData] = useState(false);
  const [dataBase, setDataBase] = useState(0);
  const [dataHeight, setDataHeight] = useState(0);
  const [dataInsulation, setDataInsulation] = useState(0);
  const [dimensionTotal, setDimensionTotal] = useState(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [pitotMatrix, setPitotMatrix] = useState<IPitotTraverseTest | null>();
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [messagePsychrometric, setMessagePsychrometric] =
    useState<IMessagePsychrometric>({ text: "", color: "info" });
  const [pitotTraverseGrid, setPitotTraverseGrid] = useState(false);
  const [changeMatrixButton, setChangeMatrixButton] = useState(false);
  const [gridLoading, setGridLoading] = useState(false);
  const [ovalAction, setOvalAction] = useState<IOvalaAction>({
    ovalAction: false,
    yesOvalAction: false,
    noOvalAction: false,
  });

  let navigate = useNavigate();
  const holePointSide = [
    { key: 4, value: 4 },
    { key: 5, value: 5 },
    { key: 6, value: 6 },
    { key: 7, value: 7 },
    { key: 8, value: 8 },
  ];
  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(pitotTraverseTestService.update, {
      successfulMessage: "Report was updated successfully",
      onSuccess: async (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh, isLoading: isLoadingUpdate } = useAsyncQuery(
    pitotTraverseTestService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
          nocOaFirstNumber:
            result?.nocOaFirstNumber !== null ? result?.nocOaFirstNumber : 0,
          nocRaFirstNumber:
            result?.nocRaFirstNumber !== null ? result?.nocRaFirstNumber : 0,
          nocOaSecondNumber:
            result?.nocOaSecondNumber !== null ? result?.nocOaSecondNumber : 0,
          nocExhaustNumber:
            result?.nocExhaustNumber !== null ? result?.nocExhaustNumber : 0,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
      },
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (stepContinuos === STEPSVALUES.STEP2) {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .isRequired("Airflow Temperature For Energy Calculation Is Required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IPitotTraverseTest>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IPitotTraverseTest>(
    pitotTraverseTestService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          let result = await formulaSystem.setSystem(dataResult?.projectId);
          setFormulas(result);
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = result.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }
          if (!dataResult.ductTypeId) {
            dataResult.ductTypeId = DuctType.Rectangular;
          }
          if (!dataResult.methodMeasurementsId) {
            dataResult.methodMeasurementsId = MethodMeasurement.AABC;
          }
          setDataBase(
            dataResult.ductSizeBase === null ? 0 : dataResult.ductSizeBase
          );
          setDataHeight(
            dataResult.ductSizeHeight === null ? 0 : dataResult.ductSizeHeight
          );
          setDataInsulation(
            dataResult.internalInsulation === null
              ? 0
              : dataResult.internalInsulation
          );

          if (dataResult?.ductTypeId === DuctType.Oval) {
            setOvalAction({
              ovalAction: true,
              yesOvalAction: false,
              noOvalAction: false,
            });
          } else {
            setOvalAction({
              ovalAction: false,
              yesOvalAction: false,
              noOvalAction: false,
            });
          }
          setValues({
            ...dataResult,
            ductSizeBase:
              dataResult.ductSizeBase === null ? 0 : dataResult.ductSizeBase,
            ductSizeHeight:
              dataResult.ductSizeHeight === null
                ? 0
                : dataResult.ductSizeHeight,
            internalInsulation:
              dataResult.internalInsulation === null
                ? 0
                : dataResult.internalInsulation,
          });

          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(dataResult?.projectId);
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
          await executeReport(
            id,
            dataResult?.reportTypeId === 5 ? "PITT" : "PTFR"
          );
        };
        getData();
      },
    }
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [activeStep, setActiveStep] = useState<number>(
    params.step?.toLowerCase() === STEPSVALUES.STEP1 ? 0 : 1
  );

  const [saveItems, setSaveItems] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      execute(id);
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let dimensionTotal = undefined;

    if (values.ductTypeId === DuctType.Rectangular) {
      dimensionTotal = formulas?.sqft(
        ((values?.ductSizeHeight ?? 0) -
          (values?.internalInsulation ?? 0) * 2) *
          ((values?.ductSizeBase ?? 0) - (values?.internalInsulation ?? 0) * 2)
      );
    } else if (values.ductTypeId === DuctType.Round) {
      dimensionTotal = formulas?.sqft(
        (((values?.ductSizeBase ?? 0) - (values?.internalInsulation ?? 0) * 2) /
          2) *
          (((values?.ductSizeBase ?? 0) -
            (values?.internalInsulation ?? 0) * 2) /
            2) *
          3.14159265359
      );
    } else if (values.ductTypeId === DuctType.Oval) {
      let pi = 3.14159265359;
      let ovalRectWidth =
        (values?.ductSizeBase ?? 0) - (values?.ductSizeHeight ?? 0);
      let ovalCircleRadius =
        ((values?.ductSizeHeight ?? 0) -
          (values?.internalInsulation ?? 0) * 2) /
        2;
      let ovalRoundArea = pi * Math.pow(ovalCircleRadius, 2);
      let ovalRectArea =
        ovalRectWidth *
        ((values?.ductSizeHeight ?? 0) - (values?.internalInsulation ?? 0) * 2);
      dimensionTotal = formulas?.sqft(ovalRectArea + ovalRoundArea);
    }

    setDimensionTotal(dimensionTotal ?? 0);
    setValues({
      ...values,
      ductSize: dimensionTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.ductSizeBase,
    values?.ductSizeHeight,
    values.ductTypeId,
    values?.internalInsulation,
    values.methodMeasurementsId,
  ]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === STEPSVALUES.STEP1 ? 0 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  useEffectOnce(() => {
    const getData = async () => {
      const pitotMatrix = await pitotTraverseTestService.getById(id);
      setPitotMatrix(pitotMatrix.data);
      await getParentReportIsComplete(pitotMatrix.data.id, "VEMR");
      await getProject(pitotMatrix.data.projectId);
      await getProjectReport(
        pitotMatrix.data.id,
        pitotMatrix.data.reportTypeId,
        pitotMatrix.data.projectId
      );
      await getInfectiousControlData(pitotMatrix.data.id);
    };
    if (id > 0) getData();
  });

  const refreshCompletedStudy = async () => {
    try {
      const pitotMatrix = await pitotTraverseTestService.getById(id);
      setPitotMatrix(pitotMatrix.data);
      const projectResponse = await getProjectSingleService.single(
        pitotMatrix.data.projectId
      );
      setProject(projectResponse.data);
      await execute(id);
      await getProjectReport(
        pitotMatrix?.data.id ?? 0,
        pitotMatrix?.data.reportTypeId ?? 0,
        pitotMatrix?.data.projectId ?? 0
      );
      setMessagePsychrometric({ text: "", color: "info" });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  useEffect(() => {
    if (!disableStudy && dataReport) onBlurCalcPsycometrics();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  const validateAmcaAlert = () => {
    if (
      values?.ductTypeId === DuctType.Rectangular &&
      values?.methodMeasurementsId === MethodMeasurement.AMCA &&
      values?.ductSize &&
      values?.ductSize < 1
    ) {
      setAmcaAlertModal(true);
    }
  };

  const refreshCalcValue = async (
    ductTypeId?: number,
    methodMeasurementsId?: number
  ) => {
    let newValues = { ...values };
    if (ductTypeId) {
      newValues.ductSizeBase = 0;
      newValues.ductSizeHeight = 0;
      newValues.ductSize = 0;
      newValues.internalInsulation = 0;
      newValues.conditionId = 0;
      newValues.ductTypeId = ductTypeId;
      newValues.isVFD = false;
      setDataBase(0);
      setDataHeight(0);
      setDataInsulation(0);

      if (newValues?.ductTypeId === DuctType.Oval) {
        setOvalAction({
          ovalAction: true,
          yesOvalAction: false,
          noOvalAction: false,
        });
      } else {
        setOvalAction({
          ovalAction: false,
          yesOvalAction: false,
          noOvalAction: false,
        });
      }
      setChangeMatrixButton(false);
      setStepContinuos(STEPSVALUES.STEP1);
    }
    if (methodMeasurementsId)
      newValues.methodMeasurementsId = methodMeasurementsId;
    if (newValues.ductTypeId === DuctType.Oval) {
      newValues.methodMeasurementsId = MethodMeasurement.AABC;
    }
    setValues({ ...newValues });
  };

  const calcTotal = async (totalItem: number, numberItems: number) => {
    if (disableStudy) return;
    setTotal(totalItem);
    setTotalItems(numberItems);

    if (values && values.id > 0 && values?.ductTypeId !== DuctType.Oval) {
      const newValues = { ...values };
      newValues.avgVelocity = totalItem / numberItems;
      newValues.ductSize = dimensionTotal;
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    } else {
      const newValues = { ...values };
      newValues.avgVelocity =
        (totalItem + totalTT) / (numberItems + totalItemsTT);
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    }
  };

  const calcTotalTT = async (totalItem: number, numberItems: number) => {
    if (disableStudy) return;
    setTotalTT(totalItem);
    setTotalItemsTT(numberItems);
    if (values && values.id > 0 && values?.ductTypeId !== DuctType.Oval) {
      const newValues = { ...values };
      newValues.avgVelocity = totalItem / numberItems;
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    } else {
      const newValues = { ...values };
      newValues.avgVelocity = (totalItem + total) / (numberItems + totalItems);
      setValues({
        ...newValues,
      });
      executeRefresh(newValues.id, newValues);
    }
  };

  const onBlurOther = async (e: any) => {
    if (disableStudy) return;

    const newValues = {
      ...values,
      [e.target.name]: e.target.value,
    };
    setValues(newValues);

    if (stepContinuos === STEPSVALUES.STEP1) {
      await executeRefresh(values.id, newValues);
    }
  };

  const saveOnBlurHandler = async () => {
    if (disableStudy) return;
    executeRefresh(values.id, values);
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6))
      setTotalCFM(totalCFM);
  };

  const onBlurCalcPsycometrics = (e?: any) => {
    if (e) {
      const newValues = { ...values, [e.target.name]: e.target.value };
      calcPsycometrics(values?.totalCFM!, undefined, newValues);
    } else {
      calcPsycometrics(values?.totalCFM!);
    }
  };

  const calcPsycometrics = async (
    total: number,
    dryBulb?: number,
    newValues?: IPitotTraverseTest
  ) => {
    if (disableStudy) return;
    let humidityMeasuredAs = "";
    if (values?.dryBulbFId === DryBulbF.Relativeumidity) {
      humidityMeasuredAs = "RH";
    }
    if (values?.dryBulbFId === DryBulbF.DewPoint) {
      humidityMeasuredAs = "DP";
    }
    if (values?.dryBulbFId === DryBulbF.WetBulbF) {
      humidityMeasuredAs = "WB";
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: values.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: values?.humidityValue ?? 0,
      dryBulbF: values.dryBulbF!,
      totalCFM: total,
    };
    if (
      newValues?.totalHeat &&
      !isNaN(values?.humidityValue ?? 0) &&
      !isNaN(newValues?.dryBulbF ?? 0) &&
      !isNaN(newValues?.totalCFM ?? 0)
    ) {
    }

    const psychrometricRes = await psychrometric.getByProject(
      psychometricsArgs
    );

    setMessagePsychrometric({ text: "", color: "info" });
    if (stepContinuos !== STEPSVALUES.STEP1) {
      if (
        psychrometricRes.psychrometric.message !== null &&
        psychrometricRes.psychrometric.enthalpy === 0
      )
        setMessagePsychrometric({
          text: psychrometricRes.psychrometric.message,
          color: "warning",
        });
      else if (psychrometricRes.psychrometric.message !== null) {
        setMessagePsychrometric({
          text: psychrometricRes.psychrometric.message,
          color: "info",
        });
      }
      let oldValues: any = { ...values };
      if (newValues) oldValues = { ...newValues };
      oldValues.btuh = psychrometricRes.btuh!;
      oldValues.sensibleHeat = psychrometricRes.sensibleHeat!;
      if (dryBulb) {
        oldValues.dryBulbFId = dryBulb;
        oldValues.humidityValue = undefined;
        setValues({
          ...oldValues,
          btuh: psychrometricRes.btuh!,
          sensibleHeat: psychrometricRes.sensibleHeat!,
          dryBulbFId: dryBulb,
          humidityValue: undefined,
        });
      } else {
        setValues({
          ...oldValues,
          btuh: psychrometricRes.btuh!,
          sensibleHeat: psychrometricRes.sensibleHeat!,
        });
      }
      if (oldValues.id > 0) await executeRefresh(oldValues.id, oldValues);
    }
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      setSaveItems(!saveItems);
      await executeUpdate(values.id, values);
    }
    if (params.step?.toLowerCase() === STEPSVALUES.STEP1)
      navigate(
        `/studies/PitotTraverseStart${
          values?.reportTypeId === 5 ? "" : "TT"
        }/Step2/${id}`
      );
    else
      navigate(
        `/studies/PitotTraverseStart${
          values?.reportTypeId === 5 ? "" : "TT"
        }/Step3/${id}`
      );
    setActiveStep(1);
  };

  const handleCheckNoc = (e: any) => {
    values.nocActual = false;
    values.nocVisual = false;
    switch (e.target.name) {
      case "nocActual": {
        values.nocActual = true;
        break;
      }
      case "nocVisual": {
        values.nocVisual = true;
        break;
      }
    }
    setValues({ ...values });
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const isOther = useMemo(() => {
    const found = conditionOfTest.find((item) => item.value === "Other");
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (stepContinuos === STEPSVALUES.STEP1) {
      setShowSteady(true);
    }
  };

  const handleSaveButton = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);

    setSaveItems(!saveItems);
  };

  const showSteadyPopUp = useMemo(() => {
    return (
      showSteady &&
      stepContinuos !== STEPSVALUES.STEP1 &&
      values &&
      values?.projectId > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSteady, stepContinuos, values, values?.projectId]);

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const mode = disableStudy ? "read" : "read&Write";

  const handleBackStep = async () => {
    if (!disableStudy) {
      setSaveItems(!saveItems);
      await handleSave();
    }
    navigate(
      `/studies/PitotTraverseStart${
        values?.reportTypeId === 5 ? "" : "TT"
      }/Step1/${id}`
    );
    setActiveStep(0);
  };

  const handleChangeRadio = (dryBulb: number) => {
    calcPsycometrics(values?.totalCFM!, dryBulb);
  };

  const showskeleton = useMemo(
    () => isLoading || isSubmittingUpdate || gridLoading,
    [isLoading, isSubmittingUpdate, gridLoading]
  );

  const disableStep1 = disableStudy || stepContinuos === STEPSVALUES.STEP1;

  const handleInputChangeCompesation = (e: any) => {
    values.velocityReadingTemperatureCompensated = e.target.value;
    if (values.airflowTemperature === values.dryBulbF) {
      values.dryBulbF = 70;
    }
    values.airflowTemperature = 70;
    setValues({ ...values });
  };

  const handleChangeSpecificDefault = async (e: any) => {
    let isActualLocalBarometricPressure = e.target.value;
    let newValues = { ...values };
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF:
          systemOfMeasurement.measurementSystem === "imperialSystem"
            ? 70
            : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      newValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;

      calcPsycometrics(newValues?.totalCFM!, undefined, newValues);
    } else {
      newValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;

      calcPsycometrics(newValues?.totalCFM!, undefined, newValues);
    }
  };

  useMemo(() => {
    const validateStep2 = async () => {
      let active = false;

      if (
        values?.ductTypeId === DuctType.Rectangular ||
        values?.ductTypeId === DuctType.Oval
      ) {
        active = [
          values?.ductSizeBase &&
            values?.ductSizeHeight &&
            values?.methodMeasurementsId &&
            values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (active) {
          setStepContinuos(STEPSVALUES.STEP2);
          if (!disableStudy) await handleSave();
        }
      } else if (values?.ductTypeId === DuctType.Round) {
        active = [
          values?.ductSizeBase &&
            values?.methodMeasurementsId &&
            values?.conditionId,
        ].every((value) => value !== 0 && value !== null);
        if (active) {
          setStepContinuos(STEPSVALUES.STEP2);
          if (!disableStudy) await handleSave();
        }
      }
    };
    const trainingValidate = async () => {
      let active = false;

      if (
        values?.ductTypeId === DuctType.Rectangular ||
        values?.ductTypeId === DuctType.Oval
      ) {
        active =
          (values?.conditionId ?? 0) !== 0 &&
          (values?.ductSizeBase ?? 0) < 23 &&
          (values?.ductSizeHeight ?? 0) < 23;

        if (active) {
          if (!disableStudy) await handleSave();
          setStepContinuos(STEPSVALUES.STEP2);
        }
      } else if (values?.ductTypeId === DuctType.Round) {
        active =
          (values?.conditionId ?? 0) !== 0 && (values?.ductSizeBase ?? 0) < 11;
        if (active) {
          if (!disableStudy) await handleSave();
          setStepContinuos(STEPSVALUES.STEP2);
        }
      }
    };
    if (user?.role !== ROLES.Training) validateStep2();
    if (user?.role === ROLES.Training) trainingValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.ductSizeBase,
    values?.ductSizeHeight,
    values?.methodMeasurementsId,
    values?.internalInsulation,
    values?.conditionId,
  ]);

  const disableCompleteStudy =
    values?.dryBulbF === 0 || values?.dryBulbF === null;

  const changeMatrix = () => {
    setPitotTraverseGrid(true);
  };

  const showUpdateButton = useMemo(() => {
    if (
      values?.ductTypeId === DuctType.Rectangular ||
      values?.ductTypeId === DuctType.Oval
    ) {
      return !!(
        user?.role !== ROLES.Training &&
        changeMatrixButton &&
        !disableStudy &&
        values?.ductSizeBase &&
        values?.ductSizeHeight &&
        values?.internalInsulation !== undefined &&
        values?.internalInsulation !== null &&
        values?.ductSizeBase > 0 &&
        values?.ductSizeHeight > 0 &&
        values?.internalInsulation >= 0
      );
    } else if (values?.ductTypeId === DuctType.Round) {
      return !!(
        user?.role !== ROLES.Training &&
        changeMatrixButton &&
        !disableStudy &&
        values?.ductSizeBase &&
        values?.internalInsulation !== undefined &&
        values?.internalInsulation !== null &&
        values?.ductSizeBase > 0 &&
        values?.internalInsulation >= 0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.ductTypeId,
    changeMatrixButton,
    disableStudy,
    values?.ductSizeBase,
    values?.ductSizeHeight,
    values?.internalInsulation,
  ]);

  const trainingModeUpdateButton =
    (user?.role === ROLES.Training &&
      changeMatrixButton &&
      (values?.ductTypeId === DuctType.Rectangular ||
        values?.ductTypeId === DuctType.Oval) &&
      values?.ductSizeBase &&
      values?.ductSizeHeight &&
      values?.ductSizeBase < 23 &&
      values?.ductSizeHeight < 23) ||
    (user?.role === ROLES.Training &&
      changeMatrixButton &&
      values?.ductTypeId === DuctType.Round &&
      values?.ductSizeBase &&
      values?.ductSizeBase < 11);

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: values?.reportTypeId === 5 ? "PITT" : "PTFR",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <h3>System Configuration</h3>
              <Grid container spacing={5}>
                <Grid item xs={12} md={3}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showskeleton}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showskeleton}
                    maxLength={50}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormText
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showskeleton}
                    mode={mode}
                  />
                </Grid>
                {dataReport && dataReport.parentReportId !== null && (
                  <SubStudyTypeSelect
                    disabled={disableStudy}
                    handleInputChange={handleInputChange}
                    setValues={setValues}
                    values={values}
                    showSkeleton={showskeleton}
                  />
                )}
                <Grid item xs={12}>
                  <Divider />
                  <h3>Type of Terminal Device being tested</h3>
                  <Grid item xs={12}>
                    <Grid container>
                      <Grid item xs={6}>
                        <FormRadioGroup
                          name="ductTypeId"
                          value={values?.ductTypeId}
                          items={typeOfTerminal}
                          row
                          onChange={(e: any) =>
                            refreshCalcValue(Number(e.target.value))
                          }
                          defaultValue={selectRadioButton}
                          showSkeleton={isLoading || isSubmittingUpdate}
                          mode={mode}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider my={3}></Divider>
                  <h3>Dimensions </h3>
                  <Grid container mt={2}>
                    <Grid item xs={12} md={6}>
                      <Stack mb={1}>
                        <FormLabel
                          text="Method for Measurements"
                          sx={{
                            marginBottom: "1px",
                          }}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container>
                        <Grid item xs={12} md={2}>
                          <FormCheckBox
                            name={"AABC"}
                            onChange={() => {
                              refreshCalcValue(
                                undefined,
                                MethodMeasurement.AABC
                              );
                            }}
                            label={"AABC"}
                            showSkeleton={showskeleton}
                            value={
                              values?.methodMeasurementsId ===
                              MethodMeasurement.AABC
                            }
                            disabled={disableStudy}
                            mode={mode}
                          ></FormCheckBox>
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {(values?.ductTypeId === DuctType.Round ||
                            values?.ductTypeId === DuctType.Rectangular) && (
                            <FormCheckBox
                              name={"LogTchebycheff"}
                              onChange={() => {
                                refreshCalcValue(
                                  undefined,
                                  MethodMeasurement.LogTchebycheff
                                );
                              }}
                              label={"Log Tchebycheff"}
                              showSkeleton={showskeleton}
                              value={
                                values?.methodMeasurementsId ===
                                MethodMeasurement.LogTchebycheff
                              }
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          )}
                        </Grid>
                        <Grid item xs={12} md={3}>
                          {(values?.ductTypeId === DuctType.Round ||
                            values?.ductTypeId === DuctType.Rectangular) && (
                            <FormCheckBox
                              name={"EqualAreaTABB"}
                              onChange={() => {
                                refreshCalcValue(
                                  undefined,
                                  MethodMeasurement.EqualAreaTABB
                                );
                              }}
                              label={"Equal Area/TABB"}
                              showSkeleton={showskeleton}
                              value={
                                values?.methodMeasurementsId ===
                                MethodMeasurement.EqualAreaTABB
                              }
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          {(values?.ductTypeId === DuctType.Round ||
                            values?.ductTypeId === DuctType.Rectangular) && (
                            <FormCheckBox
                              name={"NEBB"}
                              onChange={() => {
                                refreshCalcValue(
                                  undefined,
                                  MethodMeasurement.NEBB
                                );
                              }}
                              label={"NEBB"}
                              showSkeleton={showskeleton}
                              value={
                                values?.methodMeasurementsId ===
                                MethodMeasurement.NEBB
                              }
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                          {(values?.ductTypeId === DuctType.Round ||
                            values?.ductTypeId === DuctType.Rectangular) && (
                            <FormCheckBox
                              name={"AMCA"}
                              onChange={() => {
                                refreshCalcValue(
                                  undefined,
                                  MethodMeasurement.AMCA
                                );
                              }}
                              label={"AMCA"}
                              showSkeleton={showskeleton}
                              value={
                                values?.methodMeasurementsId ===
                                MethodMeasurement.AMCA
                              }
                              disabled={disableStudy}
                              mode={mode}
                            ></FormCheckBox>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {values?.ductTypeId === DuctType.Rectangular && (
                    <Grid container spacing={2} mt={1}>
                      <Grid item xs={12} md={disableStudy ? 3.3 : 6}>
                        <Stack mb={1}>
                          <FormLabel
                            text="Rectangular Dimension"
                            sx={{
                              marginBottom: "3px",
                            }}
                          />
                        </Stack>
                        <Grid container direction={"row"}>
                          <Grid item xs={6} textAlign={"center"}>
                            <FormNumeric2
                              name={"ductSizeBase"}
                              label={"Hole Side ↔"}
                              value={values?.ductSizeBase?.toString() ?? ""}
                              fullWidth="150px"
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              onChange={(e) => {
                                onBlurOther(e);
                                validateAmcaAlert();
                              }}
                              decimalScale={3}
                              thousandSeparator={true}
                              suffix={` ${systemOfMeasurement.get("in")}`}
                            />
                          </Grid>
                          <Grid item xs={0.5}>
                            <Grid container>
                              <Grid item xs={1} mt={2}>
                                <Typography
                                  display={"inline"}
                                  variant="subtitle2"
                                  mt={2}
                                  ml={1}
                                >
                                  by
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5.5} textAlign={"center"}>
                            <FormNumeric2
                              name={"ductSizeHeight"}
                              label={"Points Side ↕"}
                              value={values?.ductSizeHeight?.toString() ?? ""}
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              decimalScale={3}
                              thousandSeparator={true}
                              onChange={(e) => {
                                onBlurOther(e);
                                validateAmcaAlert();
                              }}
                              suffix={` ${systemOfMeasurement.get("in")}`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Grid item xs={12}>
                          <Stack mb={1}>
                            <FormLabel
                              text=" Internal Insulation"
                              sx={{
                                marginBottom: "3px",
                              }}
                            />
                          </Stack>
                          <Grid container direction={"row"}>
                            <Grid
                              item
                              xs={12}
                              textAlign={disableStudy ? "left" : "center"}
                            >
                              <FormNumeric2
                                name={"internalInsulation"}
                                label={""}
                                value={
                                  values?.internalInsulation?.toString() ?? ""
                                }
                                showSkeleton={showskeleton}
                                disabled={disableStudy}
                                mode={mode}
                                onChange={(e) => {
                                  onBlurOther(e);
                                  validateAmcaAlert();
                                }}
                                decimalScale={3}
                                thousandSeparator={true}
                                suffix={` ${systemOfMeasurement.get("in")}`}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} md={2} mt={8} textAlign={"center"}>
                        {(showUpdateButton || trainingModeUpdateButton) && (
                          <FormButton
                            startIcon={<DashboardCustomizeOutlined />}
                            onClick={changeMatrix}
                            text="Update Matrix"
                            size="small"
                            color="success"
                            isSubmitting={showskeleton}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {values?.ductTypeId === DuctType.Rectangular &&
                    values?.methodMeasurementsId ===
                      MethodMeasurement.EqualAreaTABB && (
                      <Grid container spacing={2} mt={2}>
                        <Grid item xs={12} md={6}>
                          <Stack mb={1}>
                            <FormLabel
                              text="Equal Area/TABB"
                              sx={{
                                marginBottom: "3px",
                              }}
                            />
                          </Stack>
                          <Grid container direction={"row"}>
                            <Grid item md={6} textAlign={"center"}>
                              <FormSelect
                                name={"rectTabbX"}
                                label={"Hole Side ↔"}
                                value={values?.rectTabbX}
                                onChange={handleInputChange}
                                options={holePointSide}
                                showSkeleton={showskeleton}
                                disabled={disableStudy}
                                mode={mode}
                              ></FormSelect>
                            </Grid>
                            <Grid item xs={0.5}>
                              <Grid container>
                                <Grid item xs={1} mt={2}>
                                  <Typography
                                    display={"inline"}
                                    variant="subtitle2"
                                    mt={2}
                                    ml={1}
                                  >
                                    by
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={5.5} textAlign={"center"}>
                              <FormSelect
                                name={"rectTabbY"}
                                label={"Points Side ↔"}
                                value={values?.rectTabbY}
                                onChange={handleInputChange}
                                options={holePointSide}
                                showSkeleton={showskeleton}
                                disabled={disableStudy}
                                mode={mode}
                              ></FormSelect>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  {values?.ductTypeId === DuctType.Round && (
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item xs={12} md={3}>
                        <Stack mb={1}>
                          <FormLabel
                            text="Round duct diameter ID (↔)"
                            sx={{
                              color: "rgba(0, 0, 0, 0.6)",
                              marginBottom: "3px",
                            }}
                          />
                        </Stack>
                        <Grid container direction={"row"}>
                          <FormNumeric2
                            name={"ductSizeBase"}
                            label={"Dimension"}
                            value={values?.ductSizeBase?.toString() ?? ""}
                            onChange={(e) => {
                              onBlurOther(e);
                            }}
                            fullWidth="150px"
                            showSkeleton={showskeleton}
                            disabled={disableStudy}
                            mode={mode}
                            decimalScale={3}
                            thousandSeparator={true}
                            suffix={` ${systemOfMeasurement.get("in")}`}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={0} md={0.2}></Grid>
                      <Grid item xs={12} md={3}>
                        <Stack mb={1}>
                          <FormLabel
                            text="Round or Rect Insulation Thickness"
                            sx={{
                              color: "rgba(0, 0, 0, 0.6)",
                              marginBottom: "3px",
                            }}
                          />
                        </Stack>
                        <Grid container direction={"row"}>
                          <FormNumeric2
                            name={"internalInsulation"}
                            label={""}
                            value={values?.internalInsulation?.toString() ?? ""}
                            onChange={(e) => {
                              onBlurOther(e);
                            }}
                            showSkeleton={showskeleton}
                            disabled={disableStudy}
                            mode={mode}
                            decimalScale={3}
                            thousandSeparator={true}
                            suffix={` ${systemOfMeasurement.get("in")}`}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={3} mt={6} ml={2}>
                        {(showUpdateButton || trainingModeUpdateButton) && (
                          <FormButton
                            startIcon={<DashboardCustomizeOutlined />}
                            onClick={changeMatrix}
                            text="Update Matrix"
                            size="small"
                            color="success"
                            isSubmitting={showskeleton}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  {values?.ductTypeId === DuctType.Oval && (
                    <Grid container spacing={2} mt={2}>
                      <Grid item xs={12} md={6}>
                        <Stack mb={1}>
                          <FormLabel
                            text=" Oval duct size ID"
                            sx={{
                              color: "rgba(0, 0, 0, 0.6)",
                              marginBottom: "3px",
                            }}
                          />
                        </Stack>
                        <Grid container direction={"row"}>
                          <Grid item xs={5} md={6} textAlign={"center"}>
                            <FormNumeric2
                              name={"ductSizeBase"}
                              label={"Dimension ↔"}
                              onChange={(e) => {
                                onBlurOther(e);
                              }}
                              value={values?.ductSizeBase?.toString() ?? ""}
                              fullWidth="150px"
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              decimalScale={3}
                              thousandSeparator={true}
                              suffix={` ${systemOfMeasurement.get("in")}`}
                            />
                          </Grid>
                          <Grid item xs={1} md={0.5}>
                            <Grid container>
                              <Grid item xs={1} mt={2}>
                                <Typography
                                  display={"inline"}
                                  variant="subtitle2"
                                  mt={2}
                                  ml={1}
                                >
                                  by
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={5} md={5.5} textAlign={"center"}>
                            <FormNumeric2
                              name={"ductSizeHeight"}
                              label={"Dimension ↕"}
                              onChange={(e) => {
                                onBlurOther(e);
                              }}
                              value={values?.ductSizeHeight?.toString() ?? ""}
                              showSkeleton={showskeleton}
                              disabled={disableStudy}
                              mode={mode}
                              decimalScale={3}
                              thousandSeparator={true}
                              suffix={` ${systemOfMeasurement.get("in")}`}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={5} md={3}>
                        <Grid item xs={12}>
                          <Stack mb={1}>
                            <FormLabel
                              text=" Internal Insulation"
                              sx={{
                                marginBottom: "3px",
                              }}
                            />
                          </Stack>
                          <Grid container direction={"row"}>
                            <Grid item xs={12} textAlign={"center"}>
                              <FormNumeric2
                                name={"internalInsulation"}
                                label={""}
                                value={
                                  values?.internalInsulation?.toString() ?? ""
                                }
                                showSkeleton={showskeleton}
                                disabled={disableStudy}
                                mode={mode}
                                onChange={(e) => {
                                  onBlurOther(e);
                                  validateAmcaAlert();
                                }}
                                decimalScale={3}
                                thousandSeparator={true}
                                suffix={` ${systemOfMeasurement.get("in")}`}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={6} md={2} mt={8} textAlign={"center"}>
                        {(showUpdateButton || trainingModeUpdateButton) && (
                          <FormButton
                            startIcon={<DashboardCustomizeOutlined />}
                            onClick={changeMatrix}
                            text="Update Matrix"
                            size="small"
                            color="success"
                            isSubmitting={showskeleton}
                          />
                        )}
                      </Grid>
                    </Grid>
                  )}
                  <Grid container alignItems={"center"} mt={2} mb={2}>
                    <Grid item xs={12} md={3.3}>
                      <Stack direction={"row"} spacing={2} mt={2}>
                        <StraightenOutlined />{" "}
                        <FormLabel
                          text="Dimension Total: "
                          sx={{ fontSize: "bold" }}
                        />{" "}
                        <FormLabel
                          text={` ${
                            Number(
                              dimensionTotal.toFixed(3)
                            ).toLocaleString() ?? ""
                          } ${systemOfMeasurement.get("sqft")}`}
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12} md={8.7}>
                      <Grid container>
                        <Grid item xs={12} md={4}>
                          <FormCheckSwitch
                            name={"isVFD"}
                            label={"System operated from a VFD?"}
                            value={values?.isVFD}
                            onChange={handleInputChange}
                            disabled={mode === "read" ? true : false}
                            mode={mode}
                            showSkeleton={showskeleton}
                          />
                        </Grid>
                        {values?.isVFD && (
                          <>
                            <Grid item xs={12} md={4}>
                              <Grid item xs={12} md={2.5}>
                                <Grid container>
                                  <Stack direction={"row"}>
                                    <FormCheckBox
                                      name={"vfdHzIsSelect"}
                                      label={"Hz"}
                                      value={values?.vfdHzIsSelect}
                                      onChange={() => {
                                        setValues({
                                          ...values,
                                          vfdHzIsSelect: true,
                                          vfdPercentageIsSelect: false,
                                          vfdOtherIsSelect: false,
                                        });
                                      }}
                                      disabled={mode === "read" ? true : false}
                                      mode={mode}
                                    />
                                    <FormCheckBox
                                      name={"vfdPercentageIsSelect"}
                                      label={"%"}
                                      value={values?.vfdPercentageIsSelect}
                                      onChange={() => {
                                        setValues({
                                          ...values,
                                          vfdHzIsSelect: false,
                                          vfdPercentageIsSelect: true,
                                          vfdOtherIsSelect: false,
                                        });
                                      }}
                                      disabled={mode === "read" ? true : false}
                                      mode={mode}
                                    />
                                    <FormCheckBox
                                      name={"vfdOtherIsSelect"}
                                      label={"Other"}
                                      value={values?.vfdOtherIsSelect}
                                      onChange={() => {
                                        setValues({
                                          ...values,
                                          vfdHzIsSelect: false,
                                          vfdPercentageIsSelect: false,
                                          vfdOtherIsSelect: true,
                                        });
                                      }}
                                      disabled={mode === "read" ? true : false}
                                      mode={mode}
                                    />
                                  </Stack>
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <Grid container>
                                <Grid
                                  item
                                  xs={values?.vfdOtherIsSelect ? 12 : 7}
                                >
                                  {values?.vfdOtherIsSelect && (
                                    <Stack direction={"row"} spacing={2}>
                                      <FormText
                                        name={"vfdOtherName"}
                                        label={"Other name"}
                                        value={values?.vfdOtherName}
                                        onChange={handleInputChange}
                                        mode={mode}
                                      ></FormText>
                                      <FormNumeric2
                                        name={"vfdOther"}
                                        label={"Other"}
                                        value={values?.vfdOther}
                                        onChange={handleInputChange}
                                        mode={mode}
                                        decimalScale={3}
                                        thousandSeparator={true}
                                      ></FormNumeric2>
                                    </Stack>
                                  )}

                                  {values?.vfdHzIsSelect && (
                                    <FormNumeric2
                                      name={"vfdHz"}
                                      label={"Hz"}
                                      value={values?.vfdHz}
                                      onChange={handleInputChange}
                                      mode={mode}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                  {values?.vfdPercentageIsSelect && (
                                    <FormNumeric2
                                      name={"vfdPercentage"}
                                      label={"%"}
                                      value={values?.vfdPercentage}
                                      onChange={handleInputChange}
                                      mode={mode}
                                      decimalScale={3}
                                      thousandSeparator={true}
                                    ></FormNumeric2>
                                  )}
                                </Grid>

                                <Grid
                                  item
                                  xs={values?.vfdOtherIsSelect ? 0 : 5}
                                >
                                  {values?.vfdHzIsSelect && (
                                    <Chip
                                      label={
                                        ((100 * (values?.vfdHz ?? 0)) / 60)
                                          .toFixed(3)
                                          .toString() + " %"
                                      }
                                      color="warning"
                                      m={1}
                                      size="small"
                                    />
                                  )}
                                  {values?.vfdPercentageIsSelect && (
                                    <Chip
                                      label={
                                        (
                                          (60 * (values?.vfdPercentage ?? 0)) /
                                          100
                                        )
                                          .toFixed(3)
                                          .toString() + " Hz"
                                      }
                                      color="warning"
                                      m={1}
                                      size="small"
                                    />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    {user?.role === ROLES.Training &&
                      (values?.ductTypeId === DuctType.Rectangular ||
                        values?.ductTypeId === DuctType.Oval) && (
                        <Grid container mt={1}>
                          <Grid item xs={12}>
                            <Alert severity={"warning"}>
                              Dimensions must not exceed 22" in the Trial &
                              Demonstration Version.
                            </Alert>
                          </Grid>
                        </Grid>
                      )}

                    {user?.role === ROLES.Training &&
                      values?.ductTypeId === DuctType.Round && (
                        <Grid container mt={1}>
                          <Grid item xs={12}>
                            <Alert severity={"warning"}>
                              Duct Diameter must not exceed 10" in the Trial &
                              Demonstration Version.
                            </Alert>
                          </Grid>
                        </Grid>
                      )}
                  </Grid>

                  <Divider />
                  <h3>Condition of Test</h3>
                  <Grid container mt={2} spacing={2}>
                    <Grid item xs={12} md={3}>
                      <FormSelect
                        name={"conditionId"}
                        label={"Conditon of Test"}
                        onChange={handleInputChange}
                        showSkeleton={showskeleton}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        disabled={disableStudy}
                        mode={mode}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test",
                        }}
                      />
                    </Grid>
                    <Grid item xs={0} md={0.24}></Grid>
                    <Grid item xs={12} md={1.6} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"totalHeat"}
                        label={"Total Heat"}
                        showSkeleton={showskeleton}
                        onChange={handleInputChange}
                        disabled={
                          disableStudy ||
                          params.step?.toLowerCase() === STEPSVALUES.STEP2
                        }
                        value={values?.totalHeat}
                        mode={mode}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={12} md={3.5} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"velocityReadingTemperatureCompensated"}
                        label={"Temperature Correction Required"}
                        showSkeleton={showskeleton}
                        onChange={handleInputChangeCompesation}
                        disabled={
                          disableStudy ||
                          params.step?.toLowerCase() === STEPSVALUES.STEP2
                        }
                        value={values?.velocityReadingTemperatureCompensated}
                        mode={mode}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={12} md={3.5} textAlign={"left"}>
                      <FormCheckSwitch
                        name={"barometricalyCompensated"}
                        label={"Barometric Correction Required"}
                        showSkeleton={showskeleton}
                        onChange={handleInputChange}
                        disabled={
                          disableStudy ||
                          params.step?.toLowerCase() === STEPSVALUES.STEP2
                        }
                        value={values?.barometricalyCompensated}
                        mode={mode}
                      ></FormCheckSwitch>
                    </Grid>
                  </Grid>
                  <Grid container>
                    {isNOC && (
                      <Grid
                        item
                        xs={8}
                        md={8}
                        alignContent={"center"}
                        ml={2}
                        mt={2}
                      >
                        <RadioGroup aria-label="" name="" row>
                          <Grid container>
                            <Grid item xs={4.5}>
                              {" "}
                              <FormControlLabel
                                checked={values?.nocVisual === true}
                                control={<Radio />}
                                label="Visual Damper Position"
                                onChange={handleCheckNoc}
                                name={"nocVisual"}
                                disabled={disableStudy}
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {" "}
                              <FormControlLabel
                                checked={values?.nocActual === true}
                                control={<Radio />}
                                label="Actual Damper Position"
                                onChange={handleCheckNoc}
                                name={"nocActual"}
                                disabled={disableStudy}
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </Grid>
                    )}
                  </Grid>
                  {isNOC && (
                    <Grid container justifyContent={"center"} spacing={2}>
                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocOaFirstNumber"}
                          label={""}
                          value={values?.nocOaFirstNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % O/A"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocRaFirstNumber"}
                          label={""}
                          value={values?.nocRaFirstNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % R/A"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocOaSecondNumber"}
                          label={""}
                          value={values?.nocOaSecondNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % M/A"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>

                      <Grid item xs={3} md={3}>
                        <FormNumeric2
                          name={"nocExhaustNumber"}
                          label={""}
                          value={values?.nocExhaustNumber?.toString() ?? ""}
                          onChange={handleInputChange}
                          showSkeleton={isLoading}
                          decimalScale={3}
                          thousandSeparator={true}
                          suffix=" % Exhaust"
                          disabled={disableStudy}
                          mode={mode}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {isOther && (
                    <Grid container mt={5}>
                      <Grid item xs={12}>
                        <FormTextArea
                          name={"comments"}
                          label={"Comments"}
                          value={values?.comments}
                          onChange={handleInputChange}
                          mode={mode}
                        ></FormTextArea>
                      </Grid>
                    </Grid>
                  )}
                  {values?.barometricalyCompensated && (
                    <Grid container mt={4}>
                      <>
                        <Grid item xs={3.2} textAlign={"left"}>
                          <FormCheckSwitch
                            name={"isActualLocalBarometricPressure"}
                            label={"Use Site Specific Default"}
                            showSkeleton={showskeleton}
                            onChange={handleChangeSpecificDefault}
                            disabled={disableStudy}
                            value={values?.isActualLocalBarometricPressure}
                          ></FormCheckSwitch>
                        </Grid>
                        <Grid item xs={6}>
                          <>
                            <Stack direction={"row"} spacing={2}>
                              {values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"useSiteSpecificDefault"}
                                  label={"Local Barometric Pressure Default"}
                                  value={
                                    values?.useSiteSpecificDefault?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  mode={"read"}
                                  thousandSeparator={true}
                                  disabled={disableStudy}
                                ></FormNumeric2>
                              )}
                              {!values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"localBarometricPressure"}
                                  label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                  value={
                                    values?.localBarometricPressure?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  disabled={disableStudy}
                                ></FormNumeric2>
                              )}
                              {values?.barometricalyCompensated && (
                                <FormNumeric2
                                  name={"localBarometricPressure"}
                                  label={`Local Barometric Pressure (${systemOfMeasurement.get(
                                    "inhg"
                                  )}) Pb`}
                                  value={
                                    values?.localBarometricPressure?.toString() ??
                                    ""
                                  }
                                  onChange={handleInputChange}
                                  onBlur={saveOnBlurHandler}
                                  showSkeleton={showskeleton}
                                  decimalScale={3}
                                  mode={
                                    params.step?.toLowerCase() ===
                                    STEPSVALUES.STEP2
                                      ? "read"
                                      : "read&Write"
                                  }
                                  thousandSeparator={true}
                                  disabled={disableStudy}
                                ></FormNumeric2>
                              )}
                            </Stack>
                          </>
                        </Grid>
                      </>
                    </Grid>
                  )}
                  <Divider my={5}></Divider>
                  <Stack direction={"row"} alignContent={"center"}>
                    <Grid container>
                      <Grid item xs={12} md={1.5}>
                        <h3>Distance/Location</h3>
                      </Grid>
                      <Grid item xs={12} md={10.5}>
                        <Chip
                          label={`Desired Pitot hole "distance/location" from disturbance (ie. bend, expansion, contraction)`}
                          color="primary"
                          mt={3.5}
                          ml={2}
                          size="small"
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                  <Grid container alignItems={"center"}>
                    <Grid item xs={12} md={disableStudy ? 3 : 6}>
                      <Stack mb={1}>
                        <FormLabel
                          text="Desired Stream Diameters"
                          sx={{
                            marginBottom: "3px",
                          }}
                        />
                      </Stack>
                      <Grid container direction={"row"}>
                        <Grid item xs={6} textAlign={"center"}>
                          <FormNumeric2
                            name={"desiredDownStream"}
                            label={"Down"}
                            value={values?.desiredDownStream?.toString() ?? ""}
                            onChange={handleInputChange}
                            fullWidth="150px"
                            showSkeleton={showskeleton}
                            disabled={disableStudy}
                            mode={mode}
                            onBlur={saveOnBlurHandler}
                            decimalScale={3}
                            thousandSeparator={true}
                          />
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={5.5} textAlign={"center"}>
                          <FormNumeric2
                            name={"desiredUpStream"}
                            label={"Up"}
                            value={values?.desiredUpStream?.toString() ?? ""}
                            onChange={handleInputChange}
                            showSkeleton={showskeleton}
                            disabled={disableStudy}
                            mode={mode}
                            onBlur={saveOnBlurHandler}
                            decimalScale={3}
                            thousandSeparator={true}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={disableStudy ? 3 : 6}>
                      <Stack direction={"row"} spacing={2} mt={7} ml={2}>
                        <Typography>
                          <b> Total "Ideal Straight" Duct Length:</b>
                        </Typography>
                        <FormLabel
                          text={` ${
                            (values?.totalIdealDuctLength &&
                              Number(
                                values?.totalIdealDuctLength.toFixed(3)
                              ).toLocaleString()) ??
                            0
                          } ${systemOfMeasurement.get("in")}`}
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid container mt={4}>
                    <Grid item xs={12} md={3.4}>
                      <Stack mb={1}>
                        <FormLabel
                          text="Max. Straight Duct Available"
                          sx={{
                            marginBottom: "10px",
                          }}
                        />
                      </Stack>
                      <Grid container direction={"row"}>
                        <Grid item xs={10.5}>
                          <FormNumeric2
                            name={"maxStraightDuctAvailable"}
                            label={""}
                            value={values?.maxStraightDuctAvailable}
                            onChange={handleInputChange}
                            fullWidth="150px"
                            showSkeleton={showskeleton}
                            disabled={disableStudy}
                            mode={mode}
                            onBlur={saveOnBlurHandler}
                            decimalScale={3}
                            thousandSeparator={true}
                            suffix={` ${systemOfMeasurement.get("in")}`}
                          />
                        </Grid>
                        <Grid item xs={0.5} mt={2} ml={0.5}>
                          {" "}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={8.6}>
                      <Grid container alignItems={"center"}>
                        <Grid item xs={12} md={3.7}>
                          <Stack mb={1}>
                            <FormLabel text="Pitot Traverse Point Location" />
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={5} mt={3}>
                                  <FormNumeric2
                                    name={
                                      "desirePitotGoleFromDisturbanceDownStream"
                                    }
                                    label={"Dn-Stream"}
                                    value={
                                      values?.desirePitotGoleFromDisturbanceDownStream
                                    }
                                    onChange={handleInputChange}
                                    fullWidth="150px"
                                    showSkeleton={showskeleton}
                                    disabled={disableStudy}
                                    mode={"read"}
                                    suffix={` ${systemOfMeasurement.get("in")}`}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                  />
                                </Grid>
                                <Grid item xs={2} textAlign={"left"} mt={4}>
                                  <Typography>Or</Typography>
                                </Grid>
                                <Grid item xs={5} mt={3}>
                                  <FormNumeric2
                                    name={
                                      "desirePitotGoleFromDisturbanceUpStream"
                                    }
                                    label={"Up-Stream"}
                                    value={
                                      values?.desirePitotGoleFromDisturbanceUpStream
                                    }
                                    onChange={handleInputChange}
                                    showSkeleton={showskeleton}
                                    disabled={disableStudy}
                                    mode={"read"}
                                    suffix={` ${systemOfMeasurement.get("in")}`}
                                    decimalScale={3}
                                    thousandSeparator={true}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Stack direction={"row"} spacing={2} ml={2} mt={5}>
                            <FormLabel
                              text={` ${
                                values?.percentajeOfIdeal
                                  ? Number(
                                      values?.percentajeOfIdeal.toFixed(3)
                                    ).toLocaleString()
                                  : 0
                              } `}
                            />
                            <Typography>
                              <b> % of Ideal</b>
                            </Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container mt={2}>
                    <Grid item xs={12} md={6}>
                      <Stack mb={1}>
                        <FormLabel
                          text={`Required ${systemOfMeasurement.get("cfm")}`}
                          sx={{
                            marginBottom: "3px",
                          }}
                        />
                      </Stack>
                      <Grid container direction={"row"}>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            name={"requiredCFM"}
                            label={`${systemOfMeasurement.get("cfm")}`}
                            value={values?.requiredCFM?.toString() ?? ""}
                            onChange={handleInputChange}
                            disabled={disableStudy}
                            onBlur={saveOnBlurHandler}
                            showSkeleton={showskeleton}
                            decimalScale={3}
                            mode={mode}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <>
                    <Grid container pt={3} spacing={2}>
                      <Grid item xs={12}>
                        <Divider my={3}></Divider>
                        <h3>Readings</h3>
                      </Grid>
                      {values?.ductTypeId === DuctType.Oval && (
                        <>
                          <Grid item xs={12} textAlign={"center"}>
                            <Typography variant="h6">
                              Rectangular part of the duct
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Divider my={3}></Divider>
                          </Grid>
                        </>
                      )}
                      <Grid
                        item
                        xs={12}
                        pb={3}
                        style={{
                          overflowX: "auto",
                        }}
                      >
                        {values &&
                          id &&
                          (((values.reportTypeId === 5 ||
                            ((values.ductTypeId === 1 ||
                              values.ductTypeId === 4) &&
                              values.methodMeasurementsId === 1)) &&
                            values.ductTypeId !== 2 &&
                            values.methodMeasurementsId !== 2) ||
                            values?.ductTypeId === DuctType.Oval) && (
                            <PitotFanTraverseGrid
                              id={id}
                              saveItems={saveItems}
                              disabled={
                                disableStep1 ||
                                disableEditData ||
                                changeMatrixButton
                              }
                              showSkeleton={showskeleton}
                              onBlur={calcTotal}
                              systemOfMeasurement={systemOfMeasurement}
                              values={values}
                              setValues={setValues}
                              dataBase={dataBase}
                              dataHeight={dataHeight}
                              setDataBase={setDataBase}
                              setDataHeight={setDataHeight}
                              stepContinuos={stepContinuos}
                              ductSizeBase={values?.ductSizeBase ?? 0}
                              ductSizeHeight={values?.ductSizeHeight ?? 0}
                              showSteady={showSteady}
                              setShowSteady={setShowSteady}
                              setDataInsulation={setDataInsulation}
                              dataInsulation={dataInsulation}
                              internalInsulation={
                                values?.internalInsulation ?? 0
                              }
                              setPitotTraverseGrid={setPitotTraverseGrid}
                              pitotTraverseGrid={pitotTraverseGrid}
                              setChangeMatrixButton={setChangeMatrixButton}
                              setGridLoading={setGridLoading}
                              executeRefresh={executeRefresh}
                              ovalAction={ovalAction}
                              setOvalAction={setOvalAction}
                            />
                          )}
                        {values?.ductTypeId === DuctType.Oval && (
                          <>
                            <Grid item xs={12}>
                              <Divider my={3}></Divider>
                            </Grid>
                            <Grid item xs={12} textAlign={"center"}>
                              <Typography variant="h6">
                                Round part of the duct
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Divider my={3}></Divider>
                            </Grid>
                          </>
                        )}
                        {values &&
                          id &&
                          ((values.reportTypeId === 20 &&
                            !(
                              (values.ductTypeId === 1 ||
                                values.ductTypeId === 4) &&
                              values.methodMeasurementsId === 1
                            )) ||
                            values.ductTypeId === 3 ||
                            values.ductTypeId === 2 ||
                            values.methodMeasurementsId === 2 ||
                            values?.ductTypeId === DuctType.Oval) && (
                            <PitotFanTraverseTTGrid
                              saveItems={saveItems}
                              disabled={
                                disableStep1 ||
                                disableEditData ||
                                changeMatrixButton
                              }
                              showSkeleton={showskeleton}
                              onBlur={calcTotalTT}
                              systemOfMeasurement={systemOfMeasurement}
                              values={values}
                              dataBase={dataBase}
                              dataHeight={dataHeight}
                              setDataBase={setDataBase}
                              setDataHeight={setDataHeight}
                              stepContinuos={stepContinuos}
                              ductSizeBase={values?.ductSizeBase ?? 0}
                              ductSizeHeight={values?.ductSizeHeight ?? 0}
                              showSteady={showSteady}
                              setShowSteady={setShowSteady}
                              setDataInsulation={setDataInsulation}
                              dataInsulation={dataInsulation}
                              internalInsulation={
                                values?.internalInsulation ?? 0
                              }
                              setPitotTraverseGrid={setPitotTraverseGrid}
                              pitotTraverseGrid={pitotTraverseGrid}
                              setChangeMatrixButton={setChangeMatrixButton}
                              setGridLoading={setGridLoading}
                              executeRefresh={executeRefresh}
                              ovalAction={ovalAction}
                              setOvalAction={setOvalAction}
                            />
                          )}
                      </Grid>
                    </Grid>
                    {values?.totalHeat && messagePsychrometric.text !== "" && (
                      <Grid item xs={12} justifyContent={"center"}>
                        <Stack mb={2}>
                          <Alert severity={messagePsychrometric.color}>
                            {messagePsychrometric.text}
                          </Alert>
                        </Stack>
                      </Grid>
                    )}
                    <Grid container spacing={3} pt={3}>
                      <Grid item xs={4} md={2}>
                        <FormNumeric2
                          name={"avgVelocity"}
                          label={"Avg Velocity"}
                          value={values?.avgVelocity?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton || isLoadingUpdate}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        ></FormNumeric2>
                      </Grid>
                      <Grid item xs={4} md={values.totalHeat ? 2 : 3}>
                        <FormNumeric2
                          name={"trueCorectedAirVelocity"}
                          label={"True Corrected Average Velocity"}
                          value={
                            values?.trueCorectedAirVelocity?.toString() ?? ""
                          }
                          mode="read"
                          showSkeleton={showskeleton || isLoadingUpdate}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        ></FormNumeric2>
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <FormNumeric2
                          name={"totalCFM"}
                          label={`Actual ${systemOfMeasurement.get("acfm")}`}
                          value={values?.totalCFM?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton || isLoadingUpdate}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                          color={
                            values &&
                            values?.requiredCFM &&
                            values?.totalCFM &&
                            values?.toleranceMax &&
                            (values?.totalCFM <
                              values?.requiredCFM -
                                values?.requiredCFM *
                                  (values?.toleranceMax / 100) ||
                              values?.totalCFM >
                                values?.requiredCFM *
                                  (1 + values?.toleranceMax / 100))
                              ? "error"
                              : undefined
                          }
                        ></FormNumeric2>
                      </Grid>
                      <Grid item xs={4} md={2}>
                        <FormNumeric2
                          name={"percentageOfDesign"}
                          label={"% of Design"}
                          value={values?.percentageOfDesign?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton || isLoadingUpdate}
                          color={
                            values &&
                            values?.percentageOfDesign &&
                            (values?.percentageOfDesign >
                              (values?.requiredCFMSystemToleranceTop
                                ? values?.requiredCFMSystemToleranceTop
                                : 0) +
                                100 ||
                              values?.percentageOfDesign <
                                100 -
                                  (values?.requiredCFMSystemToleranceBottom
                                    ? values?.requiredCFMSystemToleranceBottom
                                    : 0))
                              ? "error"
                              : undefined
                          }
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        ></FormNumeric2>
                      </Grid>
                      {values?.totalHeat && (
                        <Grid item xs={4} md={2}>
                          <FormNumeric2
                            name={"btuh"}
                            label={`Total ${systemOfMeasurement.get("btuh")}`}
                            value={values?.btuh?.toString() ?? ""}
                            onChange={handleInputChange}
                            mode="read"
                            showSkeleton={showskeleton || isLoadingUpdate}
                            decimalScale={2}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                      )}
                      <Grid item xs={4} md={2}>
                        <FormNumeric2
                          name={"sensibleHeat"}
                          label={`Sensible ${systemOfMeasurement.get("btuh")}`}
                          value={values?.sensibleHeat?.toString() ?? ""}
                          mode="read"
                          showSkeleton={showskeleton || isLoadingUpdate}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                        ></FormNumeric2>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Grid container>
                          <Grid item xs={disableStudy ? 12 : 10}>
                            <FormNumeric2
                              name={"dryBulbF"}
                              label={
                                "Airflow Temperature For Energy Calculation"
                              }
                              value={values?.dryBulbF?.toString() ?? ""}
                              onChange={onBlurCalcPsycometrics}
                              disabled={disableStep1 || disableEditData}
                              showSkeleton={showskeleton}
                              error={errors.dryBulbF}
                              decimalScale={3}
                              thousandSeparator={true}
                              mode={mode}
                              suffix={disableStudy ? " DB" : undefined}
                            ></FormNumeric2>
                          </Grid>
                          {!disableStudy && (
                            <>
                              {" "}
                              <Grid item xs={disableStudy ? 0 : 2}>
                                <Typography pl={2} pt={1}>
                                  DB
                                </Typography>
                              </Grid>{" "}
                            </>
                          )}
                        </Grid>
                      </Grid>
                      {values.totalHeat && (
                        <>
                          <Grid item xs={0} md={1}></Grid>
                          <Grid item xs={12} md={2}>
                            {values?.dryBulbFId ===
                              DryBulbF.Relativeumidity && (
                              <FormNumeric2
                                name={"humidityValue"}
                                label={"Relative Humidity"}
                                value={values?.humidityValue?.toString() ?? ""}
                                onChange={onBlurCalcPsycometrics}
                                disabled={disableStep1 || disableEditData}
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            )}
                            {values?.dryBulbFId === DryBulbF.WetBulbF && (
                              <FormNumeric2
                                name={"humidityValue"}
                                label={"Wet bulb F"}
                                value={values?.humidityValue?.toString() ?? ""}
                                onChange={onBlurCalcPsycometrics}
                                disabled={disableStep1 || disableEditData}
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            )}
                            {values?.dryBulbFId === DryBulbF.DewPoint && (
                              <FormNumeric2
                                name={"humidityValue"}
                                label={"Dew Point"}
                                value={values?.humidityValue?.toString() ?? ""}
                                onChange={onBlurCalcPsycometrics}
                                disabled={disableStep1 || disableEditData}
                                showSkeleton={showskeleton}
                                decimalScale={3}
                                thousandSeparator={true}
                              ></FormNumeric2>
                            )}
                          </Grid>
                          <Grid item xs={12} md={5}>
                            <RadioGroup aria-label="" name="" row>
                              <FormControlLabel
                                checked={
                                  values?.dryBulbFId ===
                                  DryBulbF.Relativeumidity
                                }
                                control={<Radio />}
                                label="Relative Humidity"
                                onChange={() => {
                                  handleChangeRadio(DryBulbF.Relativeumidity);
                                }}
                                name={"relativeHumidityBool"}
                                disabled={disableStep1 || disableEditData}
                              />
                              <FormControlLabel
                                checked={
                                  values?.dryBulbFId === DryBulbF.WetBulbF
                                }
                                control={<Radio />}
                                label="Wet bulb F"
                                onChange={() => {
                                  handleChangeRadio(DryBulbF.WetBulbF);
                                }}
                                name={"wetBulbFBool"}
                                disabled={disableStep1 || disableEditData}
                              />
                              <FormControlLabel
                                checked={
                                  values?.dryBulbFId === DryBulbF.DewPoint
                                }
                                control={<Radio />}
                                label="Dew Point"
                                onChange={() => {
                                  handleChangeRadio(DryBulbF.DewPoint);
                                }}
                                name={"dewPointBool"}
                                disabled={disableStep1 || disableEditData}
                              />
                            </RadioGroup>
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} md={2}>
                        <FormNumeric2
                          name={"centerlineStaticPressure"}
                          label={`Centerline Static Pressure`}
                          value={values?.centerlineStaticPressure}
                          showSkeleton={showskeleton || isLoadingUpdate}
                          onChange={handleInputChange}
                          decimalScale={3}
                          thousandSeparator={true}
                          disabled={disableStep1 || disableEditData}
                          mode={mode}
                        ></FormNumeric2>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <RadioGroup aria-label="" name="" row>
                          <FormControlLabel
                            checked={
                              values?.centerlineStaticPressureId ===
                              CenterlineStaticPressure.Plus
                            }
                            control={<Radio />}
                            label="+"
                            onChange={() => {
                              setValues({
                                ...values,
                                centerlineStaticPressureId:
                                  CenterlineStaticPressure.Plus,
                              });
                            }}
                            name={"plus"}
                            disabled={disableStep1 || disableEditData}
                          />
                          <FormControlLabel
                            checked={
                              values?.centerlineStaticPressureId ===
                              CenterlineStaticPressure.Minus
                            }
                            control={<Radio />}
                            label="-"
                            onChange={() => {
                              setValues({
                                ...values,
                                centerlineStaticPressureId:
                                  CenterlineStaticPressure.Minus,
                              });
                            }}
                            name={"minus"}
                            disabled={disableStep1 || disableEditData}
                          />
                          <FormControlLabel
                            checked={
                              values?.centerlineStaticPressureId ===
                              CenterlineStaticPressure.NotAvailable
                            }
                            control={<Radio />}
                            label="Not Available"
                            onChange={() => {
                              setValues({
                                ...values,
                                centerlineStaticPressureId:
                                  CenterlineStaticPressure.NotAvailable,
                              });
                            }}
                            name={"notAvailable"}
                            disabled={disableStep1 || disableEditData}
                          />
                        </RadioGroup>
                      </Grid>
                    </Grid>
                  </>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={activeStep}
                      stepsCount={STEPS.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={isLoading || isSubmittingUpdate}
                      reportName={"Pitot Traverse Report"}
                      saveHandler={handleSaveButton}
                      nextStepHandler={nextStepHandler}
                      disabled={disableStudy}
                      previousStepHandler={handleBackStep}
                      isComplete={values?.isComplete ?? true}
                      singleStep={true}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {project && projectReport && pitotMatrix && (
                      <CompleteStudyPopup
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        project={project}
                        projectReport={projectReport}
                        completed={pitotMatrix?.isComplete}
                        infectiousComplianceControl={
                          infectiousComplianceControl
                        }
                        parentStudy={parentStudy ?? null}
                        singleStep={disableCompleteStudy}
                        handlerCustomAccept={refreshCompletedStudy}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title={"Information"}
        text={
          <>
            <Typography>
              SQ/FT is not enough to perform AMCA Method. Please use another
              Measurement Method or verify your duct size.
            </Typography>
            <Typography>
              The minimum recommended SQ/FT for AMCA is 1 SQ/FT
            </Typography>
            <Typography>However AMCA is recommended for big ducts. </Typography>
          </>
        }
        showPopup={amcaAlertModal}
        setShowPopup={setAmcaAlertModal}
        onSave={() => {}}
        hideAccept={true}
        isSubmitting={false}
        cancelTextButton="Close"
      ></DialogMessagePopup>
      {showSteadyPopUp && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={values?.reportTypeId}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
          setDisableEditData={setDisableEditData}
        />
      )}
    </>
  );
};

export default PitotFanTraverse;
