import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  Checkbox,
  useTheme,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import outletMasterService from "src/services/study/outletMasterService";
import { useAsyncQuery, useAuth, useLog } from "src/hooks";
import {
  FormCheckSwitch,
  FormNumeric2,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IKeyValue,
  IOutletMaster,
  IOutletMasterItem,
  IPsychrometricArgs,
  IPsychrometricUI,
  StudyInformation,
} from "src/ts/interfaces";
import projectReportService from "src/services/study/projectReportService";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import outletMasterItemsService from "src/services/study/outletMasterItemsService";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import meterDeviceService from "src/services/catalogs/meterDeviceService";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import FormReadOnlyField from "src/components/formControls/FormReadOnlyField";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { NumericFormat } from "react-number-format";
import useLocalTheme from "src/hooks/useLocalTheme";
import getProjectService from "src/services/project/getProjectService";
import { zipCodeService } from "src/services";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import useFormulas from "src/hooks/useFormulas";
import usePsychrometric from "src/hooks/usePsychrometric";
import studiesHistoryService from "src/services/study/studiesHistoryService";
import useSystemOfMeasurement from "src/hooks/useSystemOfMeasurement";
import projectService from "src/services/project/projectService";
import { steps } from "../const/const";
import { yellow } from "@mui/material/colors";
import FormNumericReadOnlyField from "src/components/formControls/FormNumericReadOnlyField";
import SteadyStateDialog from "../../components/SteadyState";
import { FooterButtons } from "../../components/FooterButtons";
import StudyWizard from "../../components/StudyWizard";
import TerminalDevicePopup from "../components/TerminalDevicePopup";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";

interface UIControl {
  readOnly: boolean;
  viewMode: boolean;
  editMode: boolean;
}

const initialValues: any = {
  projectName: "",
  systemField: "",
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  outsideAir: false,
  systemTotal: false,
  outletTotal: false,
  fillNextToogle: false,
  temperatureSensibleDryBulb: false,
  heat: false,
  diffuserGrillerNeckSize: false,
  minimumOutletCFMRecorded: false,
  diffuserGrilleModel: false,
  useSiteSpecificDefaultBool: false,
  localBarometricPressure: null,
  useSiteSpecificDefault: null,
};

const reportInitialState: StudyInformation = {
  isComplete: false,
  id: -1,
  name: "Diffuser, Register & Grille Test Report",
  typeId: 9,
  projectId: 0,
  validatePermissionReport: false,
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const OutletMasterStep2 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();
  const { theme: localTheme } = useLocalTheme();
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { psychrometric } = usePsychrometric();
  const { formulaSystem } = useFormulas();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const row = 12;

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const [uiControl, setUiControl] = useState<UIControl>({
    readOnly: false,
    viewMode: false,
    editMode: false,
  });

  const [studyInformation, setStudyInformation] =
    useState<StudyInformation>(reportInitialState);

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IOutletMaster>(initialValues, false, validate);

  const [items, setItems] = useState<IOutletMasterItem[]>([]);

  const [activeStep] = useState<number>(1);

  const [psychrometricUi, setPsychrometricUi] =
    useState<IPsychrometricUI | null>(null);

  const [measurementSystem, setMeasurementSystem] = useState("");

  const [selectedItem, setSelectedItem] = useState<{
    e: any;
    row: IOutletMasterItem;
    index: number;
  } | null>(null);

  const [
    showUpdateMeterDeviceConfirmation,
    setShowUpdateMeterDeviceConfirmation,
  ] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { execute: getMeterDeviceKeyValues, data: meterDeviceKeyValue } =
    useAsyncQuery<IKeyValue<number, string>[]>(meterDeviceService.getKeyValues);

  const getLocalBarometricPressure = async (projectId: number) => {
    try {
      const zipCodeResponse = await projectService.getZipCode(projectId);

      const elevationResponse = await zipCodeService.getElevation(
        zipCodeResponse.data
      );

      const elevationCorrectedResponse =
        await elevationCorrectedService.getById(elevationResponse.data);

      const formulas = await formulaSystem.setSystem(projectId);
      const localBarometricPressure = formulas.InHgToKPa(
        elevationCorrectedResponse.data.atmosphericPressure
      );
      return localBarometricPressure;
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      return null;
    }
  };

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  useEffect(() => {
    const getData = async () => {
      try {
        setIsLoading(true);
        await executeReport(id, "OUMA");
        await getMeterDeviceKeyValues();

        const outletMasterResponse = await outletMasterService.getById(id);

        const projectReportRespose = await projectReportService.projectReport(
          outletMasterResponse.data.id,
          outletMasterResponse.data.reportTypeId,
          outletMasterResponse.data.projectId
        );

        getProjectReport(projectReportRespose.data, outletMasterResponse.data);

        const viewMode = activateViewModeAfterSteadyStep(
          outletMasterResponse.data.isComplete,
          user?.role
        );
        setUiControl({ ...uiControl, viewMode: viewMode });

        const measurementSystemResponse =
          await getProjectService.getMeasurementSystem(
            outletMasterResponse.data.projectId
          );
        setMeasurementSystem(measurementSystemResponse.data);
        systemOfMeasurement.setSystem(measurementSystemResponse.data);

        let psychrometric: IPsychrometricUI = {
          RelativeHumidity: false,
          humidityMeasuredAs: "",
          WetBulbF: false,
          DewPoint: false,
          humidityValue: null,
        };

        if (outletMasterResponse.data.dryBulbFId === 1) {
          psychrometric.RelativeHumidity = true;
          psychrometric.humidityMeasuredAs = "RH";
        }
        if (outletMasterResponse.data.dryBulbFId === 2) {
          psychrometric.WetBulbF = true;
          psychrometric.humidityMeasuredAs = "WB";
        }
        if (outletMasterResponse.data.dryBulbFId === 3) {
          psychrometric.DewPoint = true;
          psychrometric.humidityMeasuredAs = "DP";
        }
        setPsychrometricUi(psychrometric);

        let localBarometricPressure =
          outletMasterResponse.data.localBarometricPressure;

        if (outletMasterResponse.data.localBarometricPressure == null) {
          localBarometricPressure = await getLocalBarometricPressure(
            outletMasterResponse.data.projectId
          );
        }

        setValues({
          ...outletMasterResponse.data,
          localBarometricPressure: localBarometricPressure,
        });

        await getOuletMasterItems();
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      } finally {
        setIsLoading(false);
      }
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProjectReport = (
    projectReport: IProjectReport,
    outletMaster: IOutletMaster
  ) => {
    setCommentConfiguration(outletMaster);

    if (
      (projectReport.isInUse && projectReport.isInUseById !== user?.userId) ||
      projectReport.isPartOfProject === false
    ) {
      setUiControl({
        readOnly: true,
        viewMode: true,
        editMode: false,
      });
    } else {
      if (studyInformation !== null) {
        setStudyInformation({
          ...studyInformation,
          validatePermissionReport: true,
        });
      }
    }

    //Steady state questions
  };

  const getOuletMasterItems = async () => {
    try {
      const outletMasterItemsResponse =
        await outletMasterItemsService.getOutletMasterId(id);

      if (outletMasterItemsResponse.data.length === 0) {
        const item: any /* IOutletMasterItem */ = {
          outletMasterId: id,
        };

        const outletMasterItemsRes = await outletMasterItemsService.addItem(
          item
        );
        setItems([outletMasterItemsRes.data]);
      } else {
        setItems(outletMasterItemsResponse.data);
      }
    } catch (error) {
      log.error("Error loading Items Data");
    }
  };

  const setCommentConfiguration = (outletMaster: IOutletMaster) => {
    setStudyInformation({
      isComplete: outletMaster.isComplete,
      id: outletMaster.id,
      name: "Diffuser, Register & Grille Test Report",
      typeId: 9,
      projectId: outletMaster.projectId,
      validatePermissionReport: false,
    });
  };

  const activateViewModeAfterSteadyStep = (
    reportCompleted: boolean,
    userRole: string
  ) => {
    if (reportCompleted) {
      // If the report is completed then viewMode is always true
      return true;
    }
    var result = false;
    switch (userRole) {
      case "Administrator":
      case "ProjectManager":
      case "CompanyOwner":
      case "Architect":
      case "ArchitectAux":
      case "SysAdmin":
      case "TemporalAuditor":
      case "TemporalEnergyAuditor":
        result = true;
        break;
      default:
        result = false;
    }
    return result;
  };

  //useCallback

  const selectKey = useCallback(
    async (row: IOutletMasterItem, index: number) => {
      const lowertGrillerId = values.lowestGrillerId === row.id ? 0 : row.id;

      const newValues: IOutletMaster = {
        ...values,
        lowestGrillerId: lowertGrillerId,
      };
      setValues(newValues);
      try {
        await outletMasterService.update(newValues.id, newValues);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [log, setValues, values]
  );

  const onChangeText = useCallback(
    (e: any, row: IOutletMasterItem, indexRow: number) => {
      const { name, value } = e.target;

      const modifiedRow = { ...row, [name]: value };

      if (row.meterDeviceId !== 2) {
        modifiedRow.minimumOutletCFMRecorded =
          (modifiedRow.akColumn ?? 0) *
          (modifiedRow.actualMinimumVelocity ?? 0);
      }

      const unmodified = items.map((item, index) =>
        index === indexRow ? modifiedRow : item
      );

      setItems(unmodified);
      updateActualCFMComplete(e, modifiedRow, indexRow);
    },
    [items]
  );

  const getPsychrometric = useCallback(
    async (row: IOutletMasterItem) => {
      let humidityMeasuredAs = "";
      let humidityValue = null;

      if (psychrometricUi?.RelativeHumidity) {
        humidityMeasuredAs = "RH";
        humidityValue = row.relHum;
      } else if (psychrometricUi?.DewPoint) {
        humidityMeasuredAs = "DP";
        humidityValue = row.dewPoint;
      } else if (psychrometricUi?.WetBulbF) {
        humidityMeasuredAs = "WB";
        humidityValue = row.wetBulb;
      }
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: humidityMeasuredAs,
        humidityValue: humidityValue,
        dryBulbF: row.temperatureDB,
        totalCFM: row.finalCorrectedAirflowACFM ?? 0,
      };

      if (
        psychometricsArgs.humidityValue !== null &&
        psychometricsArgs.dryBulbF !== null &&
        psychometricsArgs.totalCFM !== null &&
        values?.heat
      ) {
        const psychrometricRes = await psychrometric.getByProject(
          psychometricsArgs
        );

        return Promise.resolve(psychrometricRes);
      } else {
        return Promise.resolve(null);
      }
    },
    [
      psychrometric,
      psychrometricUi?.DewPoint,
      psychrometricUi?.RelativeHumidity,
      psychrometricUi?.WetBulbF,
      values?.heat,
      values.projectId,
    ]
  );

  const calcBTUHExtra = useCallback(
    async (row: IOutletMasterItem) => {
      const psychrometricRes = await getPsychrometric(row);

      if (psychrometricRes === null)
        return {
          atmosphericPress: null,
          outletReportItem: row,
        };

      if (
        psychrometricRes?.psychrometric.message != null &&
        psychrometricRes?.psychrometric.enthalpy === 0
      ) {
        log.warning(psychrometricRes?.psychrometric.message);
      } else if (psychrometricRes?.psychrometric.message != null) {
        log.info(psychrometricRes?.psychrometric.message);
      }

      const updateBtuItem: IOutletMasterItem = {
        ...row,
        btuh: psychrometricRes?.btuh ?? null,
      };

      try {
        await outletMasterItemsService.updateItem(updateBtuItem);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }

      return {
        atmosphericPress: psychrometricRes?.psychrometric?.atmosphericPress,
        outletReportItem: updateBtuItem,
      };
    },
    [getPsychrometric, log]
  );
  const [showTerminalDevicePopup, setShowTerminalDevicePopup] = useState(false);
  const changeTerminalDevice = useCallback(
    async (
      e: any,
      row: IOutletMasterItem,
      index: number,
      outletMaster: IOutletMaster
    ) => {
      if (outletMaster.isComplete) return;

      const { value } = e.target.checked;

      const modifiedRow: IOutletMasterItem = {
        ...row,
        terminalDevice: value,
      };

      if (row.terminalDevice === true) modifiedRow.terminalDevice = false;
      else {
        modifiedRow.terminalDevice = true;
        setShowTerminalDevicePopup(true);
      }

      setSelectedRow({ row: modifiedRow, index });

      const res = await outletMasterItemsService.updateItem(modifiedRow);

      const updatedItem = {
        ...items[index],
        percentaje: res.data.percentaje,
        actualCFM: res.data.actualCFM,
        preliminary: res.data.preliminary,
        finalCorrectedAirflowACFM: res.data.finalCorrectedAirflowACFM,
        terminalDevice: value,
      };

      const newItems = items.map((item, i) =>
        i === index ? updatedItem : item
      );

      setItems(newItems);
      try {
        const calc = await calcBTUHExtra(updatedItem);
        const resLowestGriller = await outletMasterService.getLowesGriller(id);
        setValues({
          ...resLowestGriller.data,
          useSiteSpecificDefault:
            calc?.atmosphericPress === null
              ? values?.useSiteSpecificDefault
              : calc?.atmosphericPress,
        });
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [calcBTUHExtra, id, items, log, setValues]
  );

  const changeMeterDevice = useCallback(
    (e: any, row: IOutletMasterItem, index: number) => {
      setSelectedItem({ e, row, index });
      setShowUpdateMeterDeviceConfirmation(true);
    },
    []
  );

  const updateActualCFM = useCallback(
    async (row: IOutletMasterItem, index: number) => {
      const res = await outletMasterItemsService.updateItem(row);

      const updatedItem = {
        ...row,
        ...res.data,
      };

      try {
        const calc = await calcBTUHExtra(updatedItem);
        if (calc?.outletReportItem) {
          const newItems = items.map((item, i) =>
            i === index ? calc?.outletReportItem : item
          );
          setItems(newItems);
        } else {
          const newItems = items.map((item, i) =>
            i === index ? updatedItem : item
          );
          setItems(newItems);
        }

        await outletMasterService.update(values.id, values);
        const resLowestGriller = await outletMasterService.getLowesGriller(id);
        setValues({
          ...resLowestGriller.data,
          useSiteSpecificDefault:
            calc?.atmosphericPress === null
              ? values?.useSiteSpecificDefault
              : calc?.atmosphericPress,
        });
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [calcBTUHExtra, id, items, log, setValues, values]
  );

  const updateActualCFMComplete = useCallback(
    async (e: any, row: IOutletMasterItem, index: number) => {
      const modifiedRow: IOutletMasterItem = {
        ...row,
        [e.target.name]: parseFloat(e.target.value),
      };
      const res = await outletMasterItemsService.updateItem(modifiedRow);

      const updatedItem = {
        ...modifiedRow,
        ...res.data,
      };

      const newItems = items.map((item, i) =>
        i === index ? updatedItem : item
      );

      setItems(newItems);
      try {
        const calc = await calcBTUHExtra(updatedItem);
        await outletMasterService.update(values.id, values);
        const resLowestGriller = await outletMasterService.getLowesGriller(id);
        setValues({
          ...resLowestGriller.data,
          useSiteSpecificDefault:
            calc?.atmosphericPress === null
              ? values?.useSiteSpecificDefault
              : calc?.atmosphericPress,
        });
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    },
    [calcBTUHExtra, id, items, log, setValues]
  );

  const correctForBarometrically = useCallback(
    async (e: any, row: IOutletMasterItem, index: number) => {
      const value = e.target.value as boolean;
      const modifiedRow = { ...row, barometricCorrectionRequired: value };
      await updateActualCFM(modifiedRow, index);
    },
    [updateActualCFM]
  );

  const correctForTemperature = useCallback(
    async (e: any, row: IOutletMasterItem, index: number) => {
      const value = e.target.value as boolean;
      const modifiedRow = { ...row, temperatureCorrectionRequired: value };
      await updateActualCFM(modifiedRow, index);
    },
    [updateActualCFM]
  );

  const calcBTUHHandler = useCallback(
    async (e: any, row: IOutletMasterItem, indexRow: number) => {
      const modifiedRow = {
        ...row,
        [e.target.name]: parseFloat(e.target.value),
      };

      const psychrometricRes = await getPsychrometric(modifiedRow);
      if (
        psychrometricRes?.psychrometric.message != null &&
        psychrometricRes?.psychrometric.enthalpy === 0
      ) {
        log.warning(psychrometricRes?.psychrometric.message);
      } else if (psychrometricRes?.psychrometric.message != null) {
        log.info(psychrometricRes?.psychrometric.message);
      }

      setValues({
        ...values,
        useSiteSpecificDefault:
          psychrometricRes?.psychrometric?.atmosphericPress ?? null,
      });

      const updateBtuItem: IOutletMasterItem = {
        ...modifiedRow,
        btuh: psychrometricRes?.btuh ?? null,
      };
      const updateRes = await outletMasterItemsService.updateItem(
        updateBtuItem
      );

      const modifiedItems = items?.map((item, index) =>
        index === indexRow ? updateRes.data : item
      );

      setItems(modifiedItems);

      const calc = await calcBTUHExtra(updateRes.data);
      const resLowestGriller = await outletMasterService.getLowesGriller(id);
      setValues({
        ...resLowestGriller.data,
        useSiteSpecificDefault:
          calc?.atmosphericPress === null
            ? values?.useSiteSpecificDefault
            : calc?.atmosphericPress,
      });
    },
    [calcBTUHExtra, getPsychrometric, id, items, log, setValues, values]
  );

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    user?.userId,
    values?.isComplete,
  ]);

  const mode = useMemo(
    () => (values.isComplete ? "read" : "read&Write"),
    [values.isComplete]
  );

  const highlight = useCallback(
    (row: IOutletMasterItem) =>
      row.id === values.lowestGrillerId
        ? localTheme === "DARK"
          ? "rgb(19 39 51)"
          : yellow[200]
        : undefined,
    [localTheme, theme.palette.primary.dark, values.lowestGrillerId]
  );

  const exceedTolerance = (row: IOutletMasterItem) => {
    if (row.max && row.min && row.finalCorrectedAirflowACFM) {
      return (
        row.max < row.finalCorrectedAirflowACFM ||
        row.min > row.finalCorrectedAirflowACFM
      );
    }
    return false;
  };

  const getDimensionText = (row: IOutletMasterItem) => {
    if (row.rectangle) {
      return `${row.width ?? ""}, ${row.heigth ?? ""}`;
    } else {
      return `${row.width ?? ""}`;
    }
  };

  //useMemo
  const columns = useMemo(() => {
    const transposed = true;
    const columnsArray: ISpreadsheetColumn[] = [
      {
        name: "terminalDevice",
        label: "Missing / Extra",
        align: "left",
        disabled: values.isComplete,
        mode: mode,
        type: "custom",
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell transposed={transposed} align="right">
              <>
                {mode === "read&Write" ? (
                  <>
                    <Checkbox
                      checked={row.terminalDevice}
                      onChange={async (e: any) => {
                        await changeTerminalDevice(e, row, index, values);
                      }}
                      disabled={disableStudy}
                      sx={{ padding: 0, marginTop: 2, marginLeft: 2 }}
                    />
                    {row.extra && row.terminalDevice ? "Extra" : ""}
                    {row.missing && row.terminalDevice ? "Missing" : ""}
                  </>
                ) : true ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "index",
        type: "index",
        label: "Number #",
        align: "right",
      },
      {
        name: "roomAreaServed",
        type: "string",
        label: "Room or Area Served",
        align: "right",
      },
      {
        name: "diffuserGrilleModel",
        type: "string",
        label: "Diffuser / Grille Model",
        color: theme.palette.success.main,
        hide: !values.diffuserGrilleModel,
        align: "right",
      },
      {
        name: "type",
        type: "custom",
        label: "Type",
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell transposed={transposed} align="right">
              <Typography mt={0.8} paddingLeft={1.8} color="success.main">
                {row.circle && <>Round</>}
                {row.rectangle && <>Rectangle</>}
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "dimension",
        type: "custom",
        label: "Dimension(s)",
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell transposed={transposed} align="right">
              <Typography mt={0.8} paddingLeft={1.8} color="success.main">
                {getDimensionText(row)}
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "neckType",
        type: "custom",
        label: "Neck Type",
        color: theme.palette.success.main,
        hide: !values.diffuserGrillerNeckSize,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell transposed={transposed} align="right">
              <Typography mt={0.8} paddingLeft={1.8} color="success.main">
                {row.neckCircle && <>Round</>}
                {row.neckRectangle && <>Rectangle</>}
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "neckdimension",
        type: "custom",
        label: "Neck Dimension(s)",
        hide: !values.diffuserGrillerNeckSize,
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell transposed={transposed} align="right">
              <Typography mt={0.8} paddingLeft={1.8} color="success.main">
                {`${row.neckWidth ?? ""}`}
                {`${!!row.neckRectangle ? ", " : " "}`}
                {row.neckHeigth}
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "akColumn",
        label: `AK Factor (${systemOfMeasurement.get("sqft").trim()})`,
        type: "custom",
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              borderColor=""
              transposed={transposed}
              align="right"
            >
              <SpreadsheetNumericInput2
                value={row.akColumn}
                onChange={(e: any) => {
                  const modifiedRow: IOutletMasterItem = {
                    ...row,
                    [e.target.name]: parseFloat(e.target.value),
                  };

                  if (row.meterDeviceId !== 2) {
                    modifiedRow.minimumOutletCFMRecorded =
                      (modifiedRow.akColumn ?? 0) *
                      (modifiedRow.actualMinimumVelocity ?? 0);
                  }
                  updateActualCFM(modifiedRow, index);
                }}
                name="akColumn"
                decimalScale={2}
                maxValue={1000}
                disabled={values.isComplete}
                mode={mode}
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "meterDeviceId",
        label: "Meter / Device",
        type: "select",
        items: meterDeviceKeyValue,
        disabled: disableStudy,
        mode: mode,
        align: "right",
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          changeMeterDevice(e, row, index);
        },
      },
      {
        name: "barometricCorrectionRequired",
        type: "checkBox",
        label: "Correct for Barometric",
        align: "right",
        disabled: disableStudy,
        mode: mode,
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          await correctForBarometrically(e, row, index);
        },
      },
      {
        name: "temperatureCorrectionRequired",
        type: "checkBox",
        label: "Correct for Temperature",
        align: "right",
        disabled: disableStudy,
        mode: mode,
        hide: !values.temperatureSensibleDryBulb,
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          await correctForTemperature(e, row, index);
        },
      },
      {
        name: "temperatureDB",
        type: "numericInput",
        label:
          "Diffuser Temperature DB " + systemOfMeasurement.get("temperature"),
        //borderColor: theme.palette.info.dark,
        decimalScale: 3,
        maxValue: 1000000000,
        hide: !values.temperatureSensibleDryBulb,
        disabled: disableStudy,
        mode: mode,
        align: "right",
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          const modifiedRow: IOutletMasterItem = {
            ...row,
            temperatureDB: parseFloat(e.target.value),
          };
          await updateActualCFM(modifiedRow, index);
        },
      },

      {
        name: "wetBulb",
        type: "numericInput",
        label: "Wet Bulb " + systemOfMeasurement.get("temperature"),
        //borderColor: theme.palette.info.dark,
        decimalScale: 3,
        maxValue: 1000000000,
        hide: !(values.heat && psychrometricUi?.WetBulbF),
        disabled: disableStudy,
        mode: mode,
        align: "right",
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          await calcBTUHHandler(e, row, index);
        },
      },
      {
        name: "dewPoint",
        type: "numericInput",
        label: "Dew Point",
        //borderColor: theme.palette.info.dark,
        decimalScale: 3,
        maxValue: 1000000000,
        hide: !(values.heat && psychrometricUi?.DewPoint),
        disabled: values.isComplete,
        mode: mode,
        align: "right",
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          await calcBTUHHandler(e, row, index);
        },
      },

      {
        name: "relHum",
        type: "numericInput",
        label: "Relative Humidity %",
        //borderColor: theme.palette.info.dark,
        decimalScale: 3,
        maxValue: 1000000000,
        hide: !(values.heat && psychrometricUi?.RelativeHumidity),
        disabled: disableStudy,
        mode: mode,
        align: "right",
        onChange: async (
          row: IOutletMasterItem,
          e: any,
          index: number
        ): Promise<void> => {
          await calcBTUHHandler(e, row, index);
        },
      },
      {
        name: "sensibleHeat",
        type: "numeric",
        label: "Sensible Heat",
        decimalScale: 2,
        thousandSeparator: true,
        color: theme.palette.success.main,
        hide: !values.heat,
        align: "right",
      },
      {
        name: "btuh",
        type: "numeric",
        label: "Total Heat",
        decimalScale: 2,
        thousandSeparator: true,
        color: theme.palette.success.main,
        hide: !values.heat,
        align: "right",
      },
      {
        name: "requiredVelocity",
        type: "custom",
        label: "Required Velocity " + systemOfMeasurement.get("fpm"),
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
            >
              <Typography mt={0.8} paddingLeft={1.8}>
                <NumericFormat
                  value={row.requiredVelocity}
                  displayType="text"
                  decimalScale={3}
                  thousandSeparator
                  valueIsNumericString
                  fixedDecimalScale
                  style={{ color: theme.palette.success.main }}
                  color="success.main"
                />
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "actualVelocity",
        type: "custom",
        label: "Actual Velocity " + systemOfMeasurement.get("fpm"),
        //borderColor: theme.palette.info.dark,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
              // borderColor={
              //   row.meterDeviceId === 2 ? undefined : theme.palette.info.dark
              // }
            >
              <SpreadsheetNumericInput2
                value={row.actualVelocity}
                onChange={(e: any) => {
                  const modifiedRow: IOutletMasterItem = {
                    ...row,
                    actualVelocity: parseFloat(e.target.value),
                  };
                  updateActualCFM(modifiedRow, index);
                }}
                name="actualVelocity"
                decimalScale={3}
                maxValue={100000}
                disabled={row.meterDeviceId === 2 || disableStudy}
                mode={
                  mode === "read&Write"
                    ? row.meterDeviceId === 2 || disableStudy
                      ? "read"
                      : mode
                    : mode
                }
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "requiredCFM",
        type: "custom",
        label: "Required " + systemOfMeasurement.get("cfm"),
        color: theme.palette.info.dark,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
            >
              <Typography mt={0.8} paddingLeft={1.8} mr={1}>
                <NumericFormat
                  value={row.requiredCFM}
                  displayType="text"
                  decimalScale={1}
                  thousandSeparator
                  valueIsNumericString
                  fixedDecimalScale
                />
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "actualCFM",
        type: "custom",
        label: systemOfMeasurement.get("cfm") + " Read",
        color: theme.palette.info.dark,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
            >
              <SpreadsheetNumericInput2
                value={row.actualCFM}
                name="actualCFM"
                decimalScale={2}
                maxValue={100000}
                disabled={row.meterDeviceId !== 2 || values.isComplete}
                onChange={async (e) => {
                  const modifiedRow: IOutletMasterItem = {
                    ...row,
                    actualCFM: parseFloat(e.target.value),
                  };

                  await updateActualCFM(modifiedRow, index);
                }}
                mode={
                  mode === "read&Write"
                    ? row.meterDeviceId !== 2 || values.isComplete
                      ? "read"
                      : mode
                    : mode
                }
                colorTextInput={
                  exceedTolerance(row) ? theme.palette.error.main : undefined
                }
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "minimumCFERequired",
        type: "custom",
        label: "Minimum " + systemOfMeasurement.get("cfm") + " Required",
        //borderColor: theme.palette.info.dark,
        color: theme.palette.info.dark,
        hide: !values.minimumOutletCFMRecorded,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              //borderColor={theme.palette.info.dark}
              align="right"
            >
              <SpreadsheetNumericInput2
                value={row.minimumCFERequired}
                onChange={(e: any) => updateActualCFMComplete(e, row, index)}
                name="minimumCFERequired"
                decimalScale={2}
                maxValue={1000000}
                disabled={row.meterDeviceId !== 2 || values.isComplete}
                mode={
                  mode === "read&Write"
                    ? row.meterDeviceId !== 2 || values.isComplete
                      ? "read"
                      : mode
                    : mode
                }
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "minimumOutletCFMRecorded",
        type: "custom",
        label: "Minimum " + systemOfMeasurement.get("cfm") + " Recorded",
        //borderColor: theme.palette.info.dark,
        color: theme.palette.info.dark,
        hide: !values.minimumOutletCFMRecorded,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              //borderColor={theme.palette.info.dark}
              align="right"
            >
              <SpreadsheetNumericInput2
                value={row.minimumOutletCFMRecorded}
                onChange={(e: any) => updateActualCFMComplete(e, row, index)}
                name="minimumOutletCFMRecorded"
                decimalScale={2}
                maxValue={1000000}
                disabled={values.isComplete || row.meterDeviceId !== 2}
                mode={
                  mode === "read&Write"
                    ? values.isComplete || row.meterDeviceId !== 2
                      ? "read"
                      : mode
                    : mode
                }
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "actualMinimumVelocity",
        type: "custom",
        label: "Actual Minimum Velocity",
        //borderColor: theme.palette.info.dark,
        color: theme.palette.info.dark,
        hide: !values.minimumOutletCFMRecorded,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
            >
              <SpreadsheetNumericInput2
                value={row.actualMinimumVelocity}
                onChange={(e: any) => onChangeText(e, row, index)}
                name="actualMinimumVelocity"
                decimalScale={2}
                maxValue={1000000}
                disabled={row.meterDeviceId === 2 || disableStudy}
                mode={
                  mode === "read&Write"
                    ? row.meterDeviceId === 2 || disableStudy
                      ? "read"
                      : mode
                    : mode
                }
              />
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "finalCorrectedAirflowACFM",
        type: "custom",
        label: "Final Corrected Airflow " + systemOfMeasurement.get("cfm"),
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
              color={exceedTolerance(row) ? "red" : undefined}
            >
              <Typography mt={0.8} paddingLeft={1.8}>
                <NumericFormat
                  value={row.finalCorrectedAirflowACFM}
                  displayType="text"
                  decimalScale={2}
                  thousandSeparator
                  fixedDecimalScale
                />
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "percentaje",
        type: "custom",
        label: "% of Design",
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
              color={exceedTolerance(row) ? "red" : undefined}
            >
              <Typography mt={0.8} paddingLeft={1.8}>
                <NumericFormat
                  value={row.percentaje}
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                  suffix="%"
                />
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "neckdimension",
        type: "custom",
        label: "Min/Max Flows Allowable " + systemOfMeasurement.get("cfm"),
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
            >
              <Typography mt={0.8} paddingLeft={1.8} mr={1}>
                <NumericFormat
                  value={row.min}
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                />{" "}
                /{" "}
                <NumericFormat
                  value={row.max}
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                />
              </Typography>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "barometricCorrectionRequired",
        type: "custom", //extra functionality
        label: "Key Outlet",
        color: theme.palette.success.main,
        render: (
          row: IOutletMasterItem,
          col: ISpreadsheetColumn,
          index: number
        ) => {
          return (
            <SpreadSheetCell
              transposed={transposed}
              backgroundColor={highlight(row)}
              align="right"
            >
              <>
                {mode === "read&Write" ? (
                  <Checkbox
                    checked={row.id === values.lowestGrillerId}
                    onChange={(e: any) => {
                      selectKey(row, index);
                    }}
                    disabled={disableStudy}
                    sx={{ padding: 0, marginTop: 2, marginLeft: 2 }}
                  />
                ) : row.id === values.lowestGrillerId ? (
                  "Yes"
                ) : (
                  "No"
                )}
              </>
            </SpreadSheetCell>
          );
        },
      },
      {
        name: "index2",
        type: "index",
        label: "Number #",
        align: "right",
        color: theme.palette.success.main,
      },
      {
        name: "roomAreaServed",
        type: "string",
        label: "Room or Area Served",
        align: "right",
        color: theme.palette.success.main,
      },
    ];

    return columnsArray;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    calcBTUHHandler,
    changeMeterDevice,
    changeTerminalDevice,
    correctForBarometrically,
    correctForTemperature,
    highlight,
    meterDeviceKeyValue,
    mode,
    onChangeText,
    psychrometricUi?.DewPoint,
    psychrometricUi?.RelativeHumidity,
    psychrometricUi?.WetBulbF,
    disableStudy,
    selectKey,
    selectedItem,
    systemOfMeasurement,
    theme.palette.info.dark,
    theme.palette.success.main,
    updateActualCFM,
    updateActualCFMComplete,
    values,
  ]);

  //Handler
  const confirmChangeMeterDevice = async () => {
    if (selectedItem === null) return;

    const { e, row, index } = selectedItem;

    const modifiedRow = { ...row, meterDeviceId: parseInt(e.target.value) };
    if (e.target.value === 2) {
      modifiedRow.actualVelocity = 0;
      modifiedRow.akColumn = 1;
      modifiedRow.actualMinimumVelocity = 0;
      modifiedRow.minimumOutletCFMRecorded = 0;
      modifiedRow.minimumCFERequired = 0;
    } else {
      modifiedRow.actualVelocity = 0;
      modifiedRow.akColumn = 1;
      modifiedRow.minimumOutletCFMRecorded = 0;
      modifiedRow.minimumCFERequired = 0;
    }

    const res = await outletMasterItemsService.updateItem(modifiedRow);
    const updatedRow = {
      ...modifiedRow,
      ...res.data,
    };
    const newItems = items.map((item, i) => (i === index ? updatedRow : item));
    setItems(newItems);

    const calc = await calcBTUHExtra(updatedRow);
    const resLowestGriller = await outletMasterService.getLowesGriller(id);
    setValues({
      ...resLowestGriller.data,
      useSiteSpecificDefault:
        calc?.atmosphericPress === null
          ? values?.useSiteSpecificDefault
          : calc?.atmosphericPress,
    });

    setShowUpdateMeterDeviceConfirmation(false);
  };

  const useSpecificDefaultHandler = async (e: any) => {
    handleInputChange(e);
    const useSiteSpecificDefaultBool = e.target.value;

    if (useSiteSpecificDefaultBool) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF: measurementSystem === "imperialSystem" ? 70 : 21.11,
        totalCFM: 0,
      };

      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );

      if (
        psychrometricRes.psychrometric.message != null &&
        psychrometricRes.psychrometric.enthalpy === 0
      ) {
        log.warning(psychrometricRes.psychrometric.message);
      } else if (psychrometricRes.psychrometric.message != null) {
        log.info(psychrometricRes.psychrometric.message);
      }
      const useSiteSpecificDefault =
        psychrometricRes.psychrometric.atmosphericPress;

      const newOutletMaster = {
        ...values,
        useSiteSpecificDefault,
        useSiteSpecificDefaultBool,
      };
      setValues(newOutletMaster);
      await outletMasterService.update(newOutletMaster.id, newOutletMaster);
    } else {
      const newOutletMaster = {
        ...values,
        useSiteSpecificDefaultBool,
      };
      await outletMasterService.update(newOutletMaster.id, newOutletMaster);
      setValues(newOutletMaster);
    }

    await outletMasterItemsService.updateAll(items);
    await studiesHistoryService.save(
      values.projectId,
      studyInformation.typeId,
      values.id,
      "saved"
    );
  };

  let temporalItems: IOutletMasterItem[] = [];
  let lastAtmosphericPress: number | null = null;

  const UpdateBarometricPresureHandler = async (e: any) => {
    const { name, value } = e.target;

    const newValues = { ...values, [name]: value };

    setValues({ ...newValues });
    await outletMasterService.update(values.id, newValues);
    const res = await outletMasterItemsService.updateAll(items);
    const orderedItems: IOutletMasterItem[] = res.data.map((item, index) => ({
      ...item,
      order: index,
    }));

    temporalItems = orderedItems;
    let index = 0;
    calcBTUHExtraRepeat(temporalItems[index], index);

    const resLowestGriller = await outletMasterService.getLowesGriller(id);

    setValues({
      ...resLowestGriller.data,
      useSiteSpecificDefault: lastAtmosphericPress,
    });
    setItems(temporalItems);
  };

  const calcBTUHExtraRepeat = async (
    item: IOutletMasterItem,
    index: number
  ) => {
    let humidityMeasuredAs = "";
    let humidityValue = null;

    if (psychrometricUi?.RelativeHumidity) {
      humidityMeasuredAs = "RH";
      humidityValue = item.relHum;
    } else if (psychrometricUi?.DewPoint) {
      humidityMeasuredAs = "DP";
      humidityValue = item.dewPoint;
    } else if (psychrometricUi?.WetBulbF) {
      humidityMeasuredAs = "WB";
      humidityValue = item.wetBulb;
    }

    if (
      humidityValue !== null &&
      item.temperatureDB !== null &&
      item.finalCorrectedAirflowACFM !== null &&
      !values?.heat
    )
      return;

    const psychometricsArgs: IPsychrometricArgs = {
      projectId: values.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: item.temperatureDB,
      totalCFM: item.finalCorrectedAirflowACFM ?? 0,
    };

    const psychrometricRes = await psychrometric.getByProject(
      psychometricsArgs
    );

    lastAtmosphericPress = psychrometricRes.psychrometric.atmosphericPress;

    temporalItems[index].btuh = psychrometricRes.btuh;
    temporalItems[index].sensibleHeat = psychrometricRes.sensibleHeat;
    if (temporalItems.length - index > 1) {
      let newIndex = index + 1;
      await calcBTUHExtraRepeat(temporalItems[newIndex], newIndex);
    }
  };

  const previousStepHandler = async () => {
    if (!disableStudy) {
      await outletMasterService.update(values.id, values);
      await outletMasterItemsService.updateAll(items);
    }

    navigate(`/studies/OutletMaster/${id}`);
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      await outletMasterService.update(values.id, values);
      await outletMasterItemsService.updateAll(items);
    }

    navigate(`/studies/OutletMasterStep3/${id}`);
  };

  const saveHandler = async () => {
    if (!validate()) return;

    try {
      await outletMasterService.update(values.id, values);
      await outletMasterItemsService.updateAll(items);
      log.info("Report was updated successfully");
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const colorResult =
    (values?.totalMax ?? 0) < (values?.finalCorrectedArflowACFM ?? 0) ||
    (values?.totalMin ?? 0) > (values?.finalCorrectedArflowACFM ?? 0)
      ? theme.palette.error.main
      : "";

  const [selectedRow, setSelectedRow] = useState<{
    row: IOutletMasterItem;
    index: number;
  } | null>(null);

  const onSelectMissingExtra = (row: IOutletMasterItem, index: number) => {
    const newSet = items.map((item: IOutletMasterItem, i: number) =>
      i === index ? row : item
    );

    setItems(newSet);
  };

  const totalFinalCorrectedActualAirflowColor =
    (values.minimumMax ?? 0) < (values.actualMinimumCfm ?? 0) ||
    (values.minimumMin ?? 0) > (values.actualMinimumCfm ?? 0)
      ? theme.palette.error.main
      : undefined;

  const totalMinimumColor =
    (values.minimumMax ?? 0) < (values.actualMinimumCfm ?? 0) ||
    (values.minimumMin ?? 0) > (values.actualMinimumCfm ?? 0)
      ? theme.palette.error.main
      : undefined;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "OUMA",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StudyWizard
            steps={steps}
            activeStep={activeStep}
            projectId={values?.projectId}
            reportId={values?.id}
            reportTypeId={9}
          />
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={row}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={isLoading}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField ?? ""}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={isLoading}
                    mode={mode}
                    disabled={disableStudy}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={row}>
                  <FormTextArea
                    name="description"
                    label="Description"
                    value={values?.description ?? ""}
                    onChange={handleInputChange}
                    error={errors.description}
                    mode={mode}
                    disabled={disableStudy}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={row}>
                  <h3>Barometric Pressure</h3>
                </Grid>

                <Grid item xs={2.35}>
                  <FormCheckSwitch
                    label="Use Site Specific Default"
                    name="useSiteSpecificDefaultBool"
                    value={values.useSiteSpecificDefaultBool}
                    onChange={useSpecificDefaultHandler}
                    disabled={disableStudy}
                    mode={mode}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={3}>
                  {values.useSiteSpecificDefaultBool ? (
                    <FormNumeric2
                      name="useSiteSpecificDefault"
                      label="Local Barometric Pressure Default"
                      value={values?.useSiteSpecificDefault?.toString() || ""}
                      onChange={handleInputChange}
                      error={errors.useSiteSpecificDefault}
                      showSkeleton={isLoading}
                      disabled={true}
                      mode={mode}
                      maxValue={1000000000}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  ) : (
                    <FormNumeric2
                      name="localBarometricPressure"
                      label="Actual Barometric Pressure"
                      value={values?.localBarometricPressure?.toString() || ""}
                      error={errors.localBarometricPressure}
                      showSkeleton={isLoading}
                      disabled={values.barometricalyCompensated || disableStudy}
                      onChange={(e: any) => {
                        UpdateBarometricPresureHandler(e);
                      }}
                      mode={mode}
                      maxValue={1000000000}
                      decimalScale={3}
                      thousandSeparator={true}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Spreadsheet
                    items={items}
                    setItems={setItems}
                    cols={columns}
                    transposed={true}
                    defaultRowPerPage={10}
                    rowsPerPageOptions={[10]}
                    showRowTotals={false}
                    totals={false}
                    showSkeleton={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormReadOnlyField
                        label="Key Outlet / Most Restrictive and Wide Open"
                        color={theme.palette.warning.light}
                        value={values.keyOutlet?.toString()}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormNumericReadOnlyField
                        label={`Total Final Corrected Actual Airflow ${systemOfMeasurement.get(
                          "acfm"
                        )}`}
                        value={values.finalCorrectedArflowACFM?.toLocaleString()}
                        decimalScale={2}
                        valueColor={colorResult}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormNumericReadOnlyField
                        label={`Total Required ${systemOfMeasurement.get(
                          "cfm"
                        )}`}
                        decimalScale={2}
                        value={values.totalRequiredCFM?.toString()}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormNumericReadOnlyField
                        label={`Total ${systemOfMeasurement.get(
                          "acfm"
                        )} Percentage`}
                        value={`${values.percentage?.toLocaleString()}`}
                        suffix="%"
                        decimalScale={2}
                        valueColor={colorResult}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {values.minimumOutletCFMRecorded && (
                        <Grid item xs={6}>
                          <FormNumeric2
                            name="requiredMinimumCfm"
                            label={`Total Required Minimum ${systemOfMeasurement.get(
                              "cfm"
                            )}`}
                            value={values?.requiredMinimumCfm?.toString() || ""}
                            onChange={UpdateBarometricPresureHandler}
                            error={errors.requiredMinimumCfm}
                            showSkeleton={isLoading}
                            mode={mode}
                            maxValue={1000000000}
                            decimalScale={3}
                            thousandSeparator={true}
                          />
                        </Grid>
                      )}
                    </Grid>
                    {values.minimumOutletCFMRecorded && (
                      <Grid item xs={4}>
                        <FormNumericReadOnlyField
                          label={`Total Final Corrected Actual Airflow ${systemOfMeasurement.get(
                            "acfm"
                          )} Recorded`}
                          value={values.actualMinimumCfm?.toLocaleString()}
                          decimalScale={2}
                          valueColor={totalFinalCorrectedActualAirflowColor}
                        />
                      </Grid>
                    )}
                    {values.minimumOutletCFMRecorded && (
                      <Grid item xs={4}>
                        <FormNumericReadOnlyField
                          label={`Total Minimum ${systemOfMeasurement.get(
                            "acfm"
                          )} Percentage`}
                          value={`${values.minimumCfmPod?.toLocaleString()}`}
                          suffix="%"
                          decimalScale={2}
                          valueColor={totalMinimumColor}
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <FooterButtons
                    activeStep={activeStep}
                    stepsCount={steps.length}
                    projectId={values?.projectId}
                    companyId={user?.companyId as number}
                    isSaving={false}
                    disabled={false}
                    reportName={"Diffuser, Register & Grille Test Report"}
                    previousStepHandler={previousStepHandler}
                    saveHandler={saveHandler}
                    nextStepHandler={nextStepHandler}
                    isComplete={values?.isComplete ?? true}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <DialogMessagePopup
        title="Important"
        text={
          "This action will modify your current Item's data. Are you certain you want continue?"
        }
        showPopup={showUpdateMeterDeviceConfirmation}
        setShowPopup={setShowUpdateMeterDeviceConfirmation}
        onSave={confirmChangeMeterDevice}
        onCancel={() => {
          setShowUpdateMeterDeviceConfirmation(false);
        }}
        isSubmitting={false}
      />
      <SteadyStateDialog
        reportId={values?.id}
        reportTypeId={values?.reportTypeId as number}
        projectId={values?.projectId}
        reportCompleted={values?.isComplete}
      />
      <TerminalDevicePopup
        value={""}
        isDialogOpen={showTerminalDevicePopup}
        setIsDialogOpen={setShowTerminalDevicePopup}
        onConfirm={onSelectMissingExtra}
        selectedRow={selectedRow}
        reportTypeId={8}
        reportId={dataReport?.reportId}
        projectId={dataReport?.projectId}
      />
    </>
  );
};

export default OutletMasterStep2;
