import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  Skeleton,
  SxProps,
  TextField,
  Theme,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { Mode } from "src/ts/types/common";
import FormNumericReadOnlyField from "./FormNumericReadOnlyField";
import { CSSProperties } from "react";
import React from "react";

interface Props {
  value?: number | string | null;
  onChange?: (e: any) => void;
  name: string;
  decimalScale?: number | undefined;
  maxValue?: number | undefined;
  disabled?: boolean;
  label: string;
  error?: any;
  showSkeleton?: boolean;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onPaste?: (e: any) => void;
  mode?: Mode;
  size?: "small" | "medium" | undefined;
  placeholder?: string;
  suffix?: string;
  prefix?: string;
  fullWidth?: string | boolean;
  thousandSeparator?: boolean;
  fixedDecimalScale?: boolean;
  color?: string;
  defaultValue?: number;
  sx?: SxProps<Theme> | undefined;
  rightAlign?: boolean;
  inputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  readOnly?: boolean;
  style?: CSSProperties | undefined;
  allowNegative?: boolean;
  requiredField?: boolean;
  emptyFieldAccept?: boolean;
}

const FormNumeric2 = ({
  style,
  value,
  onChange,
  name,
  decimalScale = 2,
  maxValue,
  disabled,
  label,
  error,
  onBlur,
  onKeyDown,
  onPaste,
  mode,
  size,
  placeholder = "0.00",
  suffix,
  prefix,
  fullWidth,
  thousandSeparator = true,
  fixedDecimalScale = true,
  showSkeleton,
  color,
  defaultValue,
  sx,
  inputProps,
  rightAlign = true,
  readOnly,
  allowNegative,
  requiredField,
  emptyFieldAccept = true,
}: Props) => {
  const valueRef = React.useRef<string>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    var input = inputRef.current;
    if (input) {
      valueRef.current = value as string;
    }
  }, [value]);

  const handleFocus = (event: any) => {
    event.target.select();
    var input = inputRef.current;
    if (input) {
      valueRef.current = input.value;
    }
  };

  const handleBlur = () => {
    var input = inputRef.current;
    if (input) {
      let valueNew = "";
      if (typeof input.value === "string") {
        const valueSplitted = input.value.split(" ");

        valueNew =
          valueSplitted.length > 1
            ? Number(valueSplitted[0].replace(/,/g, ""))
              ? valueSplitted[0].replace(/,/g, "")
              : valueSplitted[1].replace(/,/g, "")
            : input.value.replace(/,/g, "");
      }
      const ev = {
        target: {
          name: name,
          value: valueNew,
        },
      };
      if (onChange) {
        if (input.value !== valueRef.current) {
          onChange && onChange(ev);
        }
      }
      if (onBlur) {
        onBlur && onBlur(ev);
      }
    }
  };

  if (mode && mode === "read") {
    return (
      // <FormReadOnlyField
      //   label={label}
      //   value={`${prefix ?? ""} ${
      //     value === null || value === ""
      //       ? "-"
      //       : !isNaN(Number(value?.toString()))
      //       ? Number(value).toFixed(decimalScale ?? 2)
      //       : value === null || value === ""
      //       ? "-"
      //       : value?.toString()
      //   } ${suffix ?? ""}`}
      //   valueColor={color}
      // />
      <FormNumericReadOnlyField
        label={label}
        value={value}
        valueColor={color}
        suffix={suffix}
        prefix={prefix}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        thousandSeparator={thousandSeparator}
      />
    );
  }

  return (
    <>
      {showSkeleton ? (
        <Skeleton height={50} />
      ) : (
        <NumericFormat
          inputRef={inputRef}
          style={{
            ...style,
            fontWeight: disabled ? "bolder" : "normal",
          }}
          value={value}
          customInput={TextField}
          size={size ?? "small"}
          // onChange={onChange}
          /*onValueChange={(values) => {
            // Reference comment: const { formattedValue, value, floatValue } = values;
            const { floatValue } = values;

            onChange && onChange({ target: { name, value: floatValue ?? 0 } });
          }}*/
          onFocus={handleFocus}
          onBlur={handleBlur}
          onBlurCapture={onBlur}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          autoComplete="off"
          name={name}
          label={label}
          placeholder={placeholder}
          suffix={suffix ? ` ${suffix?.trim()}` : undefined}
          prefix={prefix ? `${prefix?.trim()} ` : undefined}
          allowNegative={allowNegative}
          {...(error && { error: true, helperText: error })}
          decimalScale={decimalScale}
          fullWidth={fullWidth ?? true}
          disabled={disabled}
          thousandSeparator={thousandSeparator}
          valueIsNumericString
          fixedDecimalScale={fixedDecimalScale}
          isAllowed={(values) => {
            const { floatValue } = values;
            if (floatValue === undefined && emptyFieldAccept) return true;
            if (floatValue !== 0 && !floatValue) return false;

            if (maxValue) return floatValue < maxValue;

            return true;
          }}
          color={color}
          defaultValue={defaultValue}
          sx={{
            ...sx,
            ...(requiredField && {
              "& .MuiInputBase-root": {
                border: "1px solid inherit",
              },
              "& .MuiInputLabel-shrink": {
                transform: "translate(14px, -6px) scale(0.75)",
                backgroundColor: "white",
                padding: "0 4px",
              },
            }),
          }}
          InputProps={{
            ...inputProps,
            inputProps: {
              ...inputProps?.inputProps,
              style: {
                ...inputProps?.inputProps?.style,
                textAlign: rightAlign ? "right" : "start",
                paddingLeft: 5,
                paddingRight: 5,
              },
            },
          }}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default FormNumeric2;
