/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Alert,
  Stack,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Chip,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncQuery, useLog, useAuth } from "src/hooks";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormRadioGroup,
  FormText,
  FormStopwatch,
  FormButton,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IComment,
  IEnergyStudyAirHydronicsDTO,
  IReportType,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import Popup from "src/components/Popup";
import reportTypeService from "src/services/study/reportTypeService";
import singleEnergyStudyAirHydronicsService from "src/services/study/singleEnergyStudyAirHydronicsService";
import energyStudyAirHydronicsPutService from "src/services/study/energyStudyAirHydronicsPutService";
import projectService from "src/services/project/projectService";
import { useSystemOfMeasurement } from "src/hooks";
import SteadyStateDialog from "../../components/SteadyState";
import commentService from "src/services/commentService";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import StudyWizard from "../../components/StudyWizard";
import { useConfig } from "src/contexts/EnergyConfigProvider";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  areaServed: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const meterMeasuredAsOptions = [
  { key: "RH", value: "RH" },
  { key: "WB", value: "WB" },
  { key: "DP", value: "DP" },
];

const EnergyStep4 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [project, setProject] = useState<ProjectDTO>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [openHydWaterIsolationWarning, setOpenHydWaterIsolationWarning] =
    useState(false);
  const [openMeterAvgWaring, setOpenMeterAvgWarning] = useState(false);
  const [openBleedThroughWarning, setOpenBleedThroughWarning] = useState(false);
  const [openHotWaterFlowWarning, setOpenHotWaterFlowWarning] = useState(false);
  const [beginPreTestTimeStamp, setBeginPreTestTimeStamp] = useState<Date>();
  const [endPreTestTimeStamp, setEndPreTestTimeStamp] = useState<Date>();
  const [startPreTestTimer, setStartPreTestTimer] = useState(false);
  const [beginSteadyOffTimeStamp, setBeginSteadyOffTimeStamp] =
    useState<Date>();
  const [endSteadyOffTimeStamp, setEndSteadyOffTimeStamp] = useState<Date>();
  const [startSteadyOffTimer, setStartSteadyOffTimer] = useState(false);
  const [steadyOffWarmestValue, setSteadyOffWarmestValue] = useState<number>();
  const [disableEditData, setDisableEditData] = useState(false);
  const config = useConfig();

  let navigate = useNavigate();

  const validate: any = (fieldValues: IEnergyStudyAirHydronicsDTO = values) => {
    let temp: Record<string, string> = { ...errors };
    let valid = true;

    if (disableStudy()) return true;

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "" && valid);
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IEnergyStudyAirHydronicsDTO>(initialValues, false, validate);

  const activeStep = 3;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute, isLoading } = useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
    singleEnergyStudyAirHydronicsService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(dataResult.projectId);
          setValues({ ...dataResult });
          setBeginPreTestTimeStamp(dataResult.beginPreTestTimeStamp);
          setBeginSteadyOffTimeStamp(dataResult.beginTestTimeStamp);
          setEndPreTestTimeStamp(dataResult.endPreTestTimeStamp);
          setEndSteadyOffTimeStamp(dataResult.endTestTimeStamp);
          setStartPreTestTimer(
            !!dataResult.beginPreTestTimeStamp &&
              !dataResult.endPreTestTimeStamp
          );
          setStartSteadyOffTimer(
            !!dataResult.beginTestTimeStamp && !dataResult.endTestTimeStamp
          );
        };
        getData();
      },
    }
  );

  const { execute: executeReportType, isLoading: isLoadingReportType } =
    useAsyncQuery<IReportType>(reportTypeService.getReportTypeById, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
        };
        getData();
      },
    });

  const { execute: executeProject, isLoading: isLoadingProject } =
    useAsyncQuery<ProjectDTO>(projectService.getProjectTolerancesByReportId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProject(dataResult);
        };
        getData();
      },
    });

  const { execute: executeUpdtMetersAvg } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.update,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              meterAverage: dataResult.meterAverage,
              meter1Enthalpy: dataResult.meter1Enthalpy,
              meter1Deviation: dataResult.meter1Deviation,
              meter2Enthalpy: dataResult.meter1Enthalpy,
              meter2Deviation: dataResult.meter1Deviation,
              meter3Enthalpy: dataResult.meter1Enthalpy,
              meter3Deviation: dataResult.meter1Deviation,
            });
            log.info("Meters deviation saved correctly");
          };
          getData();
        },
      }
    );

  const { execute: executeBeginTime } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.beginTime,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              beginPreTestTimeStamp: dataResult,
              preTestTimeStamp: true,
            });
            setBeginPreTestTimeStamp(new Date(dataResult));
            setStartPreTestTimer(true);
          };
          getData();
        },
      }
    );

  const { execute: executeEndTime } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endTime,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              endPreTestTimeStamp: dataResult.endPreTestTimeStamp,
            });
            setStartPreTestTimer(false);
          };
          getData();
        },
      }
    );

  const { execute: executeBeginTimeSteadyOff } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.beginTimeSteadyOff,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              beginTestTimeStamp: dataResult,
            });
            setBeginSteadyOffTimeStamp(new Date(dataResult));
            setStartSteadyOffTimer(true);
          };
          getData();
        },
      }
    );

  const { execute: executeEndTimeSteadyOff } =
    useAsyncQuery<IEnergyStudyAirHydronicsDTO>(
      energyStudyAirHydronicsPutService.endTimeSteadyOff,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            setValues({
              ...values,
              endTestTimeStamp: dataResult.endTestTimeStamp,
            });
            setStartSteadyOffTimer(false);
            if (
              dataResult.psychrometricsSteadyOffMessage !== "" &&
              dataResult.testingMaValue !== null &&
              dataResult.testingMaValue !== 0 &&
              dataResult.testingMaDryBulb !== null &&
              dataResult.testingMaDryBulb !== 0
            ) {
              if (dataResult.psychrometricsSteadyOffMessageIsError) {
                log.error(
                  "Error in Energy Off Steady State M/A: " +
                    dataResult.psychrometricsSteadyOffMessage
                );
              } else {
                log.message(
                  "Message for Energy Off Steady State M/A: " +
                    dataResult.psychrometricsSteadyOffMessage
                );
              }
            }
          };
          getData();
        },
      }
    );

  const disableStudy = () => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableEditData
    );
  };

  useEffect(() => {
    if (values?.reportTypeId) executeReportType(values?.reportTypeId);
  }, [values?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
        await executeProject(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    let lowest = 999999999;
    let highest = 0;

    lowest =
      (values?.hamiltonErrorTemp1 || 999999999) < lowest
        ? values?.hamiltonErrorTemp1 || 0
        : lowest;
    lowest =
      (values?.hamiltonErrorTemp2 || 999999999) < lowest
        ? values?.hamiltonErrorTemp2 || 0
        : lowest;
    lowest =
      (values?.hamiltonErrorTemp3 || 999999999) < lowest
        ? values?.hamiltonErrorTemp3 || 0
        : lowest;

    highest =
      (values?.hamiltonErrorTemp1 || 0) > highest
        ? values?.hamiltonErrorTemp1 || 0
        : highest;
    highest =
      (values?.hamiltonErrorTemp2 || 0) > highest
        ? values?.hamiltonErrorTemp2 || 0
        : highest;
    highest =
      (values?.hamiltonErrorTemp3 || 0) > highest
        ? values?.hamiltonErrorTemp3 || 0
        : highest;

    const deviation = Math.abs(highest - lowest);
    setValues({
      ...values,
      hamiltonErrorDeviation: deviation,
    });
  }, [
    values?.hamiltonErrorTemp1,
    values?.hamiltonErrorTemp2,
    values?.hamiltonErrorTemp3,
  ]);

  const saveHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
    } catch (error) {}
  };

  const saveEnergyHandler = async () => {
    try {
      if (!disableStudy()) {
        setIsUpdating(true);
        const dataResult = (
          await energyStudyAirHydronicsPutService.update(id, values)
        ).data;
        if (
          dataResult.psychrometricsSteadyOffMessage !== "" &&
          dataResult.testingMaValue !== null &&
          dataResult.testingMaValue !== 0 &&
          dataResult.testingMaDryBulb !== null &&
          dataResult.testingMaDryBulb !== 0
        ) {
          if (dataResult.psychrometricsSteadyOffMessageIsError) {
            log.error(
              "Error in Energy Off Steady State M/A: " +
                dataResult.psychrometricsSteadyOffMessage
            );
          } else {
            log.message(
              "Message for Energy Off Steady State M/A: " +
                dataResult.psychrometricsSteadyOffMessage
            );
          }
        }
        log.success("Report was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step5/${id}`);
    } catch (error) {}
  };

  const previousStepHandler = async () => {
    if (!validate()) return;
    try {
      await saveEnergyHandler();
      navigate(`/studies/${config?.baseUrlName}/step3/${id}`);
    } catch (error) {}
  };

  const showEnergyFirstOffButton = () =>
    values?.preDeterminedSetPoits &&
    values?.preDeterminedDevices &&
    values?.validateDampersValvesVFDDevices &&
    values?.beginActivatingMeterToDialog &&
    values?.metersWithinTolerance &&
    values?.testSystemLoadEnsured &&
    values?.locateIdealReturn &&
    values?.locateIdealMixed &&
    values?.ensureAirFiltersClean;

  const showEnOffPreTest = () =>
    showEnergyFirstOffButton() && values.preTestTimeStamp;

  const showEnOffPreTestEntry = () =>
    showEnOffPreTest() &&
    values?.haveHydronicControlValve &&
    values?.valveOffCoilFlowInlet;

  const showSteadyOffStartButton = () =>
    showEnOffPreTestEntry() && !!values?.endPreTestTimeStamp;

  const showSteadyOffTestEntry = () =>
    showSteadyOffStartButton() && !!values?.beginTestTimeStamp;

  const enableNextButton = useMemo(
    () =>
      (showSteadyOffTestEntry() && !!values?.endTestTimeStamp) ||
      values?.systemIs100OA ||
      values?.systemIs100RA,
    [
      showSteadyOffTestEntry,
      values?.endTestTimeStamp,
      values?.systemIs100OA,
      values?.systemIs100RA,
    ]
  );

  const handleHydWaterIsolationWarningClose = () =>
    setOpenHydWaterIsolationWarning(false);

  const handleValidateDampersValvesVFDDevicesChange = (e: any) => {
    let signature = "";
    if (e.target.value && values?.reportTypeId === 18) {
      const now = new Date(Date.now());
      signature = `${user?.userName} - ${now.toLocaleString()}`;
      setOpenHydWaterIsolationWarning(true);
    }
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      hydronicWaterIsolationSignature: signature,
    });
  };

  const handleMetersWithinToleranceChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
    if (e.target.value) setOpenMeterAvgWarning(true);
  };

  const handleMeterAvgWarningClose = () => {
    setOpenMeterAvgWarning(false);
    setValues({
      ...values,
      metersWithinTolerance: false,
      meter1DB: 0,
      meter1Value: 0,
      meter1MeasuredAs: "",
      meter1Location: "",
      meter1Label: "Meter 1",
      meter2DB: 0,
      meter2Value: 0,
      meter2MeasuredAs: "",
      meter2Location: "",
      meter2Label: "Meter 2",
      meter3DB: 0,
      meter3Value: 0,
      meter3MeasuredAs: "",
      meter3Location: "",
      meter3Label: "Meter 3",
    });
  };

  const handleMeterAvgWarningOk = () => {
    setOpenMeterAvgWarning(false);
    executeUpdtMetersAvg(id, { ...values, metersWithinTolerance: true });
  };

  const handleBleedThroughWarningClose = () =>
    setOpenBleedThroughWarning(false);

  const handleHotWaterFlowWarningClose = () =>
    setOpenHotWaterFlowWarning(false);

  const hanldeMeterLocationChange = (e: any) => {
    const name = (e.target.name as string).split(
      "-"
    )[0] as keyof IEnergyStudyAirHydronicsDTO;
    const location = (e.target.name as string).split("-")[1];
    setValues({
      ...values,
      [name]: values[name] !== location ? location : "",
    });
  };

  const handleValidateMeterAvgFrm = () =>
    (!!values?.meter1Label &&
      !!values?.meter1Location &&
      values?.meter1DB !== null &&
      values?.meter1Value !== null &&
      !!values?.meter1MeasuredAs &&
      !!values?.meter2Label &&
      !!values?.meter2Location &&
      values?.meter2DB !== null &&
      values?.meter2Value !== null &&
      !!values?.meter2MeasuredAs &&
      !!values?.meter3Label &&
      !!values?.meter3Location &&
      values?.meter3DB !== null &&
      values?.meter3Value !== null &&
      !!values?.meter3MeasuredAs) ||
    (!!values?.meter1Label &&
      !!values?.meter1Location &&
      values?.meter1DB !== null &&
      values?.meter1Value !== null &&
      !!values?.meter1MeasuredAs &&
      !!values?.meter2Label &&
      !!values?.meter2Location &&
      values?.meter2DB !== null &&
      values?.meter2Value !== null &&
      !!values?.meter2MeasuredAs &&
      !values?.systemIsMA);

  const handleBeginPreTestTimerClick = async (e: any) => {
    setValues({ ...values, preTestTimeStamp: true });
    await executeBeginTime(id, { ...values, preTestTimeStamp: true });
  };

  const handleEndPreTestTimerClick = async (e: any) => {
    await executeEndTime(id, values);
  };

  const handleBeginSteadyOffTimerClick = async (e: any) => {
    setValues({ ...values, preTestTimeStamp: true });
    await executeBeginTimeSteadyOff(id, { ...values, preTestTimeStamp: true });
  };

  const handleEndSteadyOffTimerClick = async (e: any) => {
    await executeEndTimeSteadyOff(id, values);
  };

  const hanldeHumidityMeasureChange = (e: any) => {
    if (user?.role === "Training") {
      log.error("Training Mode will allow to use only WB");
      return;
    }
    const name = (e.target.name as string).split(
      "-"
    )[0] as keyof IEnergyStudyAirHydronicsDTO;
    const location = (e.target.name as string).split("-")[1];
    setValues({
      ...values,
      [name]: values[name] !== location ? location : "",
    });
  };

  const handleOaRaCantBeIsolatedChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      neutralPressurized: false,
      recordOA: e.target.value ? false : true,
      ra: e.target.value ? false : true,
    });

    if (e.target.value) {
      const date = new Date();

      const crud = {
        commentTypeId: 3,
        reportTypeId: values?.reportTypeId,
        reportId: id,
        projectId: values?.projectId,
        note:
          "System: " +
          values?.systemField +
          ", Location: " +
          values?.location +
          ",  O/A and R/A can't be isolated.",
        createdDate: date,
      } as IComment;

      commentService.postComment(crud);
    }
  };

  const handleNeutralPressurizedChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      oaRaCantBeIsolated: false,
      recordOA: e.target.value ? false : true,
      ra: true,
    });

    if (e.target.value) {
      const date = new Date();

      const crud = {
        commentTypeId: 3,
        reportTypeId: values?.reportTypeId,
        reportId: id,
        projectId: values?.projectId,
        note:
          "System: " +
          values?.systemField +
          ", Location: " +
          values?.location +
          ", Mixed Air Plenum Chamber is Neutral or Pressurized.",
        createdDate: date,
      } as IComment;

      commentService.postComment(crud);
    }
  };

  const handleStaticPressureSignChange = (
    sign: "-" | "+",
    name: keyof IEnergyStudyAirHydronicsDTO
  ) => {
    if (!disableStudy())
      setValues({
        ...values,
        [name]: sign === "-",
      });
  };

  const handleTestingReadingsChange = (e: any) => {
    const name = e.target.name as keyof IEnergyStudyAirHydronicsDTO;
    const nameDrift = `${name}Drift` as keyof IEnergyStudyAirHydronicsDTO;
    let driftValue = 0;
    const preName = name.replace(
      "testing",
      "pre"
    ) as keyof IEnergyStudyAirHydronicsDTO;
    driftValue = e.target.value - ((values[preName] as number) || 0);
    const newValues = {
      ...values,
      [name]: !!e.target.value ? parseFloat(e.target.value) : e.target.value,
      [nameDrift]: driftValue,
    };
    if (["testingOaDryBulb", "testingRaDryBulb"].includes(e.target.name))
      evaluateBleedthrough(newValues);
    else setValues(newValues);
  };

  const handleTestingMaDryBulbChange = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    evaluateBleedthrough(newValues);
  };

  const evaluateBleedthrough = (newValues: IEnergyStudyAirHydronicsDTO) => {
    let bleedThroughAlertPresent = false;
    let bleedThroughAlertSignature = null;
    let hotWaterFlowAlertPresent = false;
    let hotWaterFlowAlertSignature = null;
    const now = new Date(Date.now());
    if (newValues.recordOA && newValues.ra) {
      const steadyOffColdestValueTemp =
        (newValues.testingOaDryBulb || 0) < (newValues.testingRaDryBulb || 0)
          ? newValues.testingOaDryBulb
          : newValues.testingRaDryBulb;
      const steadyOffWarmestValueTemp =
        (newValues.testingOaDryBulb || 0) > (newValues.testingRaDryBulb || 0)
          ? newValues.testingOaDryBulb || 0
          : newValues.testingRaDryBulb || 0;
      setSteadyOffWarmestValue(steadyOffWarmestValueTemp || 0);

      if (
        (newValues.testingMaDryBulb || 999999) <
          (steadyOffColdestValueTemp || 0) &&
        newValues.testMode === "Cooling" &&
        newValues.studyType === "Hydronics"
      ) {
        bleedThroughAlertPresent = true;
        bleedThroughAlertSignature = `${
          user?.userName
        } - ${now.toLocaleString()}`;
        setOpenBleedThroughWarning(true);
      } else if (
        (newValues.testingMaDryBulb || 0) > (steadyOffWarmestValueTemp || 0) &&
        newValues.testMode === "Heating" &&
        newValues.studyType === "Hydronics"
      ) {
        hotWaterFlowAlertPresent = true;
        hotWaterFlowAlertSignature = `${
          user?.userName
        } - ${now.toLocaleString()}`;
        setOpenHotWaterFlowWarning(true);
      }
    }
    setValues({
      ...newValues,
      bleedThroughAlertPresent,
      bleedThroughAlertSignature,
      hotWaterFlowAlertPresent,
      hotWaterFlowAlertSignature,
    });
  };

  const handleMeterMeasuredAsChange = (e: any) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      meter2MeasuredAs: values?.meter2MeasuredAs ?? e.target.value,
      meter3MeasuredAs: values?.meter3MeasuredAs ?? e.target.value,
    });
  };

  return (
    <>
      {values?.systemIsMA && (
        <SteadyStateDialog
          reportId={values?.id as number}
          reportTypeId={values?.reportTypeId as number}
          projectId={values?.projectId as number}
          reportCompleted={values?.isComplete || false}
          setDisableEditData={setDisableEditData}
        />
      )}
      <Popup
        title="Energy Off, Hydronic Water Isolation"
        openPopup={openHydWaterIsolationWarning}
        setOpenPopup={setOpenHydWaterIsolationWarning}
        onClose={handleHydWaterIsolationWarningClose}
        disableClickOutside
        hideClose
        size="md"
      >
        <>
          <p style={{ textAlign: "justify", fontSize: "medium" }}>
            To ensure accurate energy measurement and airflow rates in systems
            utilizing hot water, chilled water, and condenser water, it is
            crucial to verify the complete isolation of entering and leaving
            water via isolation valves. This involves ensuring no bleed-through
            or trickle is present, which could cause undesired delta
            temperatures due to leakage. Failure to properly isolate these
            valves can compromise system integrity, leading to inaccurately low
            airflow volume rates.
            <br />
            Additionally, temperature measurements should not be taken too close
            to a coil's supply and return water headers to avoid misinterpreting
            hydronic water bleed-through and thermal conduction as system flaws.
          </p>
          <p style={{ textAlign: "justify", color: "red", fontSize: "medium" }}>
            Neglecting to account for water bleed-through can result in
            underreported airflow readings.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleHydWaterIsolationWarningClose}
            autoFocus
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Meters Deviation"
        openPopup={openMeterAvgWaring}
        setOpenPopup={setOpenMeterAvgWarning}
        onClose={handleMeterAvgWarningClose}
        disableClickOutside
        hideClose
        size="lg"
      >
        <>
          <h2 style={{ textAlign: "center" }}>
            Comparing the Individual Enthalpy Meter Sensors to each other
          </h2>
          <p style={{ textAlign: "justify", fontSize: "medium" }}>
            Place the temperature/enthalpy sensors in the exact same location
            next to each other that are being used for this test in a
            steady-state temperature airstream (O/A or R/A). Ensure that the
            sensing elements are only being affected by the fluid medium and
            allow proper time for each of the sensor temperature elements to
            stabilize. Identify each meter and associated sensor by an
            identification mark and its correlation for each point being tested.
          </p>
          <Table stickyHeader aria-label="static components">
            <TableHead>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>#</TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Identifier
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>
                  Location/Point
                </TableCell>
                <TableCell style={{ textAlign: "center" }}>Reading</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>1</TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormText
                    label=""
                    name="meter1Label"
                    value={values?.meter1Label}
                    onChange={handleInputChange}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormCheckBox
                    name="meter1Location-OA"
                    showSkeleton={isLoading}
                    label="OA"
                    value={values?.meter1Location === "OA"}
                    disabled={
                      values?.meter2Location === "OA" ||
                      values?.meter3Location === "OA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter1Location-RA"
                    showSkeleton={isLoading}
                    label="RA"
                    value={values?.meter1Location === "RA"}
                    disabled={
                      values?.meter2Location === "RA" ||
                      values?.meter3Location === "RA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter1Location-MA"
                    showSkeleton={isLoading}
                    label="MA"
                    value={values?.meter1Location === "MA"}
                    disabled={
                      values?.meter2Location === "MA" ||
                      values?.meter3Location === "MA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`DB ${systemOfMeasurement.get("temperature")}`}
                        name="meter1DB"
                        onChange={handleInputChange}
                        value={values?.meter1DB}
                        showSkeleton={isLoading}
                        error={errors.meter1DB}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`${values?.meter1MeasuredAs || ""}`}
                        suffix={
                          values?.meter1MeasuredAs === "RH"
                            ? "%"
                            : systemOfMeasurement.get("temperature")
                        }
                        name="meter1Value"
                        onChange={handleInputChange}
                        value={values?.meter1Value}
                        showSkeleton={isLoading}
                        error={errors.meter1Value}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormRadioGroup
                        showSkeleton={isLoading}
                        name="meter1MeasuredAs"
                        label=""
                        items={meterMeasuredAsOptions}
                        value={values?.meter1MeasuredAs}
                        onChange={handleMeterMeasuredAsChange}
                        disabled={disableStudy() || isLoading}
                        row
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ textAlign: "center" }}>1</TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormText
                    label=""
                    name="meter2Label"
                    value={values?.meter2Label}
                    onChange={handleInputChange}
                    showSkeleton={isLoading}
                    disabled={disableStudy()}
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <FormCheckBox
                    name="meter2Location-OA"
                    showSkeleton={isLoading}
                    label="OA"
                    value={values?.meter2Location === "OA"}
                    disabled={
                      values?.meter1Location === "OA" ||
                      values?.meter3Location === "OA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter2Location-RA"
                    showSkeleton={isLoading}
                    label="RA"
                    value={values?.meter2Location === "RA"}
                    disabled={
                      values?.meter1Location === "RA" ||
                      values?.meter3Location === "RA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                  <FormCheckBox
                    name="meter2Location-MA"
                    showSkeleton={isLoading}
                    label="MA"
                    value={values?.meter2Location === "MA"}
                    disabled={
                      values?.meter1Location === "MA" ||
                      values?.meter3Location === "MA"
                    }
                    onChange={hanldeMeterLocationChange}
                    size="medium"
                  />
                </TableCell>
                <TableCell
                  style={{
                    textAlign: "center",
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`DB ${systemOfMeasurement.get("temperature")}`}
                        name="meter2DB"
                        onChange={handleInputChange}
                        value={values?.meter2DB}
                        showSkeleton={isLoading}
                        error={errors.meter2DB}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        disabled={disableStudy()}
                        placeholder="0.00"
                        decimalScale={2}
                        thousandSeparator
                        fixedDecimalScale
                        label={`${values?.meter2MeasuredAs || ""}`}
                        suffix={
                          values?.meter2MeasuredAs === "RH"
                            ? "%"
                            : systemOfMeasurement.get("temperature")
                        }
                        name="meter2Value"
                        onChange={handleInputChange}
                        value={values?.meter2Value}
                        showSkeleton={isLoading}
                        error={errors.meter2Value}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormRadioGroup
                        showSkeleton={isLoading}
                        name="meter2MeasuredAs"
                        label=""
                        items={meterMeasuredAsOptions}
                        value={values?.meter2MeasuredAs}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        row
                      />
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              {values?.systemIsMA && (
                <TableRow>
                  <TableCell style={{ textAlign: "center" }}>1</TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <FormText
                      label=""
                      name="meter3Label"
                      value={values?.meter3Label}
                      onChange={handleInputChange}
                      showSkeleton={isLoading}
                      disabled={disableStudy()}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <FormCheckBox
                      name="meter3Location-OA"
                      showSkeleton={isLoading}
                      label="OA"
                      value={values?.meter3Location === "OA"}
                      disabled={
                        values?.meter1Location === "OA" ||
                        values?.meter2Location === "OA"
                      }
                      onChange={hanldeMeterLocationChange}
                      size="medium"
                    />
                    <FormCheckBox
                      name="meter3Location-RA"
                      showSkeleton={isLoading}
                      label="RA"
                      value={values?.meter3Location === "RA"}
                      disabled={
                        values?.meter1Location === "RA" ||
                        values?.meter2Location === "RA"
                      }
                      onChange={hanldeMeterLocationChange}
                      size="medium"
                    />
                    <FormCheckBox
                      name="meter3Location-MA"
                      showSkeleton={isLoading}
                      label="MA"
                      value={values?.meter3Location === "MA"}
                      disabled={
                        values?.meter1Location === "MA" ||
                        values?.meter2Location === "MA"
                      }
                      onChange={hanldeMeterLocationChange}
                      size="medium"
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={3}>
                        <FormNumeric2
                          disabled={disableStudy()}
                          placeholder="0.00"
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          label={`DB ${systemOfMeasurement.get("temperature")}`}
                          name="meter3DB"
                          onChange={handleInputChange}
                          value={values?.meter3DB}
                          showSkeleton={isLoading}
                          error={errors.meter3DB}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <FormNumeric2
                          disabled={disableStudy()}
                          placeholder="0.00"
                          decimalScale={2}
                          thousandSeparator
                          fixedDecimalScale
                          label={`${values?.meter3MeasuredAs || ""}`}
                          suffix={
                            values?.meter3MeasuredAs === "RH"
                              ? "%"
                              : systemOfMeasurement.get("temperature")
                          }
                          name="meter3Value"
                          onChange={handleInputChange}
                          value={values?.meter3Value}
                          showSkeleton={isLoading}
                          error={errors.meter3Value}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <FormRadioGroup
                          showSkeleton={isLoading}
                          name="meter3MeasuredAs"
                          label=""
                          items={meterMeasuredAsOptions}
                          value={values?.meter3MeasuredAs}
                          onChange={handleInputChange}
                          disabled={disableStudy() || isLoading}
                          row
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="error"
            onClick={handleMeterAvgWarningClose}
            size="small"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleMeterAvgWarningOk}
            autoFocus
            disabled={!handleValidateMeterAvgFrm()}
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Potential Coil Bleedthrough"
        openPopup={openBleedThroughWarning}
        setOpenPopup={setOpenBleedThroughWarning}
        onClose={handleBleedThroughWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            The Mixed Air (M/A) temperature during the "Energy Off" phase is
            lower than the temperature value of either the Outside Air (O/A) or
            Return Air (R/A). This suggests a possible issue with your M/A
            Temperature selection point or coil bleed through.
          </p>
          <p>
            Please review your M/A temperature placement, your coil shut-off
            procedures, and re-examine the positions of your O/A and R/A
            sensors.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleBleedThroughWarningClose}
            autoFocus
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={openHotWaterFlowWarning}
        setOpenPopup={setOpenHotWaterFlowWarning}
        onClose={handleHotWaterFlowWarningClose}
        disableClickOutside
        hideClose
      >
        <>
          <p>
            Your Hot Water Flow exceeds {steadyOffWarmestValue?.toFixed(2)} this
            may encompass Heat Of Compression and Motor Heat, this will be
            determined once the report is finished.
          </p>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="success"
            size="small"
            onClick={handleHotWaterFlowWarningClose}
            autoFocus
          >
            Acknowledged
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{ code: code, system: values?.systemField, id: id }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              <Divider />
              {values?.systemIs100OA || values?.systemIs100RA ? (
                <Alert severity="info">
                  <span style={{ fontWeight: "bold" }}>
                    Energy Off test not required
                  </span>
                  <br />
                  The Energy-Off test is not required for this system
                  configuration.
                  <br />
                  Press the Next Step button to move forward and perform the
                  Energy-On Test.
                </Alert>
              ) : (
                <>
                  <Grid container spacing={3} marginLeft={5}>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="preDeterminedSetPoits"
                        label="Set system pre determined set-points."
                        value={values?.preDeterminedSetPoits}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        size="medium"
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="preDeterminedDevices"
                        label="Set system pre determined devices."
                        value={values?.preDeterminedDevices}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="validateDampersValvesVFDDevices"
                        label="Validate Dampers, Valves, VFD's, Devices, etc are at desired test condition settings."
                        value={values?.validateDampersValvesVFDDevices}
                        onChange={handleValidateDampersValvesVFDDevicesChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="beginActivatingMeterToDialog"
                        label="Begin activating meters to datalog or begin recording to the point of self stabilization."
                        value={values?.beginActivatingMeterToDialog}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="metersWithinTolerance"
                        label="Ensure O/A, R/A, & M/A Temperature/Enthalpy meters are within tolerance of each other."
                        value={values?.metersWithinTolerance}
                        onChange={handleMetersWithinToleranceChange}
                        disabled={
                          disableStudy() ||
                          isLoading ||
                          values?.metersWithinTolerance
                        }
                        switchStyle="Android"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckSwitch
                        showSkeleton={
                          isLoading || isLoadingReportType || isLoadingProject
                        }
                        name="testSystemLoadEnsured"
                        label={config?.labels.testSystemLoadEnsured(
                          values?.testMode || ""
                        )}
                        value={values?.testSystemLoadEnsured}
                        onChange={handleInputChange}
                        disabled={disableStudy() || isLoading}
                        switchStyle="Android"
                      />
                    </Grid>
                  </Grid>
                  {showEnergyFirstOffButton() && (
                    <>
                      <Divider />
                      <Grid
                        container
                        spacing={3}
                        marginTop={1}
                        marginBottom={2}
                      >
                        <Grid item xs={12} md={4} textAlign="center">
                          <FormButton
                            text="Begin Pre-Test, Energy First Time Off"
                            onClick={handleBeginPreTestTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={values.preTestTimeStamp}
                          />
                          {!!values?.beginPreTestTimeStamp && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                First Time Off, Begin Pre-Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.beginPreTestTimeStamp as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormStopwatch
                            date={beginPreTestTimeStamp || new Date()}
                            start={startPreTestTimer}
                            endDate={endPreTestTimeStamp}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showEnOffPreTest() && (
                    <>
                      <Divider />
                      <Grid container spacing={3} marginLeft={5}>
                        <Grid item xs={12}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="haveHydronicControlValve"
                            label={config?.labels.haveHydronicControlValve}
                            value={values?.haveHydronicControlValve}
                            onChange={handleInputChange}
                            disabled={disableStudy() || isLoading}
                            size="medium"
                            switchStyle="Android"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="valveOffCoilFlowInlet"
                            label={config?.labels.valveOffCoilFlowInlet}
                            value={values?.valveOffCoilFlowInlet}
                            onChange={handleInputChange}
                            disabled={disableStudy() || isLoading}
                            switchStyle="Android"
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showEnOffPreTestEntry() && (
                    <>
                      <Divider />
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={3}>
                          <p>When collecting Airflow Transfer Data use:</p>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <FormCheckBox
                            name="humidityMeasure-WB"
                            showSkeleton={isLoading}
                            label="WB"
                            value={values?.humidityMeasure === "WB"}
                            onChange={hanldeHumidityMeasureChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                          <FormCheckBox
                            name="humidityMeasure-RH"
                            showSkeleton={isLoading}
                            label="RH"
                            value={values?.humidityMeasure === "RH"}
                            onChange={hanldeHumidityMeasureChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                          <FormCheckBox
                            name="humidityMeasure-DP"
                            showSkeleton={isLoading}
                            label="DP"
                            value={values?.humidityMeasure === "DP"}
                            onChange={hanldeHumidityMeasureChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="neutralPressurized"
                            label="Mixed Air Plenum Chamber is Neutral or Pressurized"
                            size="medium"
                            value={values?.neutralPressurized}
                            onChange={handleNeutralPressurizedChange}
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                            switchStyle="Android"
                            labelStyle={{
                              color: "darkred",
                              fontWeight: "bolder",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormCheckSwitch
                            showSkeleton={
                              isLoading ||
                              isLoadingReportType ||
                              isLoadingProject
                            }
                            name="oaRaCantBeIsolated"
                            label="O/A and R/A can't be isolated"
                            value={values?.oaRaCantBeIsolated}
                            onChange={handleOaRaCantBeIsolatedChange}
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                            switchStyle="Android"
                            labelStyle={{
                              color: "orangered",
                              fontWeight: "bolder",
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            Point when Energy is First Stopped
                          </Alert>
                        </Grid>
                        {/**Record OA */}
                        <Grid item xs={12} md={2}>
                          <FormCheckBox
                            name="recordOA"
                            showSkeleton={isLoading}
                            label="Record O/A"
                            value={values?.recordOA}
                            onChange={handleInputChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              disableStudy() ||
                              !values?.recordOA ||
                              showSteadyOffStartButton()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="DB"
                            suffix={` ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="preOaDryBulb"
                            onChange={handleInputChange}
                            value={values?.preOaDryBulb}
                            showSkeleton={isLoading}
                            error={errors.preOaDryBulb}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              disableStudy() ||
                              !values?.recordOA ||
                              showSteadyOffStartButton()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`${values?.humidityMeasure || ""}`}
                            suffix={
                              values?.humidityMeasure === "RH"
                                ? " %"
                                : ` ${systemOfMeasurement.get("temperature")}`
                            }
                            name="preOaValue"
                            onChange={handleInputChange}
                            value={values?.preOaValue}
                            showSkeleton={isLoading}
                            error={errors.preOaValue}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {!disableStudy() &&
                            values?.recordOA &&
                            !showSteadyOffStartButton() && (
                              <>
                                <AddCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "+",
                                      "preOaStaticPressureIsMinus"
                                    )
                                  }
                                />
                                <RemoveCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "-",
                                      "preOaStaticPressureIsMinus"
                                    )
                                  }
                                />{" "}
                              </>
                            )}
                          <FormNumeric2
                            label="SP"
                            placeholder="0.00"
                            allowNegative={false}
                            prefix={
                              values?.preOaStaticPressureIsMinus ? "-" : "+"
                            }
                            suffix={` ${systemOfMeasurement.get("inwc")}`}
                            fixedDecimalScale
                            decimalScale={2}
                            fullWidth={disableStudy() || !values?.recordOA}
                            style={{
                              width:
                                !disableStudy() && values?.recordOA
                                  ? 380
                                  : "100%",
                            }}
                            name="preOaStaticPressure"
                            value={values?.preOaStaticPressure}
                            onChange={handleInputChange}
                            showSkeleton={isLoading || isLoadingReportType}
                            disabled={
                              disableStudy() ||
                              !values?.recordOA ||
                              showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        {/**Record RA */}
                        <Grid item xs={12} md={2}>
                          <FormCheckBox
                            name="ra"
                            showSkeleton={isLoading}
                            label="Record R/A"
                            value={values?.ra}
                            onChange={handleInputChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              disableStudy() ||
                              !values?.ra ||
                              showSteadyOffStartButton()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="DB"
                            suffix={` ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="preRaDryBulb"
                            onChange={handleInputChange}
                            value={values?.preRaDryBulb}
                            showSkeleton={isLoading}
                            error={errors.preRaDryBulb}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              disableStudy() ||
                              !values?.ra ||
                              showSteadyOffStartButton()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`${values?.humidityMeasure || ""}`}
                            suffix={
                              values?.humidityMeasure === "RH"
                                ? " %"
                                : ` ${systemOfMeasurement.get("temperature")}`
                            }
                            name="preRaValue"
                            onChange={handleInputChange}
                            value={values?.preRaValue}
                            showSkeleton={isLoading}
                            error={errors.preRaValue}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {!disableStudy() &&
                            values?.ra &&
                            !showSteadyOffStartButton() && (
                              <>
                                <AddCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "+",
                                      "preRaStaticPressureIsMinus"
                                    )
                                  }
                                />
                                <RemoveCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "-",
                                      "preRaStaticPressureIsMinus"
                                    )
                                  }
                                />{" "}
                              </>
                            )}
                          <FormNumeric2
                            label="SP"
                            placeholder="0.00"
                            allowNegative={false}
                            prefix={
                              values?.preRaStaticPressureIsMinus ? "-" : "+"
                            }
                            suffix={` ${systemOfMeasurement.get("inwc")}`}
                            fixedDecimalScale
                            decimalScale={2}
                            fullWidth={disableStudy() || !values?.ra}
                            style={{
                              width:
                                !disableStudy() && values?.ra ? 380 : "100%",
                            }}
                            name="preRaStaticPressure"
                            value={values?.preRaStaticPressure}
                            onChange={handleInputChange}
                            showSkeleton={isLoading || isLoadingReportType}
                            disabled={
                              disableStudy() ||
                              !values?.ra ||
                              showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        {/**Record MA */}
                        <Grid item xs={12} md={2}>
                          <FormCheckBox
                            name="ma"
                            showSkeleton={isLoading}
                            label="Record M/A"
                            value={values?.ma}
                            onChange={handleInputChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="Blocked"
                            suffix={` ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="preMaDryBulb"
                            onChange={handleInputChange}
                            value={values?.preMaDryBulb}
                            showSkeleton={isLoading}
                            error={errors.preMaDryBulb}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="Blocked"
                            suffix={
                              values?.humidityMeasure === "RH"
                                ? " %"
                                : ` ${systemOfMeasurement.get("temperature")}`
                            }
                            name="preMaValue"
                            onChange={handleInputChange}
                            value={values?.preMaValue}
                            showSkeleton={isLoading}
                            error={errors.preMaValue}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {!disableStudy() &&
                            values?.ma &&
                            !showSteadyOffStartButton() && (
                              <>
                                <AddCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "+",
                                      "preMaStaticPressureIsMinus"
                                    )
                                  }
                                />
                                <RemoveCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "-",
                                      "preMaStaticPressureIsMinus"
                                    )
                                  }
                                />{" "}
                              </>
                            )}
                          <FormNumeric2
                            label="SP"
                            placeholder="0.00"
                            allowNegative={false}
                            prefix={
                              values?.preMaStaticPressureIsMinus ? "-" : "+"
                            }
                            suffix={` ${systemOfMeasurement.get("inwc")}`}
                            fixedDecimalScale
                            decimalScale={2}
                            fullWidth={disableStudy() || !values?.ma}
                            style={{
                              width:
                                !disableStudy() && values?.ma ? 380 : "100%",
                            }}
                            name="preMaStaticPressure"
                            value={values?.preMaStaticPressure}
                            onChange={handleInputChange}
                            showSkeleton={isLoading || isLoadingReportType}
                            disabled={
                              disableStudy() ||
                              !values?.ma ||
                              showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        {/**Record EA */}
                        <Grid item xs={12} md={2}>
                          <FormCheckBox
                            name="eaUsingDB"
                            showSkeleton={isLoading}
                            label="Record E/A"
                            value={values?.eaUsingDB}
                            onChange={handleInputChange}
                            size="medium"
                            disabled={
                              disableStudy() || showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              disableStudy() ||
                              !values?.eaUsingDB ||
                              showSteadyOffStartButton()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label="DB"
                            suffix={` ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            name="preEaDryBulb"
                            onChange={handleInputChange}
                            value={values?.preEaDryBulb}
                            showSkeleton={isLoading}
                            error={errors.preEaDryBulb}
                          />
                        </Grid>
                        <Grid item xs={12} md={3}>
                          <FormNumeric2
                            disabled={
                              disableStudy() ||
                              !values?.eaUsingDB ||
                              showSteadyOffStartButton()
                            }
                            placeholder="0.00"
                            decimalScale={2}
                            thousandSeparator
                            fixedDecimalScale
                            label={`${values?.humidityMeasure || ""}`}
                            suffix={
                              values?.humidityMeasure === "RH"
                                ? " %"
                                : ` ${systemOfMeasurement.get("temperature")}`
                            }
                            name="preEaValue"
                            onChange={handleInputChange}
                            value={values?.preEaValue}
                            showSkeleton={isLoading}
                            error={errors.preEaValue}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          {!disableStudy() &&
                            values?.eaUsingDB &&
                            !showSteadyOffStartButton() && (
                              <>
                                <AddCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "+",
                                      "preEaStaticPressureIsMinus"
                                    )
                                  }
                                />
                                <RemoveCircleOutlineIcon
                                  style={{
                                    marginTop: 7,
                                    cursor: "pointer",
                                  }}
                                  onClick={(e: any) =>
                                    handleStaticPressureSignChange(
                                      "-",
                                      "preEaStaticPressureIsMinus"
                                    )
                                  }
                                />{" "}
                              </>
                            )}
                          <FormNumeric2
                            label="SP"
                            placeholder="0.00"
                            allowNegative={false}
                            prefix={
                              values?.preEaStaticPressureIsMinus ? "-" : "+"
                            }
                            suffix={` ${systemOfMeasurement.get("inwc")}`}
                            fixedDecimalScale
                            decimalScale={2}
                            fullWidth={disableStudy() || !values?.eaUsingDB}
                            style={{
                              width:
                                !disableStudy() && values?.eaUsingDB
                                  ? 380
                                  : "100%",
                            }}
                            name="preEaStaticPressure"
                            value={values?.preEaStaticPressure}
                            onChange={handleInputChange}
                            showSkeleton={isLoading || isLoadingReportType}
                            disabled={
                              disableStudy() ||
                              !values?.eaUsingDB ||
                              showSteadyOffStartButton()
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          textAlign="center"
                          marginBottom={3}
                        >
                          <FormButton
                            text="End Pre-Test, Energy First Time Off"
                            onClick={handleEndPreTestTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values.endPreTestTimeStamp}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {!!values?.endPreTestTimeStamp && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                First Time Off, End Pre-Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.endPreTestTimeStamp as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showSteadyOffStartButton() && (
                    <>
                      <Divider />
                      <Grid
                        container
                        spacing={3}
                        marginTop={1}
                        marginBottom={2}
                      >
                        <Grid item xs={12} md={4} textAlign="center">
                          <FormButton
                            text="Begin Test, Energy Off Steady State"
                            onClick={handleBeginSteadyOffTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values.beginTestTimeStamp}
                          />
                          {!!values?.beginTestTimeStamp && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                Off Steady State, Begin Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.beginTestTimeStamp as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                        <Grid item xs={12} md={8}>
                          <FormStopwatch
                            date={beginSteadyOffTimeStamp || new Date()}
                            start={startSteadyOffTimer}
                            endDate={endSteadyOffTimeStamp}
                          />
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {showSteadyOffTestEntry() && (
                    <>
                      <Divider />
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            Point of Energy Off Steady State
                          </Alert>
                        </Grid>
                        {/**Record OA */}
                        {values?.recordOA && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>O/A:</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="testingOaDryBulb"
                                onBlur={handleTestingReadingsChange}
                                value={values?.testingOaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.testingOaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift: ${(
                                  values?.testingOaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="testingOaValue"
                                onChange={handleInputChange}
                                value={values?.testingOaValue}
                                showSkeleton={isLoading}
                                error={errors.testingOaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.recordOA &&
                                !values.endTestTimeStamp && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "testingOaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "testingOaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.testingOaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={disableStudy() || !values?.recordOA}
                                style={{
                                  width:
                                    !disableStudy() && values?.recordOA
                                      ? 270
                                      : "100%",
                                }}
                                name="testingOaStaticPressure"
                                value={values?.testingOaStaticPressure}
                                onChange={handleTestingReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.recordOA ||
                                  !!values.endTestTimeStamp
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift: ${(
                                  values?.testingOaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record RA */}
                        {values?.ra && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>R/A:</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="testingRaDryBulb"
                                onBlur={handleTestingReadingsChange}
                                value={values?.testingRaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.testingRaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift: ${(
                                  values?.testingRaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="testingRaValue"
                                onChange={handleInputChange}
                                value={values?.testingRaValue}
                                showSkeleton={isLoading}
                                error={errors.testingRaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.ra &&
                                !values.endTestTimeStamp && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "testingRaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "testingRaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.testingRaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={disableStudy() || !values?.ra}
                                style={{
                                  width:
                                    !disableStudy() && values?.ra
                                      ? 270
                                      : "100%",
                                }}
                                name="testingRaStaticPressure"
                                value={values?.testingRaStaticPressure}
                                onChange={handleTestingReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.ra ||
                                  !!values.endTestTimeStamp
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift: ${(
                                  values?.testingRaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record MA */}
                        {values?.ma && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>M/A:</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="testingMaDryBulb"
                                onBlur={handleTestingMaDryBulbChange}
                                value={values?.testingMaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.testingMaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  width: "100%",
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label="N/A"
                                color="default"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="testingMaValue"
                                onChange={handleInputChange}
                                value={values?.testingMaValue}
                                showSkeleton={isLoading}
                                error={errors.testingMaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.ma &&
                                !values.endTestTimeStamp && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "testingMaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "testingMaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.testingMaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 270,
                                }}
                                name="testingMaStaticPressure"
                                value={values?.testingMaStaticPressure}
                                onChange={handleTestingReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.ma ||
                                  !!values.endTestTimeStamp
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift: ${(
                                  values?.testingMaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        {/**Record EA */}
                        {values?.eaUsingDB && (
                          <>
                            <Grid item xs={12} md={1}>
                              <p>E/A:</p>
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label="DB"
                                suffix={` ${systemOfMeasurement.get(
                                  "temperature"
                                )}`}
                                name="testingEaDryBulb"
                                onChange={handleTestingReadingsChange}
                                value={values?.testingEaDryBulb}
                                showSkeleton={isLoading}
                                error={errors.testingEaDryBulb}
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`DB Drift: ${(
                                  values?.testingEaDryBulbDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              <FormNumeric2
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.endTestTimeStamp
                                }
                                placeholder="0.00"
                                decimalScale={2}
                                thousandSeparator
                                fixedDecimalScale
                                label={`${values?.humidityMeasure || ""}`}
                                suffix={
                                  values?.humidityMeasure === "RH"
                                    ? " %"
                                    : ` ${systemOfMeasurement.get(
                                        "temperature"
                                      )}`
                                }
                                name="testingEaValue"
                                onChange={handleInputChange}
                                value={values?.testingEaValue}
                                showSkeleton={isLoading}
                                error={errors.testingEaValue}
                              />
                            </Grid>
                            <Grid item xs={12} md={3}>
                              {!disableStudy() &&
                                values?.eaUsingDB &&
                                !values.endTestTimeStamp && (
                                  <>
                                    <AddCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "+",
                                          "testingEaStaticPressureIsMinus"
                                        )
                                      }
                                    />
                                    <RemoveCircleOutlineIcon
                                      style={{
                                        marginTop: 7,
                                        cursor: "pointer",
                                      }}
                                      onClick={(e: any) =>
                                        handleStaticPressureSignChange(
                                          "-",
                                          "testingEaStaticPressureIsMinus"
                                        )
                                      }
                                    />{" "}
                                  </>
                                )}
                              <FormNumeric2
                                label="SP"
                                placeholder="0.00"
                                allowNegative={false}
                                prefix={
                                  values?.testingEaStaticPressureIsMinus
                                    ? "-"
                                    : "+"
                                }
                                suffix={` ${systemOfMeasurement.get("inwc")}`}
                                fixedDecimalScale
                                decimalScale={2}
                                fullWidth={false}
                                style={{
                                  width: 270,
                                }}
                                name="testingEaStaticPressure"
                                value={values?.testingEaStaticPressure}
                                onChange={handleTestingReadingsChange}
                                showSkeleton={isLoading || isLoadingReportType}
                                disabled={
                                  disableStudy() ||
                                  !values?.eaUsingDB ||
                                  !!values.endTestTimeStamp
                                }
                              />
                            </Grid>
                            <Grid item xs={12} md={1}>
                              <Chip
                                sx={{
                                  height: 36.2,
                                  "& .MuiChip-label": {
                                    display: "block",
                                    whiteSpace: "normal",
                                  },
                                  textAlign: "center",
                                }}
                                label={`SP Drift: ${(
                                  values?.testingEaStaticPressureDrift ?? 0
                                ).toFixed(2)}`}
                                color="info"
                                size="medium"
                              />
                            </Grid>
                          </>
                        )}
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          textAlign="center"
                          marginBottom={3}
                        >
                          <FormButton
                            text="End Test, Energy Off Steady State"
                            onClick={handleEndSteadyOffTimerClick}
                            variant="outlined"
                            color="secondary"
                            disabled={!!values.endTestTimeStamp}
                          />
                        </Grid>
                        <Grid item xs={12} md={8}>
                          {!!values?.endTestTimeStamp && (
                            <p>
                              Energy{" "}
                              <span style={{ fontWeight: "bold" }}>
                                Off Steady State, End Test
                              </span>{" "}
                              Time Stamp:{" "}
                              {new Date(
                                values?.endTestTimeStamp as string
                              ).toLocaleString()}
                            </p>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </>
              )}
              <Divider />
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={values?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={
                  isUpdating ||
                  isLoading ||
                  isLoadingReportType ||
                  isLoadingProject ||
                  disableStudy()
                }
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                disableNext={!enableNextButton}
                previousStepHandler={previousStepHandler}
                isComplete={values?.isComplete}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default EnergyStep4;
