import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  SxProps,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import React, { CSSProperties } from "react";
import { NumericFormat } from "react-number-format";
import { Mode } from "src/ts/types/common";

interface Props {
  value?: number | string | null;
  onChange?: (e: any) => void;
  name: string;
  decimalScale?: number | undefined;
  maxValue?: number | undefined;
  disabled?: boolean;
  mode?: Mode;
  onBlur?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onPaste?: (e: any) => void;
  placeholder?: string;
  fixedDecimalScale?: boolean;
  thousandSeparator?: boolean;
  emptyFieldAccept?: boolean;
  style?: CSSProperties | undefined;
  size?: "small" | "medium" | undefined;
  suffix?: string;
  prefix?: string;
  label?: string;
  allowNegative?: boolean;
  error?: any;
  fullWidth?: string | boolean;
  color?: string;
  defaultValue?: number;
  sx?: SxProps<Theme> | undefined;
  rightAlign?: boolean;
  inputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>
    | undefined;
  readOnly?: boolean;
  requiredField?: boolean;
  colorTextInput?: string;
}

const SpreadsheetNumericInput2 = ({
  value,
  onChange,
  name,
  decimalScale = 2,
  maxValue,
  disabled,
  mode,
  onBlur,
  onKeyDown,
  onPaste,
  placeholder = "0.00",
  fixedDecimalScale = true,
  thousandSeparator = true,
  emptyFieldAccept = true,
  style,
  size,
  suffix,
  prefix,
  label,
  allowNegative,
  error,
  fullWidth,
  color,
  defaultValue,
  sx,
  rightAlign = true,
  inputProps,
  readOnly,
  requiredField,
  colorTextInput,
}: Props) => {
  const valueRef = React.useRef<string>();
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    var input = inputRef.current;
    if (input) {
      valueRef.current = value as string;
    }
  }, [value]);

  const handleFocus = (event: any) => {
    event.target.select();
    var input = inputRef.current;
    if (input) {
      valueRef.current = input.value;
    }
  };

  const handleBlur = () => {
    var input = inputRef.current;
    if (input) {
      let valueNew = "";
      if (typeof input.value === "string") {
        const valueSplitted = input.value.split(" ");
        valueNew =
          valueSplitted.length > 1
            ? Number(valueSplitted[0].replace(/,/g, ""))
              ? valueSplitted[0].replace(/,/g, "")
              : valueSplitted[1].replace(/,/g, "")
            : input.value.replace(/,/g, "");
      }
      const ev = {
        target: {
          name: name,
          value: valueNew,
        },
      };
      if (onChange) {
        if (input.value !== valueRef.current) {
          onChange && onChange(ev);
        }
      }
      if (onBlur) {
        onBlur && onBlur(ev);
      }
    }
  };

  if (mode && mode === "read")
    return (
      <Typography
        style={{
          width: "100%",

          paddingLeft: "2px",
          paddingRight: "2px",
        }}
        variant="body1"
      >
        <NumericFormat
          displayType="text"
          value={value}
          thousandSeparator={thousandSeparator}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
        />
      </Typography>
    );

  return (
    <NumericFormat
      disabled={disabled}
      onFocus={handleFocus}
      onBlur={handleBlur}
      name={name}
      inputRef={inputRef}
      value={value}
      customInput={TextField}
      thousandSeparator={thousandSeparator}
      fixedDecimalScale={fixedDecimalScale}
      decimalScale={decimalScale ?? 3}
      //onBlurCapture={onBlur}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue === undefined && emptyFieldAccept) return true;
        if (floatValue !== 0 && !floatValue) return false;

        if (maxValue) return floatValue < maxValue;

        return true;
      }}
      placeholder={placeholder}
      style={{
        ...style,
        fontWeight: disabled ? "bolder" : "normal",
        paddingLeft: "2px",
        paddingRight: "2px",
        width: "100%",
      }}
      size={size ?? "small"}
      // onChange={onChange}
      /*onValueChange={(values) => {
        // Reference comment: const { formattedValue, value, floatValue } = values;
        const { floatValue } = values;

        onChange && onChange({ target: { name, value: floatValue ?? 0 } });
      }}*/
      onKeyDown={onKeyDown}
      onPaste={onPaste}
      autoComplete="off"
      label={label}
      suffix={suffix ? ` ${suffix?.trim()}` : undefined}
      prefix={prefix ? `${prefix?.trim()} ` : undefined}
      allowNegative={allowNegative}
      {...(error && { error: true, helperText: error })}
      fullWidth={fullWidth ?? true}
      valueIsNumericString
      color={color}
      defaultValue={defaultValue}
      sx={{
        ...sx,
        ...(requiredField && {
          "& .MuiInputBase-root": {
            border: "1px solid red",
          },
          "& .MuiInputLabel-shrink": {
            transform: "translate(14px, -6px) scale(0.75)",
            backgroundColor: "white",
            padding: "0 4px",
          },
        }),
      }}
      InputProps={{
        ...inputProps,
        inputProps: {
          ...inputProps?.inputProps,
          style: {
            ...inputProps?.inputProps?.style,
            textAlign: rightAlign ? "right" : "start",
            paddingLeft: 5,
            paddingRight: 5,
            color: colorTextInput ?? "",
          },
        },
      }}
      readOnly={readOnly}
    />
  );
};

export default SpreadsheetNumericInput2;
