import { Grid, Stack } from "@mui/material";
import { useEffect } from "react";
import { FormCheckSwitch, FormNumeric2 } from "src/components/formControls";
import { MEASUREMENTTYPE } from "src/constants";
import { ISystemOfMeasurement } from "src/ts/interfaces";

interface IResultsDataMatrixProps {
  systemOfMeasurement: ISystemOfMeasurement;
  values: any;
  disabel: boolean;
  modeReadWrite: "read" | "read&Write";
  handlerChangeData: (e: any) => void;
  showskeleton: boolean;
  disableStudy: boolean;
  handleChangeRadio: (e: any) => void;
  porcentajeDesign: boolean | number | undefined;
  handlerTotalHeat: (e: any) => void;
  handleInputChange: (e: any) => void;
  errors: Record<string, string>;
  setErrors: (val: Record<string, string>) => void;
}

const ResultsDataMatrix = (props: IResultsDataMatrixProps) => {
  const {
    systemOfMeasurement,
    values,
    disabel,
    modeReadWrite,
    handlerChangeData,
    showskeleton,
    handleChangeRadio,
    porcentajeDesign,
    handlerTotalHeat,
    handleInputChange,
    errors,
    setErrors,
  } = props;

  const decimalNumber =
    systemOfMeasurement.measurementSystem === MEASUREMENTTYPE.IMPERIALTYPE
      ? 2
      : 3;

  useEffect(() => {
    let temp: Record<string, string> = { ...errors };

    temp.dryBulbF =
      values?.dryBulbF > 0
        ? ""
        : "Airflow Temperature For Energy Calculation Is Required";

    setErrors({
      ...temp,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.dryBulbF]);

  return (
    <>
      <Grid container spacing={3} mt={2}>
        <Grid container ml={4}>
          <Grid item md={3} xs={6}>
            <Grid container>
              <Grid item xs={10}>
                <FormNumeric2
                  name={"required"}
                  label={`Required ${systemOfMeasurement.get("cfm")}`}
                  value={values?.required?.toString() ?? ""}
                  disabled={disabel}
                  onChange={handlerChangeData}
                  showSkeleton={showskeleton}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                  mode={modeReadWrite}
                ></FormNumeric2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={6}>
            <Grid container>
              <Grid item xs={10}>
                <FormNumeric2
                  name={"akFactor"}
                  label={"AK Factor"}
                  value={values?.akFactor?.toString() ?? ""}
                  onChange={handlerChangeData}
                  disabled={disabel}
                  showSkeleton={showskeleton}
                  decimalScale={decimalNumber}
                  maxValue={1000}
                  thousandSeparator={true}
                  mode={modeReadWrite}
                ></FormNumeric2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={6}>
            <Grid container>
              <Grid item xs={10}>
                <FormNumeric2
                  name={"dryBulbF"}
                  label={"Airflow Temperature For Energy Calculation*"}
                  value={values?.dryBulbF?.toString() ?? ""}
                  onChange={handlerChangeData}
                  disabled={disabel}
                  showSkeleton={showskeleton}
                  error={errors.dryBulbF}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                  mode={modeReadWrite}
                  suffix={"DB"}
                ></FormNumeric2>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={3} xs={6}></Grid>
        </Grid>
        {values?.totalHeat && (
          <>
            <Grid item md={3} xs={6}></Grid>
            <Grid item md={3} xs={6}>
              <Grid container>
                <Grid item xs={10}>
                  {values?.relativeHumidityBool === true && (
                    <FormNumeric2
                      name={"relativeHumidity"}
                      label={"Relative Humidity"}
                      value={values?.relativeHumidity?.toString() ?? ""}
                      onChange={handlerTotalHeat}
                      disabled={disabel}
                      showSkeleton={showskeleton}
                      decimalScale={decimalNumber}
                      thousandSeparator={true}
                      mode={modeReadWrite}
                    ></FormNumeric2>
                  )}
                  {values?.wetBulbFBool === true && (
                    <FormNumeric2
                      name={"wetBulbF"}
                      label={"Wet bulb F"}
                      value={values?.wetBulbF?.toString() ?? ""}
                      onChange={handlerTotalHeat}
                      disabled={disabel}
                      showSkeleton={showskeleton}
                      decimalScale={decimalNumber}
                      thousandSeparator={true}
                      mode={modeReadWrite}
                    ></FormNumeric2>
                  )}
                  {values?.dewPointBool === true && (
                    <FormNumeric2
                      name={"dewPoint"}
                      label={"Dew Point"}
                      value={values?.dewPoint?.toString() ?? ""}
                      onChange={handlerTotalHeat}
                      disabled={disabel}
                      showSkeleton={showskeleton}
                      decimalScale={decimalNumber}
                      thousandSeparator={true}
                      mode={modeReadWrite}
                    ></FormNumeric2>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Stack direction={"row"}>
                <FormCheckSwitch
                  name={"relativeHumidityBool"}
                  label={"Relative Humidity"}
                  showSkeleton={showskeleton}
                  onChange={handleChangeRadio}
                  disabled={disabel}
                  value={values?.relativeHumidityBool}
                  mode={modeReadWrite}
                />

                <FormCheckSwitch
                  name={"wetBulbFBool"}
                  label={"Wet bulb F"}
                  showSkeleton={showskeleton}
                  onChange={handleChangeRadio}
                  disabled={disabel}
                  value={values?.wetBulbFBool}
                  mode={modeReadWrite}
                />
                <FormCheckSwitch
                  name={"dewPointBool"}
                  label={"Dew Point"}
                  showSkeleton={showskeleton}
                  onChange={handleChangeRadio}
                  disabled={disabel}
                  value={values?.dewPointBool}
                  mode={modeReadWrite}
                />
              </Stack>
            </Grid>
          </>
        )}
        <Grid container mt={2} ml={4}>
          <Grid item md={3} xs={6}>
            <FormNumeric2
              name={"totalVelocity"}
              label={"Total Velocity"}
              value={values?.totalVelocity?.toString() ?? ""}
              mode="read"
              showSkeleton={showskeleton}
              onChange={handleInputChange}
              decimalScale={decimalNumber}
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
          <Grid item md={3} xs={6}>
            <FormNumeric2
              name={"avgVelocity"}
              label={"Avg Velocity"}
              value={values?.avgVelocity?.toString() ?? ""}
              mode="read"
              showSkeleton={showskeleton}
              onChange={handleInputChange}
              decimalScale={decimalNumber}
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
          <Grid item md={3} xs={6}>
            <FormNumeric2
              name={"trueCorectedAirVelocity"}
              label={"True Corrected Average Velocity"}
              value={values?.trueCorectedAirVelocity?.toFixed(3) ?? ""}
              mode="read"
              showSkeleton={showskeleton}
              onChange={handleInputChange}
              decimalScale={decimalNumber}
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
          <Grid item md={3} xs={6}>
            <FormNumeric2
              name={"totalCFM"}
              label={`Actual ${systemOfMeasurement.get("acfm")}`}
              value={values?.totalCFM?.toFixed(3) ?? ""}
              mode="read"
              showSkeleton={showskeleton}
              onChange={handleInputChange}
              decimalScale={decimalNumber}
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
          <Grid item md={3} xs={6}>
            <FormNumeric2
              name={"percentajeOfDesign"}
              label={"% of Design"}
              value={values?.percentajeOfDesign?.toFixed(3) ?? ""}
              mode="read"
              showSkeleton={showskeleton}
              color={porcentajeDesign ? "red" : undefined}
              onChange={handleInputChange}
              decimalScale={decimalNumber}
              suffix="%"
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
          {values?.totalHeat && (
            <>
              <Grid item md={3} xs={6}>
                <FormNumeric2
                  name={"btuh"}
                  label={`Total ${systemOfMeasurement.get("btuh")}`}
                  value={values?.btuh?.toFixed(3) ?? ""}
                  onChange={handleInputChange}
                  mode="read"
                  showSkeleton={showskeleton}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                  disabled={disabel}
                ></FormNumeric2>
              </Grid>
              <Grid item md={3} xs={6}>
                <FormNumeric2
                  name={"sensibleHeat"}
                  label={`Sensible ${systemOfMeasurement.get("btuh")}`}
                  value={values?.sensibleHeat}
                  mode="read"
                  showSkeleton={showskeleton}
                  onChange={handleInputChange}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default ResultsDataMatrix;
