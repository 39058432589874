import { Alert, Chip, Grid, Typography } from "@mui/material";
import {
  FormCheckBox,
  FormNumeric2,
  FormSelect,
} from "src/components/formControls";
import FormTooltip from "src/components/formControls/FormTooltip";
import {
  IEnergyStudyAirHydronicsDTO,
  ISystemOfMeasurement,
} from "src/ts/interfaces";
import { Validator } from "src/ts/types";
import SummaryCard from "../components/SummaryCard";
import WaterIcon from "@mui/icons-material/Water";
import { blue } from "@mui/material/colors";
import { TextAlign } from "chart.js";

const numberOfPumpsOptions = [
  { key: 0, value: "Select number of pumps" },
  { key: 1, value: "1 Pump" },
  { key: 2, value: "2 Pumps" },
  { key: 3, value: "3 Pumps" },
  { key: 4, value: "4 Pumps" },
  { key: 5, value: "5 Pumps" },
  { key: 6, value: "6 Pumps" },
  { key: 7, value: "7 Pumps" },
  { key: 8, value: "8 Pumps" },
  { key: 9, value: "9 Pumps" },
];

const _STUDY_STATUS_APPROVED = 7;
const _STUDY_STATUS_NOT_COMPLIANT = 8;
const _STUDY_STATUS_REJECTED = 9;
const _STUDY_STATUS_APPEALED = 10;
const _STUDY_STATUS_FINAL_REJECTED = 11;

export const energyHydronicsConfig = {
  title: "Energy Study Air Hydronics",
  baseUrlName: "EnergyStudyAirHydronics",
  targetOptions: [
    { key: "Air", value: "I know the water, find for air" },
    { key: "Water", value: "I know the air, find for water" },
  ],
  labels: {
    heatingCoilTitle: "Heating Coil Design Data",
    heatingCoilHasMultipleCircuits:
      "This Unit has multiple stacked Heating Coils",
    numberOfCircuitsInHeatingCoil: "Number of Heating Coils",
    sensibleBTUHHeating: (systemOfMeasurement: ISystemOfMeasurement) =>
      `Sensible ${systemOfMeasurement.get("btuhw")} via Airflow`,
    latentBTUHHeating: (systemOfMeasurement: ISystemOfMeasurement) =>
      `Latent ${systemOfMeasurement.get("btuhw")} via Airflow`,
    shrCoilHeating: "Sensible Heat Ratio (SHR) via Airflow:",
    val1TheMechanicalCoilBeingTested:
      "Validate the mechanical coil being tested can be isolated from allowing any fluid flow (Mechanical or Convective) through the coils piping entering and leaving side.",
    val2IfItsDeterminedFluidFlow:
      "If its determined fluid flow can't be fully isolated, have system isolation issue repaired to fully isolate the coil or install isolation valves.",
    pretestSystemLoadEnsured: (testMode: string) =>
      `When testing is performed, ensure you have a thermal load imposed on this system - If no load is present, ${
        testMode === "Cooling" ? "heat" : "cool"
      } the space ${testMode === "Cooling" ? "up" : "down"} while no ${
        testMode === "Cooling" ? "chilled water" : "hot water"
      } is flowing.`,
    testSystemLoadEnsured: (testMode: string) =>
      `At time of system test, ensure you have a thermal load imposed on this system - If no load is present, ${
        testMode === "Cooling" ? "heat" : "cool"
      } the space ${testMode === "Cooling" ? "up" : "down"} while no ${
        testMode === "Cooling" ? "chilled water" : "hot water"
      } is flowing.`,
    haveHydronicControlValve:
      "Have Hydronic Control Valve stop the flow of fluids through the coil.",
    valveOffCoilFlowInlet:
      "Valve off Coil flow inlet and discharge valves to the (Chilled / Hot) coil being tested.",
    haveHydronicControlValveOpen:
      "Have Hydronic Control Valve open for the flow of fluids through the coil.",
    openAnyCoilValvePreviouslyClosed:
      "Open any Coil Valve previously closed off allowing flow at the inlet and discharge to the (Chilled / Hot) water coil being tested.",
    heatingElectricResistanceTitle: () => <></>,
  },
  controlStatus: {
    numberOfCircuitsInHeatingCoil: { disabled: false },
    numberOfCircuitsInHeatingCoilNotListed: { disabled: false },
  },
  tooltips: {
    numberOfCircuitsInHeatingCoil: <FormTooltip tooltipId={28} />,
    actualTestedCoilSP: <></>,
    spThroughCoil: <></>,
  },
  inputs: {
    numberOfPumps: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="numberOfPumpsNotListed"
            value={values?.numberOfPumpsNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormSelect
            label="Number of pumps serving this Unit Only"
            name="numberOfPumps"
            value={values?.numberOfPumps}
            onChange={handleInputChange}
            options={numberOfPumpsOptions}
            showSkeleton={isLoading}
            disabled={values?.numberOfPumpsNotListed || disableStudy()}
            error={errors.numberOfPumps}
          />
        </Grid>
      </>
    ),
    numberOfLeadPumpsDesign: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="numberOfLeadPumpsDesignNotListed"
            value={values?.numberOfLeadPumpsDesignNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormSelect
            label="Current operating lead Pumps"
            name="numberOfLeadPumpsDesign"
            value={values?.numberOfLeadPumpsDesign}
            onChange={handleInputChange}
            options={numberOfPumpsOptions.filter(
              (x) => x.key <= values?.numberOfPumps
            )}
            showSkeleton={isLoading}
            disabled={
              values?.numberOfLeadPumpsDesignNotListed || disableStudy()
            }
            error={errors.numberOfLeadPumpsDesign}
          />
        </Grid>
      </>
    ),
    maAirPressureDeltaHeating: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="maAirPressureDeltaHeatingNotListed"
            value={values?.maAirPressureDeltaHeatingNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.maAirPressureDeltaHeatingNotListed || disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Max "Air" Delta Pressure (ΔP) ${systemOfMeasurement.get(
              "inwc"
            )}`}
            name="maAirPressureDeltaHeating"
            onChange={handleInputChange}
            value={values?.maAirPressureDeltaHeating}
            showSkeleton={isLoading}
            error={errors.maAirPressureDeltaHeating}
          />
        </Grid>
      </>
    ),
    designWaterDeltaPressureHeating: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="designWaterDeltaPressureHeatingNotListed"
            value={values?.designWaterDeltaPressureHeatingNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.designWaterDeltaPressureHeatingNotListed || disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Design Water Δ ${systemOfMeasurement.get("psi")}`}
            name="designWaterDeltaPressureHeating"
            onChange={handleInputChange}
            value={values?.designWaterDeltaPressureHeating}
            showSkeleton={isLoading}
            error={errors.designWaterDeltaPressureHeating}
          />
        </Grid>
      </>
    ),
    heatingCoilGPM: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      handleHeatingCoilGPMBlur: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingCoilGPMNotListed"
            value={values?.heatingCoilGPMNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={values?.heatingCoilGPMNotListed || disableStudy()}
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Design ${systemOfMeasurement.get("gpm")}`}
            name="heatingCoilGPM"
            //onChange={handleInputChange}
            onBlur={handleHeatingCoilGPMBlur}
            value={values?.heatingCoilGPM}
            showSkeleton={isLoading}
            error={errors.heatingCoilGPM}
            inputProps={{ sx: { color: "darkred" } }}
          />
        </Grid>
      </>
    ),
    enteringHydronicHeatingCoilTemperature: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      handleHeatingCoilEWTBlur: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="enteringHydronicHeatingCoilTemperatureNotListed"
            value={values?.enteringHydronicHeatingCoilTemperatureNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.enteringHydronicHeatingCoilTemperatureNotListed ||
              disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Entering Hydronic/Water ${systemOfMeasurement.get(
              "temperature"
            )}`}
            name="enteringHydronicHeatingCoilTemperature"
            //onChange={handleInputChange}
            onBlur={handleHeatingCoilEWTBlur}
            value={values?.enteringHydronicHeatingCoilTemperature}
            showSkeleton={isLoading}
            error={errors.enteringHydronicHeatingCoilTemperature}
          />
        </Grid>
      </>
    ),
    leavingHydronicHeatingCoilTemperature: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      handleHeatingCoilLWTBlur: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="leavingHydronicHeatingCoilTemperatureNotListed"
            value={values?.leavingHydronicHeatingCoilTemperatureNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.leavingHydronicHeatingCoilTemperatureNotListed ||
              disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Leaving Hydronic/Water ${systemOfMeasurement.get(
              "temperature"
            )}`}
            name="leavingHydronicHeatingCoilTemperature"
            //onChange={handleInputChange}
            onBlur={handleHeatingCoilLWTBlur}
            value={values?.leavingHydronicHeatingCoilTemperature}
            showSkeleton={isLoading}
            error={errors.leavingHydronicHeatingCoilTemperature}
          />
        </Grid>
      </>
    ),
    hydronicFluidFlowDeltaTemperatureHeating: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      handleHeatingCoilAWTBlur: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="hydronicFluidFlowDeltaTemperatureHeatingNotListed"
            value={values?.hydronicFluidFlowDeltaTemperatureHeatingNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={
              values?.hydronicFluidFlowDeltaTemperatureHeatingNotListed ||
              disableStudy()
            }
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Hydronic Fluid Flow Δ ${systemOfMeasurement.get(
              "temperature"
            )}`}
            name="hydronicFluidFlowDeltaTemperatureHeating"
            //onChange={handleInputChange}
            onBlur={handleHeatingCoilAWTBlur}
            value={values?.hydronicFluidFlowDeltaTemperatureHeating}
            showSkeleton={isLoading}
            error={errors.hydronicFluidFlowDeltaTemperatureHeating}
          />
        </Grid>
      </>
    ),
    wpdFeetHeadHeating: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="wpdFeetHeadHeatingNotListed"
            value={values?.wpdFeetHeadHeatingNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={values?.wpdFeetHeadHeatingNotListed || disableStudy()}
            placeholder="0.00"
            decimalScale={2}
            thousandSeparator
            fixedDecimalScale
            label={`Hydronic/Water W.P.D. ${systemOfMeasurement.get(
              "feet"
            )} Head`}
            name="wpdFeetHeadHeating"
            onChange={handleInputChange}
            value={values?.wpdFeetHeadHeating}
            showSkeleton={isLoading}
            error={errors.wpdFeetHeadHeating}
          />
        </Grid>
      </>
    ),
    finsPerInchHeating: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="finsPerInchHeatingNotListed"
            value={values?.finsPerInchHeatingNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={values?.finsPerInchHeatingNotListed || disableStudy()}
            placeholder="0"
            decimalScale={0}
            thousandSeparator={false}
            fixedDecimalScale={false}
            label={`Fins per ${systemOfMeasurement.get("inches")}`}
            name="finsPerInchHeating"
            onChange={handleInputChange}
            value={values?.finsPerInchHeating}
            showSkeleton={isLoading}
            error={errors.finsPerInchHeating}
          />
        </Grid>
      </>
    ),
    heatingCoilRows: (
      values: IEnergyStudyAirHydronicsDTO,
      handleInputChange: (e: any) => void,
      isLoading: boolean,
      disableStudy: () => boolean,
      systemOfMeasurement: ISystemOfMeasurement,
      errors: Record<string, string>
    ) => (
      <>
        <Grid item xs={12} md={3}>
          <FormCheckBox
            label="Not Listed"
            name="heatingCoilRowsNotListed"
            value={values?.heatingCoilRowsNotListed}
            onChange={handleInputChange}
            showSkeleton={isLoading}
            disabled={disableStudy()}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <FormNumeric2
            disabled={values?.heatingCoilRowsNotListed || disableStudy()}
            placeholder="0"
            decimalScale={0}
            thousandSeparator={false}
            fixedDecimalScale={false}
            label="Number of Rows"
            name="heatingCoilRows"
            onChange={handleInputChange}
            value={values?.heatingCoilRows}
            showSkeleton={isLoading}
            error={errors.heatingCoilRows}
          />
        </Grid>
      </>
    ),
    heatingElectricResistanceManufacturer: () => <></>,
    heatingElectricResistanceModel: () => <></>,
    heatingElectricResistanceHz: () => <></>,
    heatingElectricResistanceRatedKws: () => <></>,
    heatingElectricResistancePhase: () => <></>,
    heatingElectricResistanceRatedVoltage: () => <></>,
    heatingElectricResistanceRatedAmperage: () => <></>,
    heatingElectricResistanceSteps: () => <></>,
    heatingElectricResistanceStages: () => <></>,
    banks: () => <></>,
    cfms: () => <></>,
    heatingElectricResistanceActualWatts: () => <></>,
    heatingElectricResistanceActualKWatts: () => <></>,
  },
  validators: {
    step1: (fieldValues: IEnergyStudyAirHydronicsDTO) => {
      let temp: Record<string, string> = {};

      temp.numberOfPumps = new Validator(fieldValues, "numberOfPumps")
        .validateIf(!fieldValues.numberOfPumpsNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field is required")
        .toString();
      temp.numberOfLeadPumpsDesign = new Validator(
        fieldValues,
        "numberOfLeadPumpsDesign"
      )
        .validateIf(!fieldValues.numberOfLeadPumpsDesignNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field is required")
        .toString();
      temp.numberOfCircuitsInHeatingCoil = new Validator(
        fieldValues,
        "numberOfCircuitsInHeatingCoil"
      )
        .validateIf(
          !fieldValues.numberOfCircuitsInHeatingCoilNotListed &&
            fieldValues.heatingCoilHasMultipleCircuits
        )
        .isRequired("This field is required")
        .greatThan(1, "This field must be greater than 1")
        .lessThan(16, "This field must be lower than 16")
        .toString();
      temp.maAirPressureDeltaHeating = new Validator(
        fieldValues,
        "maAirPressureDeltaHeating"
      )
        .validateIf(!fieldValues.maAirPressureDeltaHeatingNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.designWaterDeltaPressureHeating = new Validator(
        fieldValues,
        "designWaterDeltaPressureHeating"
      )
        .validateIf(!fieldValues.designWaterDeltaPressureHeatingNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.heatingCoilGPM = new Validator(fieldValues, "heatingCoilGPM")
        .validateIf(!fieldValues.heatingCoilGPMNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.enteringHydronicHeatingCoilTemperature = new Validator(
        fieldValues,
        "enteringHydronicHeatingCoilTemperature"
      )
        .validateIf(
          !fieldValues.enteringHydronicHeatingCoilTemperatureNotListed
        )
        .isRequired("This field is required")
        .toString();
      temp.leavingHydronicHeatingCoilTemperature = new Validator(
        fieldValues,
        "leavingHydronicHeatingCoilTemperature"
      )
        .validateIf(!fieldValues.leavingHydronicHeatingCoilTemperatureNotListed)
        .isRequired("This field is required")
        .toString();
      temp.hydronicFluidFlowDeltaTemperatureHeating = new Validator(
        fieldValues,
        "hydronicFluidFlowDeltaTemperatureHeating"
      )
        .validateIf(
          !fieldValues.hydronicFluidFlowDeltaTemperatureHeatingNotListed
        )
        .isRequired("This field is required")
        .toString();
      temp.wpdFeetHeadHeating = new Validator(fieldValues, "wpdFeetHeadHeating")
        .validateIf(!fieldValues.wpdFeetHeadHeatingNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.finsPerInchHeating = new Validator(fieldValues, "finsPerInchHeating")
        .validateIf(!fieldValues.finsPerInchHeatingNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      temp.heatingCoilRows = new Validator(fieldValues, "heatingCoilRows")
        .validateIf(!fieldValues.heatingCoilRowsNotListed)
        .isRequired("This field is required")
        .greatThan(0, "This field must be greater than 0")
        .toString();
      return temp;
    },
  },
  actualsSummary: {
    columnLeft: (
      systemOfMeasurement: ISystemOfMeasurement,
      values: IEnergyStudyAirHydronicsDTO
    ) => (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <h2 style={{ textAlign: "center" }}>WATER ACTUALS DATA</h2>
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Chip
              label={`Coil ${systemOfMeasurement.get("gpm")}`}
              color="info"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            {values?.target === "Air" && (
              <p>
                <span
                  style={{ fontWeight: "bold" }}
                >{`(Actual) Coil ${systemOfMeasurement.get(
                  "gpm"
                )}:`}</span>{" "}
                {(values?.gpms || 0).toLocaleString("en-US", {
                  useGrouping: true,
                  minimumFractionDigits: 3,
                })}
              </p>
            )}
            {values?.target === "Water" && (
              <Alert severity="info">
                {systemOfMeasurement.get("gpm")} is unknown at this point, the
                Energy Process is looking for the Water.
              </Alert>
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Chip
              label={`Actual Coil ΔT ${systemOfMeasurement.get(
                "temperature"
              )} Water Temperature`}
              color="info"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span
              style={{ fontWeight: "bold" }}
            >{`Entering Coil Water Temperature ${systemOfMeasurement.get(
              "temperature"
            )}:`}</span>{" "}
            {(values?.enteringWaterTempToCoil || 0).toLocaleString("en-US", {
              useGrouping: true,
              minimumFractionDigits: 3,
            })}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span style={{ fontWeight: "bold" }}>{`ΔT ${systemOfMeasurement.get(
              "temperature"
            )}:`}</span>{" "}
            {(values?.waterDeltaT || 0).toLocaleString("en-US", {
              useGrouping: true,
              minimumFractionDigits: 3,
            })}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span
              style={{ fontWeight: "bold" }}
            >{`Leaving Coil Water Temperature ${systemOfMeasurement.get(
              "temperature"
            )}:`}</span>{" "}
            {(values?.leavingWaterTempFromCoil || 0).toLocaleString("en-US", {
              useGrouping: true,
              minimumFractionDigits: 3,
            })}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Chip
              label={`Actual Coil Δ ${systemOfMeasurement.get("psi")} Water`}
              color="info"
            />
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span
              style={{ fontWeight: "bold" }}
            >{`Entering Water ${systemOfMeasurement.get("psi")}:`}</span>{" "}
            {(values?.enteringWaterInletPressure || 0).toLocaleString("en-US", {
              useGrouping: true,
              minimumFractionDigits: 3,
            })}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span style={{ fontWeight: "bold" }}>{`Δ ${systemOfMeasurement.get(
              "psi"
            )}:`}</span>{" "}
            {(values?.waterDeltaP || 0).toLocaleString("en-US", {
              useGrouping: true,
              minimumFractionDigits: 3,
            })}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 40 }}>
            <span
              style={{ fontWeight: "bold" }}
            >{`Leaving Water ${systemOfMeasurement.get("psi")}:`}</span>{" "}
            {(values?.leavingWaterDischargePressure || 0).toLocaleString(
              "en-US",
              {
                useGrouping: true,
                minimumFractionDigits: 3,
              }
            )}
          </Grid>
        </Grid>
      </>
    ),
  },
  energySummarySections: {
    gpm: (
      values: IEnergyStudyAirHydronicsDTO,
      systemOfMeasurement: ISystemOfMeasurement,
      isInfectiousControl: boolean,
      userRole: string,
      isInReview: boolean | undefined,
      studyStatusId: number | undefined,
      insertApprovalComment: (section: string, decision: string) => void,
      actionsDisabled: (string | null)[],
      getSectionStatus: (section: string) => string | undefined,
      summaryDataBlock: (
        title: string,
        attribute: keyof IEnergyStudyAirHydronicsDTO | undefined,
        validateShowSummary?: boolean,
        colorAttr?: keyof IEnergyStudyAirHydronicsDTO | undefined,
        helperText?: string,
        factor?: number,
        colorValidatorLower?: number,
        colorValidatorHigher?: number,
        customContent?: string,
        customColor?: string,
        customContentAlignment?: TextAlign | undefined
      ) => JSX.Element | undefined,
      renderApprovalComment: (section: string) => JSX.Element | JSX.Element[],
      showSummary: boolean,
      designGPM: number | null
    ) => (
      <>
        <Grid item xs={12} md={6}>
          <SummaryCard
            section="gpm"
            avatarIcon={<WaterIcon />}
            avatarColor={blue[500]}
            title={`System ${systemOfMeasurement.get("gpm")}`}
            isInfectiousControl={isInfectiousControl}
            userRole={userRole}
            isInReview={isInReview || false}
            showFinalReject={studyStatusId === _STUDY_STATUS_APPEALED}
            finalRejectAction={() =>
              insertApprovalComment("gpm", "finalreject")
            }
            initExpanded={[6, 7, 8, 9, 10, 11].includes(studyStatusId || 0)}
            approveAction={() => insertApprovalComment("gpm", "approve")}
            rejectAction={() => insertApprovalComment("gpm", "reject")}
            compliantAction={() => insertApprovalComment("gpm", "compliant")}
            disabledActions={actionsDisabled.includes("gpm")}
            currentDecision={getSectionStatus("gpm")}
            cardContent={
              <>
                <Grid container spacing={0}>
                  {values?.testMode === "Cooling" &&
                    summaryDataBlock(
                      `(Required) System ${systemOfMeasurement.get("gpm")}:`,
                      "coolingCoilGPM"
                    )}
                  {values?.testMode === "Heating" &&
                    summaryDataBlock(
                      `(Required) System ${systemOfMeasurement.get("gpm")}:`,
                      "heatingCoilGPM"
                    )}
                  {summaryDataBlock(
                    `(Actual) System ${systemOfMeasurement.get("gpm")}:`,
                    "gpms",
                    true,
                    "gpmPercentageOfDesign"
                  )}
                  {summaryDataBlock(
                    `(Percentage of design) System ${systemOfMeasurement.get(
                      "gpm"
                    )} %:`,
                    "gpmPercentageOfDesign",
                    true,
                    "gpmPercentageOfDesign"
                  )}
                </Grid>
                {(values?.gpmPercentageOfDesign || 0) < 100 && showSummary && (
                  <Alert severity="warning" style={{ marginTop: 10 }}>
                    Adjust {systemOfMeasurement.get("gpm")} Flow Up to{" "}
                    {(designGPM || 0).toLocaleString("en-US", {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                    })}{" "}
                    to Maximize Energy Efficiency and Heat Transfer
                  </Alert>
                )}
                {(values?.gpmPercentageOfDesign || 0) > 100 && showSummary && (
                  <Alert severity="warning" style={{ marginTop: 10 }}>
                    Adjust {systemOfMeasurement.get("gpm")} Airiflow Down to{" "}
                    {(designGPM || 0).toLocaleString("en-US", {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                    })}{" "}
                    to Maximize Energy Efficiency and Heat Transfer
                  </Alert>
                )}
              </>
            }
            collapsibleContent={
              <>
                <Grid container spacing={0}>
                  <Grid item xs={12} paddingBottom={5}>
                    <Typography
                      fontWeight="bold"
                      textAlign="center"
                      fontSize="medium"
                    >
                      Approval comments made by AMP's Super Administrator
                    </Typography>
                  </Grid>
                  {renderApprovalComment("gpm")}
                </Grid>
              </>
            }
          />
        </Grid>
        {(!!values?.coilFoulingFactorAir ||
          !!values?.btUhDeficitWater ||
          !!values?.energyWasteFactorWater ||
          !!values?.bhpReductionFactorWater ||
          !!values?.spReductionFactorWater) && (
          <Grid item xs={12} md={6}>
            <SummaryCard
              section="gpm-warnings"
              avatarIcon={<WaterIcon />}
              avatarColor={blue[500]}
              title={`${systemOfMeasurement.get("gpm")} realted warnings`}
              isInfectiousControl={false}
              userRole={userRole}
              isInReview={isInReview || false}
              initExpanded={false}
              disabledActions={actionsDisabled.includes("gpm")}
              cardContent={
                <>
                  <Grid container spacing={0}>
                    {!!values?.coilFoulingFactorWater &&
                      summaryDataBlock(
                        "(Water) Coil Fouling Factor %:",
                        "coilFoulingFactorWater",
                        true
                      )}
                    {!!values?.btUhDeficitWater &&
                      summaryDataBlock(
                        `(Water) ${systemOfMeasurement.get("btuh")} Deficit:`,
                        "btUhDeficitWater",
                        true
                      )}
                    {!!values?.energyWasteFactorWater &&
                      summaryDataBlock(
                        "(Water) Energy Waste Factor %:",
                        "energyWasteFactorWater",
                        true,
                        "energyWasteFactorWater",
                        "",
                        100,
                        0,
                        0.035
                      )}
                    {!!values?.bhpReductionFactorWater &&
                      summaryDataBlock(
                        `(Water) Operating ${systemOfMeasurement.get(
                          "bhp"
                        )} can drop %:`,
                        "bhpReductionFactorWater",
                        true,
                        undefined,
                        "",
                        100
                      )}
                    {!!values?.spReductionFactorWater &&
                      summaryDataBlock(
                        "(Water) Pressure can drop %",
                        "spReductionFactorWater",
                        true,
                        undefined,
                        "",
                        100
                      )}
                  </Grid>
                </>
              }
              collapsibleContent={<></>}
            />
          </Grid>
        )}
      </>
    ),
    chilledWater: (
      values: IEnergyStudyAirHydronicsDTO,
      systemOfMeasurement: ISystemOfMeasurement,
      isInfectiousControl: boolean,
      userRole: string,
      isInReview: boolean | undefined,
      studyStatusId: number | undefined,
      insertApprovalComment: (section: string, decision: string) => void,
      actionsDisabled: (string | null)[],
      getSectionStatus: (section: string) => string | undefined,
      summaryDataBlock: (
        title: string,
        attribute: keyof IEnergyStudyAirHydronicsDTO | undefined,
        validateShowSummary?: boolean,
        colorAttr?: keyof IEnergyStudyAirHydronicsDTO | undefined,
        helperText?: string,
        factor?: number,
        colorValidatorLower?: number,
        colorValidatorHigher?: number,
        customContent?: string,
        customColor?: string,
        customContentAlignment?: TextAlign | undefined
      ) => JSX.Element | undefined,
      renderApprovalComment: (section: string) => JSX.Element | JSX.Element[],
      showSummary: boolean,
      designGPM: number | null
    ) => (
      <>
        <Grid item xs={12} md={6}>
          <SummaryCard
            section="hydronic"
            avatarIcon={<WaterIcon />}
            avatarColor={blue[500]}
            title={`System Chilled Water - ΔT ${systemOfMeasurement.get(
              "temperature"
            )}`}
            isInfectiousControl={isInfectiousControl}
            userRole={userRole}
            isInReview={isInReview || false}
            showFinalReject={studyStatusId === _STUDY_STATUS_APPEALED}
            finalRejectAction={() =>
              insertApprovalComment("hydronic", "finalreject")
            }
            initExpanded={[6, 7, 8, 9, 10, 11].includes(studyStatusId || 0)}
            approveAction={() => insertApprovalComment("hydronic", "approve")}
            rejectAction={() => insertApprovalComment("hydronic", "reject")}
            compliantAction={() =>
              insertApprovalComment("hydronic", "compliant")
            }
            disabledActions={actionsDisabled.includes("hydronic")}
            currentDecision={getSectionStatus("hydronic")}
            cardContent={
              <>
                <Grid container spacing={0}>
                  {values?.testMode === "Cooling" &&
                    summaryDataBlock(
                      `(Required) Chilled Water ΔT ${systemOfMeasurement.get(
                        "temperature"
                      )}:`,
                      "hydronicFluidFlowDeltaTemperatureCooling"
                    )}
                  {values?.testMode === "Heating" &&
                    summaryDataBlock(
                      `(Required) Chilled Water ΔT ${systemOfMeasurement.get(
                        "temperature"
                      )}:`,
                      "hydronicFluidFlowDeltaTemperatureHeating"
                    )}
                  {summaryDataBlock(
                    `(Actual) Chilled Water ΔT ${systemOfMeasurement.get(
                      "temperature"
                    )}:`,
                    "waterDeltaT",
                    true,
                    "atfPercentageOfDesign"
                  )}
                  {summaryDataBlock(
                    `(Percentage of design) Chilled Water ΔT ${systemOfMeasurement.get(
                      "temperature"
                    )} %:`,
                    "atfPercentageOfDesign",
                    true,
                    "atfPercentageOfDesign"
                  )}
                </Grid>
                {(values?.gpmPercentageOfDesign || 0) < 100 && showSummary && (
                  <Alert severity="warning" style={{ marginTop: 10 }}>
                    Adjust {systemOfMeasurement.get("gpm")} Flow Up to{" "}
                    {(designGPM || 0).toLocaleString("en-US", {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                    })}{" "}
                    to Maximize Energy Efficiency and Heat Transfer
                  </Alert>
                )}
                {(values?.gpmPercentageOfDesign || 0) > 100 && showSummary && (
                  <Alert severity="warning" style={{ marginTop: 10 }}>
                    Adjust {systemOfMeasurement.get("gpm")} Airiflow Down to{" "}
                    {(designGPM || 0).toLocaleString("en-US", {
                      useGrouping: true,
                      minimumFractionDigits: 2,
                    })}{" "}
                    to Maximize Energy Efficiency and Heat Transfer
                  </Alert>
                )}
              </>
            }
            collapsibleContent={
              <>
                <Grid container spacing={0}>
                  <Grid item xs={12} paddingBottom={5}>
                    <Typography
                      fontWeight="bold"
                      textAlign="center"
                      fontSize="medium"
                    >
                      Approval comments made by AMP's Super Administrator
                    </Typography>
                  </Grid>
                  {renderApprovalComment("hydronic")}
                </Grid>
              </>
            }
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <SummaryCard
            section="hydronic-warnings"
            avatarIcon={<WaterIcon />}
            avatarColor={blue[500]}
            title={`Water Temperature ${systemOfMeasurement.get(
              "temperature"
            )}`}
            isInfectiousControl={false}
            userRole={userRole}
            isInReview={isInReview || false}
            initExpanded={false}
            disabledActions={actionsDisabled.includes("hydronic")}
            cardContent={
              <>
                <Grid container spacing={0}>
                  {values?.testMode === "Cooling" &&
                    summaryDataBlock(
                      "(Design) Entering Water Temperature:",
                      "enteringHydronicCoolingCoilTemperature",
                      true
                    )}
                  {values?.testMode === "Heating" &&
                    summaryDataBlock(
                      "(Design) Entering Water Temperature:",
                      "enteringHydronicHeatingCoilTemperature",
                      true
                    )}
                  {values?.testMode === "Cooling" &&
                    summaryDataBlock(
                      "(Actual) Entering Water Temperature:",
                      "enteringWaterTempToCoil",
                      true,
                      "enteringWaterTempToCoil",
                      "",
                      1,
                      0,
                      (values?.enteringHydronicCoolingCoilTemperature || 0) +
                        0.5
                    )}
                  {values?.testMode === "Heating" &&
                    summaryDataBlock(
                      "(Actual) Entering Water Temperature:",
                      "enteringWaterTempToCoil",
                      true,
                      "enteringWaterTempToCoil",
                      "",
                      100,
                      (values?.enteringHydronicHeatingCoilTemperature || 0) -
                        0.5,
                      999999999
                    )}
                </Grid>
                {(values?.enteringWaterTempToCoil || 0) >
                  (values?.enteringHydronicCoolingCoilTemperature || 0) &&
                  values?.testMode === "Cooling" &&
                  showSummary && (
                    <Alert severity="warning" style={{ marginTop: 10 }}>
                      <span style={{ color: "red" }}>WARNING:</span> Actual
                      Water Temprerature Delivery exceeds design
                    </Alert>
                  )}
                {(values?.enteringWaterTempToCoil || 0) <
                  (values?.enteringHydronicCoolingCoilTemperature || 0) &&
                  values?.testMode === "Heating" &&
                  showSummary && (
                    <Alert severity="warning" style={{ marginTop: 10 }}>
                      <span style={{ color: "red" }}>WARNING:</span> Actual
                      Actual Water Temprerature Delivery is below design
                    </Alert>
                  )}
              </>
            }
            collapsibleContent={<></>}
          />
        </Grid>
      </>
    ),
  },
  allAHUDesignDataNA: (values: IEnergyStudyAirHydronicsDTO) =>
    values?.numberOfPumpsNotListed && values?.numberOfLeadPumpsDesignNotListed,
  allHeatingCoilDataNA: (values: IEnergyStudyAirHydronicsDTO) =>
    values?.numberOfCircuitsInHeatingCoilNotListed &&
    values?.maAirPressureDeltaHeatingNotListed &&
    values?.designWaterDeltaPressureHeatingNotListed &&
    values?.heatingCoilGPMNotListed &&
    values?.enteringHydronicHeatingCoilTemperatureNotListed &&
    values?.leavingHydronicHeatingCoilTemperatureNotListed &&
    values?.hydronicFluidFlowDeltaTemperatureHeatingNotListed &&
    values?.wpdFeetHeadHeatingNotListed &&
    values?.finsPerInchHeatingNotListed &&
    values?.heatingCoilRowsNotListed,
  requriedApprovalSections: [
    "cfm",
    "gpm",
    "oa",
    "ra",
    "building",
    "coilStatic",
    "coilDelta",
    "btu",
    "hydronic",
  ],
};
