import {
  DashboardCustomizeOutlined,
  StraightenOutlined,
} from "@mui/icons-material";
import { Grid, Typography, Stack, Alert } from "@mui/material";
import { useMemo } from "react";
import {
  FormButton,
  FormCheckBox,
  FormLabel,
  FormNumeric2,
} from "src/components/formControls";
import { DuctType, MEASUREMENTTYPE, ROLES } from "src/constants";
import { useAuth, useLog } from "src/hooks";
import velgridMatrixService from "src/services/study/velgridMatrixService";
import { ISystemOfMeasurement } from "src/ts/interfaces";
import { IDimensions } from "src/ts/interfaces/study/velgridMatrix";

interface IDimensionsMatrixProps {
  typeReport: "Velgrid" | "General";
  values: any;
  setValues: (val: any) => void;
  disableStudy: boolean;
  mode: "read" | "read&Write";
  systemOfMeasurement: ISystemOfMeasurement;
  changeMatrix: (e: any) => void;
  executeRefresh: (val1: number, val2: any) => void;
  changeMatrixButton: boolean;
  showSkeleton: boolean;
  dimensions: IDimensions;
  dataX: number;
  dataY: number;
  errors: Record<string, string>;
  showUpdateButton: boolean;
  customValidation: boolean;
  setCustomValidation: (val: boolean) => void;
  trainingModeUpdateButton: boolean | number | undefined;
  setErrors: (val: Record<string, string>) => void;
  studieROVA?: boolean;
}
interface IAlertMessaage {
  customMatrix: boolean;
  buttonMatrix: boolean;
}

const AlertMessageMatrix = (props: IAlertMessaage) => {
  const { buttonMatrix, customMatrix } = props;
  return (
    <>
      {buttonMatrix && !customMatrix && (
        <Alert severity="warning">
          Since the dimensions of the Terminal Device being tested have changed,
          the matrix needs to be updated.
        </Alert>
      )}
      {buttonMatrix && customMatrix && (
        <Alert severity="warning">
          Since the custom numbers of readings have changed, the matrix needs to
          be updated.
        </Alert>
      )}
    </>
  );
};

const DimensionsMatrix = (props: IDimensionsMatrixProps) => {
  const {
    typeReport,
    values,
    setValues,
    disableStudy,
    mode,
    systemOfMeasurement,
    changeMatrix,
    executeRefresh,
    changeMatrixButton,
    dimensions,
    dataX,
    dataY,
    showSkeleton,
    errors,
    showUpdateButton,
    setCustomValidation,
    trainingModeUpdateButton,
    setErrors,
    studieROVA,
  } = props;

  const { user } = useAuth();
  const { log } = useLog();

  const typeVelgird = typeReport === "Velgrid";

  let widthX = values?.dimensionWidth - 2;
  let heightY = values?.dimensionHeight - 2;
  let diameter = values?.ductSizeBase - 2;

  const conditionsOptions = () => {
    if (!typeVelgird && values?.readingsX !== null && values?.readingsY) {
      const status = values?.isRectangleType
        ? values?.readingsX > widthX || values?.readingsY > heightY
        : values?.readingsX > widthX || values?.readingsY > widthX;
      return status;
    } else if (typeVelgird && values?.customMatrix) {
      const status =
        values?.ductTypeId === DuctType.Rectangular
          ? values?.x > widthX || values?.y > heightY
          : values?.x > diameter || values?.y > diameter;
      return status;
    } else {
      return false;
    }
  };

  const reviewXY = () => {
    let temp: Record<string, string> = { ...errors };
    if (typeVelgird && values?.customMatrix) {
      temp.y =
        (values?.ductTypeId === DuctType.Rectangular &&
          values?.y !== null &&
          values?.y > heightY) ||
        (values?.ductTypeId === DuctType.Round &&
          values?.y !== null &&
          values?.y > diameter)
          ? `The number of readings exceeds the allowed limit. The maximum allowed number of readings is ${
              values?.ductTypeId === DuctType.Rectangular ? heightY : diameter
            }.`
          : "";
      temp.x =
        (values?.ductTypeId === DuctType.Rectangular &&
          values?.x !== null &&
          values?.x > widthX) ||
        (values?.ductTypeId === DuctType.Round &&
          values?.x !== null &&
          values?.x > diameter)
          ? `The number of readings exceeds the allowed limit. The maximum allowed number of readings is ${
              values?.ductTypeId === DuctType.Rectangular ? widthX : diameter
            }.`
          : "";
    } else {
      temp.readingsY =
        (heightY > values?.isRectangleType &&
          values?.readingsY !== null &&
          values?.readingsY > heightY) ||
        (values?.isRoundType &&
          values?.readingsY !== null &&
          values?.readingsY > widthX)
          ? `The number of readings exceeds the allowed limit. The maximum allowed number of readings is ${
              values?.isRoundType ? widthX : heightY
            }.`
          : "";
      temp.readingsX =
        (values?.isRectangleType &&
          values?.readingsX !== null &&
          values?.readingsX > widthX) ||
        (values?.isRoundType &&
          values?.readingsY !== null &&
          values?.readingsX > widthX)
          ? `The number of readings exceeds the allowed limit. The maximum allowed number of readings is ${widthX}.`
          : "";
    }

    setErrors({
      ...temp,
    });
  };

  const conditionXY = useMemo(() => {
    const condition = conditionsOptions();

    setCustomValidation(condition);
    reviewXY();

    return condition;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.x,
    values?.y,
    values?.readingsX,
    values?.readingsY,
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.ductSizeBase,
    values?.conditionId,
  ]);

  const saveOnBlurHandler = async (e: any, ductType?: number) => {
    const valueIsNumber = Number(e.target.value) ?? 0;
    const newValues = {
      ...values,
      [e.target.name]: isNaN(valueIsNumber) ? 0 : e.target.value,
    };
    if (typeReport === "Velgrid" && !values?.customMatrix) {
      try {
        const response = await velgridMatrixService.getReadings(
          newValues?.projectId,
          newValues?.dimensionHeight ?? 0,
          newValues?.dimensionWidth ?? 0,
          newValues?.ductTypeId,
          newValues?.ductSizeBase ?? 0
        );

        if (response.data.x !== dataX || response.data.y !== dataY) {
          setValues({
            ...newValues,
            x: response.data.x,
            y: response.data.y,
          });
        } else {
          await executeRefresh(newValues.id, newValues);
        }
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    } else {
      if (studieROVA) {
        handleRecreateMatrixFromRVAHeadDimension(e);
      }
      setValues(newValues);
    }
  };

  const handleBlurXY = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: Number(value),
    });
  };

  const handleChangeCustomMatrix = async (e: any) => {
    const { name, value } = e.target;

    const newValues = { ...values, [name]: value };
    setValues(newValues);
    if (!value) {
      await executeRefresh(values.id, newValues);
    }
  };

  const handleRecreateMatrixFromRVAHeadDimension = (e: any) => {
    const { name, value } = e.target;
    const changeValues = { ...values, [name]: Number(value) };
    if (
      changeValues.headDiameterRVA != null &&
      changeValues.headDiameterRVA > 0
    ) {
      var newValues = { ...changeValues };
      newValues.readingsX = Math.round(
        (newValues.dimensionWidth ?? 0) / (newValues?.headDiameterRVA ?? 0)
      );
      newValues.readingsX =
        newValues.readingsX === 0 &&
        newValues.dimensionWidth !== 0 &&
        newValues.dimensionWidth !== null
          ? 1
          : newValues.readingsX;
      newValues.readingsY = Math.round(
        (newValues.dimensionHeight ?? 0) / (newValues?.headDiameterRVA ?? 0)
      );
      newValues.readingsY =
        newValues.readingsY === 0 &&
        newValues.dimensionHeight !== 0 &&
        newValues.dimensionHeight !== null
          ? 1
          : newValues.readingsY;

      setValues({
        ...values,
        [name]: value,
        readingsX: newValues.readingsX,
        readingsY: newValues.readingsY,
      });
    }
  };

  const validateFieldTraining = useMemo(() => {
    return (
      (values?.dimensionWidth &&
        values?.dimensionHeight &&
        values?.dimensionWidth > 22) ||
      values?.dimensionHeight > 22 ||
      (values?.readingsX && values?.readingsY && values?.readingsX > 4) ||
      values?.readingsY > 4
    );
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.readingsY,
    values?.readingsX,
  ]);

  const decimalNumber =
    systemOfMeasurement.measurementSystem === MEASUREMENTTYPE.IMPERIALTYPE
      ? 2
      : 3;

  const dimensionsTraining = user?.role === ROLES.Training ? 23 : undefined;
  const readingsTraining =
    user?.role === ROLES.Training
      ? typeVelgird && values?.customMatrix
        ? 5
        : !typeVelgird
        ? 5
        : undefined
      : undefined;

  return (
    <>
      {(values?.ductTypeId === DuctType.Rectangular ||
        values?.isRectangleType) && (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={disableStudy ? 4 : 6}>
            <Stack mb={1}>
              <FormLabel
                text="Rectangular Dimension*"
                sx={{
                  marginBottom: "3px",
                  fontWeight: "bold",
                }}
              />
            </Stack>
            <Grid container direction={"row"}>
              <Grid item xs={disableStudy ? 4 : 5.5}>
                <FormNumeric2
                  name={"dimensionWidth"}
                  label={
                    changeMatrixButton || conditionXY
                      ? `Width* (Previous value entered: ${dimensions.width})`
                      : "Width*"
                  }
                  value={values?.dimensionWidth}
                  onChange={saveOnBlurHandler}
                  showSkeleton={false}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                  suffix={` ${systemOfMeasurement.get("in")}`}
                  size="small"
                  disabled={disableStudy}
                  mode={mode}
                  maxValue={dimensionsTraining}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography
                  variant="subtitle2"
                  mt={2}
                  textAlign={disableStudy ? "left" : "center"}
                >
                  by
                </Typography>
              </Grid>
              <Grid item xs={disableStudy ? 4 : 5.5}>
                <FormNumeric2
                  name={"dimensionHeight"}
                  label={
                    changeMatrixButton || conditionXY
                      ? `Height* (Previous value entered: ${dimensions.height})`
                      : "Height*"
                  }
                  value={values?.dimensionHeight?.toString() ?? ""}
                  onChange={saveOnBlurHandler}
                  showSkeleton={false}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                  suffix={` ${systemOfMeasurement.get("in")}`}
                  size="small"
                  disabled={disableStudy}
                  mode={mode}
                  maxValue={dimensionsTraining}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={disableStudy ? 3 : 6} direction={"row"}>
            <Stack mb={5}></Stack>
            <Stack direction={"row"} spacing={2}>
              <StraightenOutlined />{" "}
              <FormLabel
                text="Dimension Total Area: "
                sx={{ fontSize: "bold" }}
              />{" "}
              <FormLabel
                text={` ${
                  Number(
                    values?.dimensionTotal?.toFixed(decimalNumber)
                  ).toLocaleString() ?? ""
                }  Duct ${systemOfMeasurement.get("sqft")}`}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
      {studieROVA && (
        <Grid item xs={12} md={2.7} mt={2}>
          <Stack mb={1}>
            <FormLabel
              text={`RVA Head Diameter (${systemOfMeasurement.get("in")})`}
              sx={{
                marginBottom: "3px",
                fontWeight: "bold",
              }}
            />
          </Stack>
          <Grid container>
            <Grid item xs={12}>
              <FormNumeric2
                name={"headDiameterRVA"}
                label={"Diameter"}
                value={values?.headDiameterRVA?.toString() ?? ""}
                showSkeleton={false}
                decimalScale={decimalNumber}
                thousandSeparator={true}
                onChange={handleRecreateMatrixFromRVAHeadDimension}
                disabled={disableStudy}
                mode={mode}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {(values?.ductTypeId === DuctType.Round || values?.isRoundType) && (
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <Stack mb={1}>
              <FormLabel
                text="Round duct diameter ID"
                sx={{
                  marginBottom: "3px",
                  fontWeight: "bold",
                }}
              />
            </Stack>
            <Grid container direction={"row"}>
              <Grid item xs={disableStudy ? 2 : 5.5}>
                <FormNumeric2
                  name={typeVelgird ? "ductSizeBase" : "dimensionWidth"}
                  label={
                    changeMatrixButton || conditionXY
                      ? `Diameter* (Previous value entered: ${dimensions.width})`
                      : "Diameter*"
                  }
                  value={
                    typeVelgird ? values?.ductSizeBase : values?.dimensionWidth
                  }
                  onChange={saveOnBlurHandler}
                  showSkeleton={showSkeleton}
                  decimalScale={decimalNumber}
                  thousandSeparator={true}
                  suffix={` ${systemOfMeasurement.get("in")}`}
                  size="small"
                  disabled={disableStudy}
                  mode={mode}
                />
              </Grid>
              <Grid item xs={disableStudy ? 0.8 : 1}></Grid>
              <Grid item xs={disableStudy ? 6 : 5.5}>
                <Stack mb={2}></Stack>
                <Stack direction={"row"} spacing={2}>
                  <StraightenOutlined />{" "}
                  <FormLabel
                    text="Dimension Total: "
                    sx={{ fontSize: "bold" }}
                  />{" "}
                  <FormLabel
                    text={` ${
                      Number(
                        values?.dimensionTotal?.toFixed(3)
                      ).toLocaleString() ?? ""
                    }  ${systemOfMeasurement.get("sqft")}`}
                  />
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6} direction={"row"}></Grid>
        </Grid>
      )}
      <Grid container spacing={2} alignItems={"center"} mt={2}>
        <Grid item xs={12} md={disableStudy ? 4 : 6}>
          <Grid item xs={12}>
            <Stack mb={1}>
              <FormLabel
                text="Number of readings*"
                sx={{
                  marginBottom: "3px",
                  fontWeight: "bold",
                }}
              />
            </Stack>
            <Grid container direction={"row"}>
              <Grid item xs={5} md={disableStudy ? 4 : 5.5}>
                <FormNumeric2
                  name={typeVelgird ? "x" : "readingsX"}
                  label={
                    changeMatrixButton
                      ? `X axis ↔* (Previous value entered: ${dataX})`
                      : "X axis ↔ (Left to Right)*"
                  }
                  value={typeVelgird ? values?.x : values?.readingsX}
                  onChange={handleBlurXY}
                  showSkeleton={showSkeleton}
                  decimalScale={0}
                  thousandSeparator={true}
                  disabled={
                    (typeVelgird && !values?.customMatrix) || disableStudy
                  }
                  error={typeVelgird ? errors.x : errors.readingsX}
                  mode={mode}
                  maxValue={readingsTraining}
                />
              </Grid>
              <Grid item xs={1} md={1}>
                {" "}
                <Typography
                  variant="subtitle2"
                  mt={2}
                  textAlign={disableStudy ? "left" : "center"}
                >
                  by
                </Typography>
              </Grid>
              <Grid item xs={5} md={disableStudy ? 4 : 5.5}>
                <FormNumeric2
                  name={typeVelgird ? "y" : "readingsY"}
                  label={
                    changeMatrixButton
                      ? `Y axis ↕* (Previous value entered: ${dataY})`
                      : "Y axis ↕ (Up to Down)*"
                  }
                  value={typeVelgird ? values?.y : values?.readingsY}
                  onChange={handleBlurXY}
                  showSkeleton={showSkeleton}
                  decimalScale={0}
                  thousandSeparator={true}
                  error={typeVelgird ? errors.y : errors.readingsY}
                  disabled={
                    (typeVelgird && !values?.customMatrix) || disableStudy
                  }
                  mode={mode}
                  maxValue={readingsTraining}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {typeVelgird && (
          <Grid item xs={12} md={6} direction={"row"}>
            <Grid item xs={6}>
              <Stack spacing={2} mt={6}>
                <FormCheckBox
                  name={"customMatrix"}
                  label={"Custom Matrix"}
                  value={values?.customMatrix}
                  onChange={handleChangeCustomMatrix}
                  showSkeleton={showSkeleton}
                  disabled={disableStudy || showUpdateButton}
                  mode={mode}
                ></FormCheckBox>
              </Stack>
            </Grid>
          </Grid>
        )}

        <Grid item md={2} xs={4} direction={"row"}>
          <Stack direction={"row"} mt={typeVelgird ? 0 : 6}>
            {(showUpdateButton || trainingModeUpdateButton) && (
              <FormButton
                startIcon={<DashboardCustomizeOutlined />}
                onClick={changeMatrix}
                text="Update Matrix"
                size="small"
                color="success"
                isSubmitting={showSkeleton}
                disabled={conditionXY}
              />
            )}
          </Stack>
        </Grid>
      </Grid>
      {typeVelgird && user?.role === ROLES.Training && (
        <Grid container mt={1}>
          <Grid item xs={12}>
            <Alert severity={"warning"}>
              Duct dimensions must not exceed 22" in the Trial & Demonstration
              Version.
            </Alert>
          </Grid>
        </Grid>
      )}
      {typeVelgird && showUpdateButton && (
        <Grid item xs={12} mt={3}>
          <AlertMessageMatrix
            customMatrix={values?.customMatrix}
            buttonMatrix={showUpdateButton}
          />
        </Grid>
      )}

      {!typeVelgird && showUpdateButton && (
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Alert severity="warning">
              Since the Readings of the Terminal Device being tested have
              changed, the matrix needs to be updated.
            </Alert>
          </Grid>
        </Grid>
      )}

      {!typeVelgird && user?.role === ROLES.Training && (
        <Grid container mt={2}>
          <Grid item xs={12}>
            <Alert severity={validateFieldTraining ? "error" : "warning"}>
              Duct dimensions must not exceed 22", and readings are limited to a
              maximum of 4 in the Trial & Demonstration Version.
            </Alert>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default DimensionsMatrix;
