import { useAsyncQuery, useLoading } from "src/hooks";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import { Skeleton, Stack, Table, TableBody, Typography } from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import {
  IGeneralMatrix,
  IGeneralMatrixItems,
} from "src/ts/interfaces/study/generalMatrix";
import generalMatrixItemsService from "src/services/study/generalMatrixItemsService";
import generalMatrixService from "src/services/study/generalMatrixService";
import ChangeReadingsPopUp from "../../components/ChangeReadingsPopUp";
import { MEASUREMENTTYPE } from "src/constants";
import { IDimensions } from "src/ts/interfaces/study/velgridMatrix";
interface Props {
  id: number;
  x: number;
  y: number;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number) => void;
  useSweepMethod: boolean;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
    measurementSystem: string | null;
  };
  values: IGeneralMatrix;
  setValues: (val: IGeneralMatrix) => void;
  dataX: number;
  dataY: number;
  setShowSteady: (val: boolean) => void;
  showSteady: boolean;
  stepContinuos: string;
  setGeneralaMatrixItemChanges: (val: boolean) => void;
  setDataX: (val: number) => void;
  setDataY: (val: number) => void;
  setChangeMatrixButton: (val: boolean) => void;
  changeMatrixButton: boolean;
  setButtonAction: (val: boolean) => void;
  buttonAction: boolean;
  validateXY: boolean;
  setItems: (val: IGeneralMatrixItems[][]) => void;
  items: IGeneralMatrixItems[][] | undefined;
  setDimensions: (val: IDimensions) => void;
}
const GeneralMatrixGrid = ({
  id,
  x,
  y,
  disabled,
  showSkeleton,
  onBlur,
  useSweepMethod,
  systemOfMeasurement,
  values,
  setValues,
  dataX,
  dataY,
  setShowSteady,
  showSteady,
  stepContinuos,
  setGeneralaMatrixItemChanges,
  setDataX,
  setDataY,
  setChangeMatrixButton,
  changeMatrixButton,
  setButtonAction,
  buttonAction,
  validateXY,
  items,
  setItems,
  setDimensions,
}: Props) => {
  // const [items, setItems] = useState<IGeneralMatrixItems[][]>(); //<--- mover a matrix
  const [item, setItem] = useState<IGeneralMatrixItems>();
  const [showChangeXYModal, setShowChangeXYModal] = useState(false);
  const { startRequest, endRequest, isLoading } = useLoading();

  const { execute, data, setData } = useAsyncQuery<IGeneralMatrixItems[]>(
    generalMatrixItemsService.getByGeneralId
  );
  const { execute: executeDeleteMatrix } = useAsyncQuery(
    generalMatrixItemsService.deleteMatrixItem
  );
  const { execute: executeUpdateMatrix } = useAsyncQuery(
    generalMatrixService.update
  );
  useEffect(() => {
    const runProcess = () => {
      const matrix = Array(Math.ceil(data.length / x))
        .fill("")
        .reduce((acc, cur, index) => {
          return [...acc, [...data].splice(index * x, x)];
        }, []);
      setItems(matrix);
    };
    if (data && data.length > 0 && x > 0 && y > 0) runProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (buttonAction) setShowChangeXYModal(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonAction]);

  useEffect(() => {
    const getData = async () => {
      setChangeMatrixButton(true);
    };
    const matrixNotChange = async () => {
      setChangeMatrixButton(false);
    };
    const getInfo = async () => {
      await execute(id);
      setDimensions({
        width: values?.dimensionWidth ?? 0,
        height: values?.dimensionHeight ?? 0,
      });
    };
    const getDataNew = async () => {
      startRequest("matrix");
      await executeUpdateMatrix(id, values);
      await execute(id);
      setDataX(x);
      setDataY(y);
      setShowSteady(true);
      endRequest("matrix");
    };

    if (
      items &&
      items.length > 0 &&
      x > 0 &&
      y > 0 &&
      (x !== dataX || y !== dataY) &&
      stepContinuos === "step2"
    ) {
      getData();
    } else if (
      items &&
      items.length > 0 &&
      x > 0 &&
      y > 0 &&
      (x === dataX || y === dataY) &&
      stepContinuos === "step2"
    ) {
      matrixNotChange();
    } else if (
      data &&
      !values?.isComplete &&
      x > 0 &&
      y > 0 &&
      !validateXY &&
      stepContinuos === "step2"
    ) {
      getDataNew();
    } else {
      if (id > 0) getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    x,
    y,
    values?.conditionId,
    values?.dimensionWidth,
    values?.dimensionHeight,
  ]);

  useEffect(() => {
    if (item) {
      let total = sumItems();

      onBlur(total);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    if (useSweepMethod) {
      let total = 0;
      if (data) {
        let newValue = Number(data[0].item);
        data.forEach((element) => (element.item = newValue));
        total = data.length * (newValue ?? 0);
        setData(data);
        onBlur(total);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useSweepMethod]);

  const setItemsCalc = async (element: IGeneralMatrixItems, e: any) => {
    let total = 0;
    let newValue = Number(e.target.value);
    if (useSweepMethod) {
      data.forEach((element) => (element.item = newValue));
      total = data.length * newValue;
      setData(data);
      onBlur(total);
    } else {
      if (newValue !== 0 && newValue !== null) {
        element.item = newValue;
        setItem({ ...element });
      }
    }
  };
  const sumItems = () => {
    const sum = data
      ? data.reduce((sum, current) => sum + (current?.item ?? 0), 0)
      : 0;
    return sum;
  };
  const width = useMemo(
    () => `${95 / (changeMatrixButton ? dataX : x)}%`,
    [x, changeMatrixButton, dataX]
  );

  const changeMatrixXY = async () => {
    setGeneralaMatrixItemChanges(true);
    startRequest("deleteMatrix");
    await executeDeleteMatrix(id);
    const newValues = {
      ...values,
      totalVelocity: 0,
      avgVelocity: 0,
      trueCorectedAirVelocity: 0,
      totalCFM: 0,
      required: 0,
      akFactor: 1,
      percentajeOfDesign: 0,
      dryBulbF: 0,
      relativeHumidity: 0,
      wetBulbF: 0,
      dewPoint: 0,
      btuh: 0,
      sensibleHeat: 0,
    };
    await executeUpdateMatrix(id, newValues);
    await execute(id);
    setValues({ ...newValues });
    setShowSteady(true);
    setDataX(x);
    setDataY(y);
    setDimensions({
      width: values?.dimensionWidth ?? 0,
      height: values?.dimensionHeight ?? 0,
    });
    endRequest("deleteMatrix");
    setGeneralaMatrixItemChanges(false);
    setChangeMatrixButton(false);
    setButtonAction(false);
  };
  const cancelModalXY = async () => {
    setValues({ ...values, readingsX: dataX, readingsY: dataY });
    setShowChangeXYModal(false);
    setChangeMatrixButton(false);
    setButtonAction(false);
  };

  const decimalNumber =
    systemOfMeasurement.measurementSystem === MEASUREMENTTYPE.IMPERIALTYPE
      ? 2
      : 3;

  return (
    <>
      <Stack sx={{ overflow: "auto" }}>
        <Table>
          <TableBody>
            <SpreadSheetRow>
              <SpreadSheetCell key={`number`} width={"5%"}>
                <Typography mt={0.8} padding={1.8} fontWeight="bold">
                  #
                </Typography>
              </SpreadSheetCell>
              <>
                {Array.from(
                  { length: changeMatrixButton ? dataX : x },
                  (_, i) => (
                    <SpreadSheetCell
                      key={`${i + 1}`}
                      width={width}
                      minWidth={95}
                    >
                      <Typography mt={0.8} padding={1.8} fontWeight="bold">
                        {i + 1}
                      </Typography>
                    </SpreadSheetCell>
                  )
                )}
              </>
            </SpreadSheetRow>
            {items &&
              items?.length > 0 &&
              Object.values(items ?? []).map(
                (item: IGeneralMatrixItems[], index: number) => (
                  <SpreadSheetRow>
                    <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                      <Typography
                        m={2}
                        mt={0.8}
                        paddingLeft={1.8}
                        fontWeight="bold"
                      >
                        {index + 1}
                      </Typography>
                    </SpreadSheetCell>
                    <>
                      {item.map((element: IGeneralMatrixItems, indexy) => (
                        <SpreadSheetCell
                          key={`${element.id}`}
                          width={width}
                          minWidth={95}
                        >
                          {showSkeleton || isLoading ? (
                            <Skeleton height={50} />
                          ) : (
                            <>
                              <SpreadsheetNumericInput2
                                name={element.id.toString()}
                                value={element.item}
                                onBlur={(e: any) => {
                                  setItemsCalc(element, e);
                                }}
                                decimalScale={decimalNumber}
                                maxValue={1000000}
                                disabled={
                                  disabled ||
                                  (useSweepMethod === true &&
                                    !(index === 0 && indexy === 0))
                                }
                                mode={
                                  disabled ||
                                  (useSweepMethod === true &&
                                    !(index === 0 && indexy === 0))
                                    ? "read"
                                    : undefined
                                }
                                placeholder={systemOfMeasurement.get("phfpm")}
                              ></SpreadsheetNumericInput2>
                            </>
                          )}
                        </SpreadSheetCell>
                      ))}
                    </>
                  </SpreadSheetRow>
                )
              )}
          </TableBody>
        </Table>
      </Stack>
      <ChangeReadingsPopUp
        onConfirm={changeMatrixXY}
        text={`Are you certain you want to change the values of the readings (X,Y)?`}
        matchWord={"CHANGE"}
        isDialogOpen={showChangeXYModal}
        setIsDialogOpen={setShowChangeXYModal}
        onCancel={cancelModalXY}
        setShowSteady={setShowSteady}
        showSteady={showSteady}
      />
    </>
  );
};
export default GeneralMatrixGrid;
