import { useAsyncQuery, useLoading, useTheme } from "src/hooks";
import {
  IDimensions,
  IVelgridMatrix,
  IVelgridMatrixItems,
} from "src/ts/interfaces/study/velgridMatrix";
import velgridMatrixItemsService from "src/services/study/velgridMatrixItemsService";
import SpreadSheetRow from "src/components/spreadsheet/SpreadSheetRow";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Skeleton,
  Stack,
  Table,
  TableBody,
  Typography,
} from "@mui/material";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import velgridMatrixService from "src/services/study/velgridMatrixService";
import ChangeReadingsPopUp from "../../components/ChangeReadingsPopUp";
import { STEPSVALUES } from "src/constants";
interface Props {
  id: number;
  x: number;
  y: number;
  saveItems: boolean;
  disabled: boolean;
  showSkeleton: boolean;
  onBlur: (total: number) => void;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
  values: IVelgridMatrix;
  dataX: number;
  dataY: number;
  stepContinuos: string;
  setDataX: (total: number) => void;
  setDataY: (total: number) => void;
  setShowSteady: (total: boolean) => void;
  showSteady: boolean;
  setValues: (val: IVelgridMatrix) => void;
  setChangeMatrixButton: (val: boolean) => void;
  changeMatrixButton: boolean;
  setButtonAction: (val: boolean) => void;
  buttonAction: boolean;
  dimensions: IDimensions;
  setDimensions: (val: IDimensions) => void;
  executeRefresh: (id: number, values: IVelgridMatrix) => void;
  customValidation: boolean;
  items: IVelgridMatrixItems[][] | undefined;
  setItems: (val: IVelgridMatrixItems[][]) => void;
}
const VelgridGrid = ({
  id,
  x,
  y,
  disabled,
  showSkeleton,
  onBlur,
  systemOfMeasurement,
  values,
  dataX,
  setDataX,
  setDataY,
  dataY,
  stepContinuos,
  setShowSteady,
  showSteady,
  setValues,
  setButtonAction,
  buttonAction,
  changeMatrixButton,
  setChangeMatrixButton,
  dimensions,
  setDimensions,
  executeRefresh,
  customValidation,
  items,
  setItems,
}: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<IVelgridMatrixItems>();
  const [showChangeXYModal, setShowChangeXYModal] = useState(false);
  const { theme } = useTheme();
  const { startRequest, endRequest, isLoading } = useLoading();
  const { execute, data } = useAsyncQuery<IVelgridMatrixItems[]>(
    velgridMatrixItemsService.getByVelgridId
  );
  const { execute: executeUpdate } = useAsyncQuery<number>(
    velgridMatrixService.update,
    {
      hideErrorMessage: true,
    }
  );

  useEffect(() => {
    const runProcess = () => {
      const matrix = Array(Math.ceil(data.length / x))
        .fill("")
        .reduce((acc, cur, index) => {
          return [...acc, [...data].splice(index * x, x)];
        }, []);
      setItems(matrix);
    };
    if (data && data.length > 0 && x > 0 && y > 0) runProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  useEffect(() => {
    if (buttonAction) {
      setShowChangeXYModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buttonAction]);

  const resetMatrix = async () => {
    setChangeMatrixButton(true);
  };

  useEffect(() => {
    const getData = async () => {
      setChangeMatrixButton(true);
    };
    const matrixNotChange = async () => {
      setChangeMatrixButton(false);
      setShowSteady(true);
    };
    const getInfo = async () => {
      await execute(id);
      setDataX(values?.x);
      setDataY(values?.y);
      setDimensions({
        width: values?.dimensionWidth ?? 0,
        height: values?.dimensionHeight ?? 0,
      });
    };
    const getDataNew = async () => {
      startRequest("newData");
      await executeUpdate(id, values);
      await execute(id);
      setDataX(x);
      setDataY(y);
      setShowSteady(true);
      endRequest("newData");
    };

    if (
      items &&
      items.length > 0 &&
      x > 0 &&
      y > 0 &&
      (x !== dataX || y !== dataY) &&
      stepContinuos === "step2"
    ) {
      getData();
    } else if (
      items &&
      items.length > 0 &&
      x > 0 &&
      y > 0 &&
      (x === dataX || y === dataY) &&
      stepContinuos === "step2"
    ) {
      matrixNotChange();
      getInfo();
    } else if (
      x > 0 &&
      y > 0 &&
      !values?.isComplete &&
      stepContinuos === STEPSVALUES.STEP2 &&
      !customValidation
    ) {
      getDataNew();
    } else {
      if (id > 0) getInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [x, y, values?.conditionId]);

  useEffect(() => {
    const sum = data
      ? data.reduce(
          (sum, current) => Number(sum) + Number(current?.item ?? 0),
          0
        )
      : 0;
    if (item) {
      onBlur(sum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  const handleSetItems = async (element: IVelgridMatrixItems, e: any) => {
    element.item = Number(e.target.value);
    setItem({ ...element });
  };

  let dimensionTotal =
    ((values?.dimensionWidth ?? 0) - (values?.insulationThickness ?? 0) * 2) *
    ((values?.dimensionHeight ?? 0) - (values?.insulationThickness ?? 0) * 2);
  let ductSizeBaseCalc =
    values?.ductSizeBase - (values?.insulationThickness ?? 0) * 2;
  let ductSize =
    (ductSizeBaseCalc / 2) * (ductSizeBaseCalc / 2) * 3.14159265359;

  const changeMatrixXY = async () => {
    const newValues = {
      ...values,
      totalVelocity: 0,
      avgVelocity: 0,
      trueCorectedAirVelocity: 0,
      totalCFM: 0,
      required: 0,
      akFactor: 1,
      percentajeOfDesign: 0,
      dryBulbF: 0,
      relativeHumidity: 0,
      wetBulbF: 0,
      dewPoint: 0,
      btuh: 0,
      sensibleHeat: 0,
    };
    startRequest("deleteMatrix");
    await executeUpdate(id, newValues);
    await execute(id);
    setDataX(values?.x);
    setDataY(values?.y);
    setDimensions({
      height: values?.dimensionHeight ?? 0,
      width: values?.dimensionWidth ?? 0,
    });
    setValues({
      ...newValues,
      dimensionTotal: dimensionTotal,
      ductSize: ductSize,
    });
    setShowSteady(true);
    setChangeMatrixButton(false);
    setButtonAction(false);
    endRequest("deleteMatrix");
  };
  const cancelModalXY = async () => {
    await executeRefresh(id, {
      ...values,
      x: dataX,
      y: dataY,
      dimensionHeight: dimensions.height,
      dimensionWidth: dimensions.width,
    });
    setChangeMatrixButton(false);
    setButtonAction(false);
    setShowChangeXYModal(false);
  };
  const width = useMemo(
    () => `${95 / (changeMatrixButton ? dataX : x)}%`,
    [x, changeMatrixButton, dataX]
  );
  return (
    <>
      <Stack sx={{ overflow: "auto" }}>
        <Table>
          <TableBody>
            <SpreadSheetRow>
              <SpreadSheetCell key={`number`} width={"5%"}>
                <Typography
                  sx={{
                    background: `linear-gradient(to right top, ${
                      theme === "DARK" ? "#233044" : "#FFFFFF"
                    } 0%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 48%,${
                      theme === "DARK" ? "#FFFFFF50" : "#0000001f"
                    } 50%,${theme === "DARK" ? "#FFFFFF50" : "#0000001f"} 50%,${
                      theme === "DARK" ? "#233044" : "#FFFFFF"
                    } 52%,${theme === "DARK" ? "#233044" : "#FFFFFF"} 100%)`,
                    padding: "2px",
                  }}
                >
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      sx={{ padding: "0px", marginTop: "15px" }}
                    >
                      Y
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sx={{
                        textAlign: "right",
                        padding: "0px",
                        paddingBottom: "15px",
                      }}
                    >
                      X
                    </Grid>
                  </Grid>
                </Typography>
              </SpreadSheetCell>
              <>
                {Array.from(
                  { length: changeMatrixButton ? dataX : x },
                  (_, i) => (
                    <SpreadSheetCell
                      key={`${i + 1}`}
                      width={width}
                      minWidth={95}
                    >
                      <Typography mt={0.8} padding={1.8} fontWeight="bold">
                        {i + 1}
                      </Typography>
                    </SpreadSheetCell>
                  )
                )}
              </>
            </SpreadSheetRow>
            {items &&
              items?.length > 0 &&
              Object.values(items ?? []).map(
                (item: IVelgridMatrixItems[], index: number) => (
                  <SpreadSheetRow>
                    <SpreadSheetCell key={`${index + 1}`} width={"5%"}>
                      <Typography
                        m={2}
                        mt={0.8}
                        paddingLeft={1.8}
                        fontWeight="bold"
                      >
                        {index + 1}
                      </Typography>
                    </SpreadSheetCell>
                    <>
                      {item.map((element: IVelgridMatrixItems, index) => (
                        <SpreadSheetCell
                          key={`${element.id}`}
                          width={width}
                          minWidth={95}
                        >
                          {showSkeleton || isLoading ? (
                            <Skeleton height={50} />
                          ) : (
                            <SpreadsheetNumericInput2
                              name={element.id.toString()}
                              value={element.item}
                              onChange={(e: any) => {
                                handleSetItems(element, e);
                              }}
                              decimalScale={2}
                              maxValue={1000000}
                              disabled={disabled}
                              mode={disabled ? "read" : undefined}
                              placeholder={systemOfMeasurement.get("phfpm")}
                            ></SpreadsheetNumericInput2>
                          )}
                        </SpreadSheetCell>
                      ))}
                    </>
                  </SpreadSheetRow>
                )
              )}
          </TableBody>
        </Table>
      </Stack>
      <ChangeReadingsPopUp
        onConfirm={changeMatrixXY}
        text={`Are you certain you want to change the values of the readings (X,Y)?`}
        matchWord={"CHANGE"}
        isDialogOpen={showChangeXYModal}
        setIsDialogOpen={setShowChangeXYModal}
        onCancel={cancelModalXY}
        setShowSteady={setShowSteady}
        showSteady={showSteady}
      />
    </>
  );
};
export default VelgridGrid;
