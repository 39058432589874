/* eslint-disable react-hooks/exhaustive-deps */
import {
  CardContent,
  Grid,
  Divider as MuiDivider,
  Card as MuiCard,
  Stepper,
  Step,
  StepLabel,
  StepIconProps,
  StepConnector,
  stepConnectorClasses,
  Alert,
  ButtonGroup,
  Button,
} from "@mui/material";

import { Stack, spacing } from "@mui/system";
import styled from "@emotion/styled";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAsyncQuery,
  useLog,
  useAuth,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  FormButton,
  FormCheckBox,
  FormDatePicker,
  FormLabel,
  FormNumeric2,
  FormRadioGroup,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IDefaultComboBoxCatalog,
  IItemKeyValueDTO,
  IMotorDTO,
  IPumpDTO,
  IPumpReportActualDTO,
  IPumpReportActualFormulaDTO,
  IPumpReportViewModelDTO,
  IReportType,
} from "src/ts/interfaces";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectService from "src/services/project/projectService";
import projectReportService from "src/services/study/projectReportService";
import { steps } from "../const/consts";
import { FooterButtons } from "../../components/FooterButtons";
import SteadyStateDialog from "../../components/SteadyState";
import ChecklistIcon from "@mui/icons-material/Checklist";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import ElectricBoltIcon from "@mui/icons-material/ElectricBolt";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import { East as EastIcon } from "@mui/icons-material";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import FormTooltip from "src/components/formControls/FormTooltip";
import Popup from "src/components/Popup";
import { Validator } from "src/ts/types/Validator";
import reportTypeService from "src/services/study/reportTypeService";
import pumpReportService from "src/services/study/pumpReportService";
import motorService from "src/services/study/motorService";
import npsaChartImg from "./assets/NPSAChart.png";
import npsaCurveImg from "./assets/NPSACurve.png";
import IncompleteDataDialog from "../../components/IncompleteDataDialog";
import StudyWizard from "../../components/StudyWizard";
import moment from "moment";

const initialValues: any = {
  projectName: "",
  system: "",
  equipmentLocation: "",
  conditionOfTest: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <ChecklistIcon />,
    2: <SafetyDividerIcon />,
    3: <ElectricBoltIcon />,
    4: <ChangeCircleIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

const preTrestSteps = ["Start test", "Safety", "Shock Hazard", "Fan rotation"];

const PumpStep5 = () => {
  const { log } = useLog();
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [isUpdating, setIsUpdating] = useState(false);
  const [title, setTitle] = useState<string>();
  const [code, setCode] = useState<string>();
  const [currentPretestStep, setCurrentPretestStep] = useState(0);
  const [selectedPump, setSelectedPump] = useState(0);
  const [selectedPumpIndex, setSelectedPumpIndex] = useState(0);
  const [openVoltsAmpsInvalidDialog, setOpenVoltsAmpsInvalidDialog] =
    useState(false);
  const [pumpReportValues, setPumpReportValues] =
    useState<IPumpReportViewModelDTO>();
  const [pumpMotorValues, setPumpMotorValues] = useState<IMotorDTO>();
  const [pumpValues, setPumpValues] = useState<IPumpDTO>();
  const [projectValues, setProjectValues] = useState<ProjectDTO>();
  const [openIncompleteDataDialog, setOpenIncompleteDataDialog] =
    useState(false);
  const [shutDiffOptions, setShutDiffOptions] =
    useState<IDefaultComboBoxCatalog[]>();
  const [openNpsaImgPopup, setoPenNpsaImgPopup] = useState(false);
  const [npsaImg, setNpsaImg] = useState("");
  const [gpmTolerance, setGpmTolerance] = useState<number>();
  const [newTotalDeisgnGpm, setNewTotalActualGpm] = useState(0);
  const [gpmDiversityDialogOpen, setGpmDiversityDialogOpen] = useState(false);
  const [isEnergyProcess, setIsEnergyProcess] = useState(false);
  const npshCurveTooltip = "<p>Click to show NPSHA Chart</p>";
  const npshCurveExampleTooltip = "<p>Click to show NPSHA Example</p>";
  const [disableEditData, setDisableEditData] = useState(false);

  let navigate = useNavigate();

  const validate: any = (fieldValues: IPumpReportActualDTO = values) => {
    let temp: Record<string, string> = { ...errors };

    if (disableStudy) return true;

    temp.actualPumpRPM = new Validator(fieldValues, "actualPumpRPM")
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualGPM = new Validator(fieldValues, "actualGPM")
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.finalRunningInlet = new Validator(fieldValues, "finalRunningInlet")
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.finalRunningDisch = new Validator(fieldValues, "finalRunningDisch")
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingVoltsPhase1 = new Validator(
      fieldValues,
      "actualOperatingVoltsPhase1"
    )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingVoltsPhase2 = new Validator(
      fieldValues,
      "actualOperatingVoltsPhase2"
    )
      .validateIf(
        pumpMotorValues?.motorPhase === "Three phase" ||
          pumpMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingVoltsPhase3 = new Validator(
      fieldValues,
      "actualOperatingVoltsPhase3"
    )
      .validateIf(
        pumpMotorValues?.motorPhase === "Three phase" ||
          pumpMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingAmpsPhase1 = new Validator(
      fieldValues,
      "actualOperatingAmpsPhase1"
    )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingAmpsPhase2 = new Validator(
      fieldValues,
      "actualOperatingAmpsPhase2"
    )
      .validateIf(
        pumpMotorValues?.motorPhase === "Three phase" ||
          pumpMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();
    temp.actualOperatingAmpsPhase3 = new Validator(
      fieldValues,
      "actualOperatingAmpsPhase3"
    )
      .validateIf(
        pumpMotorValues?.motorPhase === "Three phase" ||
          pumpMotorValues?.motorPhase === "2 wire single phase"
      )
      .isRequired(`This field is required.`)
      .greatThan(0, `This field must be greater than 0.`)
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === "");
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IPumpReportActualDTO>(initialValues, false, validate);

  const activeStep = 4;

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const { execute: executeProject } = useAsyncQuery<ProjectDTO>(
    projectService.getProjectTolerancesByReportId,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setProjectValues(dataResult);
          setGpmTolerance(dataResult.requiredGPMSystemToleranceTop);
        };
        getData();
      },
    }
  );

  const { execute, isLoading } = useAsyncQuery<IPumpReportViewModelDTO>(
    pumpReportService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          await systemOfMeasurement.getByProject(
            dataResult.projectId ?? pumpReportValues?.projectId
          );
          await setPumpReportValues({ ...dataResult });
          window.scrollTo(0, 0);
        };
        getData();
      },
    }
  );

  const { execute: executeActual, isLoading: isLoadingActual } =
    useAsyncQuery<IPumpReportActualFormulaDTO>(
      pumpReportService.getActualFormulaByPumpId,
      {
        onSuccess: (dataResult) => {
          const getData = async () => {
            const { actual, pump } = dataResult;
            const newActual = {
              ...actual,
              motorPhase: dataResult.motorPhase,
              voltReadFromVFDScreen:
                !actual?.voltReadFromVFDScreen && !actual?.voltMeterUsed
                  ? true
                  : actual?.voltReadFromVFDScreen,
              pumpShutDifferentialUnit:
                actual?.pumpShutDifferentialUnit === ""
                  ? systemOfMeasurement.get("psi")
                  : actual?.pumpShutDifferentialUnit,
              finalRunningPressuresUnit:
                actual?.finalRunningPressuresUnit === ""
                  ? systemOfMeasurement.get("psi")
                  : actual?.finalRunningPressuresUnit,
            } as IPumpReportActualDTO;
            setValues(newActual);
            setPumpValues(pump as IPumpDTO);
          };
          getData();
        },
      }
    );

  const { execute: executePumpMotor, isLoading: isLoadingPumpMotor } =
    useAsyncQuery<IMotorDTO>(motorService.getByPumpId, {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setPumpMotorValues(dataResult);
          await executeActual(id, selectedPump);
        };
        getData();
      },
    });

  const { execute: executeReportType } = useAsyncQuery<IReportType>(
    reportTypeService.getReportTypeById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          setCode(dataResult.code);
          setTitle(dataResult.name);
          await executeProject(id, dataResult.code);
        };
        getData();
      },
    }
  );

  const disableStudy = useMemo(
    () =>
      pumpReportValues?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      disableEditData,
    [
      pumpReportValues?.isComplete,
      dataReport?.isInUse,
      dataReport?.isInUseById,
      user?.userId,
      dataReport?.isPartOfProject,
      disableEditData,
    ]
  );

  useEffect(() => {
    if (pumpReportValues?.id) {
      setErrors({});
      setSelectedPump(
        (pumpReportValues?.pumps as IItemKeyValueDTO[])[selectedPumpIndex].key
      );
    }
  }, [pumpReportValues, selectedPumpIndex]);

  useEffect(() => {
    if (pumpReportValues?.id) {
      setErrors({});
      executePumpMotor(selectedPump);
    }
  }, [selectedPump]);

  useEffect(() => {
    if (pumpReportValues?.reportTypeId)
      executeReportType(pumpReportValues?.reportTypeId);
  }, [pumpReportValues?.reportTypeId]);

  useEffect(() => {
    const getData = async () => {
      try {
        await execute(id);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0) getData();
  }, [id]);

  useEffect(() => {
    const getData = async () => {
      try {
        await executeReport(id, code);
      } catch (error: any) {
        log.error(error?.message?.exceptionMessage ?? "Something went wrong");
      }
    };

    if (id !== 0 && code !== undefined) getData();
  }, [id, code]);

  useEffect(() => {
    if (systemOfMeasurement.measurementSystem) {
      const psi = systemOfMeasurement.get("psi");
      const inhg = systemOfMeasurement.get("inhg");
      setShutDiffOptions([
        {
          key: psi,
          value: psi,
        },
        {
          key: inhg,
          value: inhg,
        },
      ]);
    }
  }, [systemOfMeasurement.measurementSystem]);

  useEffect(() => {
    if (values?.pumpShutDifferentialUnit)
      calculateShutDiff(
        values?.pumpShutDifferentialUnit,
        values?.inlet || 0,
        values?.disch || 0,
        values
      );
  }, [values?.pumpShutDifferentialUnit]);

  useEffect(() => {
    if (values?.finalRunningPressuresUnit)
      calculateFinalRunningPressure(
        values?.finalRunningPressuresUnit,
        values?.finalRunningInlet || 0,
        values?.finalRunningDisch || 0,
        values
      );
  }, [values?.finalRunningPressuresUnit]);

  useEffect(() => {
    if (values?.id && pumpValues?.id && !values?.final)
      calculateFinal(values?.actualGPM || 0, pumpValues?.designGpm || 0);
  }, [values?.actualGPM, pumpValues?.designGpm]);

  useEffect(() => {
    if (dataReport?.id)
      setIsEnergyProcess(dataReport?.parentReportTypeId === 18);
  }, [dataReport?.parentReportTypeId]);

  const saveHandler = async () => {
    if (!validate()) return null;
    await savePumpMotorHandler();
    await savePumpActualsHandler();
  };

  const savePumpActualsHandler = async () => {
    try {
      if (!disableStudy) {
        setIsUpdating(true);
        const valuesBeforeSend = {
          ...values,
          wasUpdate: true,
        };
        await pumpReportService.updateActual(
          id,
          pumpValues?.id || 0,
          valuesBeforeSend
        );
        log.success("Pump actuals data was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const savePumpMotorHandler = async () => {
    try {
      if (!disableStudy) {
        setIsUpdating(true);
        await motorService.update(id, pumpMotorValues as IMotorDTO);
        log.success("Fan motor was updated successfully");
        setIsUpdating(false);
      }
    } catch (error) {}
  };

  const nextStepHandler = async () => {
    if (!validate()) setOpenIncompleteDataDialog(true);
    else if (await getPumpsGpm()) return false;
    else await movingForwardHandler();
  };

  const clickYesIncompleteDialogHandle = async () => {
    setOpenIncompleteDataDialog(false);
    if (await getPumpsGpm()) return false;
    else await movingForwardHandler();
  };

  const movingForwardHandler = async () => {
    setOpenIncompleteDataDialog(false);
    setGpmDiversityDialogOpen(false);
    await savePumpMotorHandler();
    await savePumpActualsHandler();
    if (
      selectedPumpIndex <
      (pumpReportValues?.pumps as IItemKeyValueDTO[]).length - 1
    ) {
      setSelectedPumpIndex(selectedPumpIndex + 1);
      window.scrollTo(0, 0);
    } else {
      navigate(`/studies/pumpReport/step6/${id}`);
    }
  };

  const previousStepHandler = async () => {
    if (currentPretestStep > 3) {
      await savePumpMotorHandler();
      await savePumpActualsHandler();
    }
    if (selectedPumpIndex > 0) {
      setSelectedPumpIndex(selectedPumpIndex - 1);
      window.scrollTo(0, 0);
    } else {
      navigate(`/studies/pumpReport/step4/${id}`);
    }
  };

  const loadPump = (index: number) => {
    setSelectedPumpIndex(index);
  };

  const handleSelectedVoltsReferenceChange = (index: number) => {
    setPumpMotorValues({
      ...(pumpMotorValues as IMotorDTO),
      selectedVoltAmpsReference: index,
      voltageNotListed: false,
    });
  };

  const handlePumpMotorValuesChange = (e: any) => {
    setPumpMotorValues({
      ...(pumpMotorValues as IMotorDTO),
      selectedVoltAmpsReference: null,
      [e.target.name]: e.target.value,
    });
  };

  const handleVoltsReadChange = (
    voltReadFromVFDScreen: boolean,
    voltMeterUsed: boolean
  ) => {
    setValues({
      ...values,
      voltReadFromVFDScreen,
      voltMeterUsed,
    });
  };

  const handleVfdOnlyDisplaysSingleVoltageChange = (e: any) => {
    const voltsPhase2 = e.target.value
      ? values?.actualOperatingVoltsPhase1
      : values?.actualOperatingVoltsPhase2;
    const voltsPhase3 = e.target.value
      ? values?.actualOperatingVoltsPhase1
      : values?.actualOperatingVoltsPhase3;
    const ampsPhase2 = e.target.value
      ? values?.actualOperatingAmpsPhase1
      : values?.actualOperatingAmpsPhase2;
    const ampsPhase3 = e.target.value
      ? values?.actualOperatingAmpsPhase1
      : values?.actualOperatingAmpsPhase3;
    setValues({
      ...values,
      vfdOnlyDisplaysSingleVolAmp: e.target.value,
      actualOperatingVoltsPhase2: voltsPhase2,
      actualOperatingVoltsPhase3: voltsPhase3,
      actualOperatingAmpsPhase2: ampsPhase2,
      actualOperatingAmpsPhase3: ampsPhase3,
    });
  };

  const handleCloseVoltsAmpsInvalidDialog = () => {
    setOpenVoltsAmpsInvalidDialog(false);
  };

  const handleValidateVoltsAmps = (e: any) => {
    if (
      !pumpMotorValues?.selectedVoltAmpsReference &&
      !pumpMotorValues?.voltageNotListed
    ) {
      setOpenVoltsAmpsInvalidDialog(true);
      return null;
    }
  };

  const ampsValid = (line: number) => {
    if (values && pumpMotorValues) {
      if (pumpMotorValues.selectedVoltAmpsReference) {
        const ampsReferenceName =
          `nameplateAmpsPhase${pumpMotorValues?.selectedVoltAmpsReference}` as
            | "nameplateAmpsPhase1"
            | "nameplateAmpsPhase2"
            | "nameplateAmpsPhase3"
            | "nameplateAmpsPhase4"
            | "nameplateAmpsPhase5"
            | "nameplateAmpsPhase6";
        const ampsTargetName = `actualOperatingAmpsPhase${line}` as
          | "actualOperatingAmpsPhase1"
          | "actualOperatingAmpsPhase2"
          | "actualOperatingAmpsPhase3"
          | "actualOperatingAmpsPhase4"
          | "actualOperatingAmpsPhase5";
        const actualAmps = values[ampsTargetName] ?? 0;
        const referenceAmps = pumpMotorValues[ampsReferenceName] ?? 0;
        return actualAmps <= referenceAmps || pumpMotorValues?.voltageNotListed;
      }
    }
    return true;
  };

  const handleVfdOperatingHertzChange = (e: any) => {
    const speed = projectValues?.suppliedPowerGridFrequency
      ? ((e.target.value || 0) / projectValues?.suppliedPowerGridFrequency) *
        100
      : null;
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      vfdPwmPercentage: `${speed}`,
    });
  };

  const handleVfdOperatingVelocityChange = (e: any) => {
    const hz =
      ((e.target.value || 0) / 100) *
      (projectValues?.suppliedPowerGridFrequency || 0);

    setValues({
      ...values,
      [e.target.name]: e.target.value,
      vfdPwm: `${hz}`,
    });
  };

  const handleVoltsPhase1Blur = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    if (values?.vfdOnlyDisplaysSingleVolAmp) {
      setValues({
        ...newValues,
        actualOperatingVoltsPhase2: e.target.value,
        actualOperatingVoltsPhase3: e.target.value,
      });
    } else setValues(newValues);
  };

  const handleAmpsPhase1Blur = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    if (values?.vfdOnlyDisplaysSingleVolAmp) {
      setValues({
        ...newValues,
        actualOperatingAmpsPhase2: e.target.value,
        actualOperatingAmpsPhase3: e.target.value,
      });
    } else setValues(newValues);
  };

  const handleCloseNpsaImgPopup = () => {
    setoPenNpsaImgPopup(false);
  };

  const showNpsaChartDialog = () => {
    setNpsaImg("Chart");
    setoPenNpsaImgPopup(true);
  };

  const showNpsaExampleDialog = () => {
    setNpsaImg("Example");
    setoPenNpsaImgPopup(true);
  };

  const handlePumpOffPressureChange = (e: any) => {
    let result = 0;
    if (systemOfMeasurement.measurementSystem === "metricSystem")
      result = e.target.value * 0.00010199773339984;
    else result = e.target.value * 2.31;
    setValues({
      ...values,
      [e.target.name]: e.target.value,
      feetWaterColumn: result,
    });
  };

  const calculateDeltaPSI = (inlet: number, disch: number) => {
    if (systemOfMeasurement.measurementSystem === "metricSystem") {
      return Math.abs(disch - inlet) / 9806.0282;
    } else {
      return Math.abs(disch - inlet) / 0.4335;
    }
  };

  var calculateNpshaFt = (finalRunningPressure: number) => {
    if (systemOfMeasurement.measurementSystem === "metricSystem") {
      return finalRunningPressure * 0.00010199773339984;
    } else {
      return finalRunningPressure * 2.31;
    }
  };

  const calculateShutDiff = (
    diffUnit: string,
    inlet: number,
    disch: number,
    newValues: IPumpReportActualDTO
  ) => {
    let deltaFeet = calculateDeltaPSI(inlet, disch);
    let psi = Math.abs(disch - inlet);
    if (
      systemOfMeasurement.measurementSystem === "metricSystem" &&
      diffUnit === "kPa"
    ) {
      deltaFeet = calculateDeltaPSI(inlet * 1000, disch);
      psi = Math.abs(disch - inlet * 1000);
    } else if (diffUnit === "In/Hg") {
      deltaFeet = calculateDeltaPSI(inlet * 0.4911, disch);
      psi = Math.abs(disch - inlet * 0.4911);
    }

    setValues({
      ...newValues,
      psi,
      deltaFeet,
    });
  };

  const calculateFinalRunningPressure = (
    diffUnit: string,
    inlet: number,
    disch: number,
    newValues: IPumpReportActualDTO
  ) => {
    let finalRunningDeltaFeet = calculateDeltaPSI(inlet, disch);
    let finalRunningPSI = Math.abs(disch - inlet);
    let pumpCurveNpshaFt = calculateNpshaFt(inlet);

    if (
      systemOfMeasurement.measurementSystem === "metricSystem" &&
      diffUnit === "kPa"
    ) {
      finalRunningDeltaFeet = calculateDeltaPSI(inlet * 1000, disch);
      pumpCurveNpshaFt = calculateNpshaFt(inlet * 1000);
      finalRunningPSI = Math.abs(disch - inlet * 1000);
    } else if (diffUnit === "In/Hg") {
      finalRunningDeltaFeet = calculateDeltaPSI(inlet * 0.4911, disch);
      pumpCurveNpshaFt = calculateNpshaFt(inlet * 0.4911);
      finalRunningPSI = Math.abs(disch - inlet * 0.4911);
    }

    setValues({
      ...newValues,
      finalRunningPSI,
      pumpCurveNpshaFt,
      pumpCurveNpsha: inlet,
      finalRunningDeltaFeet,
    });
  };

  const handleInletChange = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    calculateShutDiff(
      values?.pumpShutDifferentialUnit || "PSI",
      e.target.value,
      values?.disch || 0,
      newValues
    );
  };

  const handleDischChange = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    calculateShutDiff(
      values?.pumpShutDifferentialUnit || "PSI",
      values?.inlet || 0,
      e.target.value,
      newValues
    );
  };

  const handleFinalRunningInletChange = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    calculateFinalRunningPressure(
      values?.finalRunningPressuresUnit || "PSI",
      e.target.value,
      values?.finalRunningDisch || 0,
      newValues
    );
  };

  const handleFinalRunningDischChange = (e: any) => {
    const newValues = { ...values, [e.target.name]: e.target.value };
    calculateFinalRunningPressure(
      values?.finalRunningPressuresUnit || "PSI",
      values?.finalRunningInlet || 0,
      e.target.value,
      newValues
    );
  };

  const handleActualGPMChange = (e: any) => {
    let final =
      e.target.value === 0 || (pumpValues?.designGpm || 0) === 0
        ? 0
        : 100 / ((pumpValues?.designGpm || 0) / e.target.value);

    setValues({ ...values, [e.target.name]: e.target.value, final });
  };

  const getPumpsGpm = async () => {
    try {
      if (disableStudy) return false;
      const dataResult = (
        await pumpReportService.getActualGpm(id, values?.id || 0)
      ).data;
      const currentPumpActualGpm =
        typeof values?.actualGPM === "string"
          ? parseFloat(values?.actualGPM)
          : values?.actualGPM;
      const newTotalActualGpm = !pumpValues?.standByLag
        ? dataResult + (currentPumpActualGpm || 0)
        : dataResult;
      setNewTotalActualGpm(newTotalActualGpm);
      const gpmActualDiversity = pumpReportValues?.actualSystemGpm
        ? Math.abs(
            1 - (newTotalActualGpm || 0) / pumpReportValues?.actualSystemGpm
          ) * 100
        : 0;
      const gpmPOD = pumpReportValues?.actualSystemGpm
        ? (newTotalActualGpm / pumpReportValues?.actualSystemGpm) * 100
        : 0;
      const pumpReport = (await pumpReportService.getBaseById(id)).data;
      await pumpReportService.update(id, {
        ...pumpReport,
        systemTotalCombinedGPMActual: newTotalActualGpm,
        systemDiversityActual: gpmActualDiversity,
        systemActualCombinedGpmPOD: gpmPOD,
      });
      if (
        gpmActualDiversity > (gpmTolerance || 0) &&
        pumpReportValues?.isGpmKnown &&
        !pumpReportValues?.isComplete &&
        selectedPumpIndex ===
          (pumpReportValues?.pumps as IItemKeyValueDTO[])?.length - 1
      ) {
        setGpmDiversityDialogOpen(true);
        return true;
      }
      return false;
    } catch (error: any) {
      log.error(
        error?.message?.exceptionMessage ??
          "Error updateing System Diversity Design"
      );
    }
  };

  const finalColorEval = () => {
    if (!values?.final) return "inherit";
    const final = values?.final || 0;
    const requiredGPMSystemToleranceBottom =
      projectValues?.requiredGPMSystemToleranceBottom || 0;
    const requiredGPMSystemToleranceTop =
      projectValues?.requiredGPMSystemToleranceTop || 0;
    return final < 100 - requiredGPMSystemToleranceBottom ||
      final > 100 + requiredGPMSystemToleranceTop
      ? "red"
      : "inherit";
  };

  const calculateFinal = (actualGPM: number, designGPM: number) => {
    let final =
      actualGPM === 0 || designGPM === 0 ? 0 : 100 / (designGPM / actualGPM);

    setValues({ ...values, final });
  };

  useEffect(() => {
    if (
      values.actualDateTestPerformed === null ||
      values.actualDateTestPerformed === undefined
    ) {
      let newvalues = { ...values };
      newvalues.actualDateTestPerformed = moment().format("MM/DD/YYYY");
      setValues(newvalues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const showActualReadingsDate = useMemo(
    () => dataReport?.parentReportId === null && selectedPumpIndex === 0,
    [dataReport?.parentReportId, selectedPumpIndex]
  );

  return (
    <>
      <SteadyStateDialog
        reportId={pumpReportValues?.id as number}
        reportTypeId={pumpReportValues?.reportTypeId as number}
        projectId={pumpReportValues?.projectId as number}
        reportCompleted={pumpReportValues?.isComplete || false}
        setDisableEditData={setDisableEditData}
      />
      <IncompleteDataDialog
        setOpenIncompleteDataDialog={setOpenIncompleteDataDialog}
        openIncompleteDataDialog={openIncompleteDataDialog}
        yesButtonClickHandler={clickYesIncompleteDialogHandle}
      />
      <Popup
        openPopup={openVoltsAmpsInvalidDialog}
        setOpenPopup={setOpenVoltsAmpsInvalidDialog}
        onClose={handleCloseVoltsAmpsInvalidDialog}
        title="No Volts/Amps reference set selected"
        titleStyle={{ color: "red" }}
      >
        <p>
          You must select a Voltage that best represents Actual Voltage being
          read.
        </p>
        <Stack direction="row" spacing={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleCloseVoltsAmpsInvalidDialog}
          >
            Ok
          </Button>
        </Stack>
      </Popup>
      <Popup
        openPopup={openNpsaImgPopup}
        setOpenPopup={setoPenNpsaImgPopup}
        title={`NPSHA ${npsaImg}`}
        onClose={handleCloseNpsaImgPopup}
        size="lg"
      >
        <>
          <div style={{ textAlign: "center" }}>
            <img
              src={npsaImg === "Chart" ? npsaCurveImg : npsaChartImg}
              style={{ width: 900 }}
              alt={`NPSHA ${npsaImg}`}
            />
          </div>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseNpsaImgPopup}
          >
            Close
          </Button>
        </Stack>
      </Popup>
      <Popup
        title="Warning"
        openPopup={gpmDiversityDialogOpen}
        setOpenPopup={setGpmDiversityDialogOpen}
        onClose={() => setGpmDiversityDialogOpen(false)}
      >
        <>
          <Alert severity="warning">
            <strong>
              The Total Combined Pump(s) Actual {systemOfMeasurement.get("gpm")}{" "}
              exceeds the System Actual {systemOfMeasurement.get("gpm")}
              /Diversity Tolerance.
            </strong>
            <br />
            System Actual {systemOfMeasurement.get("gpm")}:{" "}
            <span style={{ color: "blue" }}>
              {pumpReportValues?.actualSystemGpm?.toLocaleString("en-US")}{" "}
              {systemOfMeasurement.get("gpm")}
            </span>
            <br />
            Pump(s) Total Combined Actual {systemOfMeasurement.get("gpm")}:{" "}
            <span style={{ color: "red" }}>
              {newTotalDeisgnGpm.toLocaleString("en-US")}{" "}
              {systemOfMeasurement.get("gpm")}
            </span>
            <br />
          </Alert>
        </>
        <Stack direction="row" spacing={3}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setGpmDiversityDialogOpen(false)}
            size="small"
          >
            No
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={movingForwardHandler}
            autoFocus
          >
            Yes
          </Button>
        </Stack>
      </Popup>
      <HeaderStudyPage
        headerStudiesPage={{
          code: code,
          system: pumpReportValues?.system,
          id: id,
        }}
        parentText="Project"
        parentLink={`/app/ProjectStudies/${pumpReportValues?.projectId}/${user?.companyId}`}
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={6}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={activeStep}
                projectId={dataReport?.projectId}
                reportId={dataReport?.reportId}
                reportTypeId={dataReport?.reportTypeId}
              />
              {currentPretestStep <= 3 && !disableStudy && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <Stepper
                        alternativeLabel
                        activeStep={currentPretestStep}
                        connector={<ColorlibConnector />}
                      >
                        {preTrestSteps.map((label) => (
                          <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>
                              {label}
                            </StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </Grid>
                    {currentPretestStep === 0 && (
                      <Grid item xs={12} textAlign="center">
                        <FormButton
                          text="Start Test"
                          variant="contained"
                          color="success"
                          endIcon={<EastIcon />}
                          onClick={() => setCurrentPretestStep(1)}
                        />
                      </Grid>
                    )}
                    {currentPretestStep === 1 && (
                      <Grid item xs={12} textAlign="center">
                        <Alert severity="warning">
                          Ensure everybody is clear of any potential system
                          moving parts, ie motors, pumps, etc.
                        </Alert>
                        <br />
                        <FormButton
                          text="Accept"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setCurrentPretestStep(2)}
                        />{" "}
                        <FormButton
                          text="Cancel"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setCurrentPretestStep(0)}
                        />
                      </Grid>
                    )}
                    {currentPretestStep === 2 && (
                      <Grid item xs={12} textAlign="center">
                        <Alert severity="error" style={{ textAlign: "left" }}>
                          <p style={{ fontWeight: "bold", marginTop: 0 }}>
                            Arc Flash And Shock Hazard - Appropriate PPE
                            Required
                          </p>
                          <ul>
                            <li>
                              Do not operate controls or open covers without
                              appropriate Personal Protection Equipment (PPE).
                              Failure to comply may result in Injury or Death.
                            </li>
                            <li>
                              Refer to NFPA 70E for minimum PPE Requirements.
                            </li>
                            <li>
                              Warning NFPA code requirements may change, always
                              check for current or updated code requirements.
                            </li>
                            <li>
                              Request a qualified and licensed electrician to
                              collect voltage and Current/Amperage data if not
                              ARC Flash Trained.
                            </li>
                          </ul>
                          <p style={{ fontWeight: "bold" }}>
                            Warning: Above is understood and will be adhered to.
                          </p>
                        </Alert>
                        <br />
                        <FormButton
                          text="Accept"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setCurrentPretestStep(3)}
                        />{" "}
                        <FormButton
                          text="Cancel"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setCurrentPretestStep(0)}
                        />
                      </Grid>
                    )}
                    {currentPretestStep === 3 && (
                      <Grid item xs={12} textAlign="center">
                        <Alert severity="info" style={{ textAlign: "left" }}>
                          Is the pump turning in the correct rotation/direction?
                        </Alert>
                        <br />
                        <FormButton
                          text="Yes"
                          variant="contained"
                          color="primary"
                          size="small"
                          onClick={() => setCurrentPretestStep(4)}
                        />{" "}
                        <FormButton
                          text="No"
                          variant="outlined"
                          color="secondary"
                          size="small"
                          onClick={() => setCurrentPretestStep(0)}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
              {(currentPretestStep > 3 || disableStudy) && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <h2>Actuals</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <ButtonGroup
                        variant="outlined"
                        aria-label="Loading button group"
                      >
                        {pumpReportValues?.pumps?.map((pump, index) => (
                          <Button
                            key={pump.key}
                            disabled={selectedPump === pump.key}
                            onClick={() => loadPump(index)}
                          >
                            <AcUnitIcon />
                            {`Pmp ${index + 1}`}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </Grid>
                  </Grid>
                  <br />
                  <Grid container spacing={3}>
                    {showActualReadingsDate && (
                      <Grid item xs={12} md={3}>
                        <FormDatePicker
                          name="actualDateTestPerformed"
                          label={`Actual Date Of When The Test Was Performed`}
                          value={values.actualDateTestPerformed}
                          onChange={handleInputChange}
                          error={errors.actualDateTestPerformed}
                          disable={disableStudy}
                          showSkeleton={
                            isLoadingPumpMotor || isLoadingActual || isLoading
                          }
                          disableFuture={true}
                          fullWidth
                        />
                      </Grid>
                    )}
                    <Grid item xs={12} md={showActualReadingsDate ? 3 : 6}>
                      <FormNumeric2
                        label={`Pump off pressure ${systemOfMeasurement.get(
                          "psi"
                        )}`}
                        placeholder="0.00"
                        name="pumpOffPressure"
                        value={values?.pumpOffPressure}
                        decimalScale={2}
                        fixedDecimalScale
                        //onChange={handleInputChange}
                        onBlur={handlePumpOffPressureChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.pumpOffPressure}
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <FormLabel
                        text={`${systemOfMeasurement.get("ftwg")}: ${
                          values?.feetWaterColumn?.toFixed(3) || "0.000"
                        }`}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name="pumpShutDifferentialUnit"
                        onChange={handleInputChange}
                        items={shutDiffOptions}
                        row
                        value={values?.pumpShutDifferentialUnit}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Pump shut differential INLET ${values?.pumpShutDifferentialUnit}`}
                        placeholder="0.00"
                        name="inlet"
                        value={values?.inlet}
                        decimalScale={2}
                        fixedDecimalScale
                        //onChange={handleInputChange}
                        onBlur={handleInletChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.inlet}
                        disabled={
                          disableStudy || !values?.pumpShutDifferentialUnit
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Pump shut differential DISCHARGE ${systemOfMeasurement.get(
                          "psi"
                        )}`}
                        placeholder="0.00"
                        name="disch"
                        value={values?.disch}
                        decimalScale={2}
                        fixedDecimalScale
                        //onChange={handleInputChange}
                        onBlur={handleDischChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.disch}
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Delta ${systemOfMeasurement.get("psi")}`}
                        placeholder="0.00"
                        name="psi"
                        value={values?.psi}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.psi}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Delta ${systemOfMeasurement.get("feet")}`}
                        placeholder="0.00"
                        name="deltaFeet"
                        value={values?.deltaFeet}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.deltaFeet}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        label={`Actual pump impeller diameter (${systemOfMeasurement.get(
                          "in"
                        )})`}
                        placeholder="0.00"
                        name="actualPumpImpellerDiameter"
                        value={values?.actualPumpImpellerDiameter}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.actualPumpImpellerDiameter}
                        disabled={disableStudy}
                        fullWidth={false}
                        style={{ width: "95%" }}
                      />
                      <FormTooltip tooltipId={20} />
                    </Grid>
                    {pumpMotorValues?.systemMotorOperatesFromAVfDPwmDevice && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="VFD/PWM set at"
                            placeholder="0.00"
                            name="vfdPwm"
                            value={values?.vfdPwm}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleVfdOperatingHertzChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            disabled={disableStudy}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label="VFD/PWM Operating % Speed"
                            placeholder="0.00"
                            name="vfdPwmPercentage"
                            value={values?.vfdPwmPercentage}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleVfdOperatingVelocityChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            disabled={disableStudy}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            If VFD has a display screen - Collect Operating
                            Voltage and Current/Amperage directly from this
                            display if available.
                            <br />
                            If the screen only displays a single averaged
                            Voltage and Current/Amperage, enter its value in
                            each data input box.
                            <br />
                            If using a standard Volt/Amp meter not rated to read
                            VFD or PWM voltage output, the meter won't work when
                            recording VFD / PWM "Output Voltage". This meter
                            will work when collecting Amperage(s). Collecting
                            actual VFD /PWM "Output Voltage" can be done with
                            the meters designed and available to do this task.
                          </Alert>
                        </Grid>
                        <Grid item xs={12}>
                          <Alert severity="info">
                            <strong>RECOMMENDATION:</strong>
                            <br />
                            <b>
                              Use VFD/PWM display data to collect actual Volts
                              and Amps
                            </b>
                            <br />
                          </Alert>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormCheckBox
                            name="voltReadFromVFDScreen"
                            label="Volts & Amperages read from VFD Screen"
                            value={values?.voltReadFromVFDScreen}
                            onChange={(e: any) =>
                              handleVoltsReadChange(
                                e.target.value,
                                !e.target.value
                              )
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            disabled={disableStudy}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormCheckBox
                            name="voltMeterUsed"
                            label="Volts & Amperages read with a handheld voltmeter"
                            value={values?.voltMeterUsed}
                            onChange={(e: any) =>
                              handleVoltsReadChange(
                                !e.target.value,
                                e.target.value
                              )
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            disabled={disableStudy}
                          />
                        </Grid>
                        {values?.voltMeterUsed && (
                          <Grid item xs={12}>
                            <Alert severity="info">
                              <b>DECLARATION</b>
                              <br />I hereby state that I'm using a voltmeter
                              rated for VFD.
                            </Alert>
                          </Grid>
                        )}
                      </>
                    )}
                    {pumpMotorValues?.motorPhase !== "Single phase" && (
                      <Grid item xs={12}>
                        <FormLabel text="Select Voltage that best represents Actual read:" />
                        <FormCheckBox
                          name=""
                          label={`${
                            pumpMotorValues?.nameplateVoltsPhase1 ?? ""
                          }V/${pumpMotorValues?.nameplateAmpsPhase1 ?? ""}A`}
                          value={
                            pumpMotorValues?.selectedVoltAmpsReference === 1
                          }
                          onChange={(e: any) =>
                            handleSelectedVoltsReferenceChange(1)
                          }
                          showSkeleton={
                            isLoadingPumpMotor || isLoadingActual || isLoading
                          }
                          disabled={disableStudy}
                        />
                        {(pumpMotorValues?.motorPhase === "Three phase" ||
                          (pumpMotorValues?.motorPhase ===
                            "2 wire single phase" &&
                            pumpMotorValues?.additionalVolAmpValue >= 1)) && (
                          <FormCheckBox
                            name=""
                            label={`${
                              pumpMotorValues?.nameplateVoltsPhase2 ?? ""
                            }V/${pumpMotorValues?.nameplateAmpsPhase2 ?? ""}A`}
                            value={
                              pumpMotorValues?.selectedVoltAmpsReference === 2
                            }
                            onChange={(e: any) =>
                              handleSelectedVoltsReferenceChange(2)
                            }
                            disabled={disableStudy}
                          />
                        )}
                        {(pumpMotorValues?.motorPhase === "Three phase" ||
                          (pumpMotorValues?.motorPhase ===
                            "2 wire single phase" &&
                            pumpMotorValues?.additionalVolAmpValue === 2)) && (
                          <FormCheckBox
                            name=""
                            label={`${
                              pumpMotorValues?.nameplateVoltsPhase3 ?? ""
                            }V/${pumpMotorValues?.nameplateAmpsPhase3 ?? ""}A`}
                            value={
                              pumpMotorValues?.selectedVoltAmpsReference === 3
                            }
                            onChange={(e: any) =>
                              handleSelectedVoltsReferenceChange(3)
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            disabled={disableStudy}
                          />
                        )}
                        {pumpMotorValues?.motorPhase === "Three phase" &&
                          pumpMotorValues?.additionalVolAmpValue >= 1 && (
                            <FormCheckBox
                              name=""
                              label={`${
                                pumpMotorValues?.nameplateVoltsPhase4 ?? ""
                              }V/${
                                pumpMotorValues?.nameplateAmpsPhase4 ?? ""
                              }A`}
                              value={
                                pumpMotorValues?.selectedVoltAmpsReference === 4
                              }
                              onChange={(e: any) =>
                                handleSelectedVoltsReferenceChange(4)
                              }
                              disabled={disableStudy}
                            />
                          )}
                        {pumpMotorValues?.motorPhase === "Three phase" &&
                          pumpMotorValues?.additionalVolAmpValue >= 2 && (
                            <FormCheckBox
                              name=""
                              label={`${
                                pumpMotorValues?.nameplateVoltsPhase5 ?? ""
                              }V/${
                                pumpMotorValues?.nameplateAmpsPhase5 ?? ""
                              }A`}
                              value={
                                pumpMotorValues?.selectedVoltAmpsReference === 5
                              }
                              onChange={(e: any) =>
                                handleSelectedVoltsReferenceChange(5)
                              }
                              showSkeleton={
                                isLoadingPumpMotor ||
                                isLoadingActual ||
                                isLoading
                              }
                              disabled={disableStudy}
                            />
                          )}
                        {pumpMotorValues?.motorPhase === "Three phase" &&
                          pumpMotorValues?.additionalVolAmpValue === 3 && (
                            <FormCheckBox
                              name=""
                              label={`${
                                pumpMotorValues?.nameplateVoltsPhase6 ?? ""
                              }V/${
                                pumpMotorValues?.nameplateAmpsPhase6 ?? ""
                              }A`}
                              value={
                                pumpMotorValues?.selectedVoltAmpsReference === 6
                              }
                              onChange={(e: any) =>
                                handleSelectedVoltsReferenceChange(6)
                              }
                              showSkeleton={
                                isLoadingPumpMotor ||
                                isLoadingActual ||
                                isLoading
                              }
                              disabled={disableStudy}
                            />
                          )}
                        <FormCheckBox
                          name="voltageNotListed"
                          label="Voltage not listed"
                          value={pumpMotorValues?.voltageNotListed}
                          onChange={handlePumpMotorValuesChange}
                          showSkeleton={
                            isLoadingPumpMotor || isLoadingActual || isLoading
                          }
                          disabled={disableStudy}
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <Alert severity="warning">
                        1. Single Phase and 3 Phase Voltages are recorded from
                        Phase to Phase, not Phase to Ground.
                        {pumpMotorValues?.systemMotorOperatesFromAVfDPwmDevice && (
                          <>
                            <br />
                            2. Reading Actual Volts requires a handheld
                            voltmeter rated for VFDs. A typical RMS Voltmeter
                            will not provide accurate readings.
                            <br />
                            3. Actual Voltage and Amperage associated with the
                            VFD must be recorded on the leaving side of the VFD
                            to the Motor.
                          </>
                        )}
                      </Alert>
                    </Grid>
                    {values?.voltReadFromVFDScreen &&
                      pumpMotorValues?.systemMotorOperatesFromAVfDPwmDevice && (
                        <Grid item xs={12}>
                          <FormCheckBox
                            name="vfdOnlyDisplaysSingleVolAmp"
                            label="VFD/PWM Only displays 1 Voltage and 1 Amperage"
                            value={values?.vfdOnlyDisplaysSingleVolAmp}
                            onChange={handleVfdOnlyDisplaysSingleVoltageChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            disabled={disableStudy}
                          />
                        </Grid>
                      )}
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        label="Actual Volts L1-L2"
                        placeholder="0.00"
                        name="actualOperatingVoltsPhase1"
                        value={values?.actualOperatingVoltsPhase1}
                        decimalScale={2}
                        fixedDecimalScale
                        onKeyDown={handleValidateVoltsAmps}
                        onPaste={handleValidateVoltsAmps}
                        //onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.actualOperatingVoltsPhase1}
                        disabled={disableStudy}
                        onBlur={handleVoltsPhase1Blur}
                      />
                    </Grid>
                    {pumpMotorValues?.motorPhase === "Three phase" && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Actual Volts L1-L3"
                            placeholder="0.00"
                            name="actualOperatingVoltsPhase2"
                            value={values?.actualOperatingVoltsPhase2}
                            decimalScale={2}
                            fixedDecimalScale
                            onKeyDown={handleValidateVoltsAmps}
                            onPaste={handleValidateVoltsAmps}
                            onChange={handleInputChange}
                            disabled={
                              values?.vfdOnlyDisplaysSingleVolAmp ||
                              disableStudy
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.actualOperatingVoltsPhase2}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Actual Volts L2-L3"
                            placeholder="0.00"
                            name="actualOperatingVoltsPhase3"
                            value={values?.actualOperatingVoltsPhase3}
                            decimalScale={2}
                            fixedDecimalScale
                            onKeyDown={handleValidateVoltsAmps}
                            onPaste={handleValidateVoltsAmps}
                            onChange={handleInputChange}
                            disabled={
                              values?.vfdOnlyDisplaysSingleVolAmp ||
                              disableStudy
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.actualOperatingVoltsPhase3}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        label="Actual Amps L1-L2"
                        placeholder="0.00"
                        name="actualOperatingAmpsPhase1"
                        value={values?.actualOperatingAmpsPhase1}
                        decimalScale={2}
                        fixedDecimalScale
                        onKeyDown={handleValidateVoltsAmps}
                        onPaste={handleValidateVoltsAmps}
                        //onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.actualOperatingAmpsPhase1}
                        disabled={disableStudy}
                        onBlur={handleAmpsPhase1Blur}
                      />
                    </Grid>
                    {pumpMotorValues?.motorPhase === "Three phase" && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Actual Amps L1-L3"
                            placeholder="0.00"
                            name="actualOperatingAmpsPhase2"
                            value={values?.actualOperatingAmpsPhase2}
                            decimalScale={2}
                            fixedDecimalScale
                            onKeyDown={handleValidateVoltsAmps}
                            onPaste={handleValidateVoltsAmps}
                            onChange={handleInputChange}
                            disabled={
                              values?.vfdOnlyDisplaysSingleVolAmp ||
                              disableStudy
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.actualOperatingAmpsPhase2}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Actual Amps L2-L3"
                            placeholder="0.00"
                            name="actualOperatingAmpsPhase3"
                            value={values?.actualOperatingAmpsPhase3}
                            decimalScale={2}
                            fixedDecimalScale
                            onKeyDown={handleValidateVoltsAmps}
                            onPaste={handleValidateVoltsAmps}
                            onChange={handleInputChange}
                            disabled={
                              values?.vfdOnlyDisplaysSingleVolAmp ||
                              disableStudy
                            }
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.actualOperatingAmpsPhase3}
                          />
                        </Grid>
                      </>
                    )}
                    {(!ampsValid(1) || !ampsValid(2) || !ampsValid(3)) && (
                      <Grid item xs={12}>
                        <Alert severity="error">
                          {`WARNING: Actual Amps exceed referenced nameplate Amp rating in lines${
                            !ampsValid(1) ? " | 1" : ""
                          }${!ampsValid(2) ? " | 2" : ""}${
                            !ampsValid(3) ? " | 3" : ""
                          }`}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name="finalRunningPressuresUnit"
                        onChange={handleInputChange}
                        items={shutDiffOptions}
                        row
                        value={values?.finalRunningPressuresUnit}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Final running pressure INLET ${values?.finalRunningPressuresUnit}`}
                        placeholder="0.00"
                        name="finalRunningInlet"
                        value={values?.finalRunningInlet}
                        decimalScale={2}
                        fixedDecimalScale
                        //onChange={handleInputChange}
                        onBlur={handleFinalRunningInletChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.finalRunningInlet}
                        disabled={
                          disableStudy || !values?.finalRunningPressuresUnit
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Final running pressure DISCHARGE ${systemOfMeasurement.get(
                          "psi"
                        )}`}
                        placeholder="0.00"
                        name="finalRunningDisch"
                        value={values?.finalRunningDisch}
                        decimalScale={2}
                        fixedDecimalScale
                        //onChange={handleInputChange}
                        onBlur={handleFinalRunningDischChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.finalRunningDisch}
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Delta ${systemOfMeasurement.get("psi")}`}
                        placeholder="0.00"
                        name="finalRunningPSI"
                        value={values?.finalRunningPSI}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.finalRunningPSI}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <FormNumeric2
                        label={`Delta ${systemOfMeasurement.get("feet")}`}
                        placeholder="0.00"
                        name="finalRunningDeltaFeet"
                        value={values?.finalRunningDeltaFeet}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.finalRunningDeltaFeet}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormNumeric2
                        label={`Actual pump ${systemOfMeasurement.get("rpm")}`}
                        placeholder="0.00"
                        name="actualPumpRPM"
                        value={values?.actualPumpRPM}
                        decimalScale={2}
                        fixedDecimalScale
                        onChange={handleInputChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.actualPumpRPM}
                        disabled={disableStudy}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckBox
                        name="npshTesting"
                        label="NPSH testing"
                        value={values?.npshTesting}
                        onChange={handleInputChange}
                        disabled={disableStudy}
                      />
                    </Grid>
                    {values?.npshTesting && (
                      <>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label={`Pump curve NPSHA (${systemOfMeasurement.get(
                              "ft"
                            )})`}
                            placeholder="0.00"
                            name="pumpCurveNpshaFt"
                            value={values?.pumpCurveNpshaFt}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.pumpCurveNpshaFt}
                            disabled={disableStudy}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label={`Pump curve NPSHR (${systemOfMeasurement.get(
                              "ft"
                            )})`}
                            placeholder="0.00"
                            name="pumoCurveNpsh"
                            value={values?.pumoCurveNpsh}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.pumoCurveNpsh}
                            fullWidth={false}
                            style={{ width: "90%" }}
                            disabled={disableStudy}
                          />
                          <FormTooltip tooltipId={21} />
                        </Grid>
                        {(values?.pumoCurveNpsh || 0) >
                          (values?.pumpCurveNpshaFt || 0) && (
                          <Grid item xs={12}>
                            <Alert severity="warning">
                              <strong>Important:</strong> Pump operating below
                              NPSHR, cavitation may occur.
                            </Alert>
                          </Grid>
                        )}
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label={`NPSH Pump Inlet water temperature (${systemOfMeasurement.get(
                              "temperature"
                            )})`}
                            placeholder="0.00"
                            name="npshWaterTemperature"
                            value={values?.npshWaterTemperature}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.npshWaterTemperature}
                            disabled={disableStudy}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormNumeric2
                            label={`NPSHA Inlet Pressure (${values?.finalRunningPressuresUnit})`}
                            placeholder="0.00"
                            name="pumpCurveNpsha"
                            value={values?.pumpCurveNpsha}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.pumpCurveNpsha}
                            disabled={
                              !values?.finalRunningPressuresUnit || disableStudy
                            }
                            fullWidth={false}
                            style={{ width: "82%" }}
                          />
                          <FormTooltip
                            text={npshCurveTooltip}
                            onClick={showNpsaChartDialog}
                          />
                          <FormTooltip
                            text={npshCurveExampleTooltip}
                            onClick={showNpsaExampleDialog}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12} md={4}>
                      <FormNumeric2
                        label={`Actual ${systemOfMeasurement.get("gpm")}`}
                        placeholder="0.00"
                        name="actualGPM"
                        value={values?.actualGPM}
                        decimalScale={2}
                        fixedDecimalScale
                        //onChange={handleInputChange}
                        onBlur={handleActualGPMChange}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        error={errors?.actualGPM}
                        disabled={disableStudy || isEnergyProcess}
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel
                        text={`Design ${systemOfMeasurement.get(
                          "gpm"
                        )}: ${pumpValues?.designGpm?.toFixed(2)}`}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormLabel
                        text={`Final %: ${values?.final?.toFixed(2) || "0.00"}`}
                        showSkeleton={
                          isLoadingPumpMotor || isLoadingActual || isLoading
                        }
                        sx={{ color: finalColorEval() }}
                      />
                    </Grid>
                    {isEnergyProcess && (
                      <Grid item xs={12}>
                        <Alert severity="info">
                          Actual {systemOfMeasurement.get("gpm")} is disabled
                          during an Energy Process
                        </Alert>
                      </Grid>
                    )}
                    {pumpMotorValues?.systemMotorOperatesFromAVfDPwmDevice && (
                      <>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="System Delta Pressure set-point based on worst case flowing coil/device listed as"
                            placeholder="0.00"
                            name="systemSPWorstCaseFlowing"
                            value={values?.systemSPWorstCaseFlowing}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.systemSPWorstCaseFlowing}
                            disabled={disableStudy}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="Balance valve serving worst case flowing device is"
                            placeholder="0.00"
                            name="blanceValvePercentageOpen"
                            value={values?.blanceValvePercentageOpen}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.blanceValvePercentageOpen}
                            suffix="% open"
                            fullWidth={false}
                            style={{ width: "85%" }}
                            disabled={disableStudy}
                          />
                          <FormTooltip tooltipId={37} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <FormNumeric2
                            label="System Delta Pressure set-point"
                            placeholder="0.00"
                            name="systemDeltaPressureSetPoint"
                            value={values?.systemDeltaPressureSetPoint}
                            decimalScale={2}
                            fixedDecimalScale
                            onChange={handleInputChange}
                            showSkeleton={
                              isLoadingPumpMotor || isLoadingActual || isLoading
                            }
                            error={errors?.systemDeltaPressureSetPoint}
                            disabled={disableStudy}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </>
              )}
              <FooterButtons
                activeStep={activeStep}
                stepsCount={steps.length}
                projectId={pumpReportValues?.projectId as number}
                companyId={user?.companyId as number}
                isSaving={isUpdating}
                disabled={isUpdating || isLoading || disableStudy}
                reportName={title as string}
                saveHandler={saveHandler}
                nextStepHandler={nextStepHandler}
                previousStepHandler={previousStepHandler}
                hideSaveButton={currentPretestStep <= 3 || disableStudy}
                disableNext={currentPretestStep <= 3 && !disableStudy}
                isComplete={pumpReportValues?.isComplete ?? true}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PumpStep5;
