import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Grid,
  IconButton,
  Toolbar,
  Divider as MuiDivider,
  styled,
  Typography,
  Fab as MuiFab,
  Slider,
  Alert as MuiAlert,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Spreadsheet, {
  ISpreadsheetColumn,
} from "src/components/spreadsheet/Spreadsheet";
import { AddIcon, ContentCopyIcon, DeleteIcon } from "src/components/icons";
import { Mode } from "src/ts/types/common";
import {
  FormCheckBox,
  FormCheckSwitch,
  FormNumeric2,
  FormSelect,
  FormText,
} from "src/components/formControls";
import { useAsyncMutation, useAsyncQuery, useLog } from "src/hooks";
import { Box, spacing } from "@mui/system";
import MatchWordConfirmModal from "src/components/modals/MatchWordConfirmModal";
import DialogMessagePopup from "src/components/DialogMessagePopup";
import { ICoils, ICoilsItems } from "src/ts/interfaces/study/coils";
import CoilsBank from "./CoilsBank";
import coilsItemsService from "src/services/study/coilsItemsService";
import { IDefaultComboBoxCatalog, IKeyValue } from "src/ts/interfaces";
import { CatalogService } from "src/services";
import FormTooltip from "src/components/formControls/FormTooltip";
import SpreadSheetCell from "src/components/spreadsheet/SpreadSheetCell";
import SpreadsheetNumericInput2 from "src/components/spreadsheet/SpreadsheetNumericInput2";
import getProjectSingleService from "src/services/getProjectSingleService";
import CommentsModal from "src/components/floatingModalBar/CommentsModal";
import { COMMENTSTYPE } from "src/constants";
import { GridActionButton } from "src/components/gridControls";
import { Rowing } from "@mui/icons-material";
import constructWithOptions from "styled-components/dist/constructors/constructWithOptions";
const Divider = styled(MuiDivider)(spacing);
const Fab = styled(MuiFab)(spacing);
const Alert = styled(MuiAlert)(spacing);
interface Props {
  id: number;
  isLoading: boolean;
  mode: Mode;
  readOnly: boolean;
  saveItems: boolean;
  systemOfMeasurement: {
    get: (key: string) => any;
    setSystem: (system: string) => void;
  };
  disableStudy: boolean;
  coils: ICoils;
  setSaveTotals: Dispatch<SetStateAction<boolean>>;
  saveTotals: boolean;
  setShowSteadyState: Dispatch<SetStateAction<boolean>>;
  isEnergyProcess: boolean;
  systemIsMA?: boolean;
  parentTarget?: "Water" | "Air";
}

const CoilsGrid = ({
  id,
  isLoading,
  mode,
  readOnly,
  saveItems,
  systemOfMeasurement,
  disableStudy,
  coils,
  setSaveTotals,
  saveTotals,
  setShowSteadyState,
  isEnergyProcess,
  systemIsMA,
  parentTarget,
}: Props) => {
  const { log } = useLog();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [item, setItem] = useState<ICoilsItems>();
  const [indexv, setIndexv] = useState(0);
  const [deleteItem, setDeleteItem] = useState(0);
  const [loadingSlide, setLoadingSlide] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isLoadingItem, setIsLoadingItem] = useState(false);
  const [decreaseModal, setDecreaseModal] = useState(false);
  const [loadingTable, setLoadingTable] = useState(true);
  const [data, setData] = useState<ICoilsItems[]>([]);
  const [showComments, setShowComments] = useState(false);
  const [modalwarningNo, setModalwarningNo] = useState(false);
  const [pictureModal, setPictureModal] = useState(false);
  const [pictureName, setPictureName] = useState("");
  const [newNumberOfCircuits, setNewNumberOfCircuits] = useState(0);
  const [
    hydronicTerminalDevicesToleranceBottom,
    setHydronicTerminalDevicesToleranceBottom,
  ] = useState(0);
  const [
    hydronicTerminalDevicesToleranceTop,
    setHydronicTerminalDevicesToleranceTop,
  ] = useState(0);
  const [refreshCircuits, setRefreshCircuits] = useState(false);
  const [coilTypes, setCoilTypes] = useState<IKeyValue<number, string>[]>([]);
  const [coilPipeType, setCoilPipeType] = useState<IKeyValue<number, string>[]>(
    []
  );

  const [coilPipeTypeAll, setCoilPipeTypeAll] = useState<any>([]);
  const [showCoilMatrixModal, setShowCoilMatrixModal] = useState(false);
  const [isSavingItem, setIsSavingItem] = useState(false);

  const { execute: executeProject } = useAsyncQuery(
    getProjectSingleService.single,
    {
      onSuccess: (result) => {
        setHydronicTerminalDevicesToleranceTop(
          coils.coilsReportTypeId === 1
            ? result.hydronicTerminalDevicesToleranceTop
            : result.requiredGPMSystemToleranceTop
        );
        setHydronicTerminalDevicesToleranceBottom(
          coils.coilsReportTypeId === 1
            ? result.hydronicTerminalDevicesToleranceBottom
            : result.requiredGPMSystemToleranceBottom
        );
      },
    }
  );

  const { execute: executeSaveAll } = useAsyncQuery(
    coilsItemsService.saveItems
  );

  const catalogValueOtherEval = (
    id: number,
    catalog: IKeyValue<number, string>[]
  ) => {
    if (catalog && catalog.length > 0 && id) {
      const [{ value }] = catalog.filter((x) => x.key === id);
      return value === "Other";
    }
    return false;
  };

  const { execute: executedevActualPDWPSI, isLoading: isLoadingActualPDWPSI } =
    useAsyncQuery(coilsItemsService.devActualPDWPSI, {
      onSuccess: (result, callItem) => {
        const modifiedRow = { ...callItem[0] };
        modifiedRow.deviceActualPDW = result;
        const modifiedItems = data.map((item, i) =>
          item.id === modifiedRow.id ? modifiedRow : item
        );
        setData(modifiedItems);
      },
    });

  const { execute: executedevDesignPDWPSI, isLoading: isLoadingDesignPDWPSI } =
    useAsyncQuery(coilsItemsService.devDesignPDWPSI, {
      onSuccess: (result, callItem) => {
        const modifiedRow = { ...callItem[0] };
        modifiedRow.plansPrintDesignCoilWaterPSI = result;
        const modifiedItems = data.map((item, i) =>
          item.id === modifiedRow.id ? modifiedRow : item
        );
        setData(modifiedItems);
      },
    });

  const { execute: executeDevActPDWFeet, isLoading: isLoadingActualPDWFeet } =
    useAsyncQuery(coilsItemsService.devActPDWFeet, {
      onSuccess: (result, callItem) => {
        const modifiedRow = { ...callItem[0] };
        modifiedRow.deviceActualPDWFet = result;
        const modifiedItems = data.map((item, i) =>
          item.id === modifiedRow.id ? modifiedRow : item
        );
        setData(modifiedItems);
      },
    });

  const { execute: executeCreate, isSubmitting: isSubmittingCreate } =
    useAsyncMutation(coilsItemsService.create, {
      successfulMessage: "The item was created",
      onSuccess: (resultItem) => {
        setData([...data, resultItem]);
      },
    });

  const { execute: executeRemove, isSubmitting: isSubmittingRemove } =
    useAsyncMutation(coilsItemsService.remove, {
      successfulMessage: "The item was removed",
      onSuccess: async () => {
        let oldData = [...data];
        const newList = oldData.filter((item) => item.id !== deleteItem);
        setData(newList);
        if (data.length === indexv + 1) setIndexv(0);
      },
    });

  const { execute: executeSaveCircuits } = useAsyncQuery<ICoilsItems>(
    coilsItemsService.save,
    {
      onSuccess: () => {
        setRefreshCircuits(!refreshCircuits);
      },
    }
  );

  useEffect(() => {
    if (pictureModal) {
      const selected = coilPipeTypeAll?.find(
        (item: any) => item.id === data[indexv]?.coilPipeTypeId
      );
      setPictureName(selected.imageName);
    } else setPictureName("");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureModal]);

  useEffect(() => {
    const getData = async () => {
      await executeSaveAll(data);
    };
    if (data && data.length > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveItems]);

  useEffect(() => {
    setIsLoadingItem(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexv]);

  useEffect(() => {
    const getData = async () => {
      await executeProject(coils.projectId);
    };
    if (coils.projectId && coils.projectId > 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coils.projectId]);

  useEffect(() => {
    const getData = async () => {
      await getCoilsItems();
      const responseCoilType = await CatalogService.getAllDropdown("CoilType");
      setCoilTypes(responseCoilType);
      const responseCoilPipeType = await CatalogService.getAllDropdown(
        "CoilPipeType"
      );
      setCoilPipeType(responseCoilPipeType);

      const responseCoilPipeTypeAll = await CatalogService.getAll(
        "CoilPipeType"
      );
      setCoilPipeTypeAll(responseCoilPipeTypeAll.data);
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const formIsLoading = useMemo(
    () =>
      disableStudy ||
      isLoadingDesignPDWPSI ||
      isLoadingActualPDWPSI ||
      isLoadingActualPDWFeet ||
      isSavingItem,
    [
      disableStudy,
      isLoadingDesignPDWPSI,
      isLoadingActualPDWPSI,
      isLoadingActualPDWFeet,
      isSavingItem,
    ]
  );

  const columns: ISpreadsheetColumn[] = [
    {
      name: "index",
      type: "index",
      label: "#",
      headerAlign: "center",
      disabled: true,
      width: "30px",
      minWidth: "30px",
    },
    {
      name: "roomAreaServed",
      type: "text",
      label: "System, Room, or Area Served",
      headerAlign: "center",
      hide: coils.reportTypeId === 17 || isEnergyProcess,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "numberOfFeeds",
      type: "numericInput",
      headerAlign: "center",
      label: (
        <>
          <Typography textAlign="center" variant={"subtitle2"} fontWeight={600}>
            <FormTooltip tooltipId={28}></FormTooltip>
            <br />
            Number of Feeds/Circuits
          </Typography>
        </>
      ),
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "throttledDeviceSet",
      type: "numericInput",
      label: "Throttled Device Set @",
      headerAlign: "center",
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "finsPerInch",
      type: "numericInput",
      headerAlign: "center",
      label: `Fins per ${systemOfMeasurement.get("in")}`,
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "numberOfRows",
      type: "numericInput",
      label: (
        <>
          <Typography textAlign="center" variant={"subtitle2"} fontWeight={600}>
            <FormTooltip tooltipId={29}></FormTooltip>
            <br />
            Rows
          </Typography>
        </>
      ),
      decimalScale: 0,
      width: "90px",
      minWidth: "90px",
      disabled: formIsLoading,
    },
    {
      name: "pdwDeltaPressure",
      type: "numericInput",
      label: (
        <Typography textAlign="center">
          <Typography display={"inline"} variant={"subtitle2"} fontWeight={600}>
            Design H2O PDW
          </Typography>{" "}
          <Typography
            display={"inline"}
            variant={"subtitle2"}
            fontWeight={600}
            color={"green"}
          >
            {systemOfMeasurement.get("feet")}
          </Typography>
        </Typography>
      ),
      decimalScale: 3,
      onChange: async (row: any, e: any, index: number) => {
        row.pdwDeltaPressure = e.target.value;
        await executedevDesignPDWPSI(row);
      },
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading || isEnergyProcess,
    },
    {
      name: "deviceActualPDWFet",
      type: "numericInput",
      label: (
        <Typography textAlign="center">
          <Typography display={"inline"} variant={"subtitle2"} fontWeight={600}>
            Device Actual PDW
          </Typography>{" "}
          <Typography
            display={"inline"}
            variant={"subtitle2"}
            fontWeight={600}
            color={"green"}
          >
            {systemOfMeasurement.get("feet")}
          </Typography>
        </Typography>
      ),
      //borderColor: "red",
      hide: !coils.devicePDWFeet,
      onChange: async (row: any, e: any, index: number) => {
        row.deviceActualPDWFet = e.target.value;
        await executedevActualPDWPSI(row);
      },
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "deviceActualPDW",
      type: "numericInput",
      label: (
        <Typography textAlign="center">
          <Typography display={"inline"} variant={"subtitle2"} fontWeight={600}>
            Device Actual PDW
          </Typography>{" "}
          <Typography
            display={"inline"}
            variant={"subtitle2"}
            fontWeight={600}
            color={"blue"}
          >
            {systemOfMeasurement.get("psi")}
          </Typography>
        </Typography>
      ),
      //borderColor: "red",
      hide: !coils.devicePDWPSI,
      onChange: async (row: any, e: any, index: number) => {
        row.deviceActualPDW = e.target.value;
        await executeDevActPDWFeet(row);
      },
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "width",
      type: "numericInput",
      label: "Coil Width",
      headerAlign: "center",
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "length",
      type: "numericInput",
      label: "Coil Length",
      headerAlign: "center",
      showTotals: true,
      decimalScale: 3,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "designGPM",
      label: `Design ${systemOfMeasurement.get("gpm")}`,
      type: "custom",
      headerAlign: "center",
      render: (row: ICoilsItems, col: ISpreadsheetColumn, index: number) => {
        return (
          <SpreadSheetCell width={90} minWidth={90}>
            <>
              <SpreadsheetNumericInput2
                value={row.designGPM}
                onBlur={(e: any) => {
                  updateItem(e, row, index, true);
                }}
                name="designGPM"
                decimalScale={3}
                maxValue={1000}
                disabled={readOnly || formIsLoading}
                mode={mode}
                thousandSeparator={true}
              />
            </>
          </SpreadSheetCell>
        );
      },
      showTotals: true,
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "percentageOfDesign14A",
      type: "custom",
      label: "% of Design",
      headerAlign: "center",
      disabled: true,
      showTotals: true,
      decimalScale: 3,
      render: (row: ICoilsItems, col: ISpreadsheetColumn, index: number) => {
        return (
          <SpreadSheetCell width={60} minWidth={60}>
            <>
              <Typography
                mt={0.8}
                paddingLeft={1.8}
                textAlign="center"
                color={
                  (row?.autoGhostMax ?? 0) < (row?.actualGPM ?? 0) ||
                  (row?.autoGhostMin ?? 0) > (row?.actualGPM ?? 0)
                    ? "red"
                    : ""
                }
              >
                {row?.percentageOfDesign14A?.toFixed(2)}%
              </Typography>
            </>
          </SpreadSheetCell>
        );
      },
      width: "80px",
      minWidth: "80px",
    },
    {
      name: "actualGPM",
      label: `Actual ${systemOfMeasurement.get("gpm")}`,
      headerAlign: "center",
      type: "custom",
      borderColor: "red",
      showTotals: true,
      render: (row: ICoilsItems, col: ISpreadsheetColumn, index: number) => {
        return (
          <SpreadSheetCell width={100} minWidth={100}>
            <>
              <SpreadsheetNumericInput2
                value={row.actualGPM}
                onBlur={(e: any) => {
                  updateItem(e, row, index, true);
                }}
                name="actualGPM"
                decimalScale={3}
                maxValue={1000}
                disabled={
                  readOnly ||
                  formIsLoading ||
                  row.hasMultipleCircuits ||
                  parentTarget === "Water"
                }
                mode={mode}
                thousandSeparator={true}
              />
            </>
          </SpreadSheetCell>
        );
      },
      width: "100px",
      minWidth: "100px",
      disabled: formIsLoading,
    },
    {
      name: "autoGhostMinMax",
      type: "text",
      label: "Auto Min/Max",
      headerAlign: "center",
      showTotals: true,
      mode: "read",
      width: "90px",
      minWidth: "90px",
      disabled: formIsLoading,
    },
  ];

  const getCoilsItems = useCallback(async () => {
    try {
      const coilsItemsResponse = await coilsItemsService.getByCoilId(id);
      setData([...coilsItemsResponse.data]);
      if (coilsItemsResponse.data.length > 0) {
        if (coilsItemsResponse.data[0].hasMultipleCircuits && !formIsLoading)
          setShowCoilMatrixModal(true);
        else setShowSteadyState(true);
      }
      setLoadingTable(false);
    } catch (error) {
      log.error("Error loading Items Data");
    }
  }, [id, log]);

  const onChangeItem = async (e: any, row: ICoilsItems, index: number) => {
    const modifiedRow: ICoilsItems = {
      ...row,
      [e.target.name]: parseFloat(e.target.value),
    };
    const modifiedItems = data.map((item, i) =>
      i === index ? modifiedRow : item
    );
    setData(modifiedItems);
  };

  const updateItem = async (
    e: any,
    row: ICoilsItems,
    index: number,
    refresh: boolean = false
  ) => {
    const modifiedRow: ICoilsItems = {
      ...row,
      [e.target.name]: e.target.value,
    };
    try {
      setIsSavingItem(true);
      const res = await coilsItemsService.save(modifiedRow);
      setSaveTotals(!saveTotals);
      const modifiedItems = data.map((item, i) =>
        i === index ? res.data : item
      );
      setData(modifiedItems);
      setLoadingTable(false);
      if (refresh) {
        setRefreshCircuits(!refreshCircuits);
      }
      setIsSavingItem(false);
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const addItem = async () => {
    if (coils?.fillNext) {
      await executeCreate(data[indexv]);
    } else {
      const create = {
        coildId: id,
        electricCoilId: id,
        heatingElectricResistancePhase: "3 Phase",
        heatingElectricResistancePhaseActual: "3 Phase",
      };
      await executeCreate(create);
    }
  };

  const handleChange = async (item: any, e: any, update = false) => {
    item[e.target.name] = e.target.value;
    if (e.target.name === "missing") {
      item.extra = false;
      item.missing = true;
    }
    if (e.target.name === "extra") {
      item.missing = false;
      item.extra = true;
    }
    if (e.target.name === "manual") {
      item.autoFlow = false;
      item.manual = true;
    }
    if (e.target.name === "autoFlow") {
      item.autoFlow = true;
      item.manual = false;
    }
    if (e.target.name === "hasMultipleCircuits") {
      item.manal = false;
      item.ultrasonicMeter = !e.target.value;
    }
    setItem({ ...item });
    if (update) await updateItem(e, item, indexv);
  };

  const handleTemperetureRecordedUsing = (item: any, value: string) => {
    item.temperetureRecordedUsing = value;
    setItem({ ...item });
  };

  const HandleThermalPasteUsed = (item: any, value: string) => {
    item.thermalPasteUsed = value;
    setItem({ ...item });
  };

  const handleDeleteItem = (deleteItem: number) => {
    setDeleteItem(deleteItem);
    setDeleteModal(true);
  };

  const handleConfirmDelete = async () => {
    await executeRemove(deleteItem);
  };

  const saveNumberOfCircuit = async (newItem: number) => {
    let currentItem = data[indexv];
    currentItem.numberOfCircuits = newItem;
    setItem({ ...currentItem });
    await executeSaveCircuits(currentItem);
    setLoadingSlide(true);
  };

  const handleAcceptDecrease = async () => {
    await saveNumberOfCircuit(newNumberOfCircuits);
    setLoadingSlide(true);
  };

  const handleCancelDecrease = () => {
    setLoadingSlide(true);
  };

  const handleChangeSlider = async (e: any, newValue: any) => {
    setLoadingSlide(false);
    if (data[indexv].numberOfCircuits > newValue) {
      setNewNumberOfCircuits(newValue);
      setDecreaseModal(true);
    } else {
      await saveNumberOfCircuit(newValue);
    }
  };

  const handleCopy = async (item: any) => {
    await executeCreate(item);
  };

  const totalPercentageDesign = useMemo(() => {
    let result = Number(
      ((coils.totalActualGpm / coils.totalDesignGpm) * 100).toFixed(2)
    );
    if (isNaN(result)) result = 0;
    return `${result.toFixed(2)}%`;
  }, [coils.totalActualGpm, coils.totalDesignGpm]);

  const autoMin = useMemo(
    () =>
      coils.totalDesignGpm -
      coils.totalDesignGpm * (hydronicTerminalDevicesToleranceBottom / 100),
    [coils.totalDesignGpm, hydronicTerminalDevicesToleranceBottom]
  );

  const autoMax = useMemo(
    () =>
      coils.totalDesignGpm +
      coils.totalDesignGpm * (hydronicTerminalDevicesToleranceTop / 100),
    [coils.totalDesignGpm, hydronicTerminalDevicesToleranceTop]
  );

  const resolveItemPercentageColor = (item: ICoilsItems) =>
    item?.percentageOfDesign11A
      ? item.percentageOfDesign11A <
          100 - hydronicTerminalDevicesToleranceBottom ||
        item.percentageOfDesign11A > 100 + hydronicTerminalDevicesToleranceTop
        ? "red"
        : "inherit"
      : "inherit";

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5" color="primary.main">
            {data.length > 1
              ? "Stacked Coil Configuration"
              : "Coil Configuration"}
          </Typography>
        </Grid>
      </Grid>

      <Spreadsheet
        items={data}
        setItems={setData}
        cols={columns}
        defaultRowPerPage={25}
        showRowTotals={true}
        showSkeleton={isLoading || loadingTable}
        totals={{
          length: "Totals",
          designGPM: coils.totalDesignGpm.toFixed(2),
          percentageOfDesign14A: {
            value: totalPercentageDesign,
            color:
              coils.totalActualGpm < autoMin || coils.totalActualGpm > autoMax
                ? "red"
                : "inherit",
          },
          actualGPM: coils.totalActualGpm.toFixed(2),
          autoGhostMinMax: `${autoMin.toFixed(2)}/${autoMax.toFixed(2)}`,
        }}
      />
      {coils.reportTypeId === 17 && (
        <Grid container spacing={4} pt={4}>
          <Grid item xs={8}></Grid>
          <Grid item xs={4} textAlign={"right"}>
            <FormNumeric2
              name={"actualHydronicTotalBTUH"}
              label={`Actual System Total Combined ${systemOfMeasurement.get(
                "btuh"
              )}`}
              value={coils?.actualHydronicTotalBTUH}
              showSkeleton={isLoadingItem}
              disabled={formIsLoading}
              decimalScale={3}
              mode="read"
              thousandSeparator={true}
            ></FormNumeric2>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={4}>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <Typography textAlign={"center"}>
            <Typography
              display={"inline"}
              textAlign={"center"}
              color={"error.main"}
              variant="h5"
              pr={1}
            >
              Important Note:
            </Typography>
            <Typography display={"inline"} textAlign={"center"}>
              If coil cleaning is required for this heat transfer device, it is
              not recommended to use an acidic coil cleaner solution. Acid
              solutions degrade the chemical and thermal bond between the coil
              tubing and heat transfer fins.
            </Typography>
          </Typography>
        </Grid>
      </Grid>
      <Divider my={6}></Divider>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
        }}
      >
        <>
          {data &&
            data.map((item, index) => (
              <>
                {item.id !== data[indexv].id && (
                  <>
                    <IconButton
                      size="medium"
                      onClick={() => {
                        setIsLoadingItem(true);
                        setIndexv(index);
                      }}
                      sx={{ border: "1px solid" }}
                    >
                      <Typography variant="h5" width={30} height={30} pt={1}>
                        {index + 1}
                      </Typography>
                    </IconButton>
                    {!formIsLoading && (
                      <Grid container width={"35px"} pl={1} pr={1}>
                        <Grid item xs={12}>
                          {" "}
                          <Fab
                            mx={2}
                            color="error"
                            size="small"
                            aria-label="Delete Item"
                            disabled={
                              isSubmittingCreate ||
                              isSubmittingRemove ||
                              formIsLoading
                            }
                            sx={{
                              height: "23px",
                              width: "23px",
                              minHeight: "23px",
                              margin: "0px",
                            }}
                            onClick={() => {
                              handleDeleteItem(item.id);
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                height: "15px",
                                width: "15px",
                                minHeight: "15px",
                              }}
                            ></DeleteIcon>
                          </Fab>
                        </Grid>
                        <Grid item xs={12}>
                          <Fab
                            mx={2}
                            color="info"
                            size="small"
                            aria-label="Copy Item"
                            disabled={
                              isSubmittingCreate ||
                              isSubmittingRemove ||
                              formIsLoading
                            }
                            sx={{
                              height: "23px",
                              width: "23px",
                              minHeight: "23px",
                              margin: "0px",
                            }}
                            onClick={() => {
                              handleCopy(item);
                            }}
                          >
                            <ContentCopyIcon
                              sx={{
                                height: "15px",
                                width: "15px",
                                minHeight: "15px",
                              }}
                            ></ContentCopyIcon>
                          </Fab>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
                {item.id === data[indexv].id && (
                  <>
                    <Fab
                      mx={2}
                      color="primary"
                      size="medium"
                      aria-label="Selected Item"
                    >
                      <Typography variant="h5" width={30} height={30} pt={1}>
                        {index + 1}
                      </Typography>
                    </Fab>
                    {!formIsLoading && (
                      <Grid container width={"35px"} pl={1} pr={1}>
                        <Grid item xs={12}>
                          <Fab
                            mx={2}
                            color="info"
                            size="small"
                            aria-label="Copy Item"
                            disabled={
                              isSubmittingCreate ||
                              isSubmittingRemove ||
                              formIsLoading
                            }
                            sx={{
                              height: "23px",
                              width: "23px",
                              minHeight: "23px",
                              margin: "0px",
                            }}
                            onClick={() => {
                              handleCopy(item);
                            }}
                          >
                            <ContentCopyIcon
                              sx={{
                                height: "15px",
                                width: "15px",
                                minHeight: "15px",
                              }}
                            ></ContentCopyIcon>
                          </Fab>
                        </Grid>
                      </Grid>
                    )}
                  </>
                )}
              </>
            ))}
          {!readOnly && !formIsLoading && (
            <Fab
              onClick={addItem}
              mx={2}
              color="success"
              size="medium"
              aria-label="Add"
              disabled={isSubmittingCreate}
            >
              <AddIcon />
            </Fab>
          )}
        </>
      </Toolbar>
      <Divider my={6}></Divider>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <FormText
                name={"deviceMfg"}
                label={"Device Mfg"}
                value={data[indexv]?.deviceMfg || ""}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
              ></FormText>
            </Grid>
            <Grid item xs={6}>
              <RadioGroup
                aria-label="Device Mfg"
                name=""
                value={false}
                onChange={() => {}}
                row
                tabIndex={-1}
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Missing"
                  onChange={(e: any) => {
                    e.target.name = "missing";
                    handleChange(data[indexv], e);
                    setShowComments(true);
                  }}
                  disabled={disableStudy}
                  checked={data[indexv]?.missing}
                  tabIndex={-1}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Extra"
                  onChange={(e: any) => {
                    e.target.name = "extra";
                    handleChange(data[indexv], e);
                    setShowComments(true);
                  }}
                  disabled={disableStudy}
                  checked={data[indexv]?.extra}
                  tabIndex={-1}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name={"deviceModel"}
                label={"Device Model"}
                value={data[indexv]?.deviceModel || ""}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
              ></FormText>
            </Grid>
            <Grid item xs={6}>
              <RadioGroup
                aria-label="Device Model"
                name=""
                value={false}
                onChange={() => {}}
                row
              >
                <FormControlLabel
                  control={<Radio />}
                  label="Manual"
                  onChange={(e: any) => {
                    e.target.name = "manual";
                    handleChange(data[indexv], e);
                  }}
                  disabled={disableStudy}
                  checked={data[indexv]?.manual}
                />
                <FormControlLabel
                  control={<Radio />}
                  label="Auto-Flow"
                  onChange={(e: any) => {
                    e.target.name = "autoFlow";
                    handleChange(data[indexv], e);
                  }}
                  disabled={disableStudy}
                  checked={data[indexv]?.autoFlow}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={6}>
              <FormSelect
                name={"coilTypeId"}
                label={"Mode of Operation"}
                value={(
                  (data && data[indexv]?.coilTypeId) ||
                  "null"
                )?.toString()}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                options={coilTypes}
                defaultValue={{
                  key: "null",
                  value: "Select Mode of Operation",
                }}
                disabled={formIsLoading || isEnergyProcess}
              ></FormSelect>
            </Grid>
            {catalogValueOtherEval(
              data[indexv]?.coilTypeId || 0,
              coilTypes
            ) && (
              <Grid item xs={6}>
                <FormText
                  name={"coilTypeOther"}
                  label={"Type of Coil Other"}
                  value={data[indexv]?.coilTypeOther || ""}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                ></FormText>
              </Grid>
            )}
            {coils?.deviceCoefficient && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.deviceCoefficient && (
              <Grid item xs={12}>
                <Typography variant="h5">Device Cv/Coefficient</Typography>
              </Grid>
            )}
            {coils?.deviceCoefficient && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"deviceCVorCoefficient"}
                  label={`Device Cv or Coefficient`}
                  value={data && data[indexv]?.deviceCVorCoefficient}
                  onBlur={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.designBTUH && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.designBTUH && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Design Total Hydronic Coil{" "}
                  {`${systemOfMeasurement.get("btuh")}`}
                </Typography>
              </Grid>
            )}
            {coils?.designBTUH && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"designSensibleCoilBTUH"}
                  label={`(Plans/Print) Design Coil ${systemOfMeasurement.get(
                    "btuh"
                  )} Total`}
                  value={data && data[indexv]?.designSensibleCoilBTUH}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualBTUH && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.actualBTUH && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Actual Total Hydronic Coil{" "}
                  {`${systemOfMeasurement.get("btuh")}`}
                </Typography>
              </Grid>
            )}
            {coils?.actualBTUH && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"actualTotalHydBTUH"}
                  label={`Actual Total Coil ${systemOfMeasurement.get("btuh")}`}
                  value={data && data[indexv]?.actualTotalHydBTUH}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualBTUH && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"percentageOfDesign11A"}
                  label={"% of Design"}
                  value={data && data[indexv]?.percentageOfDesign11A}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                  color={
                    data[indexv]
                      ? resolveItemPercentageColor(data[indexv])
                      : "inherit"
                  }
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.designBTUHTotal && coils?.coilsReportTypeId === 1 && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.designBTUHTotal && coils?.coilsReportTypeId === 1 && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow Coil {`${systemOfMeasurement.get("btuh")}`}{" "}
                  Total
                </Typography>
              </Grid>
            )}
            {coils?.designBTUHTotal && coils?.coilsReportTypeId === 1 && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"designTotalCoilBTUH"}
                  label={`(Plans/Print) Design Total Coil ${systemOfMeasurement.get(
                    "btuh"
                  )}`}
                  value={data && data[indexv]?.designTotalCoilBTUH}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualBTUHTotal &&
              coils?.actualAirflow &&
              coils?.coilsReportTypeId === 1 && (
                <Grid item xs={12}>
                  <Divider></Divider>
                </Grid>
              )}
            {coils?.actualBTUHTotal &&
              coils?.actualAirflow &&
              coils?.coilsReportTypeId === 1 && (
                <Grid item xs={12}>
                  <Typography color="success.main" variant="h5">
                    Actual Airflow Coil {`${systemOfMeasurement.get("btuh")}`}{" "}
                    Total
                  </Typography>
                </Grid>
              )}
            {coils?.actualBTUHTotal &&
              coils?.actualAirflow &&
              coils?.coilsReportTypeId === 1 && (
                <Grid item xs={6}>
                  {!coils?.isPaid && (
                    <Alert mb={4} severity="warning">
                      Data will be available when payment is received
                    </Alert>
                  )}
                  {coils?.isPaid && (
                    <FormNumeric2
                      name={"actualTotalCoilBTUH"}
                      label={`Actual Total Coil ${systemOfMeasurement.get(
                        "btuh"
                      )}`}
                      value={data && data[indexv]?.actualTotalCoilBTUH}
                      onChange={(e: any) => {
                        handleChange(data[indexv], e, true);
                      }}
                      showSkeleton={isLoadingItem}
                      disabled={disableStudy}
                      decimalScale={3}
                      thousandSeparator={true}
                    ></FormNumeric2>
                  )}
                </Grid>
              )}
            {coils?.actualBTUHTotal &&
              coils?.actualAirflow &&
              coils?.coilsReportTypeId === 1 && (
                <Grid item xs={6}>
                  {!coils?.isPaid && (
                    <Alert mb={4} severity="warning">
                      Data will be available when payment is received
                    </Alert>
                  )}
                  {coils?.isPaid && (
                    <FormNumeric2
                      name={"percentageOfDesign13A"}
                      label={"% of Design"}
                      value={data && data[indexv]?.percentageOfDesign13A}
                      onChange={(e: any) => {
                        handleChange(data[indexv], e, true);
                      }}
                      showSkeleton={isLoadingItem}
                      disabled={disableStudy}
                      decimalScale={3}
                      thousandSeparator={true}
                    ></FormNumeric2>
                  )}
                </Grid>
              )}
            {coils?.designWaterTemperature && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.designWaterTemperature && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Design ΔT {systemOfMeasurement.get("temperature")} Water
                  Temperature
                </Typography>
              </Grid>
            )}
            {coils?.designWaterTemperature && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansPrintDesignCoilWater"}
                  label={`(Plans/Print) Design Coil Water ΔT ${systemOfMeasurement.get(
                    "temperature"
                  )}`}
                  value={data && data[indexv]?.plansPrintDesignCoilWater}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualWaterTemperature && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.actualWaterTemperature && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Actual ΔT {systemOfMeasurement.get("temperature")} Water
                  Temperature
                </Typography>
              </Grid>
            )}
            {coils?.actualWaterTemperature && (
              <Grid item xs={6}>
                <Typography variant="subtitle2">
                  Water Temperature recorded using:
                </Typography>
              </Grid>
            )}
            {coils?.actualWaterTemperature && (
              <Grid item xs={3}>
                <FormCheckBox
                  name={"temperetureRecordedUsing"}
                  label={"Inmersion/Internal sensors"}
                  value={
                    data &&
                    data[indexv]?.temperetureRecordedUsing ===
                      "Inmersion/Internal sensors"
                  }
                  onChange={(e: any) => {
                    handleTemperetureRecordedUsing(
                      data[indexv],
                      "Inmersion/Internal sensors"
                    );
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                ></FormCheckBox>
              </Grid>
            )}
            {coils?.actualWaterTemperature && (
              <Grid item xs={3}>
                <FormCheckBox
                  name={"temperetureRecordedUsing "}
                  label={"Surface Mount Sensors"}
                  value={
                    data &&
                    data[indexv]?.temperetureRecordedUsing ===
                      "Surface Mount Sensors"
                  }
                  onChange={(e: any) => {
                    handleTemperetureRecordedUsing(
                      data[indexv],
                      "Surface Mount Sensors"
                    );
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                ></FormCheckBox>
              </Grid>
            )}
            {data[indexv]?.temperetureRecordedUsing ===
              "Surface Mount Sensors" &&
              coils?.actualWaterTemperature && (
                <Grid item xs={6}>
                  <Typography variant="subtitle2">
                    Thermal paste will be used when recording temperatures:
                  </Typography>
                </Grid>
              )}
            {data[indexv]?.temperetureRecordedUsing ===
              "Surface Mount Sensors" &&
              coils?.actualWaterTemperature && (
                <Grid item xs={3}>
                  <FormCheckBox
                    name={"thermalPasteUsed "}
                    label={"Yes"}
                    value={data && data[indexv]?.thermalPasteUsed === "Yes"}
                    onChange={(e: any) => {
                      HandleThermalPasteUsed(data[indexv], "Yes");
                    }}
                    showSkeleton={isLoadingItem}
                    disabled={disableStudy}
                  ></FormCheckBox>
                </Grid>
              )}
            {data[indexv]?.temperetureRecordedUsing ===
              "Surface Mount Sensors" &&
              coils?.actualWaterTemperature && (
                <Grid item xs={3}>
                  <FormCheckBox
                    name={"thermalPasteUsed "}
                    label={"No"}
                    value={data && data[indexv]?.thermalPasteUsed === "No"}
                    onChange={(e: any) => {
                      HandleThermalPasteUsed(data[indexv], "No");
                      setModalwarningNo(true);
                    }}
                    showSkeleton={isLoadingItem}
                    disabled={disableStudy}
                  ></FormCheckBox>
                </Grid>
              )}
            {coils?.actualWaterTemperature &&
              data &&
              (data[indexv]?.temperetureRecordedUsing ===
                "Inmersion/Internal sensors" ||
                (data[indexv]?.temperetureRecordedUsing ===
                  "Surface Mount Sensors" &&
                  data[indexv]?.thermalPasteUsed !== null)) && (
                <Grid item xs={6}>
                  <FormNumeric2
                    name={"enteringCoilWaterTemperature"}
                    label={`Entering Coil Water Temperature ${systemOfMeasurement.get(
                      "temperature"
                    )}`}
                    value={data && data[indexv]?.enteringCoilWaterTemperature}
                    onChange={(e: any) => {
                      handleChange(data[indexv], e, true);
                    }}
                    showSkeleton={isLoadingItem}
                    disabled={disableStudy}
                    decimalScale={3}
                    thousandSeparator={true}
                  ></FormNumeric2>
                </Grid>
              )}
            {coils?.actualWaterTemperature &&
              data &&
              (data[indexv]?.temperetureRecordedUsing ===
                "Inmersion/Internal sensors" ||
                (data[indexv]?.temperetureRecordedUsing ===
                  "Surface Mount Sensors" &&
                  data[indexv]?.thermalPasteUsed !== null)) && (
                <Grid item xs={6}>
                  <FormNumeric2
                    name={"atf"}
                    label={`ΔT ${systemOfMeasurement.get("temperature")}`}
                    value={data && data[indexv]?.atf}
                    onChange={(e: any) => {
                      handleChange(data[indexv], e, true);
                    }}
                    showSkeleton={isLoadingItem}
                    mode={"read"}
                    decimalScale={3}
                    thousandSeparator={true}
                    disabled={disableStudy}
                  ></FormNumeric2>
                </Grid>
              )}
            {coils?.actualWaterTemperature &&
              data &&
              (data[indexv]?.temperetureRecordedUsing ===
                "Inmersion/Internal sensors" ||
                (data[indexv]?.temperetureRecordedUsing ===
                  "Surface Mount Sensors" &&
                  data[indexv]?.thermalPasteUsed !== null)) && (
                <Grid item xs={6}>
                  <FormNumeric2
                    name={"leavinfCoilWaterTemperature"}
                    label={`Leaving Coil Water Temperature ${systemOfMeasurement.get(
                      "temperature"
                    )}`}
                    value={data && data[indexv]?.leavinfCoilWaterTemperature}
                    onChange={(e: any) => {
                      handleChange(data[indexv], e, true);
                    }}
                    showSkeleton={isLoadingItem}
                    decimalScale={3}
                    thousandSeparator={true}
                    disabled={disableStudy}
                  ></FormNumeric2>
                </Grid>
              )}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary.main" variant="h5">
                Calibrated Balance Device(s)
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormCheckSwitch
                name={"hasMultipleCircuits"}
                label={"This Coil has Calibrated Balance Devices"}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                  saveNumberOfCircuit(1);
                }}
                value={data && data[indexv]?.hasMultipleCircuits}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
              ></FormCheckSwitch>
            </Grid>
            <Grid item xs={6}>
              <FormCheckSwitch
                name={"ultrasonicMeter"}
                label={"Use Ultrasonic Meter"}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                value={data && data[indexv]?.ultrasonicMeter}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
              ></FormCheckSwitch>
            </Grid>
            {data && data[indexv]?.hasMultipleCircuits && (
              <Grid item xs={6}>
                <Typography>
                  Indicate the number of Balance Setter Devices:
                </Typography>
              </Grid>
            )}
            {data && data[indexv]?.hasMultipleCircuits && (
              <Grid item xs={6}>
                {data && data.length > 0 && !isLoadingItem && loadingSlide && (
                  <Slider
                    defaultValue={data[indexv]?.numberOfCircuits}
                    step={1}
                    marks
                    valueLabelDisplay="on"
                    min={1}
                    max={25}
                    onChangeCommitted={handleChangeSlider}
                    disabled={disableStudy}
                  />
                )}
              </Grid>
            )}
            {data && data[indexv]?.hasMultipleCircuits && (
              <Grid item xs={12}>
                <CoilsBank
                  data={data && data[indexv]}
                  refreshCircuits={refreshCircuits}
                  disableStudy={formIsLoading}
                ></CoilsBank>
              </Grid>
            )}
            {data[indexv]?.hasMultipleCircuits && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {data[indexv]?.hasMultipleCircuits && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"actualGPM"}
                  label={`Combined GPM`}
                  value={data[indexv]?.actualGPM}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  mode="read"
                  disabled={disableStudy}
                ></FormNumeric2>
              </Grid>
            )}
            {data[indexv]?.hasMultipleCircuits && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"actualTotalHydBTUH"}
                  label={`Total BTU/h`}
                  value={data[indexv]?.actualTotalHydBTUH}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  mode="read"
                  disabled={disableStudy}
                ></FormNumeric2>
              </Grid>
            )}
            {data[indexv]?.ultrasonicMeter && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {data[indexv]?.ultrasonicMeter && (
              <Grid item xs={12}>
                <Typography variant="h5">Ultrasonic Meter / Doppler</Typography>
              </Grid>
            )}
            {data[indexv]?.ultrasonicMeter && (
              <Grid item xs={6}>
                <FormText
                  name={"trasonicDoplerUsesMeterMfg"}
                  label={`Ultrasonic/Doppler Meter Mfg`}
                  value={data && data[indexv]?.trasonicDoplerUsesMeterMfg}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={disableStudy}
                ></FormText>
              </Grid>
            )}
            {data[indexv]?.ultrasonicMeter && (
              <Grid item xs={6}>
                <FormText
                  name={"transducersUsed"}
                  label={`Transducers Used`}
                  value={data && data[indexv]?.transducersUsed}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={disableStudy}
                ></FormText>
              </Grid>
            )}
            {data[indexv]?.ultrasonicMeter && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"transducersSpacingSetInches"}
                  label={`Transducers Spacing Set ${systemOfMeasurement.get(
                    "inches"
                  )}`}
                  value={data && data[indexv]?.transducersSpacingSetInches}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={disableStudy}
                ></FormNumeric2>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={4}>
            {coils?.designPSIWater && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Design Hydronic/Water Coil Δ{" "}
                  {`${systemOfMeasurement.get("psi")}`} Water
                </Typography>
              </Grid>
            )}
            {coils?.designPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansPrintDesignCoilWaterPSI"}
                  label={`(Plans/Print) Design Coil Water Δ ${systemOfMeasurement.get(
                    "psi"
                  )}`}
                  value={data && data[indexv]?.plansPrintDesignCoilWaterPSI}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.designPSIWater && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Actual (As Found) Hydronic/Water Coil Δ{" "}
                  {`${systemOfMeasurement.get("psi")}`} Water
                </Typography>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"asFoundSPInlet"}
                  label={`Entering Water ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.asFoundSPInlet}
                  onChange={(e: any) => {
                    data[indexv].asFoundSPDelta = Math.abs(
                      (e.target.value as number) -
                        (data[indexv].asFoundSPOutlet || 0)
                    );
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={formIsLoading || data[indexv]?.hasMultipleCircuits}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"asFoundSPDelta"}
                  label={`Δ ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.asFoundSPDelta}
                  showSkeleton={isLoadingItem}
                  mode={"read"}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={disableStudy}
                  inputProps={{ tabIndex: -1 }}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"asFoundSPOutlet"}
                  label={`Leaving Water ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.asFoundSPOutlet}
                  onChange={(e: any) => {
                    data[indexv].asFoundSPDelta = Math.abs(
                      (e.target.value as number) -
                        (data[indexv].asFoundSPInlet || 0)
                    );
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={formIsLoading || data[indexv]?.hasMultipleCircuits}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Actual (Coil Valves wide open) Hydronic/Water Coil Δ{" "}
                  {`${systemOfMeasurement.get("psi")}`} Water
                </Typography>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"valvsOpenSPInlet"}
                  label={`Entering Water ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.valvsOpenSPInlet}
                  onChange={(e: any) => {
                    data[indexv].valvsOpenSPDelta = Math.abs(
                      (e.target.value as number) -
                        (data[indexv].valvsOpenSPOutlet || 0)
                    );
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={formIsLoading || data[indexv]?.hasMultipleCircuits}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"valvsOpenSPDelta"}
                  label={`Δ ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.valvsOpenSPDelta}
                  showSkeleton={isLoadingItem}
                  mode={"read"}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={disableStudy}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"valvsOpenSPOutlet"}
                  label={`Leaving Water ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.valvsOpenSPOutlet}
                  onChange={(e: any) => {
                    data[indexv].valvsOpenSPDelta = Math.abs(
                      (e.target.value as number) -
                        (data[indexv].valvsOpenSPInlet || 0)
                    );
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={formIsLoading || data[indexv]?.hasMultipleCircuits}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={12}>
                <Typography color="primary.main" variant="h5">
                  Actual (As Left/Balanced) Hydronic/Water Coil Δ{" "}
                  {`${systemOfMeasurement.get("psi")}`} Water
                </Typography>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"enteringWaterPSI"}
                  label={`Entering Water ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.enteringWaterPSI}
                  onChange={(e: any) => {
                    data[indexv].psi = Math.abs(
                      (e.target.value as number) -
                        (data[indexv].leavingWaterPSI || 0)
                    );
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={formIsLoading || data[indexv]?.hasMultipleCircuits}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"psi"}
                  label={`Δ ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.psi}
                  showSkeleton={isLoadingItem}
                  mode={"read"}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={disableStudy}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"leavingWaterPSI"}
                  label={`Leaving Water ${systemOfMeasurement.get("psi")}`}
                  value={data && data[indexv]?.leavingWaterPSI}
                  onChange={(e: any) => {
                    data[indexv].psi = Math.abs(
                      (e.target.value as number) -
                        (data[indexv].enteringWaterPSI || 0)
                    );
                    handleChange(data[indexv], e);
                  }}
                  showSkeleton={isLoadingItem}
                  decimalScale={3}
                  thousandSeparator={true}
                  disabled={formIsLoading || data[indexv]?.hasMultipleCircuits}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualPSIWater && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.designAirflowDB && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow - ΔT{" "}
                  {`${systemOfMeasurement.get("temperature")}`} DB
                </Typography>
              </Grid>
            )}
            {coils?.designAirflowDB && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansPrintDesignCoilAirTemperture"}
                  label={`(Plans/Print) Design Coil Air Temperature ΔT ${systemOfMeasurement.get(
                    "temperature"
                  )} DB`}
                  value={
                    data && data[indexv]?.plansPrintDesignCoilAirTemperture
                  }
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.designAirflowDB && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography color="success.main" variant="h5">
                Actual Airflow - ΔT{" "}
                {`${systemOfMeasurement.get("temperature")}`} DB
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"upStreamCoilAirTemperatureDryBulb"}
                label={`Up Stream Coil Air Temperature Dry Bulb ${systemOfMeasurement.get(
                  "temperature"
                )}`}
                value={data && data[indexv]?.upStreamCoilAirTemperatureDryBulb}
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={formIsLoading || isEnergyProcess}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"atfdb"}
                label={`ΔT ${systemOfMeasurement.get("temperature")} DB`}
                value={data && data[indexv]?.atfdb}
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                mode={"read"}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"downStreamCoilAirTemperatureDryBulb"}
                label={`Down Stream Coil Air Temperature Dry Bulb ${systemOfMeasurement.get(
                  "temperature"
                )}`}
                value={
                  data && data[indexv]?.downStreamCoilAirTemperatureDryBulb
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={formIsLoading || isEnergyProcess}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            {((coils?.coilsReportTypeId === 1 && coils?.designAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {((coils?.coilsReportTypeId === 1 && coils?.designAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow - {coils?.rh && "RH"}
                  {coils?.wb && "WB"}
                  {coils?.dp && "DP"} {` %`}
                </Typography>
              </Grid>
            )}
            {((coils?.coilsReportTypeId === 1 && coils?.designAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansDesignCoilAir"}
                  label={"(Plans/Print) Design Coil Air"}
                  value={data && data[indexv]?.plansDesignCoilAir}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {((coils?.coilsReportTypeId === 1 && coils?.actualAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {((coils?.coilsReportTypeId === 1 && coils?.actualAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Actual Airflow - {coils?.rh ? "RH" : ""}
                  {coils?.wb ? "WB" : ""}
                  {coils?.dp ? "DP" : ""} {` %`}
                </Typography>
              </Grid>
            )}
            {((coils?.coilsReportTypeId === 1 && coils?.actualAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"upStreamCoilAir"}
                  label={`Up Stream Coil Air ${
                    coils?.rh ? "RH" : coils?.wb ? "WB" : coils?.dp ? "DP" : ""
                  } %`}
                  value={data && data[indexv]?.upStreamCoilAir}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {((coils?.coilsReportTypeId === 1 && coils?.actualAirflow) ||
              (isEnergyProcess && !systemIsMA)) && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"downStreamCoilAir"}
                  label={`Down Stream Coil Air ${coils?.rh ? "RH" : ""}
                ${coils?.wb ? "WB" : ""}
                ${coils?.dp ? "DP" : ""}`}
                  value={data && data[indexv]?.downStreamCoilAir}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.designAirflowSP && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.designAirflowSP && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Design Airflow - Coil Δ SP{" "}
                  {`${systemOfMeasurement.get("inwc")}`}
                </Typography>
              </Grid>
            )}
            {coils?.designAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"plansPrintDesginCoilSpInWc"}
                  label={`(Plans/Print) Design Coil Δ SP ${systemOfMeasurement.get(
                    "inwc"
                  )}`}
                  value={data && data[indexv]?.plansPrintDesginCoilSpInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualAirflowSP && (
              <Grid item xs={12}>
                <Divider></Divider>
              </Grid>
            )}
            {coils?.actualAirflowSP && (
              <Grid item xs={12}>
                <Typography color="success.main" variant="h5">
                  Actual Airflow - Coil Δ SP{" "}
                  {`${systemOfMeasurement.get("inwc")}`}
                </Typography>
              </Grid>
            )}
            {coils?.actualAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"upStreamCoilSpInWc"}
                  label={`Up Stream Coil SP ${systemOfMeasurement.get("inwc")}`}
                  value={data && data[indexv]?.upStreamCoilSpInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"spInWc"}
                  label={`Δ SP ${systemOfMeasurement.get("inwc")}`}
                  value={data && data[indexv]?.spInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            {coils?.actualAirflowSP && (
              <Grid item xs={6}>
                <FormNumeric2
                  name={"downStreamCoilSpInWc"}
                  label={`Down Stream Coil SP ${systemOfMeasurement.get(
                    "inwc"
                  )}`}
                  value={data && data[indexv]?.downStreamCoilSpInWc}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={formIsLoading || isEnergyProcess}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography color="success.main" variant="h5">
                Actual Airflow {`${systemOfMeasurement.get("cfm")}`}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"requiredAirflowCFM"}
                label={`(Plans/Print) Design Airflow ${systemOfMeasurement.get(
                  "cfm"
                )}`}
                value={data && data[indexv]?.requiredAirflowCFM}
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={formIsLoading || isEnergyProcess}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              {!coils?.isPaid && isEnergyProcess && (
                <Alert mb={4} severity="warning">
                  Data will be available when payment is received
                </Alert>
              )}
              {(coils?.isPaid || !isEnergyProcess) && (
                <FormNumeric2
                  name={"actualAirflowCFM"}
                  label={`Actual Airflow ${systemOfMeasurement.get("cfm")}`}
                  value={data && data[indexv]?.actualAirflowCFM}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {!coils?.isPaid && isEnergyProcess && (
                <Alert mb={4} severity="warning">
                  Data will be available when payment is received
                </Alert>
              )}
              {(coils?.isPaid || !isEnergyProcess) && (
                <FormNumeric2
                  name={"percentageOfDesign"}
                  label={"% Design"}
                  value={data && data[indexv]?.percentageOfDesign}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  mode={"read"}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  color={
                    data &&
                    (100 + hydronicTerminalDevicesToleranceTop <
                      (data[indexv]?.percentageOfDesign ?? 0) ||
                      100 - hydronicTerminalDevicesToleranceBottom >
                        (data[indexv]?.percentageOfDesign ?? 0))
                      ? "red"
                      : ""
                  }
                  thousandSeparator={true}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary.main" variant="h5">
                Design Pipe Information
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"designPipeSizeOutsideDiameterDecimalInches"}
                label={`(Plans/Print) Design Pipe Size ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={
                  data &&
                  data[indexv]?.designPipeSizeOutsideDiameterDecimalInches
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"designFluidVelocity"}
                label={`(Plans/Print) Design Pipe Fluid Velocity ${systemOfMeasurement.get(
                  "fps"
                )}`}
                value={data && data[indexv]?.designFluidVelocity}
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                mode={"read"}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary.main" variant="h5">
                Actual Pipe Information
              </Typography>
              <Typography>
                Dimensions are for the full flow of the Coil, not the bypass if
                present
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <FormSelect
                name={"coilPipeTypeId"}
                label={"Piping Type"}
                value={(
                  (data && data[indexv]?.coilPipeTypeId) ||
                  "null"
                )?.toString()}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                options={coilPipeType}
                defaultValue={{
                  key: "null",
                  value: "Select Piping Type",
                }}
                disabled={disableStudy}
              ></FormSelect>
            </Grid>
            <Grid item xs={1}>
              {data[indexv]?.coilPipeTypeId && (
                <GridActionButton
                  type="picture"
                  onClick={() => {
                    setPictureModal(true);
                  }}
                  tooltip="Glycol Refractometer Reading Example"
                />
              )}
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"pipeSizeOutsideDiameterDecimalInches"}
                label={`Actual Installed Pipe Size OD ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={
                  (data &&
                    data[indexv]?.pipeSizeOutsideDiameterDecimalInches) ||
                  ""
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"pipeSizeWallThicknessDecimalInches"}
                label={`Actual Pipe Wall Thickness ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={data && data[indexv]?.pipeSizeWallThicknessDecimalInches}
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"pipeSizeInsideDiameterInches"}
                label={`Actual Installed Pipe Size ID ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={data && data[indexv]?.pipeSizeInsideDiameterInches}
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                mode={"read"}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormText
                name={"pipeMaterialType"}
                label={`Installed Pipe Material Type`}
                value={data && data[indexv]?.pipeMaterialType}
                onChange={(e: any) => {
                  handleChange(data[indexv], e);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
              ></FormText>
            </Grid>
            <Grid item xs={6}>
              {!coils?.isPaid && (
                <Alert mb={4} severity="warning">
                  Data will be available when payment is received
                </Alert>
              )}
              {coils?.isPaid && (
                <FormNumeric2
                  name={"fluidVelocity"}
                  label={`Actual Fluid Velocity ${systemOfMeasurement.get(
                    "fps"
                  )}`}
                  value={data && data[indexv]?.fluidVelocity}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider></Divider>
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary.main" variant="h5">
                Actual Pipe Smallest Segment Information
              </Typography>
              <Typography>
                Smallest Pipe Dimension in this circuit's section
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"smallestPipeSizeOutsideDiameterDecimalInches"}
                label={`Smallest Segment Installed Pipe Size OD ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={
                  data &&
                  data[indexv]?.smallestPipeSizeOutsideDiameterDecimalInches
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"smallestPipeSizeWallThicknessDecimalInches"}
                label={`Smallest Segment Pipe Wall Thickness ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={
                  data &&
                  data[indexv]?.smallestPipeSizeWallThicknessDecimalInches
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              <FormNumeric2
                name={"smallestPipeSizeInsideDiameterInches"}
                label={`Smallest Segment Installed Pipe Size ID ${systemOfMeasurement.get(
                  "in"
                )}`}
                value={
                  data && data[indexv]?.smallestPipeSizeInsideDiameterInches
                }
                onChange={(e: any) => {
                  handleChange(data[indexv], e, true);
                }}
                showSkeleton={isLoadingItem}
                disabled={disableStudy}
                mode={"read"}
                decimalScale={3}
                thousandSeparator={true}
              ></FormNumeric2>
            </Grid>
            <Grid item xs={6}>
              {!coils?.isPaid && (
                <Alert mb={4} severity="warning">
                  Data will be available when payment is received
                </Alert>
              )}
              {coils?.isPaid && (
                <FormNumeric2
                  name={"smallestFluidVelocity"}
                  label={`Smallest Segment Fluid Velocity ${systemOfMeasurement.get(
                    "fps"
                  )}`}
                  value={data && data[indexv]?.smallestFluidVelocity}
                  onChange={(e: any) => {
                    handleChange(data[indexv], e, true);
                  }}
                  showSkeleton={isLoadingItem}
                  disabled={disableStudy}
                  decimalScale={3}
                  thousandSeparator={true}
                ></FormNumeric2>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <MatchWordConfirmModal
        matchWord={"DELETE"}
        isDialogOpen={deleteModal}
        setIsDialogOpen={setDeleteModal}
        text={"Are you certain you want to delete this item?"}
        onConfirm={handleConfirmDelete}
      ></MatchWordConfirmModal>
      <DialogMessagePopup
        title={"Coil's Feed/Circuits"}
        text={
          <>
            <Typography>
              You are about to decrease the number of Feed/Circuits in this
              Coil.
            </Typography>
            <Typography>You will lose the data permanently.</Typography>
            <Typography>Are you certain you want to continue? </Typography>
          </>
        }
        showPopup={decreaseModal}
        setShowPopup={setDecreaseModal}
        onSave={handleAcceptDecrease}
        isSubmitting={false}
        onCancel={handleCancelDecrease}
      />
      {showComments && (
        <CommentsModal
          isDialogOpen={showComments}
          setIsDialogOpen={setShowComments}
          type={COMMENTSTYPE.COMMENTS}
          projectId={coils.projectId}
          projectCompleted={false}
        />
      )}
      <DialogMessagePopup
        title={"Warning"}
        text={
          <Typography>
            <Typography>
              It is recommended thermal conductive paste is used when recording
              surface temperatures to record accurate field temperature data.
            </Typography>
            <Typography>
              Please go to your{" "}
              <Typography display={"inline"} fontWeight={"bold"}>
                dashboard
              </Typography>{" "}
              and select{" "}
              <Typography display={"inline"} fontWeight={"bold"}>
                “Test Equipment”
              </Typography>{" "}
              and then select{" "}
              <Typography display={"inline"} fontWeight={"bold"}>
                “Recommended Testing Equipment”
              </Typography>{" "}
              for a listing of high-performance thermal paste recommendations.
            </Typography>
          </Typography>
        }
        showPopup={modalwarningNo}
        setShowPopup={setModalwarningNo}
        onSave={() => {
          setModalwarningNo(false);
        }}
        acceptTextButton={"Acknowledged"}
        isSubmitting={false}
        hideCancel={true}
      />
      <DialogMessagePopup
        title={"Schedule Chart"}
        size={"md"}
        text={
          <Grid container>
            <Grid item xs={12} textAlign={"center"}>
              <Box
                component="img"
                alt="InstantSSL"
                src={`/static/img/${pictureName}.png`}
                sx={{ width: "100%" }}
                style={{ width: "100%" }}
              />
            </Grid>
          </Grid>
        }
        showPopup={pictureModal}
        setShowPopup={setPictureModal}
        onSave={function (): void {
          throw new Error("Function not implemented.");
        }}
        hideAccept={true}
        isSubmitting={false}
        cancelTextButton="Close"
      />
      <DialogMessagePopup
        title="Important"
        size="sm"
        text={
          <>
            <p style={{ textAlign: "justify" }}>
              You have indicated that multiple circuits supply/feed the primary
              coil. Please refer to the matrix below which match the same
              quantity of circuits that you selected.
            </p>
            <p style={{ textAlign: "justify" }}>
              The purpose of this matrix is to evaluate the performance of each
              circuit individually and analyze its performance in conjunction
              with the total flow for the primary hydronic coil. First, it is
              important to record the "As-Found" data and settings for the
              circuits prior to making any adjustments. This should be done
              before setting the valve to the wide-open position or before
              reaching the final, as-left/balanced condition.
            </p>
          </>
        }
        hideCancel
        acceptTextButton="Acknowledged"
        showPopup={showCoilMatrixModal}
        setShowPopup={setShowCoilMatrixModal}
        isSubmitting={false}
        onSave={() => setShowSteadyState(true)}
        disableClickOutside
      />
    </>
  );
};

export default CoilsGrid;
