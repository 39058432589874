import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  Alert,
} from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckSwitch,
  FormNumeric2,
  FormRadioGroup,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import {
  IFormulaSystemService,
  IKeyValue,
  IProjectReportInfectiousComplianceControl,
  IPsychrometricArgs,
} from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useLog,
  usePsychrometric,
  useSystemOfMeasurement,
} from "src/hooks";
import {
  IDimensions,
  IVelgridMatrix,
  IVelgridMatrixItems,
} from "src/ts/interfaces/study/velgridMatrix";
import velgridMatrixService from "src/services/study/velgridMatrixService";
import { DuctType, ROLES, STEPSVALUES } from "src/constants";
import { CatalogService } from "src/services";
import {
  IProjectReport,
  ProjectDTO,
} from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import VelgridGrid from "./components/VelgridGrid";
import { Validator } from "src/ts/types";
import useFormulas from "src/hooks/useFormulas";
import projectService from "src/services/project/projectService";
import elevationCorrectedService from "src/services/elevationCorrectedService";
import getProjectService from "src/services/project/getProjectService";
import SteadyStateDialog from "../components/SteadyState";
import { FooterButtons } from "../components/FooterButtons";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
import { HvacOutlined, ViewModuleOutlined } from "@mui/icons-material";
import CompleteStudyPopup from "../components/CompleteStudyPopup";
import getProjectSingleService from "src/services/getProjectSingleService";
import velgridMatrixItemsService from "src/services/study/velgridMatrixItemsService";
import SubStudyTypeSelect from "src/components/studies/SubStudyTypeSelect";
import DimensionsMatrix from "../components/DimensionsMatrix";
import ResultsDataMatrix from "../components/ResultsDataMatrix";
import DampersComponent from "../components/DampersComponent";

const initialValues: IVelgridMatrix = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  description: "",
  supply: false,
  return: false,
  exhaust: false,
  numberOfDucts: 0,
  systemTotal: false,
  outletTotal: false,
  conditionId: 0,
  temperatureCompensated: false,
  barometricalyCompensated: false,
  centerlineStaticPressureId: 0,
  x: 0,
  y: 0,
  relativeHumidityBool: false,
  wetBulbFBool: false,
  dewPointBool: false,
  ductSize: 0,
  ductSizeBase: 0,
  ductSizeHeight: 0,
  comments: "",
  nocVisual: false,
  nocActual: false,
  ductTypeId: DuctType.Rectangular,
  outsideAir: false,
  isActualLocalBarometricPressure: false,
  step: 0,
  isComplete: false,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  totalHeat: false,
  customMatrix: false,
  basAllNotApplicable: false,
  basAllNotAccessible: false,
  basOADamperpositionNotApplicable: false,
  basOADamperpositionNotAccessible: false,
  basOADamperposition: 0,
  basRADamperpositionNotApplicable: false,
  basRADamperpositionNotAccessible: false,
  basRADamperposition: 0,
  basMADamperpositionNotApplicable: false,
  basMADamperpositionNotAccessible: false,
  basMADamperposition: 0,
  basAHUExhaustDamperNotApplicable: false,
  basAHUExhaustDamperNotAccessible: false,
  basAHUExhaustDamper: 0,
  visualAllNotApplicable: false,
  visualAllNotAccessible: false,
  visualOADamperpositionNotApplicable: false,
  visualOADamperpositionNotAccessible: false,
  visualOADamperposition: 0,
  visualRADamperpositionNotApplicable: false,
  visualRADamperpositionNotAccessible: false,
  visualRADamperposition: 0,
  visualMADamperpositionNotApplicable: false,
  visualMADamperpositionNotAccessible: false,
  visualMADamperposition: 0,
  visualAHUExhaustDamperNotApplicable: false,
  visualAHUExhaustDamperNotAccessible: false,
  visualAHUExhaustDamper: 0,
};

interface IMessagePsychrometric {
  text: string;
  color: "info" | "warning" | "success" | "error";
}

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const VelgridReport = () => {
  const { user } = useAuth();
  const { log } = useLog();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const { psychrometric } = usePsychrometric();
  const params = useParams<{ id: string; step: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const { formulaSystem } = useFormulas();
  const [formulas, setFormulas] = useState<IFormulaSystemService>();
  const [totalCFM, setTotalCFM] = useState(0);
  const [disableAfterSteadyStep, setDisableAfterSteadyStep] =
    useState<boolean>(false);
  const [conditionOfTest, setConditionOfTest] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [selectRadioButton] = useState(initialValues.ductTypeId);
  const [stepContinuos, setStepContinuos] = useState("step1");
  const [dataX, setDataX] = useState(0);
  const [dataY, setDataY] = useState(0);
  const [project, setProject] = useState<ProjectDTO | null>();
  const [velgridMatrix, setVelgridMatrix] = useState<IVelgridMatrix | null>();
  const [projectReport, setProjectReport] = useState<IProjectReport | null>();
  const [infectiousComplianceControl, setInfectiousComplianceControl] =
    useState<IProjectReportInfectiousComplianceControl | null>(null);
  const [parentStudy, setParentStudy] = useState<IProjectReport | null>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [messagePsychrometric, setMessagePsychrometric] =
    useState<IMessagePsychrometric>({ text: "", color: "info" });
  const [showSteady, setShowSteady] = useState(false);
  const [disableEditData, setDisableEditData] = useState(false);
  const [changeMatrixButton, setChangeMatrixButton] = useState(false);
  const [buttonAction, setButtonAction] = useState(false);
  const [dimensions, setDimensions] = useState<IDimensions>({
    width: 0,
    height: 0,
  });
  const [items, setItems] = useState<IVelgridMatrixItems[][]>();
  const [customValidation, setCustomValidation] = useState(false);
  let navigate = useNavigate();

  const typeOfTerminal = [
    {
      key: 1,
      labelCustom: (
        <>
          <Stack direction={"row"}>
            <ViewModuleOutlined />
            <Typography mt={0.2}>
              <b>Rectangular</b>
            </Typography>
          </Stack>
        </>
      ),
    },
    {
      key: 2,
      labelCustom: (
        <>
          <Stack direction={"row"} spacing={1}>
            <HvacOutlined />
            <Typography mt={0.2}>
              <b>Round</b>
            </Typography>
          </Stack>
        </>
      ),
      disabled: user?.role === ROLES.Training,
    },
  ];

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(velgridMatrixService.update, {
      successfulMessage: "Report was updated successfully",
      hideErrorMessage: true,
      onSuccess: (result) => {
        const valuePrevious = values;

        setValues({
          ...result,
          basOADamperposition: valuePrevious?.basOADamperposition ?? 0,
          basRADamperposition: valuePrevious?.basRADamperposition ?? 0,
          basMADamperposition: valuePrevious?.basMADamperposition ?? 0,
          basAHUExhaustDamper: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualOADamperposition: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualRADamperposition: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualMADamperposition: valuePrevious?.basAHUExhaustDamper ?? 0,
          visualAHUExhaustDamper: valuePrevious?.basAHUExhaustDamper ?? 0,
        });
        if (stepContinuos === "step1") {
          setDataX(values?.x);
          setDataY(values?.y);
          setDimensions({
            width: values?.dimensionWidth ?? 0,
            height: values?.dimensionHeight ?? 0,
          });
        }

        validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefresh, isLoading: isLoadingRefresh } =
    useAsyncQuery(velgridMatrixService.update, {
      hideErrorMessage: true,
      onSuccess: async (result) => {
        setValues({
          ...result,
        });
        await validateTotalCFM(result?.totalCFM ?? 0);
      },
    });

  const { execute: executeRefreshPsyco } = useAsyncQuery(
    velgridMatrixService.update,
    {
      onSuccess: (result) => {
        setValues({
          ...result,
        });
        validateTotalCFM(result?.totalCFM ?? 0);
        calcPsycometrics(result?.totalCFM!, result);
      },
    }
  );

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (stepContinuos === STEPSVALUES.STEP2) {
      temp.dryBulbF = new Validator(fieldValues, "dryBulbF")
        .greatThan(0, "Airflow Temperature For Energy Calculation Is Required")
        .toString();
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<IVelgridMatrix>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<IVelgridMatrix>(
    velgridMatrixService.getById,
    {
      onSuccess: (dataResult) => {
        const getData = async () => {
          const formulas = await formulaSystem.setSystem(dataResult.projectId);
          setFormulas(formulas);
          if (!dataResult.ductTypeId)
            dataResult.ductTypeId = DuctType.Rectangular;
          if (
            !dataResult.relativeHumidityBool &&
            !dataResult.wetBulbFBool &&
            !dataResult.dewPointBool
          ) {
            dataResult.relativeHumidityBool = true;
          }
          if (dataResult.localBarometricPressure == null) {
            const zipCodeResponse = await projectService.getZipCode(
              dataResult.projectId
            );

            const elevationCorrectedResponse =
              await elevationCorrectedService.getById(
                Number(zipCodeResponse.data)
              );

            dataResult.localBarometricPressure = formulas.InHgToKPa(
              elevationCorrectedResponse.data.atmosphericPressure
            );
          }

          setDataX(dataResult.x === null ? 0 : dataResult.x);
          setDataY(dataResult.y === null ? 0 : dataResult.y);

          setDimensions({
            width: dataResult.dimensionWidth ?? 0,
            height: dataResult.dimensionHeight ?? 0,
          });
          setValues({
            ...dataResult,
            dimensionTotal:
              dataResult.dimensionTotal === null
                ? 0
                : dataResult.dimensionTotal,
            basOADamperposition: dataResult?.basOADamperposition ?? 0,
            basRADamperposition: dataResult?.basRADamperposition ?? 0,
            basMADamperposition: dataResult?.basMADamperposition ?? 0,
            basAHUExhaustDamper: dataResult?.basAHUExhaustDamper ?? 0,
            visualOADamperposition: dataResult?.basAHUExhaustDamper ?? 0,
            visualRADamperposition: dataResult?.basAHUExhaustDamper ?? 0,
            visualMADamperposition: dataResult?.basAHUExhaustDamper ?? 0,
            visualAHUExhaustDamper: dataResult?.basAHUExhaustDamper ?? 0,
          });
          validateTotalCFM(dataResult?.totalCFM ?? 0);
          const measurementSystemResponse =
            await getProjectService.getMeasurementSystem(
              dataResult.projectId ?? values.projectId
            );
          systemOfMeasurement.setSystem(measurementSystemResponse.data);
        };
        getData();
      },
    }
  );

  const { execute: executeSave } = useAsyncQuery(
    velgridMatrixItemsService.saveItems
  );

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  const [activeStep, setActiveStep] = useState<number>(
    params.step === "step1" ? 0 : 1
  );
  const [saveItems, setSaveItems] = useState<boolean>(false);

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeReport(id, "VEMR");
    };

    if (id !== 0) getData();

    const getDropdown = async () => {
      var result = await CatalogService.getAllDropdown(
        "ConditionPitotTraverse"
      );
      setConditionOfTest(result);
    };
    getDropdown();
    setDisableAfterSteadyStep(
      projectService.disableAfterSteadyStep(user?.role)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    let dimensionTotal = formulas?.sqft(
      ((values?.dimensionWidth ?? 0) - (values?.insulationThickness ?? 0) * 2) *
        ((values?.dimensionHeight ?? 0) -
          (values?.insulationThickness ?? 0) * 2)
    );

    setValues({
      ...values,
      dimensionTotal: dimensionTotal,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.insulationThickness,
  ]);

  useEffect(() => {
    if (!values?.totalHeat) {
      setMessagePsychrometric({ text: "", color: "info" });
    }
  }, [values?.totalHeat]);

  useEffect(() => {
    let ductSizeBaseCalc =
      values?.ductSizeBase - (values?.insulationThickness ?? 0) * 2;

    let ductSize = formulas?.sqft(
      (ductSizeBaseCalc / 2) * (ductSizeBaseCalc / 2) * 3.14159265359
    );

    setValues({
      ...values,
      ductSize: ductSize ?? 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.ductSizeBase, values?.insulationThickness]);

  useEffect(() => {
    if (!disableStudy && dataReport)
      calcPsycometrics(values?.totalCFM!, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCFM, dataReport]);

  useEffect(() => {
    setActiveStep(params.step?.toLowerCase() === "step1" ? 0 : 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.step]);

  const isOther = useMemo(() => {
    const found = conditionOfTest.find((item) => item.value === "Other");
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const calcTotal = async (totalItems: number) => {
    if (values && values.id > 0) {
      values.totalVelocity = totalItems;
      values.avgVelocity = totalItems / (values.x * values.y);
      setValues({
        ...values,
      });

      if (items && items?.length > 0) await executeRefresh(values.id, values);
    }
  };

  const handlerChangeData = async (e: any) => {
    const newValues = {
      ...values,
      [e.target.name]: e.target.value,
    };

    await executeRefresh(newValues.id, newValues);
    await saveItemsMatrix();
  };

  const validateTotalCFM = (totalCFM: number) => {
    if (totalCFM.toFixed(6) !== values?.totalCFM?.toFixed(6))
      setTotalCFM(totalCFM);
  };

  const onBlurCalcPsycometrics = (e: any) => {
    const newValues = { ...values, [e.target.name]: Number(e.target.value) };

    setValues(newValues);
    calcPsycometrics(newValues?.totalCFM!, newValues);
  };

  const calcPsycometrics = async (total: number, newValues: any) => {
    let humidityMeasuredAs = "";
    let humidityValue = 0;
    if (newValues?.relativeHumidityBool) {
      humidityMeasuredAs = "RH";
      humidityValue = newValues?.relativeHumidity ?? 0;
    }
    if (newValues?.wetBulbFBool) {
      humidityMeasuredAs = "WB";
      humidityValue = newValues?.wetBulbF ?? 0;
    }
    if (newValues?.dewPointBool) {
      humidityMeasuredAs = "DP";
      humidityValue = newValues?.dewPoint ?? 0;
    }
    const psychometricsArgs: IPsychrometricArgs = {
      projectId: newValues.projectId,
      humidityMeasuredAs: humidityMeasuredAs,
      humidityValue: humidityValue,
      dryBulbF: newValues.dryBulbF!,
      totalCFM: total,
    };

    if (
      newValues?.totalHeat &&
      !isNaN(humidityValue) &&
      !isNaN(newValues?.dryBulbF ?? 0) &&
      !isNaN(newValues?.totalCFM ?? 0)
    ) {
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      setMessagePsychrometric({ text: "", color: "info" });
      if (stepContinuos !== STEPSVALUES.STEP1) {
        if (
          psychrometricRes.psychrometric.message !== null &&
          psychrometricRes.psychrometric.enthalpy === 0
        )
          setMessagePsychrometric({
            text: psychrometricRes.psychrometric.message,
            color: "warning",
          });
        else if (psychrometricRes.psychrometric.message !== null) {
          setMessagePsychrometric({
            text: psychrometricRes.psychrometric.message,
            color: "info",
          });
        }
      }
      newValues.btuh = psychrometricRes.btuh!;
      newValues.sensibleHeat = psychrometricRes.sensibleHeat!;
      setValues({
        ...newValues,
      });
      if (newValues.id > 0) await executeRefresh(newValues.id, newValues);
    } else {
      if (newValues.id > 0) await executeRefresh(newValues.id, newValues);
    }
  };

  const handleChangeSpecificDefault = async (e: any) => {
    let isActualLocalBarometricPressure = e.target.value;
    const copyValues = { ...values };
    if (isActualLocalBarometricPressure) {
      const psychometricsArgs: IPsychrometricArgs = {
        projectId: values.projectId,
        humidityMeasuredAs: "RH",
        humidityValue: 50,
        dryBulbF:
          systemOfMeasurement.measurementSystem === "imperialSystem"
            ? 70
            : 21.11,
        totalCFM: 0,
      };
      const psychrometricRes = await psychrometric.getByProject(
        psychometricsArgs
      );
      setValues({
        ...values,
        useSiteSpecificDefault:
          psychrometricRes.psychrometric?.atmosphericPress,
        isActualLocalBarometricPressure: isActualLocalBarometricPressure,
      });
      copyValues.useSiteSpecificDefault =
        psychrometricRes.psychrometric?.atmosphericPress;
      copyValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    } else {
      setValues({
        ...values,
        isActualLocalBarometricPressure: isActualLocalBarometricPressure,
      });
      copyValues.isActualLocalBarometricPressure =
        isActualLocalBarometricPressure;
    }
    await executeRefreshPsyco(copyValues.id, copyValues);
  };

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      await executeUpdate(values.id, values);
      setSaveItems(!saveItems);
    }
    if (params.step === "step1") navigate(`/studies/VelgridMatrix/step2/${id}`);
    else navigate(`/studies/VelgridMatrix/step3/${id}`);
    setActiveStep(1);
  };

  const isNOC = useMemo(() => {
    const found = conditionOfTest.find(
      (item) => item.value === "NOC (Normal Operating Condition)"
    );
    if (found?.key === values?.conditionId) return true;
    else return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values?.conditionId]);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
    await saveItemsMatrix();
    setShowSteady(true);
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false ||
      (disableAfterSteadyStep && activeStep === 1)
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
    disableAfterSteadyStep,
    activeStep,
  ]);

  const mode = disableStudy ? "read" : "read&Write";

  const disableStep1 =
    disableStudy || stepContinuos === "step1" || changeMatrixButton;

  const handleInputChangeBarometric = async (e: any) => {
    const copyValues = { ...values };
    setValues({
      ...values,
      barometricalyCompensated: e.target.value,
    });
    copyValues.barometricalyCompensated = e.target.value;
    await executeRefreshPsyco(copyValues.id, copyValues);
  };

  const handleInputChangeCompesation = async (e: any) => {
    let temperatureCompensated = e.target.value;
    let dryBulbF = values?.dryBulbF;
    if (values.airflowTemperature === values.dryBulbF) {
      dryBulbF = formulas?.getAirflowTemperature();
    }
    let airflowTemperature = formulas?.getAirflowTemperature();
    try {
      const responseNewValues = await velgridMatrixService.update(values.id, {
        ...values,
        temperatureCompensated: temperatureCompensated,
        dryBulbF: dryBulbF,
        airflowTemperature: airflowTemperature,
      });
      setValues({
        ...responseNewValues.data,
      });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const handleBackStep = async () => {
    if (!disableStudy) await handleSave();
    navigate(`/studies/VelgridMatrix/step1/${id}`);
    setActiveStep(0);
  };

  const handleChangeRadio = (e: any) => {
    const copyvalues = { ...values };
    copyvalues.relativeHumidityBool = false;
    copyvalues.dewPointBool = false;
    copyvalues.wetBulbFBool = false;
    copyvalues.relativeHumidity = undefined;
    copyvalues.wetBulbF = undefined;
    copyvalues.dewPoint = undefined;
    switch (e.target.name) {
      case "relativeHumidityBool": {
        copyvalues.relativeHumidityBool = true;
        break;
      }
      case "dewPointBool": {
        copyvalues.dewPointBool = true;
        break;
      }
      case "wetBulbFBool": {
        copyvalues.wetBulbFBool = true;
        break;
      }
    }
    calcPsycometrics(copyvalues?.totalCFM!, copyvalues);
  };

  const onChangeTypeDevice = async (e: any) => {
    const { value } = e.target;
    setStepContinuos("step1");
    setButtonAction(false);
    setChangeMatrixButton(false);
    setDataX(0);
    setDataY(0);
    setDimensions({
      width: 0,
      height: 0,
    });

    try {
      const responseItems = await velgridMatrixItemsService.getByVelgridId(
        values?.id
      );

      if (responseItems.data.length > 0) {
        await velgridMatrixItemsService.deleteMatrixItem(values?.id);
      }
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }

    setItems([]);
    const resetValues = {
      ...values,
      ductTypeId: Number(value),
      dimensionTotal: 0,
      dimensionHeight: 0,
      dimensionWidth: 0,
      ductSizeBase: 0,
      x: 0,
      y: 0,
      totalVelocity: 0,
      avgVelocity: 0,
      trueCorectedAirVelocity: 0,
      conditionId: 0,
      totalCFM: 0,
      required: 0,
      akFactor: 0,
      percentajeOfDesign: 0,
      dryBulbF: 0,
      relativeHumidity: 0,
      wetBulbF: 0,
      dewPoint: 0,
      btuh: 0,
      sensibleHeat: 0,
      customMatrix: false,
      barometricalyCompensated: false,
      temperatureCompensated: false,
      totalHeat: false,
    };

    setValues({ ...resetValues });
  };

  useMemo(() => {
    const validateStep2 = async () => {
      let active = false;
      if (values?.ductTypeId === DuctType.Rectangular) {
        active = [
          values?.dimensionWidth,
          values?.dimensionHeight,
          values?.x,
          values?.y,
          values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (
          active &&
          !customValidation &&
          stepContinuos === STEPSVALUES.STEP1
        ) {
          setStepContinuos(STEPSVALUES.STEP2);
        }
      } else if (values?.ductTypeId === DuctType.Round) {
        active = [
          values?.ductSizeBase && values?.x && values?.y && values?.conditionId,
        ].every((value) => value !== 0 && value !== null);

        if (active) {
          setStepContinuos(STEPSVALUES.STEP2);
        }
      }
    };

    const trainingValidate = async () => {
      const active =
        values?.conditionId !== 0 &&
        values?.dimensionWidth &&
        values?.dimensionHeight &&
        values?.dimensionWidth < 23 &&
        values?.dimensionHeight < 23;

      if (active) {
        setStepContinuos(STEPSVALUES.STEP2);
      }
    };
    if (user?.role !== ROLES.Training) validateStep2();
    if (user?.role === ROLES.Training) trainingValidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.dimensionWidth,
    values?.dimensionHeight,
    values?.ductSizeBase,
    values?.x,
    values?.y,
    values?.conditionId,
  ]);

  const getParentReportIsComplete = async (
    reportId: number,
    reportType: string
  ) => {
    try {
      const res = await projectReportService.report(reportId, reportType);
      setParentStudy(res.data);
    } catch (error) {}
  };

  useEffectOnce(() => {
    const getData = async () => {
      const velgridMatrixResponse = await velgridMatrixService.getById(id);
      setVelgridMatrix(velgridMatrixResponse.data);
      await getParentReportIsComplete(velgridMatrixResponse.data.id, "VEMR");
      await getProject(velgridMatrixResponse.data.projectId);
      await getProjectReport(
        velgridMatrixResponse.data.id,
        velgridMatrixResponse.data.reportTypeId,
        velgridMatrixResponse.data.projectId
      );
      await getInfectiousControlData(velgridMatrixResponse.data.id);
    };
    if (id > 0) getData();
  });

  const getInfectiousControlData = async (reportId: number) => {
    try {
      const res = await projectReportService.getInfectiousControl(reportId);
      setInfectiousComplianceControl(res.data);
    } catch (error: any) {
      //log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const getProjectReport = async (
    reportId: number,
    reportTypeId: number,
    projectId: number
  ) => {
    const projectReportResposne = await projectReportService.projectReport(
      reportId,
      reportTypeId,
      projectId
    );
    setProjectReport(projectReportResposne.data);
  };

  const getProject = async (projectId: number) => {
    const projectResponse = await getProjectSingleService.single(projectId);
    setProject(projectResponse.data);
  };

  const refreshCompletedStudy = async () => {
    try {
      const velgridMatrixResponse = await velgridMatrixService.getById(id);
      setVelgridMatrix(velgridMatrixResponse.data);
      const projectResponse = await getProjectSingleService.single(
        velgridMatrixResponse.data.projectId
      );
      setProject(projectResponse.data);
      await execute(id);
      await getProjectReport(
        velgridMatrixResponse?.data.id ?? 0,
        velgridMatrixResponse?.data.reportTypeId ?? 0,
        velgridMatrixResponse?.data.projectId ?? 0
      );
      setMessagePsychrometric({ text: "", color: "info" });
    } catch (error: any) {
      log.error(error?.message?.exceptionMessage ?? "Something went wrong");
    }
  };

  const disableCompleteStudy =
    changeMatrixButton || stepContinuos === STEPSVALUES.STEP1;

  const showSteadyPopUp = useMemo(() => {
    return (
      showSteady && stepContinuos !== "step1" && values && values?.projectId > 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSteady, stepContinuos, values, values?.projectId]);

  const changeMatrix = () => {
    setButtonAction(true);
  };

  const preCompleteStudy = async () => {
    await executeRefresh(values.id, values);
  };

  const showSkeleton = isLoading || isSubmittingUpdate;

  const showUpdateButton = useMemo(
    () =>
      !!(
        user?.role !== ROLES.Training &&
        changeMatrixButton &&
        !disableStudy &&
        values?.x &&
        values?.y &&
        values?.x > 0 &&
        values?.y > 0
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [changeMatrixButton, disableStudy, values?.x, values?.y]
  );

  const trainingModeUpdateButton =
    user?.role === ROLES.Training &&
    changeMatrixButton &&
    values?.dimensionWidth &&
    values?.dimensionHeight &&
    values?.dimensionWidth < 23 &&
    values?.dimensionHeight < 23;

  const porcentajeDesign =
    values &&
    values?.percentajeOfDesign &&
    (values?.percentajeOfDesign >
      (values?.requiredCFMSystemToleranceTop
        ? values?.requiredCFMSystemToleranceTop
        : 0) +
        100 ||
      values?.percentajeOfDesign <
        100 -
          (values?.requiredCFMSystemToleranceBottom
            ? values?.requiredCFMSystemToleranceBottom
            : 0));

  const handlerDamperNa = (e: any) => {
    const { name, value } = e.target;

    const valueDamper = name.substring(0, name.length - 2);

    setValues({
      ...values,
      [name]: value,
      [valueDamper]: 0,
    });
  };

  const saveItemsMatrix = async () => {
    const matrixItems = items?.flat();
    await executeSave(matrixItems);
  };

  const totalHeatHandler = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
      sensibleHeat: 0,
      btuh: 0,
    });
  };

  const disableSwtiches =
    disableStep1 ||
    disableEditData ||
    disableStudy ||
    changeMatrixButton ||
    customValidation;

  return (
    <>
      <HeaderStudyPage
        headerStudiesPage={{
          code: "VEMR",
          system: values?.systemField,
          id: id,
        }}
        parentText="Project"
        parentLink="/"
      />
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <h3>System Configuration</h3>
              <Grid container spacing={5}>
                <Grid item xs={12}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showSkeleton}
                    disabled={true}
                    mode={mode}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showSkeleton}
                    disabled={disableStudy}
                    mode={mode}
                    maxLength={50}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormText
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showSkeleton}
                    disabled={disableStudy}
                    mode={mode}
                  ></FormText>
                </Grid>
                {dataReport && dataReport.parentReportId !== null && (
                  <SubStudyTypeSelect
                    disabled={disableStudy}
                    handleInputChange={handleInputChange}
                    setValues={setValues}
                    values={values}
                    showSkeleton={showSkeleton}
                  />
                )}
                <Grid item xs={12}>
                  <Divider />
                  <h3>Type of Terminal Device being tested</h3>
                  <Grid container mt={4}>
                    <Grid item xs={12}>
                      <FormRadioGroup
                        name="ductTypeId"
                        value={values?.ductTypeId}
                        items={typeOfTerminal}
                        row
                        onChange={onChangeTypeDevice}
                        defaultValue={selectRadioButton}
                        showSkeleton={showSkeleton}
                        disabled={disableStudy}
                        mode={mode}
                      />
                      {!disableStudy && (
                        <Grid item xs={12} mb={3}>
                          <Typography variant="h6" color={"primary"}>
                            Fields marked with an asterisk (*) are required for
                            matrix data enter.
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                    <DimensionsMatrix
                      typeReport="Velgrid"
                      values={values}
                      setValues={setValues}
                      disableStudy={disableStudy}
                      mode={mode}
                      systemOfMeasurement={systemOfMeasurement}
                      changeMatrix={changeMatrix}
                      executeRefresh={executeRefresh}
                      changeMatrixButton={changeMatrixButton}
                      dimensions={dimensions}
                      dataX={dataX}
                      dataY={dataY}
                      showSkeleton={showSkeleton}
                      errors={errors}
                      showUpdateButton={showUpdateButton}
                      customValidation={customValidation}
                      trainingModeUpdateButton={trainingModeUpdateButton}
                      setCustomValidation={setCustomValidation}
                      setErrors={setErrors}
                    />
                  </Grid>
                  <Divider mt={3} />
                  <h3>Condition of Test</h3>
                  <Grid container direction={"row"} spacing={2}>
                    <Grid item xs={12} md={2.9}>
                      <FormSelect
                        name={"conditionId"}
                        label={"Conditon of Test*"}
                        onChange={handleInputChange}
                        showSkeleton={showSkeleton}
                        value={values?.conditionId.toString()}
                        options={conditionOfTest}
                        disabled={disableStudy || changeMatrixButton}
                        mode={mode}
                        defaultValue={{
                          key: 0,
                          value: "Select Condition of Test*",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} md={9} ml={2}>
                      <Grid container>
                        <Grid container>
                          <Grid item xs={12} md={4}>
                            <FormCheckSwitch
                              name={"barometricalyCompensated"}
                              label={"Correct For Barometric Compensation"}
                              showSkeleton={showSkeleton}
                              onChange={handleInputChangeBarometric}
                              disabled={disableStep1}
                              value={values?.barometricalyCompensated}
                              mode={values?.isComplete ? mode : "read&Write"}
                            ></FormCheckSwitch>
                          </Grid>
                          <Grid item xs={12} md={4.5}>
                            <FormCheckSwitch
                              name={"temperatureCompensated"}
                              label={"Correct For Temperature Compensation"}
                              showSkeleton={showSkeleton}
                              onChange={handleInputChangeCompesation}
                              disabled={disableStep1}
                              mode={values?.isComplete ? mode : "read&Write"}
                              value={values?.temperatureCompensated}
                            ></FormCheckSwitch>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <FormCheckSwitch
                              name={"totalHeat"}
                              label={"Total Heat"}
                              showSkeleton={showSkeleton}
                              onChange={totalHeatHandler}
                              disabled={disableStep1}
                              mode={values?.isComplete ? mode : "read&Write"}
                              value={values?.totalHeat}
                            ></FormCheckSwitch>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {isNOC && (
                    <DampersComponent
                      values={values}
                      setValues={setValues}
                      disableStudy={disableStudy || changeMatrixButton}
                      mode={mode}
                      errors={errors}
                      setErrors={setErrors}
                      handleInputChange={handleInputChange}
                      isLoading={isLoading}
                    />
                  )}

                  {isOther && (
                    <Grid container mt={5}>
                      <Grid item xs={12}>
                        <FormTextArea
                          name={"comments"}
                          label={"Comments"}
                          value={values?.comments}
                          onChange={handleInputChange}
                          mode={mode}
                        ></FormTextArea>
                      </Grid>
                    </Grid>
                  )}
                  <Divider mt={3} mb={3} />
                  {values && (
                    <>
                      <Grid container>
                        <Grid item xs={12}>
                          <h3>Readings</h3>
                        </Grid>
                      </Grid>
                      <Grid container pt={2} spacing={0.5}>
                        {values?.barometricalyCompensated && (
                          <>
                            <Grid item xs={6} md={disableStudy ? 4 : 2.8}>
                              {!values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"localBarometricPressure"}
                                  label={`"Actual" Barometric Pressure (in Hg) Pb`}
                                  value={
                                    values?.localBarometricPressure?.toString() ??
                                    ""
                                  }
                                  onChange={handlerChangeData}
                                  showSkeleton={
                                    isLoading ||
                                    isSubmittingUpdate ||
                                    disableEditData
                                  }
                                  decimalScale={3}
                                  thousandSeparator={true}
                                  mode={mode}
                                ></FormNumeric2>
                              )}
                              {values?.isActualLocalBarometricPressure && (
                                <FormNumeric2
                                  name={"useSiteSpecificDefault"}
                                  label={"Local Barometric Pressure Default"}
                                  value={
                                    values?.useSiteSpecificDefault?.toString() ??
                                    ""
                                  }
                                  onChange={handlerChangeData}
                                  showSkeleton={showSkeleton}
                                  mode={"read"}
                                  decimalScale={2}
                                  thousandSeparator={true}
                                ></FormNumeric2>
                              )}
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Stack ml={2}>
                                <FormCheckSwitch
                                  name={"isActualLocalBarometricPressure"}
                                  label={"Use Site Specific Default"}
                                  showSkeleton={showSkeleton}
                                  onChange={handleChangeSpecificDefault}
                                  disabled={disableStudy || disableEditData}
                                  value={
                                    values?.isActualLocalBarometricPressure
                                  }
                                ></FormCheckSwitch>
                              </Stack>
                            </Grid>
                          </>
                        )}
                      </Grid>
                      <Grid item xs={12} pb={3} mt={3}>
                        {values && id && (
                          <VelgridGrid
                            id={id}
                            x={values?.x}
                            y={values?.y}
                            saveItems={saveItems}
                            disabled={
                              disableStep1 ||
                              disableEditData ||
                              changeMatrixButton
                            }
                            showSkeleton={isLoading || isSubmittingUpdate}
                            onBlur={calcTotal}
                            systemOfMeasurement={systemOfMeasurement}
                            values={values}
                            dataX={dataX}
                            setDataX={setDataX}
                            setDataY={setDataY}
                            dataY={dataY}
                            stepContinuos={stepContinuos}
                            showSteady={showSteady}
                            setShowSteady={setShowSteady}
                            setValues={setValues}
                            setChangeMatrixButton={setChangeMatrixButton}
                            changeMatrixButton={changeMatrixButton}
                            setButtonAction={setButtonAction}
                            buttonAction={buttonAction}
                            dimensions={dimensions}
                            setDimensions={setDimensions}
                            executeRefresh={executeRefresh}
                            customValidation={customValidation}
                            items={items}
                            setItems={setItems}
                          />
                        )}
                      </Grid>
                      <ResultsDataMatrix
                        systemOfMeasurement={systemOfMeasurement}
                        values={values}
                        modeReadWrite={mode}
                        handlerChangeData={handlerChangeData}
                        handleChangeRadio={handleChangeRadio}
                        disabel={disableSwtiches}
                        porcentajeDesign={porcentajeDesign}
                        disableStudy={disableStudy}
                        handleInputChange={handleInputChange}
                        errors={errors}
                        handlerTotalHeat={onBlurCalcPsycometrics}
                        showskeleton={showSkeleton}
                        setErrors={setErrors}
                      />
                    </>
                  )}
                </Grid>
                {values?.totalHeat && messagePsychrometric.text !== "" && (
                  <Grid item xs={12} justifyContent={"center"}>
                    <Stack mb={2}>
                      <Alert severity={messagePsychrometric.color}>
                        {messagePsychrometric.text}
                      </Alert>
                    </Stack>
                  </Grid>
                )}
                {stepContinuos === STEPSVALUES.STEP2 && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Caution: Make sure the AK Factor is correct for all
                      Meter/Devices being used.
                    </Alert>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={1}
                      stepsCount={3}
                      projectId={values?.projectId}
                      companyId={user?.companyId as number}
                      isSaving={showSkeleton}
                      reportName="Velgrid Matrix Test Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      disabled={disableStudy}
                      previousStepHandler={handleBackStep}
                      isComplete={values?.isComplete ?? true}
                      singleStep={true}
                      hideSaveButton={disableStudy}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    {project && projectReport && velgridMatrix && (
                      <CompleteStudyPopup
                        isDialogOpen={isDialogOpen}
                        setIsDialogOpen={setIsDialogOpen}
                        project={project}
                        projectReport={projectReport}
                        completed={velgridMatrix?.isComplete}
                        infectiousComplianceControl={
                          infectiousComplianceControl
                        }
                        parentStudy={parentStudy ?? null}
                        singleStep={disableCompleteStudy}
                        handlerCustomAccept={refreshCompletedStudy}
                        handlerPreCustomAccept={preCompleteStudy}
                      />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {showSteadyPopUp && (
        <SteadyStateDialog
          reportId={values?.id}
          reportTypeId={6}
          projectId={values?.projectId}
          reportCompleted={values?.isComplete}
          setDisableEditData={setDisableEditData}
        />
      )}
    </>
  );
};

export default VelgridReport;
