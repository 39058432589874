import axios from "src/utils/axios";
import { IVelgridMatrix } from "src/ts/interfaces/study/velgridMatrix";
const getById = (id: number) => {
  return axios.get<IVelgridMatrix>(`VelgridMatrix/${id}`);
};
const update = (id: number, outletMaster: IVelgridMatrix) => {
  return axios.put<IVelgridMatrix>(
    `VelgridMatrix/${id}?recalculate=true`,
    outletMaster
  );
};
const getReadings = (
  projectId: number,
  height: number,
  width: number,
  ductTypeId: number,
  sizeBase: number
) => {
  return axios.get<IVelgridMatrix>(
    `VelgridMatrix/Readings/${projectId}/${height}/${width}/${ductTypeId}/${sizeBase}`
  );
};
const velgridMatrixService = {
  getById,
  update,
  getReadings,
};
export default velgridMatrixService;
