import { Mode } from "src/ts/types";
import { Grid, Divider as MuiDivider } from "@mui/material";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { FormCheckSwitch } from "../formControls";
import { useSystemOfMeasurement } from "src/hooks";

interface Props {
  setValues: (val: any) => void;
  values: any;
  mode?: Mode;
  showSkeleton: boolean;
  disabled: boolean;
  handleInputChange: (e: any) => void;
  coilsSubStudy?: boolean;
}

const Divider = styled(MuiDivider)(spacing);

const SubStudyTypeSelect = ({
  setValues,
  values,
  mode,
  showSkeleton,
  disabled,
  handleInputChange,
  coilsSubStudy,
}: Props) => {
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const handlerSubStudy = (e: any) => {
    const { name } = e.target;

    setValues({
      ...values,
      supply: name === "supply",
      return: name === "return",
      exhaust: name === "exhaust",
      outsideAir: name === "outsideAir",
    });
  };

  return (
    <>
      {!coilsSubStudy && (
        <>
          <Grid item xs={12}>
            <Divider my={1} />
            <Grid container>
              <Grid item xs={3}>
                <FormCheckSwitch
                  label="Supply"
                  name="supply"
                  value={values.supply}
                  onChange={handlerSubStudy}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <FormCheckSwitch
                  label="Return"
                  name="return"
                  value={values.return}
                  onChange={handlerSubStudy}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <FormCheckSwitch
                  label="Exhaust"
                  name="exhaust"
                  value={values.exhaust}
                  onChange={handlerSubStudy}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}>
                <FormCheckSwitch
                  label="Outside Air"
                  name="outsideAir"
                  value={values.outsideAir}
                  onChange={handlerSubStudy}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
            <Grid container>
              <Grid item xs={3}></Grid>
              <Grid item xs={3} mt={2}>
                <FormCheckSwitch
                  label="Part of System Total"
                  name="systemTotal"
                  value={values.systemTotal}
                  onChange={handleInputChange}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3} mt={2}>
                <FormCheckSwitch
                  label="Part of Outlet total"
                  name="outletTotal"
                  value={values.outletTotal}
                  onChange={handleInputChange}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid item xs={3}></Grid>
            </Grid>
          </Grid>
        </>
      )}
      {coilsSubStudy && (
        <>
          <Grid item xs={12}>
            <Divider my={1} />
            <Grid container mt={2}>
              <Grid xs={3}>
                <FormCheckSwitch
                  label="Add All Coil Airflows to VAV/CAV/FPB System Total"
                  name="vavSystemTotal"
                  value={values.vavSystemTotal}
                  onChange={handleInputChange}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid xs={3}>
                <FormCheckSwitch
                  label="Add All Coil Airflows to VAV/CAV/FPB Outlet Total"
                  name="vavOutletTotal"
                  value={values.vavOutletTotal}
                  onChange={handleInputChange}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
              <Grid xs={3}>
                <FormCheckSwitch
                  label={`Add Total ${systemOfMeasurement.get(
                    "btuh"
                  )} to System Total`}
                  name="totalBTUSystem"
                  value={values.totalBTUSystem}
                  onChange={handleInputChange}
                  showSkeleton={showSkeleton}
                  mode={mode}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Divider my={1} />
    </>
  );
};

export default SubStudyTypeSelect;
