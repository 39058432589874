import { Alert, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormCheckSwitch, FormNumeric2 } from "src/components/formControls";

interface Props {
  values: any;
  setValues: (val: any) => void;
  mode: "read" | "read&Write";
  disableStudy: boolean;
  errors: any;
  setErrors: (val: any) => void;
  handleInputChange: (val: any) => void;
  isLoading: boolean;
}
interface IMessagePsychrometric {
  text: string;
  color: "info" | "warning" | "success" | "error";
}
const DampersComponent = ({
  values,
  setValues,
  mode,
  disableStudy,
  errors,
  setErrors,
  handleInputChange,
  isLoading,
}: Props) => {
  const [messageOaRa, setMessageOaRa] = useState<IMessagePsychrometric>({
    text: "",
    color: "info",
  });
  const [messageMaEa, setMessageMaEa] = useState<IMessagePsychrometric>({
    text: "",
    color: "info",
  });
  const [messageOaRaActual, setMessageOaRaActual] =
    useState<IMessagePsychrometric>({
      text: "",
      color: "info",
    });
  const [messageMaEaActual, setMessageMaEaActual] =
    useState<IMessagePsychrometric>({
      text: "",
      color: "info",
    });

  useEffect(() => {
    const messageDataVisual = () => {
      const oaRa =
        Number(values?.basOADamperposition) +
        Number(values?.basRADamperposition);
      const maExhuast =
        Number(values?.basMADamperposition) +
        Number(values?.basAHUExhaustDamper);
      oaRa > 100
        ? setMessageOaRa({
            text: "You cannot exceed 100% for Outside Air & Return Air",
            color: "error",
          })
        : oaRa < 100
        ? setMessageOaRa({
            text: "The sum of Outside Air & Return Air is not 100%",
            color: "error",
          })
        : setMessageOaRa({
            text: "",
            color: "error",
          });
      maExhuast > 100
        ? setMessageMaEa({
            text: "You cannot exceed 100% for Mixed Air & Exhaust Air",
            color: "error",
          })
        : maExhuast < 100
        ? setMessageMaEa({
            text: "The sum of Mixed Air & Exhaust Air is not 100%",
            color: "error",
          })
        : setMessageMaEa({
            text: "",
            color: "error",
          });
    };
    const messageDataActual = () => {
      const oaRa =
        Number(values?.visualOADamperposition) +
        Number(values?.visualRADamperposition);
      const maExhuast =
        Number(values?.visualMADamperposition) +
        Number(values?.visualAHUExhaustDamper);
      oaRa > 100
        ? setMessageOaRaActual({
            text: "You cannot exceed 100% for Outside Air & Return Air",
            color: "error",
          })
        : oaRa < 100
        ? setMessageOaRaActual({
            text: "The sum of Outside Air & Return Air is not 100%",
            color: "error",
          })
        : setMessageOaRaActual({
            text: "",
            color: "error",
          });
      maExhuast > 100
        ? setMessageMaEaActual({
            text: "You cannot exceed 100% for Mixed Air & Exhaust Air",
            color: "error",
          })
        : maExhuast < 100
        ? setMessageMaEaActual({
            text: " The sum of Mixed Air & Exhaust Air is not 100%",
            color: "error",
          })
        : setMessageMaEaActual({
            text: "",
            color: "error",
          });
    };

    messageDataActual();
    messageDataVisual();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values?.basOADamperposition,
    values.basRADamperposition,
    values?.basMADamperposition,
    values?.basAHUExhaustDamper,
    values?.visualOADamperposition,
    values.visualRADamperposition,
    values?.visualMADamperposition,
    values?.visualAHUExhaustDamper,
  ]);

  const oaDamperChkHandleChange = (
    e: any,
    opposite1: string,
    opposite2: string
  ) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
      [opposite1]: false,
      [opposite2]: 0,
    });
  };

  const disableInput = (naValue: boolean, naccValue: boolean) => {
    return disableStudy || naValue || naccValue;
  };

  const allBasNA = () =>
    values?.basOADamperpositionNotApplicable &&
    values?.basRADamperpositionNotApplicable &&
    values?.basMADamperpositionNotApplicable &&
    values?.basAHUExhaustDamperNotApplicable;

  const allBasNAc = () =>
    values?.basOADamperpositionNotAccessible &&
    values?.basRADamperpositionNotAccessible &&
    values?.basMADamperpositionNotAccessible &&
    values?.basAHUExhaustDamperNotAccessible;

  const allVisualNA = () =>
    values?.visualOADamperpositionNotApplicable &&
    values?.visualRADamperpositionNotApplicable &&
    values?.visualMADamperpositionNotApplicable &&
    values?.visualAHUExhaustDamperNotApplicable;

  const allVisualNAc = () =>
    values?.visualOADamperpositionNotAccessible &&
    values?.visualRADamperpositionNotAccessible &&
    values?.visualMADamperpositionNotAccessible &&
    values?.visualAHUExhaustDamperNotAccessible;

  const allNaVisual = (e: any) => {
    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
      basOADamperpositionNotApplicable: value,
      basRADamperpositionNotApplicable: value,
      basMADamperpositionNotApplicable: value,
      basAHUExhaustDamperNotApplicable: value,
      basOADamperpositionNotAccessible: false,
      basRADamperpositionNotAccessible: false,
      basMADamperpositionNotAccessible: false,
      basAHUExhaustDamperNotAccessible: false,
      basAllNotAccessible: false,
      basOADamperposition: 0,
      basRADamperposition: 0,
      basMADamperposition: 0,
      basAHUExhaustDamper: 0,
    });
  };

  const allNccVisual = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      basOADamperpositionNotApplicable: false,
      basRADamperpositionNotApplicable: false,
      basMADamperpositionNotApplicable: false,
      basAHUExhaustDamperNotApplicable: false,
      basOADamperpositionNotAccessible: value,
      basRADamperpositionNotAccessible: value,
      basMADamperpositionNotAccessible: value,
      basAHUExhaustDamperNotAccessible: value,
      basAllNotApplicable: false,
      basOADamperposition: 0,
      basRADamperposition: 0,
      basMADamperposition: 0,
      basAHUExhaustDamper: 0,
    });
  };

  const allNaActual = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      visualOADamperpositionNotApplicable: value,
      visualRADamperpositionNotApplicable: value,
      visualMADamperpositionNotApplicable: value,
      visualAHUExhaustDamperNotApplicable: value,
      visualOADamperpositionNotAccessible: false,
      visualRADamperpositionNotAccessible: false,
      visualMADamperpositionNotAccessible: false,
      visualAHUExhaustDamperNotAccessible: false,
      visualAllNotAccessible: false,
      visualOADamperposition: 0,
      visualRADamperposition: 0,
      visualMADamperposition: 0,
      visualAHUExhaustDamper: 0,
    });
  };

  const allNccActual = (e: any) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
      visualOADamperpositionNotApplicable: false,
      visualRADamperpositionNotApplicable: false,
      visualMADamperpositionNotApplicable: false,
      visualAHUExhaustDamperNotApplicable: false,
      visualOADamperpositionNotAccessible: value,
      visualRADamperpositionNotAccessible: value,
      visualMADamperpositionNotAccessible: value,
      visualAHUExhaustDamperNotAccessible: value,
      visualAllNotApplicable: false,
      visualOADamperposition: 0,
      visualRADamperposition: 0,
      visualMADamperposition: 0,
      visualAHUExhaustDamper: 0,
    });
  };

  const oaRaNaNcc =
    !values?.basOADamperpositionNotApplicable &&
    !values?.basOADamperpositionNotAccessible &&
    !values?.basRADamperpositionNotApplicable &&
    !values?.basRADamperpositionNotAccessible;

  const maEaNaNcc =
    !values?.basMADamperpositionNotApplicable &&
    !values?.basMADamperpositionNotAccessible &&
    !values?.basAHUExhaustDamperNotApplicable &&
    !values?.basAHUExhaustDamperNotAccessible;

  const oaRaNaNccActual =
    !values?.visualOADamperpositionNotApplicable &&
    !values?.visualOADamperpositionNotAccessible &&
    !values?.visualRADamperpositionNotApplicable &&
    !values?.visualRADamperpositionNotAccessible;

  const maEaNaNccActual =
    !values?.visualMADamperpositionNotApplicable &&
    !values?.visualMADamperpositionNotAccessible &&
    !values?.visualAHUExhaustDamperNotApplicable &&
    !values?.visualAHUExhaustDamperNotAccessible;

  return (
    <>
      <Grid container spacing={4} mt={1}>
        <Grid item xs={12} md={6} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Damper Position (BAS)
            </Typography>
            <Grid container>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label="All not applicable"
                  name="basAllNotApplicable"
                  value={allBasNA()}
                  onChange={allNaVisual}
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label="All not accessible"
                  name="basAllNotAccessible"
                  value={allBasNAc()}
                  onChange={allNccVisual}
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable O/A"}
                  name="basOADamperpositionNotApplicable"
                  value={values?.basOADamperpositionNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basOADamperpositionNotAccessible",
                      "basOADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible O/A"}
                  name={`basOADamperpositionNotAccessible`}
                  value={values?.basOADamperpositionNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basOADamperpositionNotApplicable",
                      "basOADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"basOADamperposition"}
                  label={"Outside/Air"}
                  value={values?.basOADamperposition?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % O/A"
                  disabled={disableInput(
                    values?.basOADamperpositionNotApplicable,
                    values?.basOADamperpositionNotAccessible
                  )}
                  mode={mode}
                  error={errors.basOADamperposition}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable R/A"}
                  name="basRADamperpositionNotApplicable"
                  value={values?.basRADamperpositionNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basRADamperpositionNotAccessible",
                      "basRADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible R/A"}
                  name={`basRADamperpositionNotAccessible`}
                  value={values?.basRADamperpositionNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basRADamperpositionNotApplicable",
                      "basRADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"basRADamperposition"}
                  label={"Return/Air"}
                  value={values?.basRADamperposition?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % R/A"
                  disabled={
                    disableInput(
                      values?.basRADamperpositionNotApplicable,
                      values?.basRADamperpositionNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.basRADamperposition}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable M/A"}
                  name="basMADamperpositionNotApplicable"
                  value={values?.basMADamperpositionNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basMADamperpositionNotAccessible",
                      "basMADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible M/A"}
                  name={`basMADamperpositionNotAccessible`}
                  value={values?.basMADamperpositionNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basMADamperpositionNotApplicable",
                      "basMADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"basMADamperposition"}
                  label={"Mixed/Air"}
                  value={values?.basMADamperposition?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % M/A"
                  disabled={
                    disableInput(
                      values?.basMADamperpositionNotApplicable,
                      values?.basMADamperpositionNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.basMADamperposition}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable E/A"}
                  name="basAHUExhaustDamperNotApplicable"
                  value={values?.basAHUExhaustDamperNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basAHUExhaustDamperNotAccessible",
                      "basAHUExhaustDamper"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible E/A"}
                  name={`basAHUExhaustDamperNotAccessible`}
                  value={values?.basAHUExhaustDamperNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "basAHUExhaustDamperNotApplicable",
                      "basAHUExhaustDamper"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"basAHUExhaustDamper"}
                  label={"Exhuast/Air"}
                  value={values?.basAHUExhaustDamper?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % E/A"
                  disabled={
                    disableInput(
                      values?.basAHUExhaustDamperNotApplicable,
                      values?.basAHUExhaustDamperNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.basAHUExhaustDamper}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            {messageOaRa.text !== "" && oaRaNaNcc && !disableStudy && (
              <Grid item xs={12} justifyContent={"center"} mt={1}>
                <Stack mb={2}>
                  <Alert severity={messageOaRa.color}>{messageOaRa.text}</Alert>
                </Stack>
              </Grid>
            )}
            {messageMaEa.text !== "" && maEaNaNcc && !disableStudy && (
              <Grid item xs={12} justifyContent={"center"} mt={1}>
                <Stack mb={2}>
                  <Alert severity={messageMaEa.color}>{messageMaEa.text}</Alert>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Visual Damper Position
            </Typography>
            <Grid container>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label="All not applicable"
                  name="visualAllNotApplicable"
                  value={allVisualNA()}
                  onChange={allNaActual}
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label="All not accessible"
                  name="visualAllNotAccessible"
                  value={allVisualNAc()}
                  onChange={allNccActual}
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable O/A"}
                  name="visualOADamperpositionNotApplicable"
                  value={values?.visualOADamperpositionNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualOADamperpositionNotAccessible",
                      "visualOADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible O/A"}
                  name={`visualOADamperpositionNotAccessible`}
                  value={values?.visualOADamperpositionNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualOADamperpositionNotApplicable",
                      "visualOADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"visualOADamperposition"}
                  label={"Outside/Air"}
                  value={values?.visualOADamperposition?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % O/A"
                  disabled={
                    disableInput(
                      values?.visualOADamperpositionNotApplicable,
                      values?.visualOADamperpositionNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.visualOADamperposition}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable R/A"}
                  name="visualRADamperpositionNotApplicable"
                  value={values?.visualRADamperpositionNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualRADamperpositionNotAccessible",
                      "visualRADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible R/A"}
                  name={`visualRADamperpositionNotAccessible`}
                  value={values?.visualRADamperpositionNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualRADamperpositionNotApplicable",
                      "visualRADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"visualRADamperposition"}
                  label={"Return/Air"}
                  value={values?.visualRADamperposition?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % R/A"
                  disabled={
                    disableInput(
                      values?.visualRADamperpositionNotApplicable,
                      values?.visualRADamperpositionNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.visualRADamperposition}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable M/A"}
                  name="visualMADamperpositionNotApplicable"
                  value={values?.visualMADamperpositionNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualMADamperpositionNotAccessible",
                      "visualMADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible M/A"}
                  name={`visualMADamperpositionNotAccessible`}
                  value={values?.visualMADamperpositionNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualMADamperpositionNotApplicable",
                      "visualMADamperposition"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"visualMADamperposition"}
                  label={"Mixed/Air"}
                  value={values?.visualMADamperposition?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % M/A"
                  disabled={
                    disableInput(
                      values?.visualMADamperpositionNotApplicable,
                      values?.visualMADamperpositionNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.visualMADamperposition}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not applicable E/A"}
                  name="visualAHUExhaustDamperNotApplicable"
                  value={values?.visualAHUExhaustDamperNotApplicable}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualAHUExhaustDamperNotAccessible",
                      "visualAHUExhaustDamper"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormCheckSwitch
                  label={"Not accessible E/A"}
                  name={`visualAHUExhaustDamperNotAccessible`}
                  value={values?.visualAHUExhaustDamperNotAccessible}
                  onChange={(e: any) =>
                    oaDamperChkHandleChange(
                      e,
                      "visualAHUExhaustDamperNotApplicable",
                      "visualAHUExhaustDamper"
                    )
                  }
                  mode={mode}
                  disabled={disableStudy}
                />
              </Grid>
              <Grid item xs={4}>
                <FormNumeric2
                  name={"visualAHUExhaustDamper"}
                  label={"Exhuast/Air"}
                  value={values?.visualAHUExhaustDamper?.toString() ?? ""}
                  onChange={handleInputChange}
                  showSkeleton={isLoading}
                  decimalScale={0}
                  thousandSeparator={false}
                  suffix=" % E/A"
                  disabled={
                    disableInput(
                      values?.visualAHUExhaustDamperNotApplicable,
                      values?.visualAHUExhaustDamperNotAccessible
                    ) || disableStudy
                  }
                  mode={mode}
                  error={errors.visualAHUExhaustDamper}
                  maxValue={101}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            {messageOaRaActual.text !== "" && oaRaNaNccActual && !disableStudy && (
              <Grid item xs={12} justifyContent={"center"} mt={1}>
                <Stack mb={2}>
                  <Alert severity={messageOaRaActual.color}>
                    {messageOaRaActual.text}
                  </Alert>
                </Stack>
              </Grid>
            )}
            {messageMaEaActual.text !== "" && maEaNaNccActual && !disableStudy && (
              <Grid item xs={12} justifyContent={"center"} mt={1}>
                <Stack mb={2}>
                  <Alert severity={messageMaEaActual.color}>
                    {messageMaEaActual.text}
                  </Alert>
                </Stack>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default DampersComponent;
