import {
  CardContent,
  Grid,
  Stack,
  Divider as MuiDivider,
  Card as MuiCard,
  Typography,
  IconButton,
} from "@mui/material";

import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  FormCheckSwitch,
  FormNumeric2,
  FormSelect,
  FormText,
  FormTextArea,
} from "src/components/formControls";
import useFormTyped from "src/hooks/useFormTyped";
import { IKeyValue } from "src/ts/interfaces";
import {
  useAsyncMutation,
  useAsyncQuery,
  useAuth,
  useEffectOnce,
  useSystemOfMeasurement,
} from "src/hooks";
import { IProjectReport } from "src/ts/interfaces/project/projectDto";
import projectReportService from "src/services/study/projectReportService";
import { CatalogService } from "src/services";
import { steps } from "./const/const";
import coilsService from "src/services/study/coilsService";
import { ICoils } from "src/ts/interfaces/study/coils";
import getProjectService from "src/services/project/getProjectService";
import {
  AddCircleOutline,
  CloudDownloadSharp,
  RemoveCircleOutline,
} from "@mui/icons-material";
import { FooterButtons } from "../components/FooterButtons";
import StudyWizard from "../components/StudyWizard";
import { Validator } from "src/ts/types";
import { IGlycolFactor } from "src/ts/interfaces/glycolFactor";
import HeaderStudyPage from "src/components/page/HeaderStudyPage";
const initialValues: ICoils = {
  id: 0,
  projectId: 0,
  projectName: "",
  reportTypeId: 0,
  systemField: "",
  roomAreaServed: "",
  description: "",
  pumpSystemTotal: false,
  pumpCoilTotal: false,
  totalBTUSystem: false,
  containsBlycol: false,
  glycolTypeOther: "",
  glycolPercentage: "",
  glycolPercentageActual: "",
  glycolFactorGPMFlows: false,
  totalDesignGpm: 0,
  totalActualGpm: 0,
  glycolFactor: "",
  glycolFreezeProtectionValueSign: "",
  glycolFreezeProtectionValueActualSign: "",
  glycolDataSource: "",
  fillNext: false,
  devicePDWPSI: false,
  devicePDWFeet: false,
  deviceCoefficient: false,
  airflowFlowingCoilsId: 0,
  nameOfPerson: "",
  title: "",
  company: "",
  dateWhenTold: "",
  designWaterTemperature: false,
  actualWaterTemperature: false,
  designPSIWater: false,
  actualPSIWater: false,
  designAirflowDB: false,
  actualAirflowDB: false,
  designAirflow: false,
  actualAirflow: false,
  rh: false,
  wb: false,
  dp: false,
  designAirflowSP: false,
  actualAirflowSP: false,
  designBTUH: false,
  actualBTUH: false,
  designBTUHTotal: false,
  actualBTUHTotal: false,
  systemSteadyThisTest: false,
  systemSteadyWillBeMonitored: false,
  step: 0,
  isComplete: false,
  statusId: 0,
  createdDate: new Date(),
  createdBy: "",
  updatedDate: new Date(),
  updatedBy: "",
  userAuth: "",
  userName: "",
  mandatoryMinimum: false,
  numberOfItems: 0,
  testMode: "",
};

const Divider = styled(MuiDivider)(spacing);
const Card = styled(MuiCard)(spacing);

const CoilsStep1 = () => {
  const { user } = useAuth();
  const { systemOfMeasurement } = useSystemOfMeasurement(true);
  const params = useParams<{ id: string }>();
  const id = parseInt(params?.id === undefined ? "0" : params?.id);
  const [airflowFlowingCoils, setAirflowFlowingCoils] = useState<
    IKeyValue<number, string>[]
  >([]);
  const [glycolType, setGlycolType] = useState<IKeyValue<number, string>[]>([]);
  const [isEnergyProcess, setIsEnergyProcess] = useState(false);
  let navigate = useNavigate();

  const { execute: executeGetGlycolFactor, data: dataGlycolFactor } =
    useAsyncQuery<IGlycolFactor[]>(CatalogService.getAll);

  const { execute: executeUpdate, isSubmitting: isSubmittingUpdate } =
    useAsyncMutation<number>(coilsService.update, {
      successfulMessage: "Report was updated successfully",
      onSuccess: (result) => {
        setValues({
          ...result,
        });
      },
    });

  const validate: any = (fieldValues = values) => {
    let temp: Record<string, string> = { ...errors };

    if (fieldValues.containsBlycol) {
      temp.glycolTypeId = new Validator(fieldValues, "glycolTypeId")
        .selectedOption(null, "Design Glycol Type is required")
        .toString();
    }

    temp.airflowFlowingCoilsId = new Validator(
      fieldValues,
      "airflowFlowingCoilsId"
    )
      .selectedOption(0, "Airflow Response is required")
      .toString();

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange } =
    useFormTyped<ICoils>(initialValues, false, validate);

  const { execute, isLoading } = useAsyncQuery<ICoils>(coilsService.getById, {
    onSuccess: (result) => {
      setValues(result);
      const getData = async () => {
        const measurementSystemResponse =
          await getProjectService.getMeasurementSystem(result?.projectId);
        systemOfMeasurement.setSystem(measurementSystemResponse.data);
        await executeReport(id, result?.reportTypeId === 4 ? "COTD" : "COAH");
      };
      getData();
    },
  });

  const { execute: executeReport, data: dataReport } =
    useAsyncQuery<IProjectReport>(projectReportService.report);

  useEffectOnce(() => {
    const getData = async () => {
      const response = await CatalogService.getAllDropdown(
        "AirflowFlowingCoils"
      );
      setAirflowFlowingCoils(response);

      const responseGlycolType = await CatalogService.getAllDropdown(
        "GlycolType"
      );
      setGlycolType(
        responseGlycolType.filter((item) => item.value !== "Other")
      );
    };

    getData();
  });

  useEffect(() => {
    const getData = async () => {
      await execute(id);
      await executeGetGlycolFactor("GlycolFactor");
    };

    if (id !== 0) getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.glycolTypeId, values?.airflowFlowingCoilsId]);

  const nextStepHandler = async () => {
    if (!disableStudy) {
      if (!validate()) return;
      await executeUpdate(values.id, values);
    }
    navigate(`/studies/Coils/step2/${id}/`);
  };

  useEffect(() => {
    setIsEnergyProcess(dataReport?.parentReportTypeId === 18);
  }, [dataReport?.parentReportTypeId]);

  const handleSave = async () => {
    if (!validate()) return;
    await executeUpdate(values.id, values);
  };

  const handleInputChangeGlycol = (e: any) => {
    const { name, value } = e.target;
    //removing comma separator for numeric values
    let newValue = value;
    setValues({
      ...values,
      [name]: newValue,
    });
    validate();
  };

  const nearestGlycolPercent = (e: any) => {
    let result: IGlycolFactor = {
      id: 0,
      glycolType: "",
      glycolPercent: 0,
      waterConstantFactor: 0,
      freezeProtectionValue: 0,
      glycolHeatTransferControlValue: 0,
      freezeProtectionValueSelectable: false,
      specificGravity: 0,
    };
    const dataGlycolFactorFiltered = dataGlycolFactor.filter(
      (item) =>
        item.glycolType ===
        (values?.glycolTypeId === 1 ? "Ethylene Glycol" : "Propylene Glycol")
    );
    dataGlycolFactorFiltered.forEach((item, index) => {
      const nextItem = dataGlycolFactorFiltered[index + 1];
      if (!!result.id) return; //This line prevents the code to get an undefined item if it's running on the edge of the array
      if (
        parseFloat(e.target.value) >= item.glycolPercent &&
        parseFloat(e.target.value) <= nextItem.glycolPercent
      ) {
        const { glycolPercent } = item;
        const glycolPercentHigh = nextItem.glycolPercent;
        const gap = Math.abs(glycolPercentHigh - glycolPercent);
        const diff = Math.abs(parseFloat(e.target.value) - glycolPercent);
        result = diff <= gap / 2 ? item : nextItem;
      }
    });
    const glycolHeatTransferControlValueValues =
      systemOfMeasurement.measurementSystem === "metricSystem"
        ? result.glycolHeatTransferControlValueIS
        : result.glycolHeatTransferControlValue;

    setValues({
      ...values,
      [e.target.name]: e.target.value,
      glycolHeatTransferControlValue: glycolHeatTransferControlValueValues,
    });
  };

  const nearestGlycolFreezeProtectionValue = (e: any) => {
    if (!values?.glycolPercentage) {
      let protectionValue = e.target.value;
      protectionValue =
        values?.glycolFreezeProtectionValueSign === "-"
          ? protectionValue * -1
          : protectionValue;
      const lowest =
        systemOfMeasurement.measurementSystem === "metricSystem" ? -51.1 : -60;
      const highest =
        systemOfMeasurement.measurementSystem === "metricSystem" ? 0 : 32;
      let result: IGlycolFactor = {
        id: 0,
        glycolType: "",
        glycolPercent: 0,
        waterConstantFactor: 0,
        freezeProtectionValue: 0,
        glycolHeatTransferControlValue: 0,
        freezeProtectionValueSelectable: false,
        specificGravity: 0,
      };

      const dataGlycolFactorFiltered = dataGlycolFactor.filter(
        (item) =>
          item.glycolType ===
          (values?.glycolTypeId === 1 ? "Ethylene Glycol" : "Propylene Glycol")
      );
      if (parseFloat(protectionValue) >= highest) {
        result = dataGlycolFactorFiltered[0];
      } else if (parseFloat(protectionValue) <= lowest) {
        result = dataGlycolFactorFiltered[12];
      } else {
        dataGlycolFactorFiltered.forEach((item, index) => {
          const nextItem = dataGlycolFactorFiltered[index + 1];
          if (index < dataGlycolFactorFiltered.length - 1) {
            const freezeProtectionValueHig =
              (systemOfMeasurement.measurementSystem === "metricSystem"
                ? item.freezeProtectionValueIS
                : item.freezeProtectionValue) || 0;
            const freezeProtectionValueLow =
              (systemOfMeasurement.measurementSystem === "metricSystem"
                ? nextItem.freezeProtectionValueIS
                : nextItem.freezeProtectionValue) || 0;
            if (
              parseFloat(protectionValue) <= freezeProtectionValueHig &&
              parseFloat(protectionValue) >= freezeProtectionValueLow
            ) {
              const gap = Math.abs(
                freezeProtectionValueHig - freezeProtectionValueLow
              );
              const diff = Math.abs(
                freezeProtectionValueHig - parseFloat(protectionValue)
              );
              result = diff <= gap / 2 ? item : nextItem;
            }
          }
        });
      }
      setValues({
        ...values,
        [e.target.name]: e.target.value,
        glycolHeatTransferControlValue:
          systemOfMeasurement.measurementSystem === "metricSystem"
            ? result.glycolHeatTransferControlValueIS
            : result.glycolHeatTransferControlValue,
      });
      return result;
    } else {
      setValues({
        ...values,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleGlycolFreezeProtectionValueSignChange = (sign: string) => {
    if (!values?.glycolPercentage && !!values?.glycolFreezeProtectionValue) {
      let protectionValue =
        typeof values.glycolFreezeProtectionValue === "string"
          ? parseFloat(values.glycolFreezeProtectionValue)
          : values.glycolFreezeProtectionValue;
      protectionValue = sign === "-" ? protectionValue * -1 : protectionValue;
      const lowest =
        systemOfMeasurement.measurementSystem === "metricSystem" ? -51.1 : -60;
      const highest =
        systemOfMeasurement.measurementSystem === "metricSystem" ? 0 : 32;
      let result: IGlycolFactor = {
        id: 0,
        glycolType: "",
        glycolPercent: 0,
        waterConstantFactor: 0,
        freezeProtectionValue: 0,
        glycolHeatTransferControlValue: 0,
        freezeProtectionValueSelectable: false,
        specificGravity: 0,
      };

      const dataGlycolFactorFiltered = dataGlycolFactor.filter(
        (item) =>
          item.glycolType ===
          (values?.glycolTypeId === 1 ? "Ethylene Glycol" : "Propylene Glycol")
      );
      if (protectionValue >= highest) {
        result = dataGlycolFactorFiltered[0];
      } else if (protectionValue <= lowest) {
        result = dataGlycolFactorFiltered[12];
      } else {
        dataGlycolFactorFiltered.forEach((item, index) => {
          const nextItem = dataGlycolFactorFiltered[index + 1];
          if (index < dataGlycolFactorFiltered.length - 1) {
            const freezeProtectionValueHig =
              (systemOfMeasurement.measurementSystem === "metricSystem"
                ? item.freezeProtectionValueIS
                : item.freezeProtectionValue) || 0;
            const freezeProtectionValueLow =
              (systemOfMeasurement.measurementSystem === "metricSystem"
                ? nextItem.freezeProtectionValueIS
                : nextItem.freezeProtectionValue) || 0;
            if (
              protectionValue <= freezeProtectionValueHig &&
              protectionValue >= freezeProtectionValueLow
            ) {
              const gap = Math.abs(
                freezeProtectionValueHig - freezeProtectionValueLow
              );
              const diff = Math.abs(freezeProtectionValueHig - protectionValue);
              result = diff <= gap / 2 ? item : nextItem;
            }
          }
        });
      }
      setValues({
        ...values,
        glycolFreezeProtectionValueSign: sign,
        glycolHeatTransferControlValue:
          systemOfMeasurement.measurementSystem === "metricSystem"
            ? result.glycolHeatTransferControlValueIS
            : result.glycolHeatTransferControlValue,
      });
      return result;
    } else {
      setValues({
        ...values,
        glycolFreezeProtectionValueSign: sign,
      });
    }
  };

  const disableStudy = useMemo(() => {
    return (
      values?.isComplete ||
      (dataReport?.isInUse && dataReport?.isInUseById !== user?.userId) ||
      dataReport?.isPartOfProject === false
    );
  }, [
    user?.userId,
    values?.isComplete,
    dataReport?.isInUse,
    dataReport?.isInUseById,
    dataReport?.isPartOfProject,
  ]);

  const showskeleton = useMemo(
    () => isLoading || isSubmittingUpdate,
    [isLoading, isSubmittingUpdate]
  );

  return (
    <>
      {dataReport?.id && (
        <HeaderStudyPage
          parentText="Project"
          parentLink={`/app/ProjectStudies/${values?.projectId}/${user?.companyId}`}
          headerStudiesPage={{
            code: dataReport?.reportTypeId === 4 ? "COTD" : "COAH",
            system: values?.systemField,
            id: id,
          }}
        />
      )}
      <Divider my={6} />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <StudyWizard
                steps={steps}
                activeStep={0}
                projectId={values?.projectId}
                reportId={id}
                reportTypeId={values?.reportTypeId}
              ></StudyWizard>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card mb={1}>
            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} md={6}>
                  <FormText
                    name="projectName"
                    label="Project Name"
                    value={values?.projectName}
                    onChange={handleInputChange}
                    error={errors.projectName}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormText
                    name="systemField"
                    label="System"
                    value={values?.systemField}
                    onChange={handleInputChange}
                    error={errors.systemField}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                    maxLength={50}
                  />
                </Grid>
                {values.reportTypeId === 17 && (
                  <Grid item xs={12} md={6}>
                    <FormText
                      name="roomAreaServed"
                      label="Room/Area Served"
                      value={values?.roomAreaServed}
                      onChange={handleInputChange}
                      error={errors.roomAreaServed}
                      showSkeleton={showskeleton}
                      disabled={disableStudy}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <FormTextArea
                    name={"description"}
                    label={"Description"}
                    value={values?.description}
                    placeholder={""}
                    onChange={handleInputChange}
                    error={errors.description}
                    showSkeleton={showskeleton}
                    disabled={disableStudy}
                  ></FormTextArea>
                </Grid>
                {!!dataReport?.parentReportId && (
                  <>
                    <Grid item xs={12} md={4}>
                      <FormCheckSwitch
                        name={"pumpSystemTotal"}
                        label={"Add All Coil Flows to Pump System Total"}
                        value={values?.pumpSystemTotal}
                        disabled={disableStudy}
                        onChange={handleInputChange}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormCheckSwitch
                        name={"pumpCoilTotal"}
                        label={"Add All Coil Flows to Pump Coil Total"}
                        value={values?.pumpCoilTotal}
                        disabled={disableStudy}
                        onChange={handleInputChange}
                      ></FormCheckSwitch>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <FormCheckSwitch
                        name={"totalBTUSystem"}
                        label={`Add Total ${systemOfMeasurement.get(
                          "btuh"
                        )} to System Total`}
                        value={values?.totalBTUSystem}
                        disabled={disableStudy || isEnergyProcess}
                        onChange={handleInputChange}
                      ></FormCheckSwitch>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} md={3}>
                  <FormCheckSwitch
                    name={"fillNext"}
                    label={"Automatically Fill Next Row & Item"}
                    value={values?.fillNext}
                    disabled={disableStudy || isEnergyProcess}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormCheckSwitch
                    name={"devicePDWPSI"}
                    label={`Add Device ${systemOfMeasurement.get(
                      "pdwpsi"
                    )} column`}
                    value={values?.devicePDWPSI}
                    disabled={disableStudy || isEnergyProcess}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormCheckSwitch
                    name={"devicePDWFeet"}
                    label={`Add Device PDW Feet' ${systemOfMeasurement.get(
                      "pdwfeet"
                    )} column`}
                    value={values?.devicePDWFeet}
                    disabled={disableStudy || isEnergyProcess}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormCheckSwitch
                    name={"deviceCoefficient"}
                    label={`Add Device ${systemOfMeasurement.get(
                      "cv"
                    )}/Coefficient column`}
                    value={values?.deviceCoefficient}
                    disabled={disableStudy}
                    onChange={handleInputChange}
                  ></FormCheckSwitch>
                </Grid>
                <Grid item xs={12} md={3}>
                  <FormCheckSwitch
                    name={"containsBlycol"}
                    label={`System Contains Glycol`}
                    value={values?.containsBlycol}
                    disabled={disableStudy}
                    onChange={handleInputChangeGlycol}
                  ></FormCheckSwitch>
                </Grid>
                {values?.containsBlycol && (
                  <Grid item xs={12} md={3}>
                    <FormSelect
                      name={"glycolTypeId"}
                      label={"Design Glycol Type"}
                      value={(values?.glycolTypeId || "null")?.toString()}
                      onChange={handleInputChange}
                      error={errors.glycolTypeId}
                      options={glycolType}
                      defaultValue={{
                        key: "null",
                        value: "Select Glycol Type",
                      }}
                      disabled={disableStudy}
                    ></FormSelect>
                  </Grid>
                )}
                {values?.containsBlycol && (
                  <>
                    <Grid item xs={12} md={2}>
                      <FormNumeric2
                        name={"glycolPercentage"}
                        label={"Design Glycol % by Volume"}
                        value={values?.glycolPercentage?.toString()}
                        onBlur={nearestGlycolPercent}
                        disabled={disableStudy || !values?.glycolTypeId}
                        maxValue={101}
                        decimalScale={3}
                        thousandSeparator={true}
                        allowNegative={false}
                      ></FormNumeric2>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Grid container>
                        <Grid item xs={1} textAlign={"right"}>
                          <Typography variant="h4" pt={1} pr={2}>
                            {values?.glycolFreezeProtectionValueSign === "-"
                              ? "-"
                              : "+"}
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <FormNumeric2
                            name={"glycolFreezeProtectionValue"}
                            label={`Design Glycol Freeze Protection Value ${systemOfMeasurement.get(
                              "temperature"
                            )}`}
                            value={values?.glycolFreezeProtectionValue?.toString()}
                            onBlur={nearestGlycolFreezeProtectionValue}
                            disabled={disableStudy}
                            decimalScale={3}
                            thousandSeparator={true}
                          ></FormNumeric2>
                        </Grid>
                        <Grid item xs={2} textAlign={"right"}>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleGlycolFreezeProtectionValueSignChange("+");
                            }}
                          >
                            <AddCircleOutline />
                          </IconButton>
                          <IconButton
                            size="small"
                            onClick={() => {
                              handleGlycolFreezeProtectionValueSignChange("-");
                            }}
                          >
                            <RemoveCircleOutline />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle2">
                    Note: It is recommended the airflow flowing through the
                    coil(s) listed below be balanced with known flows available
                    prior to recording heat transfer data.
                  </Typography>
                  <Typography pt={2}>
                    <Grid container>
                      <Grid item xs={9}>
                        <Typography variant="subtitle2" pt={2} pr={2}>
                          Airflow(s) that effect the coils was balanced with
                          "known flows/values" prior to performing detailed Coil
                          Heat Transfer Performance Recordings:
                        </Typography>
                      </Grid>
                      <Grid item xs={2} sx={{ minWidth: "200px" }}>
                        <FormSelect
                          name={"airflowFlowingCoilsId"}
                          label={""}
                          value={values?.airflowFlowingCoilsId.toString()}
                          onChange={handleInputChange}
                          options={airflowFlowingCoils}
                          error={errors.airflowFlowingCoilsId}
                          defaultValue={{
                            key: 0,
                            value: "Select Airflow Response",
                          }}
                          disabled={disableStudy}
                        ></FormSelect>
                      </Grid>
                    </Grid>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                  >
                    <FooterButtons
                      activeStep={0}
                      stepsCount={steps.length}
                      projectId={values?.projectId}
                      companyId={user?.companyId}
                      isSaving={isSubmittingUpdate}
                      disabled={isLoading || isSubmittingUpdate || disableStudy}
                      reportName="Coil AHU Mixed Air Report"
                      saveHandler={handleSave}
                      nextStepHandler={nextStepHandler}
                      isComplete={values?.isComplete ?? true}
                    />
                  </Stack>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default CoilsStep1;
