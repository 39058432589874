import {
  Breadcrumbs as MuiBreadcrumbs,
  Grid,
  Typography,
  Link,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import YoutubeLink from "./YoutubeLink";

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
interface IHeaderPage {
  title?: string;
  parentText: string;
  parentLink: string;
  actionSection?: React.ReactNode;
  infoSection?: React.ReactNode;
  pageYoutube?: string;
  reportTypeYoutube?: string;
}

const HeaderPage = ({
  actionSection,
  title,
  parentText,
  parentLink,
  pageYoutube,
  reportTypeYoutube,
  infoSection,
}: IHeaderPage) => {
  return (
    <>
      <Helmet title={title} />
      <Grid justifyContent="space-between" container spacing={2}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            {title}{" "}
            <YoutubeLink
              page={pageYoutube}
              reportType={reportTypeYoutube}
            ></YoutubeLink>
          </Typography>

          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            {/* <Link component={NavLink} to={parentLink}> */}
            <Typography> {parentText}</Typography>
            {/* </Link> */}
            <Typography>{title}</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>{infoSection}</Grid>
        <Grid item>{actionSection}</Grid>
      </Grid>
    </>
  );
};

export default HeaderPage;
